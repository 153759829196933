import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DataService } from 'src//app/services/data.service';
import { PriceService } from 'src/app/services/price.service';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import * as global from 'src/app/globals';
import * as uuid from 'uuid';
import { Subject, Subscription, Observable } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { DynamicPopsModalComponent } from '../dynamic-pops-modal/dynamic-pops-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { ExternalService } from 'src/app/services/external.service';
import { PlatformLocation } from '@angular/common';
import { CategoryhomeComponent } from 'src/app/category/categoryhome/categoryhome.component';
import { map, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RegisterComponent } from 'src/app/user/register/register.component';
import { DynamicPopupsModalComponent } from 'src/app/account/dynamic-popups-modal/dynamic-popups-modal.component';
import { ComponentCanDeactivate } from 'src/app/guard/auth-guard.service';
import { ToastrService } from 'ngx-toastr';
declare const AdyenCheckout: any;
declare var $: any;
declare var moment: any;
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
  FormArray,
  NgControl,
} from '@angular/forms';

import { StripeService } from 'ngx-stripe';
import {
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { threadId } from 'worker_threads';
// import { async } from '@angular/core/testing';
import { PwaService } from 'src/app/services/pwa.service';

@Component({
    selector: 'app-cartpage',
    templateUrl: './cartpage.component.html',
    styleUrls: ['./cartpage.component.scss'],
    standalone: false
})
export class CartpageComponent implements OnInit, ComponentCanDeactivate {
  billingAddressEditable = false;
  oldBillingData: any = {};
  oldShippingData: any = {};
  billingButtonEnable: any = true;
  shippingButtonEnable: any = true;
  billingAddressData = [];
  showStripeLoader: boolean = false;
  pageSlug: string;
  selectedCartQuantity: number;
  shippingType: boolean;
  couponGroup: string = '';
  externalCoupon: string = '';
  couponCodeGroup: string = '';
  sepaFormFields: any;
  showSepaPopup: boolean;
  charMinLengthSP: any;
  charMaxLengthSP: any;
  isZipSPRequired: boolean;
  charLenIncorrectSP: boolean;
  showSepaLoader: boolean = false;
  failure: boolean;
  failureMessage: any;
  cartVatData = { items: [], sumOfVat: '' };
  vatDataRecord: any[];
  couponChanged: boolean = false;
  disableCoupon: boolean;
  showLoader: boolean;
  shipping_between_date_text: any;
  showBuyNowButton: boolean;
  addressChanged: boolean;
  deadlineObject: any;
  deliveryDates: any;
  buyNowDisabled: boolean = false;
  displayShippingOption: boolean = false;
  priceCalculationError: boolean = false;
  clickedCoupon: boolean = false;
  colourfulSpinner: any;
  stripePaymentType: any;
  paypalOrderId: any;
  currentdateTime: any;
  failureStripeMessage: any;
  hideTaxInfo: any;
  multyCoupon: boolean;
  fromApplyCoupon: any;
  coupondetails: any;
  couponDisabled: boolean = true;
  imageIconPath: any;
  badgeImagePath: any;
  activebadgeChristmasPath: any;
  inActivebadgeChristmasPath: any;
  loadCartItem: any;
  hidePaymentOptions: boolean = false;
  isOfflineApp: boolean = false;
  redirectResult: any;
  lastTypeValue: any;
  errorMessageObj: any = {};
  termsDateValue: any = false;
  showtrimMessage: any;
  paymentMethodImg: any;
  assetIconPath: any;
  paymentProcessingLoader: boolean = false;
  phoneRequired: boolean=false;
  isExpress: boolean=false;
  canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('shippingForm') shippingForm: any;
  @ViewChild('billingForm') billingForm: any;
  @ViewChild('sepaForm') sepaForm: any;
  @ViewChild('phoneNo') phoneNo: any;
  @ViewChild('dropinModal') private dropinElement: ElementRef;
  public payPalConfig?: IPayPalConfig;
  contentType: string = 'page';
  grandTotal: any = 0.0;
  grandTotalAfterShipping: any = 0.0;
  shippingDiscAmount: any;
  isShowDiscountMsg: Boolean = false;
  totalShippinCost: any=0.0;
  totalHandlingCost: any;
  sessionID: any;
  languageData: any;
  isUserTypeGuest: Boolean = false;
  isProjectNameInValid: Boolean = false;
  userTypeGuest: Boolean = false;
  isCartDataLoading: Boolean;
  authId: any;
  cartItemList = [];
  arrProductInfo: any;
  arrProductData = [];
  arrCountries = [];
  disableCheckout = false;
  arrCountrieCodesStr = '';
  userEmail: any;
  termsPageUrl: any;
  AGBTagHtml: any;
  privacyPolicyTagHtml: any;
  popupCrossCheckmark: any;
  agbChecked: any = false;
  privacyPolicyChecked: any = false;
  isNoCheckBoxSelected: any;
  isNoPrivacyBoxSelected: any;
  dicUrls: any;
  cartItemsLength: any;
  arrowDownIcon16: any;
  searchIcon16: any;
  editProjectAlbumName = '';
  priceContainer: any;
  selectedShippingCountry = undefined;
  arrShippingInformation = [];
  arrHandlingInformation = [];
  activeTab: any = 'shipping';
  countryname = [];
  isCountryName: boolean = false;
  showErrorMessage: boolean = false;
  checkmarkPlusWht16: any;
  checkmarkMinusWht16: any;
  getGender = [];
  user = {
    shippingDetail: {
      ID: '',
      firstName: '',
      lastName: '',
      gender: '',
      company: '',
      houseNumber: '',
      street: '',
      city: '',
      zip: '',
      selectedCountry: '',
      selectedCountryCode: '',
      phoneExtension: '',
      phoneNo: '',
      phone: '',
      email: '',
      IsDefaultShipping: 0,
      IsDefaultBilling: 0,
    },
    billingDetail: {
      ID: '',
      firstName: '',
      lastName: '',
      gender: '',
      company: '',
      houseNumber: '',
      street: '',
      city: '',
      zip: '',
      selectedCountry: '',
      selectedCountryCode: '',
      phoneExtension: '',
      phoneNo: '',
      phone: '',
      email: '',
      IsDefaultShipping: 0,
      IsDefaultBilling: 0,
    },
    pickupLocationAddress: {},
    userOrderLength: 0,
  };
  maxCartValue: any;
  selectedAddressIndex: any;
  formName: any;
  addressBookData = [];
  isBillingDisabled: Boolean = false;
  isCheckboxChecked = false;
  isValidCouponInput: Boolean = false;
  countryObj: any;
  oemCountryObj: any;
  charMaxLength: number;
  charMinLength: any;
  charMinLengthBl: any;
  charMaxLengthBl: any;
  arrOemCountries: any = [];
  userProfileData: any = [];
  isEnabledBillingForm: Boolean = false;
  isSameAsShippingChecked: Boolean = false;
  pickupAddressData: any;
  arrUsedPickupLocations: any = [];
  arrAllPickupLocations: any = [];
  isDisabledShippingFeilds: Boolean = false;
  isShowCheckbox: Boolean = false;
  pickupAddressDataLength = 0;
  applyPattern: any;
  applyPatternBl: any;
  activeUsedTab: any;
  isCouponApply: Boolean = false;
  couponCodePrice = null;
  successMessage = null;
  couponValidation = false;
  errorMessage = null;
  couponcode = null;
  couponType = undefined;
  showSuccess = false;
  arrItems = [];
  checkoutIntervalId = null;
  totalProductDiscont: any;
  totalShippingDiscont: any;
  totalHandlingDiscont: any;
  adyenBtnImagePath: any;
  stripeBtnImagePath: any;
  data: any;
  totalDiscount: any;
  grandTotalOriginal: any;
  arrCouponItems: any;
  enteredCouponCode = '';
  isValidCoupon = false;
  isPickupShippingDisc: Boolean = false;
  dispalyPaypalBtn: Boolean = false;
  dispalyAdyenBtn: Boolean = false;
  dispalyStripeBtn: Boolean = false;
  dispalyInvoiceBtn: Boolean = false;
  dispalyPrepaymentBtn: Boolean = false;
  dispalySepaBtn: boolean = false;
  paymentMethodsLength: any;
  activePickUplistTab: any;
  searchText = '';
  searchTextBilling = '';
  uniqueId: any;
  userInfo: any;
  creditLimitData: any;
  isPaymentChecked: Boolean = false;
  currentPaymentType = '';
  paymentOptions: any;
  isProcessing: Boolean = false;
  totalCouponDiscount: any;
  totalShippingDiscount: any;
  totalHandlingDiscount: any;
  paymentTypes = { type: 'None' };
  orderNumber: any;
  showPaypalBtn = true;
  shippingAddress: any;
  billingAddress: any = {};
  pickupAddress: any;
  IS_PAYMENT_PROCESSING = false;
  sameShippingChecked: Boolean = false;
  IS_PAYMENT_GATEWAY_READY = false;
  public orderNoObj: Subject<any> = new Subject<any>();
  showdefaultShippingBtn = false;
  thankyouPageUrl: any;
  cartPageUrl: any;
  myAccountPageUrl: any;
  myOrderPageUrl: any;
  id: any;
  isDisplayloaderMsg: Boolean = false;
  deleteItemId: any;
  deleteItemIndex: any;
  display = 'none';
  paymentMethodsRespons:any;
  IsPaymentProcessing: Boolean = true;
  originKey;
  paymentPaid: any;
  refusalReasonCode: any;
  refusalReasonMsg: any;
  isShowPhoneInput: Boolean = false;
  showErrorMsg: Boolean = false;
  streetError: Boolean;
  streetErrorBl: Boolean;
  charLenIncorrect: Boolean;
  charLenIncorrectBl: Boolean;
  error1: Boolean = false;
  isZipRequired = false;
  isZipBlRequired = false;
  submitted = false;
  isEmailInvalid = false;
  isSavingCartData: Boolean = false;
  oemsDetail: any;
  _grandTotal: any;
  pickupAddressLength = 0;
  shippingAddressLength = 0;
  isDataLoading: Boolean = false;
  dispalyInShopBtn: Boolean = true;
  editIcon: any;
  windowWidth: any;
  duplicateProjectIcon: any;
  editProjectNameIcon: any;
  binIcon: any;
  okIcon: any;
  plusIcon: any;
  minusIcon: any;
  searchIcon: any;
  arrowDownIcon: any;
  isListActive: Boolean = true;
  faqUrl: any;
  arrAddressBook: any;
  arrBillingAddressBook: any;
  savedAddress: any;
  isPickupLocAddress: Boolean = false;
  selectedSection: any;
  contentFullData: any;
  contactusUrl: any;
  dynamicComponentRef;
  couponTypeGuestLogin: any;
  displayKeysforGuest = false;
  guestloginCoupon = false;
  totalRecord: number;
  totalOrderNo: any;
  popupCloseIcon: any;
  definedPaymentErrors = ['0', '2', '4', '5', '6', '8', '20', '24'];
  isDefinedError: Boolean = false;
  showPaperSelectError: Boolean = false;
  showOnProcess: Boolean = false;
  errorMsg: any;
  productInfo: any;
  cartData: any;
  @ViewChild('dynamicComponentDiv', { read: ViewContainerRef, static: true })
  dynamicComponentDiv: ViewContainerRef;

  isBadgeImage = false;
  validEmail: boolean = false;
  showNonverified: boolean = false;
  languageDataSubscription: Subscription;
  orderNumberSubscription: Subscription;
  headerDataSubscription: Subscription;
  hideError: boolean;
  orderInfoIcon: any;
  confirmationMsg = undefined;
  isInfoPopup: boolean = false;
  paperDiscriptionIndex: any;
  paperNameValue: any;
  paymentHandler: any = null;
  //ngx stripe start
  elements: StripeElements;
  card: StripeCardElement;

  // cardOptions: StripeCardElementOptions = {
  //   style: {
  //     base: {
  //       iconColor: '#666EE8',
  //       color: '#31325F',
  //       fontWeight: '300',
  //       fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //       fontSize: '18px',
  //       '::placeholder': {
  //         color: '#CFD7E0'
  //       }
  //     }
  //   }
  // };

  cardOptions: StripeCardElementOptions = {
    iconStyle: 'solid',
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: '#4a4a4a',
        fontWeight: 700,
        fontFamily: 'notoSans, Open Sans, Segoe UI, sans-serif',
        fontSize: '18px',

        ':-webkit-autofill': { color: '#fce883' },
        '::placeholder': { color: '#4a4a4a' },
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'auto', //global.lang == 'en' ? 'en' : 'de',
  };

  stripeTest: FormGroup;
  //ngx stripe ends

  coupanList = {
    coupans: [{ coupanDetails: '' }],
  };

  coupanForm: FormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private dataService: DataService,
    private resolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private location: PlatformLocation,
    private readonly svc: ExternalService,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private priceService: PriceService,
    public globalService: GlobalService,
    public contentfulService: ContentfulService,
    // private fb: FormBuilder,
    private stripeService: StripeService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public pwaservice: PwaService
  ) {
    this.coupanForm = this.formBuilder.group({
      coupans: this.buildContacts(this.coupanList.coupans),
    });
    const scriptList = document.querySelectorAll(
      "script[type='text/javascript']"
    );
    const convertedNodeList = Array.from(scriptList);
    const gtmScriptLoaded = convertedNodeList.find(
      (script) => script.id === 'gtm'
    );
    if (gtmScriptLoaded == undefined || gtmScriptLoaded == null) {
      if (environment[global.env].GTM_CODE) {
        var gtmScript = document.createElement('script');
        gtmScript.setAttribute('id', 'gtm');
        gtmScript.setAttribute('type', 'text/javascript');
        gtmScript.innerHTML =
          `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','` +
          environment[global.env].GTM_CODE +
          `');`;
        document.head.appendChild(gtmScript);
      }
    }

    //
    let iconsObj = this.globalService.getIconsObj();
    this.editIcon = iconsObj['EDIT_ICON'];
    this.duplicateProjectIcon = iconsObj['DUPLICATE_PROJECT_ICON'];
    // this.binIcon = iconsObj['BIN_ICON'];
    this.binIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/trash-24-turq.svg';

    this.colourfulSpinner =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/colourful_spinner.webm';

    this.editProjectNameIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/edit-project-name-32-turq.svg';
    this.plusIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/checkmark-plus-16.svg';
    this.minusIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/checkmark-minus-16.svg';
    this.popupCloseIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/checkmark-cross-32.svg';
    this.okIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/checkmark-check-24-turq.svg';
    this.searchIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/search-24.svg';
    this.searchIcon16 =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/search-16.svg';
    this.arrowDownIcon16 =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/arrow-down-16.svg';
    this.orderInfoIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/info-24-turq.svg';
    this.popupCrossCheckmark =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/checkmark-cross-32.svg';
    // this.okIcon = iconsObj['OK_ICON'];
    // this.editProjectNameIcon = iconsObj['EDIT_PROJECTNAME_ICON'];
    // this.plusIcon = iconsObj['PLUS_ICON'];
    // this.minusIcon = iconsObj['MINUS_ICON'];
    // this.searchIcon = iconsObj['SEARCH_ICON'];
    this.arrowDownIcon = iconsObj['DOWN_ARROW_ICON'];
    // this.popupCloseIcon = iconsObj['CLOSE_ICON'];
    var self = this;
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);

      // Store a reference to 'this' using the 'self' keyword

      if (
        self.route &&
        self.route.snapshot &&
        self.route.snapshot.params &&
        self.route.snapshot.params.hasOwnProperty('sessionID') &&
        self.route.snapshot.params.sessionID !== undefined &&
        self.route.snapshot.params.sessionID !== ''
      ) {
        // localStorage.setItem('reload', 'true');
      }
    };
    // window.onbeforeunload = function () {
    //   window.scrollTo(0, 0);
    //   if (this.route && this.route.snapshot && this.route.snapshot.params && this.route.snapshot.params.hasOwnProperty('sessionID') && this.route.snapshot.params.sessionID !== undefined && this.route.snapshot.params.sessionID !== '') {
    //     localStorage.setItem('reload', 'true')

    //   }
    // };
    this.contentfulService.$loadCouponCode
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response === true) {
          if (localStorage.getItem('couponcode')) {
            let couponCode = localStorage.getItem('couponcode');
            this.applyCouponCode(couponCode);
            this.setupQuill();
          }
        }
      });

    this.contentfulService.$isUserLoggedIn
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response === true) {
          if (
            this.router.url.includes('warenkorb') ||
            this.router.url.includes('shopping-cart') ||
            this.router.url.includes('mycart')
          ) {
            this.setupQuill();
          }
        }
      });

    this.contentfulService.$getCouponVolume
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response) => {
        if (response === true) {
          this.cartItemList.map((item) => {
            if (item.isVolumeDiscount) {
              if (item.listPricePromo) {
                item.listPricePromo = false;
                item.listPricePromoBefore = true;
              }
              item.volumecouponIsApplied = true;
            }
          });

          this.calculateGrandTotal();
        }
        if (this.cartItemList.length > 0) {
          localStorage.setItem('guestCartData', 'Yes');
        } else {
          localStorage.setItem('guestCartData', 'No');
        }
      });
    this.checkmarkPlusWht16 =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/checkmark-plus-16-wht.svg';
    this.checkmarkMinusWht16 =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/checkmark-minus-16-wht.svg';
    this.getGender = [
      { id: '1', gender: 'Male' },
      { id: '2', gender: 'Female' },
      { id: '3', gender: 'Neutral' },
    ];
    //
    let offlineApp = this.activeRoute.snapshot.queryParamMap.get('offlineApp');
    if (offlineApp) {
      this.isOfflineApp = true;
    }
    return;
  }
  async ngOnInit() {
    window.scrollTo(0, 0);
    this.contentfulService.emitFooterAtLast(true);
    this.couponTypeGuestLogin = 'Hello';
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      this.checkEmailValidation(userInfo.Email);
    }

    try {
      // Await the lazyAdyenQuill() observable to complete
      await new Promise<void>((resolve) => {
        this.svc.lazyAdyenQuill().subscribe((_) => {
          // Execute setupQuill() synchronously
          this.setupQuill();
          this.windowWidth = window.innerWidth;
          $(document).ready(() => {
            $('body').scrollspy({ target: '#list-example', offset: 0 });
          });

          resolve();
        });
      });

      this.dicUrls = this.globalService.getStaticPagesUrls(
        global.env,
        global.lang
      );
      this.contactusUrl = this.dicUrls['CONTACTUS_PREVIEW'];

      this.assetIconPath =
        environment[global.env].RESOURCE_URL_AMAZON +
        environment[global.env].RESOURCE_CONTAINER +
        environment[global.env].oemCode +
        '/web-assets/';

      this.imageIconPath =
        environment[global.env].RESOURCE_URL_AMAZON +
        environment[global.env].RESOURCE_CONTAINER +
        environment[global.env].oemCode +
        '/icons/';
      this.badgeImagePath =
        environment[global.env].RESOURCE_URL_AMAZON +
        environment[global.env].PRODUCT_BADGE +
        environment[global.env].oemCode +
        '/';
      this.activebadgeChristmasPath =
        this.badgeImagePath + '/' + 'badge-christmas.png';
      this.inActivebadgeChristmasPath =
        this.badgeImagePath + '/' + 'badge-no-christmas.png';
      //
    } catch (error) {
      console.error('Error in ngOnInit:', error);
    }
    this.checkedCheckboxSameAsShip(true, 'shipping');
  }

  setupQuill() {
    this.svc.lazyMomentQuill().subscribe((_) => {
      let url = this.router.url.split('?')[0];
      let arrUrl = url.split('/');
      let pageSlug = decodeURIComponent(arrUrl[1]);

      if (this.route.snapshot.params['level1']) {
        pageSlug = pageSlug + '/' + this.route.snapshot.params['level1'];
      }
      if (this.route.snapshot.params['level2']) {
        pageSlug = pageSlug + '/' + this.route.snapshot.params['level2'];
      }
      if (this.route.snapshot.params['level3']) {
        pageSlug = pageSlug + '/' + this.route.snapshot.params['level3'];
      }
      if (this.route.snapshot.params['level4']) {
        pageSlug = pageSlug + '/' + this.route.snapshot.params['level4'];
      }
      if (pageSlug) {
        this.pageSlug = pageSlug;
        this.getDataFromContentFul(pageSlug);
      }

      this.dicUrls = this.globalService.getStaticPagesUrls(
        global.env,
        global.lang
      );
      this.termsPageUrl = this.dicUrls['TERMOFUSE_PREVIEW'];
      this.thankyouPageUrl = this.dicUrls['THANKYOU_PREVIEW'];
      this.cartPageUrl = this.dicUrls['CART_PREVIEW'];
      this.myOrderPageUrl = this.dicUrls['ORDERS_PREVIEW'];

      if (localStorage.getItem('userInfo') != null) {
        this.location.onPopState(() => {
          localStorage.removeItem('cartData');
          this.router.navigate([this.cartPageUrl]);
          this.display = 'none';
          return;
        });
        let url: any = document.location;
        let params = new URL(url).searchParams;
        this.isDisplayloaderMsg = false;

        if (params.get('redirectResult')) {
          this.isDisplayloaderMsg = true;
          let paymentData = localStorage.getItem('paymentData');
          this.redirectResult = params.get('redirectResult');
          this.makeAdyenPayment(paymentData, '', '');
          return;
        }

        if (params.get('PaRes')) {
          this.isDisplayloaderMsg = true;
          let paRes = decodeURIComponent(params.get('PaRes'));
          let md = localStorage.getItem('MD');
          let paymentData = localStorage.getItem('paymentData');
          localStorage.removeItem('MD');
          localStorage.removeItem('paymentData');
          this.makeAdyenPayment(paymentData, md, paRes);
          return;
        }

        if (params.get('payload')) {
          this.isDisplayloaderMsg = true;
          let payloadValue = decodeURIComponent(params.get('payload'));

          this.makeAdyenPayment(payloadValue, '', '');
          this.globalService.setCartLengthData(0);
          return;
        }
        // this.sessionID = this.route.snapshot.params.sessionID;
        //  let userData = JSON.parse(localStorage.getItem('userInfo'));
        //   if (userData != null && userData.userType != 'Guest') {
        //     this.sessionID = userData.SSO_ID;
        //   }
        //   else{
        this.sessionID = this.route.snapshot.params.sessionID;
        // }
        if (this.globalService.gettLanguageData() != undefined) {
          this.languageData = this.globalService.gettLanguageData();
          // this.getGender = [];
          // this.getGender.push({ id:"1", gender:this.languageData.web['address_gender_male'] }, { id:"2", gender: this.languageData.web['address_gender_female']});
          this.loadCartData();
        } else {
          this.languageDataSubscription =
            this.globalService.languageDataEmiter.subscribe((data) => {
              this.languageData = data;
              this.loadCartData();
            });
        }
      } else {
        this.sessionID = this.route.snapshot.params.sessionID;

        //     this.globalService.setUserData(this.userInfo);
        // this.globalService.setSessionWindow();
        // this.globalService.startWatchingUserDuration();
        // this.changeDetectorRef.detectChanges()
        if (this.globalService.gettLanguageData() != undefined) {
          this.languageData = this.globalService.gettLanguageData();
          this.loadCartData();
        } else {
          this.languageDataSubscription =
            this.globalService.languageDataEmiter.subscribe((data) => {
              this.languageData = data;
              this.loadCartData();
            });
        }
      }
      //this.invokeStripe();

      this.stripeTest = this.formBuilder.group({
        name: ['', [Validators.required]],
      });

      this.changeDetectorRef.detectChanges();
    });
  }
  checkEmailValidation(email: any) {
    this.dataService.checkValidEmailOnly(email).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      this.termsDateValue = resJSON.data.termsDate;
    });
  }
  loadCartData(removeCoupon = false) {
    if (this.sessionID != undefined) {
      // localStorage.removeItem('reload')
      localStorage.removeItem('couponcode');
      this.dataService
        .getUserDetailBySessionID(this.sessionID)
        .subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          if (resJSON.success == true) {
            if (
              resJSON.data.user_type == 'Guest' &&
              localStorage.getItem('isUserTypeGuest') == null
            ) {
              localStorage.setItem('isUserTypeGuest', 'true');
              let data = { displayKeysforGuest: true, guestloginCoupon: false };
              // this.globalService.setOpenLogin(data); //REPLACED OLD LOGIN WITH REGISTER
              this.showSignUpModal('');
              ////////
            }
            if (resJSON.data.user_type == 'Guest') {
              this.userTypeGuest = true;
            }
            let userInfo = {
              FirstName: resJSON.data.FirstName,
              LastName: resJSON.data.LastName,
              Email: resJSON.data.Email,
              Username: resJSON.data.Username,
              isDesigner: resJSON.data.isDesigner,
              auth: resJSON.auth,
              userType: resJSON.data.user_type,
              userLoginKey: resJSON.data.userLoginKey,
              Newsletter: resJSON.data.Newsletter,
              langCode: resJSON.data.langCode,
              SSO_ID: this.sessionID,
            };
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            this.globalService.setUserData(userInfo);
            this.globalService.setSessionWindow();
            this.globalService.startWatchingUserDuration();

            setTimeout(() => {
              this.loadCart(resJSON.auth, removeCoupon);
            }, 500);
          } else {
            if (localStorage.getItem('cookieMsgVisible') != null) {
              let data = { type: 'OPEN_LOGIN' };
              //   this.globalService.setOpenLogin(data); //REPLACED OLD LOGIN WITH REGISTER
              this.showSignUpModal('');
              ////////
            }
            return;
          }
        });
    } else if (localStorage.getItem('userInfo') == null) {
      //
      // this.isCartDataLoading = true;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.globalService.setUserData(userInfo);
      this.globalService.setCartLengthData(0);
      let data = { type: 'OPEN_LOGIN' };
      localStorage.removeItem('couponcode');
      // this.globalService.setOpenLogin(data); //REPLACED OLD LOGIN WITH REGISTER
      // this.showSignUpModal('');
      this.globalService.setOpenRegister(data);
      if (localStorage.getItem('cookieMsgVisible') != null) {
        let data = { type: 'OPEN_LOGIN' };
        // this.globalService.setOpenLogin(data);  //REPLACED OLD LOGIN WITH REGISTER
        this.showSignUpModal('');
        ////////
        // this.globalService.setOpenRegister(data);
      }
      return;
    } else if (localStorage.getItem('userInfo') != null) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      this.globalService.setUserData(userInfo);
      if (userInfo.userType == 'Guest') {
        this.userTypeGuest = true;
        this.showSignUpModal('');
      }
      //////////
      this.loadCart(userInfo.auth, removeCoupon);
    }
  }
  showSignUpModal(data) {
    const modalRef = this.modalService.open(RegisterComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.result.then((result) => {}).catch((error) => {});
    modalRef.componentInstance.productdata = data;
  }
  loadCart(authID, removeCoupon = false) {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userEmail = this.userInfo.Email;
    this.authId = authID;
    this.disableCoupon = true;
    this.dataService.loadCart(authID).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      this.loadCartItem = resJSON;
      this.showLoader = true;
      if (resJSON.success == true) {
        setTimeout(() => {
          this.couponDisabled =
            resJSON.data.couponDisabled == 'Yes' ? true : false;
          this.changeDetectorRef.detectChanges();
        }, 1000);
        if (resJSON.data.displayTaxinfo == 'Yes') {
          this.hideTaxInfo = false;
        } else {
          this.hideTaxInfo = true;
        }

        this.multyCoupon = resJSON.data.multyCoupon == 'Yes' ? true : false;
        let arrProductID = [];
        this.cartItemList = resJSON.data.cartData.items;
        //
        this.globalService.setCartLengthData(this.cartItemList.length);
        if (this.cartItemList.length > 0) {
          localStorage.setItem('guestCartData', 'Yes');
        } else {
          localStorage.setItem('guestCartData', 'No');
        }
        if (this.cartItemList.length == 0) {
          this.isCartDataLoading = true;
          if (this.contentFullData && this.contentFullData.length !== 0) {
            this.dynamicComponentDiv.clear();
            this.changeDetectorRef.detectChanges();
            const factory = this.resolver.resolveComponentFactory(
              CategoryhomeComponent
            );
            this.dynamicComponentRef =
              this.dynamicComponentDiv.createComponent(factory);
          }
          return;
        } else {
          this.dynamicComponentDiv.clear();
        }
        this.cartItemsLength = this.cartItemList.length;

        setTimeout(() => {
          for (let i = 0; i < this.cartItemList.length; i++) {
            let index = i;
            this.cartItemList[i].isDisplayInputField = false;
            this.cartItemList[i].handling = 0.0;
            this.cartItemList[i].shipping = 0.0;

            this.cartItemList[i].allowChangePaper =
              this.cartItemList[i].allowChangePaper == 'Yes' ? true : false;
            this.cartItemList[i].allowChangeCover =
              this.cartItemList[i].allowChangeCover == 'Yes' ? true : false;
            this.cartItemList[i].allowChangeCase =
              this.cartItemList[i].allowChangeCase == 'Yes' ? true : false;
            this.cartItemList[i].allowChangeEnvelopes =
              this.cartItemList[i].allowChangeEnvelopes == 'Yes' ? true : false;

            arrProductID.push(this.cartItemList[i].productCode);
            //Delivery Date calculation starts

            this.cartItemList[index].shippingDaysOffset = 0;
            //
            //   'productCode=', this.cartItemList[index].productCode,
            //   'deadline=', this.cartItemList[index].xmas_order_deadline,
            //   'productionTimeDays=', this.cartItemList[index].productionTimeDays,
            //   'deliveryDays=', this.cartItemList[index].deliveryDays,
            //   'shippingDaysOffset=', this.cartItemList[index].shippingDaysOffset)

            let itemDate = moment()
              .add(this.cartItemList[index].productionTimeDays, 'days')
              .toDate();
            let itemDeadlineDate = moment(
              this.cartItemList[index].xmas_order_deadline
            ).toDate();
            const isfutureDate = moment(itemDate).isAfter(
              moment(this.cartItemList[index].xmas_order_deadline)
            );
            const isitemDeadlineDate = moment(itemDeadlineDate).isAfter(
              moment()
            );

            if (!isfutureDate) {
              let deliveryFrom =
                Number(this.cartItemList[index].productionTimeDays) +
                Number(this.cartItemList[index].deliveryDays);
              deliveryFrom = this.calculateweekdays(deliveryFrom);
              let deliverBy =
                deliveryFrom +
                Number(this.cartItemList[index].shippingDaysOffset);
              this.cartItemList[index].productAvialableForDelivery = true;
              let startEDD = moment()
                .add(deliveryFrom, 'days')
                .format('DD/MM/YYYY');
              let endEDD = moment()
                .add(deliverBy + 1, 'days')
                .format('DD/MM/YYYY');

              if (startEDD && startEDD !== null && startEDD != '') {
                startEDD = startEDD.replaceAll('/', '.');
              }
              if (endEDD && endEDD !== null && endEDD != '') {
                endEDD = endEDD.replaceAll('/', '.');
              }
              if (this.languageData) {
                let languageKey =
                  this.languageData.web['shipping_between_date_text'];

                languageKey = languageKey.replace('%e', startEDD);
                languageKey = languageKey.replace('%f', endEDD);
                this.cartItemList[index].shipping_between_date_text =
                  languageKey;
                //
                //  this.cartItemList[index].endEDD = endEDD;

                let valueendEDD = moment(
                  this.cartItemList[index].xmas_order_deadline
                ).format('DD/MM/YYYY');

                this.cartItemList[index].endEDD = valueendEDD.replaceAll(
                  '/',
                  '.'
                );
              }
            } else if (isitemDeadlineDate) {
              this.cartItemList[index].productAvialableForDelivery = true;

              let deliveryFrom = Number(this.cartItemList[index].deliveryDays);
              deliveryFrom = this.calculateweekdays(deliveryFrom);
              // Number(shippingProductInfo.productionTimeDays) +
              // Number(shippingProductInfo.deliveryDays);

              let deliverBy =
                deliveryFrom +
                Number(this.cartItemList[index].shippingDaysOffset);

              // let startEDD = moment(this.cartItemList[index].xmas_order_deadline).add(deliveryFrom, 'days').format('DD/MM/YYYY');
              // let endEDD = moment().add(deliverBy + 1, 'days').format('DD/MM/YYYY');

              let startEDD = moment(
                this.cartItemList[index].xmas_order_deadline
              )
                .add(deliveryFrom, 'days')
                .format('DD/MM/YYYY');
              let endEDD = moment(this.cartItemList[index].xmas_order_deadline)
                .add(deliveryFrom + 1, 'days')
                .format('DD/MM/YYYY');

              if (startEDD && startEDD !== null && startEDD != '') {
                startEDD = startEDD.replaceAll('/', '.');
              }
              if (endEDD && endEDD !== null && endEDD != '') {
                endEDD = endEDD.replaceAll('/', '.');
              }

              let languageKey =
                this.languageData.web['shipping_between_date_text'];

              languageKey = languageKey.replace('%e', startEDD);
              languageKey = languageKey.replace('%f', endEDD);
              this.cartItemList[index].shipping_between_date_text = languageKey;
              //
              //  this.cartItemList[index].endEDD = endEDD;

              let valueendEDD = moment(
                this.cartItemList[index].xmas_order_deadline
              ).format('DD/MM/YYYY');

              this.cartItemList[index].endEDD = valueendEDD.replaceAll(
                '/',
                '.'
              );
              // moment(item.xmas_order_deadline).format('DD.MM.YYYY')
            } else {
              this.cartItemList[index].productAvialableForDelivery = false;
              let deliveryFrom =
                Number(this.cartItemList[index].productionTimeDays) +
                Number(this.cartItemList[index].deliveryDays);
              deliveryFrom = this.calculateweekdays(deliveryFrom);
              let deliverBy =
                deliveryFrom +
                Number(this.cartItemList[index].shippingDaysOffset);
              // this.productAvialableForDelivery = true
              let startEDD = moment()
                .add(deliveryFrom, 'days')
                .format('DD/MM/YYYY');
              let endEDD = moment()
                .add(deliverBy + 1, 'days')
                .format('DD/MM/YYYY');

              if (startEDD && startEDD !== null && startEDD != '') {
                startEDD = startEDD.replaceAll('/', '.');
              }
              if (endEDD && endEDD !== null && endEDD != '') {
                endEDD = endEDD.replaceAll('/', '.');
              }

              let languageKey =
                this.languageData.web['shipping_between_date_text'];

              languageKey = languageKey.replace('%e', startEDD);
              languageKey = languageKey.replace('%f', endEDD);
              this.cartItemList[index].shipping_between_date_text = languageKey;

              // this.cartItemList[index].endEDD = endEDD;
              let valueendEDD = moment(
                this.cartItemList[index].xmas_order_deadline
              ).format('DD/MM/YYYY');

              this.cartItemList[index].endEDD = valueendEDD.replaceAll(
                '/',
                '.'
              );
              //
            }

            //Delivery Date calculation ends

            if (this.cartItemList[i].isOptimize == '') {
              this.cartItemList[i].isOptimize = 'Yes';
            }
            this.cartItemList[i].shippingError = false;
            this.cartItemList[i].minQuantityError = false;
            this.globalService.setAppVersionIcon(
              this.cartItemList[i],
              this.cartItemList[i],
              this.languageData
            );

            if (this.cartItemList[i].appVersion.includes('PhotoGenie')) {
              this.cartItemList[i].isIPL = true;
            } else {
              this.cartItemList[i].isIPL = false;
            }
            this.cartItemList[i].imageUrl =
              environment[global.env].RESOURCE_URL_AMAZON +
              environment[global.env].THUMB_BUCKET +
              this.cartItemList[i].albumCode +
              '/Thumb/' +
              this.cartItemList[i].thumbName;
          }
          this.getProductInformation(arrProductID, removeCoupon);
        }, 1000);
        //

        // setTimeout(() => {

        //   this.getdeliveryDates()
        // }, 4000);

        //
      } else {
        this.showLoader = false;
        this.dynamicComponentDiv.clear();
      }
    });
  }
  getdeliveryDates() {
    const countryCode: string = this.countryObj.code;
  
    const cartData = this.cartItemList.map((item) => ({
      productCode: item.productCode,
      caseCode: item.caseCode,
      coverCode: item.coverCode,
      envelopesCode: item.envelopesCode,
      paperCode: item.paperCode,
    }));
  
    // Check if any product is 'Notavailable' or 'Inactive'
    this.buyNowDisabled = this.cartItemList.some(
      (item) =>
        item.productStatus === 'Notavailable' || item.productStatus === 'Inactive'
    );
  
    const requestPayload = {
      cartItem: cartData,
      oemCode: environment[global.env].oemCode,
      langCode: global.lang,
      countryCode,
    };
  
    this.dataService.getdeliveryDates(requestPayload).subscribe((res: any) => {
      if (!res) {
        this.displayShippingOption = false;
        return;
      }
  
      this.displayShippingOption = true;
  
      if (res?.success) {
        this.deliveryDates = res.data;
  
        this.countryObj.shippingOptions.forEach((item) => {
          this.calculateShippingForLabel(item);
          this.changeDetectorRef.detectChanges();
        });
  
        this.changeDetectorRef.detectChanges(); // Ensure the view is updated
      }
    });
  }
  
  // callDeliveryDates(countryCode: string) {
  //   const cartData = this.cartItemList.map((item) => ({
  //     productCode: item.productCode,
  //     caseCode: item.caseCode,
  //     coverCode: item.coverCode,
  //     envelopesCode: item.envelopesCode,
  //     paperCode: item.paperCode,
  //   }));
  
  //   // Check if any product is 'Notavailable' or 'Inactive'
  //   this.buyNowDisabled = this.cartItemList.some(
  //     (item) =>
  //       item.productStatus === 'Notavailable' || item.productStatus === 'Inactive'
  //   );
  
  //   const requestPayload = {
  //     cartItem: cartData,
  //     oemCode: environment[global.env].oemCode,
  //     langCode: global.lang,
  //     countryCode,
  //   };
  
  //   this.dataService.getdeliveryDates(requestPayload).subscribe((res: any) => {
  //     this.isCartDataLoading = false;
  
  //     if (!res) {
  //       this.displayShippingOption = false;
  //       return;
  //     }
  
  //     this.displayShippingOption = true;
  
  //     if (res?.success) {
  //       this.deliveryDates = res.data;
  
  //       this.countryObj.shippingOptions.forEach((item) => {
  //         setTimeout(() => {
  //           this.calculateShippingForLabel(item);
  //           this.isCartDataLoading = true;
  //         }, 200);
  //       });
  //     }
  //   });
  // }
  
  getProductInformation(productID, removeCoupon = false) {
    var uniques = productID.filter(function (x, i, a) {
      return a.indexOf(x) == i;
    });
    var productIDUnique = uniques.toString();

    this.dataService
      .getProductInformation(productIDUnique)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          let arrProductsData = resJSON.data;
          this.productInfo = arrProductsData;
          this.cartData = arrProductsData;
          let productCodeString = '';
          let pageCountStr: any = '';
          for (let i = 0; i < this.cartItemList.length; i++) {
            for (let j = 0; j < arrProductsData.length; j++) {
              let germanDateFormat = '';
              let updateText = '';
              if (arrProductsData[j].badgeDescription) {
                let currentDate = new Date(
                  this.cartItemList[i].xmas_order_deadline
                );
                germanDateFormat = currentDate.toLocaleDateString('de-DE');
                updateText = arrProductsData[j].badgeDescription.replace(
                  '%d',
                  germanDateFormat
                );
              }
              if (
                this.cartItemList[i].productCode ==
                arrProductsData[j].productCode
              ) {
                this.cartItemList[i].badge = arrProductsData[j].badgeName;
                if (arrProductsData[j].badgeName) {
                  this.cartItemList[i].badgeUrl =
                    environment[global.env].RESOURCE_URL_AMAZON +
                    environment[global.env].PRODUCT_BADGE +
                    environment[global.env].oemCode +
                    '/' +
                    arrProductsData[j].badgeName;
                  this.cartItemList[i].badgeDescription = updateText;
                  this.cartItemList[i].badgeDescriptionInactive =
                    arrProductsData[j].badgeDescriptionInactive;
                  this.cartItemList[i].badgeName = arrProductsData[j].badgeName;
                  this.cartItemList[i].badge = arrProductsData[j].badgeName;
                  this.cartItemList[i].calculateFromDate =
                    arrProductsData[j].calculateFromDate;
                  this.cartItemList[i].badgeNameInactive =
                    arrProductsData[j].badgeNameInactive;

                  // if(arrProductsData[j].badgeName == "badge-no-christmas.jpeg" || arrProductsData[j].badgeName == "badge-no-christmas.png") {
                  //   this.isBadgeImage = true;
                  // }
                }
                this.cartItemList[i].isRenderable =
                  arrProductsData[j].isRenderable;
                if (this.cartItemList[i].isRenderable == 'No') {
                  this.cartItemList[i].imageUrl =
                    environment[global.env].RESOURCE_URL_AMAZON +
                    environment[global.env].AMAZON_IMAGE_FOLDER_PATH +
                    this.cartItemList[i].productCode +
                    'product_preview.png';
                }
                this.cartItemList[i].ProductCat = arrProductsData[j].ProductCat;
                this.cartItemList[i].online_text =
                  arrProductsData[j].online_text;
                this.cartItemList[i].preview = arrProductsData[j].preview;
                var preview = arrProductsData[j].preview;
                if (
                  preview == 'PRINT' ||
                  parseInt(this.cartItemList[i].pageCount) <= 4
                ) {
                  this.cartItemList[i].isPageCountVisible = false;
                } else {
                  this.cartItemList[i].isPageCountVisible = true;
                }
                if (preview == 'PRINT' || preview == 'RETRO') {
                  this.cartItemList[i].imageUrl =
                    environment[global.env].RESOURCE_URL_AMAZON +
                    environment[global.env].oemCode +
                    '/web-assets/' +
                    this.cartItemList[i].productCode +
                    '-cart-thumb.png';
                  this.cartItemList[i]['isQuantityChangeDisabled'] = true;
                } else {
                  this.cartItemList[i].ProductCatSubLabelText =
                    arrProductsData[j].ProductCatSubLabelText;
                  this.cartItemList[i]['isQuantityChangeDisabled'] = false;
                }

                if (productCodeString == '') {
                  productCodeString = this.cartItemList[i].productCode;
                  if (preview == 'SOFTBOOK-' || preview == 'RINGBOOK') {
                    this.cartItemList[i].pageCount =
                      this.cartItemList[i].pageCount + 2;
                  }
                  let pageCount = this.cartItemList[i].pageCount;
                  if (
                    this.cartItemList[i].preview == 'WALLCALENDAR' ||
                    this.cartItemList[i].preview == 'DESKCALENDAR'
                  ) {
                    pageCount = pageCount - 1;
                  }
                  if (preview == 'SOFTBOOK-' || preview == 'RINGBOOK') {
                    pageCount = pageCount - 2;
                  }
                  pageCountStr = pageCount;
                } else {
                  productCodeString =
                    productCodeString + ',' + this.cartItemList[i].productCode;
                  if (preview == 'SOFTBOOK-' || preview == 'RINGBOOK') {
                    this.cartItemList[i].pageCount =
                      this.cartItemList[i].pageCount + 2;
                  }
                  let pageCount = this.cartItemList[i].pageCount;
                  if (
                    this.cartItemList[i].preview == 'WALLCALENDAR' ||
                    this.cartItemList[i].preview == 'DESKCALENDAR'
                  ) {
                    pageCount = pageCount - 1;
                  }
                  if (preview == 'SOFTBOOK-' || preview == 'RINGBOOK') {
                    pageCount = pageCount - 2;
                  }
                  pageCountStr = pageCountStr + ',' + pageCount;
                }
                break;
              }
            }
          }

          // if(arrProductsData[j].badgeName == "badge-no-christmas.jpeg" || arrProductsData[j].badgeName == "badge-no-christmas.png") {
          //   this.isBadgeImage = true;
          // }
          // let isJpgBadge = this.cartItemList.findIndex(
          //   (item) => item.badgeName === 'badge-no-christmas.jpeg'
          // );
          // let isPngBadge = this.cartItemList.findIndex(
          //   (item) => item.badgeName === 'badge-no-christmas.png'
          // );
          // if (isJpgBadge >= 0 || isPngBadge >= 0) {
          //   this.isBadgeImage = true;
          // } else {
          //   this.isBadgeImage = false;
          // }

          this.cartItemList.map((item) => {
            // for (let i = 0; i < this.cartData.length; i++) {
            //   const element = this.cartData[i];
            //   item.badge = element.badgeName;

            if (!item.productAvialableForDelivery) {
              this.isBadgeImage = true;
            }

            if (
              this.cartItemList.findIndex(
                (item) =>
                  item.productStatus == 'Notavailable' ||
                  item.productStatus == 'Inactive'
              ) > -1
            ) {
              this.buyNowDisabled = true;
            } else {
              this.buyNowDisabled = false;
            }
            // }
          });

          //
          this.getProductInfoMulti(
            productCodeString,
            pageCountStr,
            removeCoupon
          );
        }
      });
  }

  getProductInfoMulti(productID, pageCountStr, removeCoupon = false) {
    this.dataService
      .productInfoMulti(productID, pageCountStr)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        this.disableCoupon = false;
        this.showLoader = false;
        if (resJSON.success == true) {
          let arrProductInfo = resJSON.data.productInfo;
          this.arrProductInfo = resJSON.data.productInfo;

          this.vatDataRecord = [];
          for (let i = 0; i < this.cartItemList.length; i++) {
            this.cartItemList[i].totalGroupShippingCost = 0;
            this.cartItemList[i].defaultPrice = false;
            this.cartItemList[i].isQuantityProcessing = false;
            this.cartItemList[i].isPaperChangeProcessing = false;
            this.cartItemList[i].quantity = parseInt(
              this.cartItemList[i].quantity
            );
            let productInfo =
              arrProductInfo[i][this.cartItemList[i].productCode];

            this.cartItemList[i].listPriceDate = productInfo.listPriceDate;
            this.cartItemList[i].isPackage = productInfo.isPackage;
            this.cartItemList[i].materialTitle = productInfo.material_title;
            this.cartItemList[i].arrdefaultProductQuantities =
              productInfo.defaultProductQuantities;
            this.cartItemList[i].vat = productInfo.defaultProductQuantities;
            if(!removeCoupon){
              this.arrProductData.push(productInfo);
             // console.info('this.arrProductData',)
            }
           

            if (productInfo.isPackage == 'Yes') {
              this.cartItemList[i].arrUniqueQuantity =
                productInfo.defaultProductQuantities;
              this.cartItemList[i].selectedQuantityModal =
                this.cartItemList[i].arrUniqueQuantity[0];
              this.selectedCartQuantity = parseInt(
                this.cartItemList[i].selectedQuantityModal.quantity
              );
              if (
                this.cartItemList[i].quantity <
                parseInt(this.cartItemList[i].arrUniqueQuantity[0].quantity)
              ) {
                this.cartItemList[i].quantity = parseInt(
                  this.cartItemList[i].arrUniqueQuantity[0].quantity
                );
              }

              for (
                let j = 0;
                j < productInfo.defaultProductQuantities.length;
                j++
              ) {
                if (
                  parseInt(productInfo.defaultProductQuantities[j].quantity) ==
                  this.cartItemList[i].quantity
                ) {
                  this.cartItemList[i].selectedQuantityModal =
                    productInfo.defaultProductQuantities[j];
                  this.selectedCartQuantity = parseInt(
                    this.cartItemList[i].selectedQuantityModal.quantity
                  );
                  break;
                }
              }
            }
            if (productInfo.paper.length > 0) {
              let arrPaper = productInfo.paper;
              this.cartItemList[i].arrPaperInfo = arrPaper;
              arrPaper = this.getUniquePaperArray(arrPaper);
              this.cartItemList[i].paper = arrPaper;

              if (this.cartItemList[i].paper.length == 1) {
                this.cartItemList[i].paperCode =
                  this.cartItemList[i].paper[0].paperCode;
              }
            } else {
              this.cartItemList[i].paper = [];
            }

            if (productInfo.cover.length > 0) {
              this.cartItemList[i].cover = productInfo.cover;
              if (this.cartItemList[i].cover.length == 1) {
                this.cartItemList[i].coverCode =
                  this.cartItemList[i].cover[0].coverCode;
              }
            } else {
              this.cartItemList[i].cover = [];
            }

            if (productInfo.cases.length > 0) {
              this.cartItemList[i].cases = productInfo.cases;
              if (this.cartItemList[i].cases.length == 1) {
                this.cartItemList[i].caseCode =
                  this.cartItemList[i].cases[0].caseCode;
              }
            } else {
              this.cartItemList[i].cases = [];
            }

            if (productInfo.envelope.length > 0) {
              this.cartItemList[i].envelopes = productInfo.envelope;

              if (this.cartItemList[i].envelopes.length == 1) {
                this.cartItemList[i].envelopesCode =
                  this.cartItemList[i].envelopes[0].envelopesCode;
              }
              // else if(this.cartItemList[i].envelopes.length < 1){
              //   this.cartItemList[i].envelopesCode =
              //   this.cartItemList[i].envelopes[0].envelopesCode;
              // }
            } else {
              this.cartItemList[i].envelopes = [];
            }

            this.cartItemList[i].selectedPaper = this.getSelectedPaper(
              this.cartItemList[i].paper,
              this.cartItemList[i].paperCode,
              this.cartItemList[i]
            );
            this.cartItemList[i].selectedCover = this.getSelectedCover(
              this.cartItemList[i].cover,
              this.cartItemList[i].coverCode
            );
            this.cartItemList[i].selectedEnvelope = this.getSelectedEnvelope(
              this.cartItemList[i].envelopes,
              this.cartItemList[i].envelopesCode
            );

            this.calculatePrice(this.cartItemList[i], i);
          }
          let selectePaperArray = this.cartItemList.filter(
            (x) => x.paper != '' && x.selectedPaper == null
          );
          if (selectePaperArray.length > 0) {
            this.showPaperSelectError = true;
          } else {
            this.showPaperSelectError = false;
          }
          if (!removeCoupon) {
            this.getUniqueZoneCountries();
              setTimeout(() => {
        this.isCartDataLoading = true;
      }, 2000);
          } else {
            this.calculateGrandTotal(true);
          }

          setTimeout(() => {
            //this.calculateVats()
            this.setCartImpression();
          }, 1000);

          // this.isCartDataLoading = true;
        }
      });
  }
  calculateVats() {
    if (this.hideTaxInfo) {
      return;
    }

    let vatDataRecord = [];

    let shippingIndex = this.countryObj.shippingOptions.findIndex(
      (item) => item.selected
    );
    let selectedShippingType;
    let zoneIndex;
    let selectedZone;
    if (shippingIndex > -1) {
      selectedShippingType =
        this.countryObj.shippingOptions[shippingIndex].type;
    }
    let sumOfShippingDiscount = 0;

    for (let i = 0; i < this.cartItemList.length; i++) {
      if (this.isCouponApply) {
        //
        if (
          this.arrCouponItems[i].couponDiscount == undefined ||
          this.arrCouponItems[i].couponDiscount == ''
        ) {
          this.arrCouponItems[i].couponDiscount = 0;
        }

        this.cartItemList[i].couponDiscountApplied =
          Number(this.arrCouponItems[i].couponDiscount) +
          Number(this.arrCouponItems[i].pageDiscount);
        if (
          this.arrCouponItems[i].shippingDiscount == undefined ||
          this.arrCouponItems[i].shippingDiscount == ''
        ) {
          this.arrCouponItems[i].shippingDiscount = 0;
        }
        this.cartItemList[i].shippingDiscountApplied =
          this.arrCouponItems[i].shippingDiscount;
      } else {
        this.cartItemList[i].couponDiscountApplied = 0;
        this.cartItemList[i].shippingDiscountApplied = 0;
      }
      sumOfShippingDiscount =
        Number(sumOfShippingDiscount) +
        Number(this.cartItemList[i].shippingDiscountApplied);
      let cartItem = this.cartItemList[i];
      let productInfo = this.arrProductInfo[i][cartItem.productCode];

      zoneIndex = productInfo.productZone.findIndex(
        (item) =>
          item.countryCode == this.countryObj.code &&
          item.shippingType == selectedShippingType
      );
      if (zoneIndex > -1) {
        selectedZone = productInfo.productZone[zoneIndex];
      }
      let itemPriceToPay;

      if (cartItem.isVolumeDiscount) {
        itemPriceToPay = cartItem.defaultPrice;
      } else if (!cartItem.isVolumeDiscount) {
        if (cartItem.promoPrice) {
          itemPriceToPay = cartItem.promoPrice;
        } else {
          itemPriceToPay = cartItem.defaultPrice;
        }
      } else if (
        cartItem.productPrice &&
        cartItem.quantity > 1 &&
        cartItem.isPackage != 'Yes'
      ) {
        if (cartItem.listPricePromo) {
          itemPriceToPay = cartItem.listPricePromo;
        } else {
          itemPriceToPay = cartItem.listPrice;
        }
      }
      // (selectedZone.productVat * (itemPriceToPay)) / 100;
      let calculatedVat: any =
        itemPriceToPay -
        cartItem.couponDiscountApplied -
        (itemPriceToPay - cartItem.couponDiscountApplied) /
          (1 + selectedZone.productVat / 100);
      cartItem.vat = selectedZone.productVat;
      cartItem['VATS'] = {
        productVatPer: selectedZone.productVat,
        calculatedVat: calculatedVat,
        shippingVatPer: selectedZone.shippingVat,
        itemPriceToPay: itemPriceToPay,
      };

      vatDataRecord.push(cartItem['VATS']);
    }

    this.vatDataRecord = vatDataRecord.reduce((acc, curr) => {
      if (!acc[curr.productVatPer]) acc[curr.productVatPer] = []; //If this type wasn't previously stored
      acc[curr.productVatPer].push(curr);
      return acc;
    }, {});

    let sumOfProductVat = {};
    let calculatedVatArray = [];
    for (var key in this.vatDataRecord) {
      let initialproductVat = 0;

      //
      this.vatDataRecord[key].map((item) => {
        initialproductVat =
          Number(initialproductVat) + Number(item.calculatedVat);
        sumOfProductVat[key] = initialproductVat;
      });

      let label = this.languageData.web.checkout_product_vat.replace(
        '%d',
        key + '%'
      );
      let priceInFormat =
        this.priceService.getCommaOrDotValue(sumOfProductVat[key].toFixed(2)) +
        this.priceService.getCurrencySymbol();
      calculatedVatArray.push({
        percentileValue: key,
        label: label,
        priceInFormat: priceInFormat,
        priceValue: sumOfProductVat[key],
        type: 'productVat',
        show: true,
      });
    }
    //

    let getMaxProductVat = calculatedVatArray.reduce((prev, current) =>
      prev.percentileValue > current.percentileValue ? current : prev
    );
    let totalShippinCost =
      this.totalShippinCost -
      sumOfShippingDiscount -
      (this.totalShippinCost - sumOfShippingDiscount) /
        (1 + getMaxProductVat.percentileValue / 100);
    let shippingVatLabel = this.languageData.web.checkout_shipping_vat.replace(
      '%d',
      getMaxProductVat.percentileValue + '%'
    );
    let shippingVatPriceInFormat =
      this.priceService.getCommaOrDotValue(totalShippinCost.toFixed(2)) +
      this.priceService.getCurrencySymbol();

    //

    calculatedVatArray.push({
      percentileValue: getMaxProductVat.percentileValue,
      label: shippingVatLabel,
      priceInFormat: shippingVatPriceInFormat,
      priceValue: totalShippinCost,
      type: 'shippingVat',
      show: true,
    });
    //

    this.cartVatData['items'] = calculatedVatArray;
    let totalVat = 0;
    calculatedVatArray.map((item) => {
      totalVat = Number(totalVat) + Number(item.priceValue);
    });
    this.cartVatData['sumOfVat'] =
      this.priceService.getCommaOrDotValue(totalVat.toFixed(2)) +
      this.priceService.getCurrencySymbol();
    setTimeout(() => {
      this.showLoader = false;
      this.changeDetectorRef.detectChanges();
    }, 2000);
  }
  focusInput(inputField: HTMLInputElement) {
    inputField.focus();
  }
  formCharacterValidation() {
    if (this.user.shippingDetail) {
      if (
        this.user.shippingDetail.lastName &&
        this.user.shippingDetail.lastName.length > 17
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 17)
          : '';
        this.errorMessageObj.lastnameLength = textCharacter;
      }
      if (
        this.user.shippingDetail.firstName &&
        this.user.shippingDetail.firstName.length > 17
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 17)
          : '';
        this.errorMessageObj.firstNameLength = textCharacter;
      }
      if (
        this.user.shippingDetail.houseNumber &&
        this.user.shippingDetail.houseNumber.length > 10
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 10)
          : '';
        this.errorMessageObj.houseLength = textCharacter;
      }
      if (
        this.user.shippingDetail.company &&
        this.user.shippingDetail.company.length > 50
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 50)
          : '';
        this.errorMessageObj.companyLength = textCharacter;
      }
      if (
        this.user.shippingDetail.street &&
        this.user.shippingDetail.street.length > 35
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 35)
          : '';
        this.errorMessageObj.streetLength = textCharacter;
      }
      if (
        this.user.shippingDetail.city &&
        this.user.shippingDetail.city.length > 35
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 35)
          : '';
        this.errorMessageObj.cityLength = textCharacter;
      }
    }
    if (this.user.billingDetail) {
      if (
        this.user.billingDetail.lastName &&
        this.user.billingDetail.lastName.length > 17
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 17)
          : '';
        this.errorMessageObj.lastnameLength = textCharacter;
      }
      if (
        this.user.billingDetail.company &&
        this.user.billingDetail.company.length > 50
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 50)
          : '';
        this.errorMessageObj.companyLength = textCharacter;
      }
      if (
        this.user.billingDetail.firstName &&
        this.user.billingDetail.firstName.length > 17
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 17)
          : '';
        this.errorMessageObj.firstNameLength = textCharacter;
      }
      if (
        this.user.billingDetail.houseNumber &&
        this.user.billingDetail.houseNumber.length > 10
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 10)
          : '';
        this.errorMessageObj.houseLength = textCharacter;
      }
      if (
        this.user.billingDetail.street &&
        this.user.billingDetail.street.length > 35
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 35)
          : '';
        this.errorMessageObj.streetLength = textCharacter;
      }
      if (
        this.user.billingDetail.city &&
        this.user.billingDetail.city.length > 35
      ) {
        let textCharacter = this.languageData?.web?.character_limit_title
          ? this.languageData.web.character_limit_title.replace('%d', 35)
          : '';
        this.errorMessageObj.cityLength = textCharacter;
      }
      //  if (this.user.billingDetail.phoneNo && this.user.billingDetail.phoneNo.length >20) {
      //   let textCharacter = this.languageData?.web?.character_limit_title? this.languageData.web.character_limit_title.replace('%d', 20) : "";
      //   this.errorMessageObj.phoneNoLength = textCharacter;
      //  }
    }
  }
  getAddressBook(event, formName) {
    this.selectedAddressIndex = undefined;
    this.formName = formName;
    if (event != undefined) {
      // this.openAddressBookModal(formName);
      return;
    }

    this.dataService
      .getShippingAndBillingAddress()
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.billingAddressEditable) {
          this.billingAddressEditable =
            resJSON.billingAddressEditable == 'Yes' ? true : false;
        }

        if (resJSON.success == true) {
          this.addressBookData = resJSON.data;
          this.billingAddressData = JSON.parse(JSON.stringify(resJSON.data));

          this.arrBillingAddressBook = Object.assign(
            [],
            this.billingAddressData
          );

          this.addressBookAssignCopy();
          this.user.billingDetail['email'] = this.userEmail;
          if (this.addressBookData.length == 0) {
            this.isBillingDisabled = false;
            this.isCheckboxChecked = false;
            this.user.billingDetail.gender = 'Female';
            // this.countryObj = this.getCountry('', environment[global.env].defaultCountryCode);
            // this.selectedShippingCountry = { 'code': this.countryObj.code, 'country': this.countryObj.country }
            // this.getZipPattern(this.countryObj);
            //////

            // for (let i = 0; i < this.arrOemCountries.length; i++) {
            //   if (this.arrOemCountries[i].isDefault == 'Yes') {
            //   //  this.oemCountryObj = this.getOemCountry(this.arrOemCountries[i].countryName, this.arrOemCountries[i].Country);
            //     this.countryObj = this.getOemCountry(this.arrOemCountries[i].countryName, this.arrOemCountries[i].Country);

            //   }
            // }

            this.getBillingAddressDetail();
            this.user.userOrderLength = 0;
          } else {
            this.getBillingAddressDetail();
            this.getUserOrderLength();
            this.dataService
              .getprofileDetails(this.authId)
              .subscribe((res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                this.arrOemCountries = resJSON.oemCountries;
                this.userProfileData = resJSON.data;
                this.oemCountryObj = this.getOemCountry(
                  resJSON.data.countryName,
                  resJSON.data.Country
                );
                this.userEmail = resJSON.data.userEmail;
                let phone = resJSON.data.Phone.split('-');

                //
                //Scrapped
                // this.user.billingDetail = {
                //   "firstName": resJSON.data.FirstName,
                //   "lastName": resJSON.data.LastName,
                //   "company": resJSON.data.Company,
                //   "street": resJSON.data.Street,
                //   "houseNumber": resJSON.data.HouseNumber,
                //   "city": resJSON.data.City,
                //   "selectedCountry": this.oemCountryObj.countryName,
                //   "selectedCountryCode": this.oemCountryObj.countryCode,
                //   "zip": resJSON.data.zipCode,
                //   "email": this.userEmail,
                //   "phone": resJSON.data.Phone,
                //   "phoneExtension": this.oemCountryObj.ISD,
                //   "phoneNo": phone[1],
                //   "ID": resJSON.data.ID,
                //   'IsDefaultShipping': 0,
                //   'IsDefaultBilling': 0
                // }

                for (var i = 0; i < this.addressBookData.length; i++) {
                  if (this.addressBookData[i].IsDefaultShipping == 1) {
                    this.countryObj = this.getCountry(
                      this.addressBookData[i].CountryName,
                      this.addressBookData[i].Country
                    );
                    let indexOfDefaultCountry = this.arrOemCountries.findIndex(
                      (item) => item.isDefault == 'Yes'
                    );
                    if (this.countryObj == undefined) {
                      let countryObj =
                        this.arrOemCountries[indexOfDefaultCountry];
                      this.countryObj =
                        this.arrOemCountries[indexOfDefaultCountry]; //this.getCountry('', environment[global.env].defaultCountryCode);
                      this.countryObj.code = this.countryObj.countryCode;
                      this.countryObj.country = this.countryObj.countryName;
                      this.countryObj.code = this.countryObj.countryCode;
                      this.countryObj.country = this.countryObj.countryName;
                      this.countryObj.maxPostalLength =
                        this.countryObj.PostalLength;

                      this.user.shippingDetail.selectedCountry =
                        this.countryObj.country;
                      //
                      let shippingIndex = this.arrCountries.findIndex(
                        (item) => item.code == this.countryObj.code
                      );
                      let shippingOptions =
                        this.arrCountries[shippingIndex].shippingOptions;
                      this.countryObj.shippingOptions = shippingOptions;
                    }
                    //
                    this.selectedShippingCountry = {
                      code: this.countryObj.countryCode,
                      country: this.countryObj.country,
                    };
                    this.getZipPattern(this.countryObj);
                    let addressBookData = this.addressBookData[i];
                    let phone = addressBookData.Phone.split('-');
                    this.user.shippingDetail = {
                      firstName: addressBookData.FirstName,
                      lastName: addressBookData.LastName,
                      gender: addressBookData.gender,
                      company: addressBookData.Company,
                      street: addressBookData.Address1,
                      houseNumber: addressBookData.Address2,
                      city: addressBookData.City,
                      selectedCountry: this.countryObj.country,
                      selectedCountryCode: addressBookData.selectedCountryCode,
                      zip: addressBookData.Zip,
                      email: addressBookData.Email,
                      phone: addressBookData.Phone,
                      phoneExtension: this.countryObj.ISD,
                      phoneNo: phone[1],
                      ID: addressBookData.ID,
                      IsDefaultShipping: addressBookData.IsDefaultShipping,
                      IsDefaultBilling: addressBookData.IsDefaultBilling,
                    };
                    this.formCharacterValidation();
                  }

                  if (this.addressBookData[i].IsDefaultBilling == 1) {
                    this.oemCountryObj = this.getOemCountry(
                      this.addressBookData[i].CountryName,
                      this.addressBookData[i].Country
                    );

                    let addressBookData = this.addressBookData[i];
                    let phone = addressBookData.Phone.split('-');

                    let userInfo;
                    if (localStorage.getItem('userInfo') != null) {
                      userInfo = JSON.parse(localStorage.getItem('userInfo'));
                    }

                    // //////
                    this.user.billingDetail = {
                      firstName: addressBookData.FirstName,
                      lastName: addressBookData.LastName,
                      gender: addressBookData.gender,
                      company: addressBookData.Company,
                      street: addressBookData.Address1,
                      houseNumber: addressBookData.Address2,
                      city: addressBookData.City,
                      selectedCountry: this.oemCountryObj.countryName,
                      selectedCountryCode: this.oemCountryObj.countryCode,
                      zip: addressBookData.Zip,
                      email: this.userTypeGuest
                        ? addressBookData.Email
                        : userInfo.Email,
                      phone: addressBookData.Phone,
                      phoneExtension: this.oemCountryObj.ISD,
                      phoneNo: phone[1],
                      ID: addressBookData.ID,
                      IsDefaultShipping: addressBookData.IsDefaultShipping,
                      IsDefaultBilling: addressBookData.IsDefaultBilling,
                    };
                    this.formCharacterValidation();
                    if (this.userTypeGuest) {
                      this.checkEmailExist(addressBookData.Email);
                    } else {
                      this.billingAddress.email = userInfo.Email;
                      this.addressBookData[i].Email = userInfo.Email;

                      let billingAddressEmailIndex =
                        this.billingAddressData.findIndex(
                          (item) => item.ID == addressBookData.ID
                        );
                      this.user.billingDetail.email = userInfo.Email;
                      this.billingAddress.email = userInfo.Email;
                      this.billingAddressData[billingAddressEmailIndex].Email =
                        userInfo.Email;

                      //
                    }

                    this.getZipPatternBl(this.oemCountryObj);
                  }
                }

                if (this.countryObj == undefined) {
                  ////////
                  this.countryObj = this.getCountry(
                    '',
                    environment[global.env].defaultCountryCode
                  );
                  this.selectedShippingCountry = {
                    code: this.countryObj ? this.countryObj.code : '',
                    country: this.countryObj ? this.countryObj.country : '',
                  };
                  this.user.shippingDetail.selectedCountry = this.countryObj
                    ? this.countryObj.country
                    : '';

                  // this.user.billingDetail.selectedCountry = this.countryObj.country;
                }
                //   setTimeout(() => {
                // //    this.getdeliveryDates()
                //   }, 200);
              });
          }
          this.getPickupLocationsList();
          //this.getAGBText();

          let pathArray = location.pathname.split('/');

          this.router.url.includes('mycart');
          if (pathArray.length > 2 && !this.router.url.includes('mycart')) {
            let urlToAdd = pathArray[1] + '/' + pathArray[2] + '/';
            let AGBkeyValue = this.languageData.web['checkout_terms_text'];
            this.AGBTagHtml = AGBkeyValue.replace(
              '%d',
              "<a class='active' href=" +
                '/' +
                urlToAdd +
                this.languageData.web.checkout_terms_link +
                " target='_blank'>" +
                this.languageData.web.checkout_terms_linktext +
                '</a>'
            );

            let privacyKeyValue = this.languageData.web.checkout_privacy_text;
            this.privacyPolicyTagHtml = privacyKeyValue.replace(
              '%d',
              "<a class='active' href=" +
                '/' +
                urlToAdd +
                this.languageData.web.checkout_privacy_link +
                " target='_blank'>" +
                this.languageData.web.checkout_privacy_linktext +
                '</a>'
            );
          } else {
            let AGBkeyValue = this.languageData.web['checkout_terms_text'];
            this.AGBTagHtml = AGBkeyValue.replace(
              '%d',
              "<a class='active' href=" +
                '/' +
                this.languageData.web.checkout_terms_link +
                " target='_blank'>" +
                this.languageData.web.checkout_terms_linktext +
                '</a>'
            );

            let privacyKeyValue = this.languageData.web.checkout_privacy_text;
            this.privacyPolicyTagHtml = privacyKeyValue.replace(
              '%d',
              "<a class='active' href=" +
                '/' +
                this.languageData.web.checkout_privacy_link +
                " target='_blank'>" +
                this.languageData.web.checkout_privacy_linktext +
                '</a>'
            );
          }

          //
        }
      });
      setTimeout(() => {
        this.isCartDataLoading = true;
      }, 2000);
     
  }

  getUserOrderLength() {
    this.dataService
      .myOrders(1, 1, 'Yes')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);

          if (resJSON.success == true) {
            if (resJSON.totalRecord == 0) {
              this.user.userOrderLength = 0;
            } else {
              this.user.userOrderLength = resJSON.totalRecord;
            }
          }
        },
        (error) => {}
      );
  }

  getBillingAddressDetail() {
    this.dataService
      .getprofileDetails(this.authId)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        this.arrOemCountries = resJSON.oemCountries;
        //
        this.userProfileData = resJSON.data;

        // if (resJSON.success == true && resJSON.data.Street != "" && resJSON.data.user_type != 'Guest') {

        if (resJSON.success == true && resJSON.data.user_type != 'Guest') {
          //  ////////
          // if(this.billingAddressEditable){
          //   this.isEnabledBillingForm = true;
          // }else{
          //   this.isEnabledBillingForm = false;
          // }
          this.isCheckboxChecked = false;
          this.isBillingDisabled = true;
          let indexOfBillingAddress = this.addressBookData.findIndex(
            (item) => item.IsDefaultBilling == '1'
          );
          if (this.billingAddressEditable || indexOfBillingAddress < 0) {
            this.isEnabledBillingForm = true;
            this.isBillingDisabled = false;
            this.isShowCheckbox = true;
          } else {
            this.isEnabledBillingForm = false;
          }

          if (this.addressBookData.length == 0) {
            let defaultIndex = this.arrOemCountries.findIndex(
              (item) => item.isDefault == 'Yes'
            );
            this.oemCountryObj = this.getOemCountry(
              this.arrOemCountries[defaultIndex].countryName,
              this.arrOemCountries[defaultIndex].countryCode
            );
            this.countryObj = this.getOemCountry(
              this.arrOemCountries[defaultIndex].countryName,
              this.arrOemCountries[defaultIndex].countryCode
            );
            let dataForShipping = this.getCountry(
              this.arrOemCountries[defaultIndex].countryName,
              this.arrOemCountries[defaultIndex].countryCode
            );
            this.countryObj.shippingOptions = dataForShipping.shippingOptions;
            //

            this.countryObj.code = this.countryObj.countryCode;
            this.countryObj.country = this.countryObj.countryName;
            this.countryObj.code = this.countryObj.countryCode;
            this.countryObj.country = this.countryObj.countryName;
            this.countryObj.minPostalLength = this.countryObj.MinPostalLength;
            this.countryObj.maxPostalLength = this.countryObj.PostalLength;
            this.getZipPattern(this.countryObj);
            this.getZipPatternBl(this.oemCountryObj);

            this.countPostalCode('');
            this.countPostalCodeBl('');
          } else {
            this.addressBookData.map((item, index) => {
              if (item.IsDefaultBilling == 1) {
                this.oemCountryObj = this.getOemCountry(
                  item.countryName,
                  item.Country
                );
              }
            });
          }
          //////
          this.userEmail = resJSON.data.userEmail;
          let phone = resJSON.data.Phone.split('-');
          //Scrapped
          // this.user.billingDetail = {
          //   "firstName": resJSON.data.FirstName,
          //   "lastName": resJSON.data.LastName,
          //   "company": resJSON.data.Company,
          //   "street": resJSON.data.Street,
          //   "houseNumber": resJSON.data.HouseNumber,
          //   "city": resJSON.data.City,
          //   "selectedCountry": this.oemCountryObj.countryName,
          //   "selectedCountryCode": this.oemCountryObj.countryCode,
          //   "zip": resJSON.data.zipCode,
          //   "email": this.userEmail,
          //   "phone": resJSON.data.Phone,
          //   "phoneExtension": this.oemCountryObj.ISD,
          //   "phoneNo": phone[1],
          //   "ID": resJSON.data.ID,
          //   'IsDefaultShipping': 0,
          //   'IsDefaultBilling': 0
          // }
          if (this.addressBookData.length == 0) {
            this.isDisabledShippingFeilds = true;
            // ////////
            this.user.shippingDetail = {
              firstName: '',
              lastName: '',
              gender: 'Female',
              company: '',
              street: '',
              houseNumber: '',
              city: '',
              selectedCountry: this.oemCountryObj.countryName,
              selectedCountryCode: this.oemCountryObj.countryCode,
              zip: '',
              email: this.userEmail,
              phone: '',
              phoneExtension: this.oemCountryObj.ISD,
              phoneNo: '',
              ID: resJSON.data.ID,
              IsDefaultShipping: 1,
              IsDefaultBilling: 0,
            };
            this.countryObj = this.getOemCountry(
              this.oemCountryObj.countryName,
              this.oemCountryObj.countryCode
            );
            this.countryObj.code = this.oemCountryObj.countryCode;
            this.countryObj.country = this.oemCountryObj.countryName;

            // this.countryObj = this.getCountry('', environment[global.env].defaultCountryCode);
            this.selectedShippingCountry = {
              code: this.countryObj.countryCode,
              country: this.countryObj.countryN,
            };
            this.getZipPattern(this.countryObj);
          } else {
            this.isDisabledShippingFeilds = false;
          }
        } else {
          this.isEnabledBillingForm = true;
          // this.billingAddressEditable=true;

          this.isShowCheckbox = true;

          for (let i = 0; i < this.arrOemCountries.length; i++) {
            if (this.arrOemCountries[i].isDefault == 'Yes') {
              this.oemCountryObj = this.getOemCountry(
                this.arrOemCountries[i].countryName,
                this.arrOemCountries[i].countryCode
              );
              this.countryObj = this.getOemCountry(
                this.arrOemCountries[i].countryName,
                this.arrOemCountries[i].countryCode
              );

              let dataForShipping = this.getCountry(
                this.arrOemCountries[i].countryName,
                this.arrOemCountries[i].countryCode
              );
              //

              //
              this.countryObj.code = this.countryObj.countryCode;
              this.countryObj.country = this.countryObj.countryName;
              this.countryObj.minPostalLength = this.countryObj.MinPostalLength;
              this.countryObj.maxPostalLength = this.countryObj.PostalLength;
              this.countryObj.shippingOptions = dataForShipping.shippingOptions;

              // this.countryObj = this.getCountry('', environment[global.env].defaultCountryCode);
              this.selectedShippingCountry = {
                code: this.countryObj.countryCode,
                country: this.countryObj.countryN,
              };
              this.getZipPattern(this.countryObj);
            }
          }
        }
        this.getZipPatternBl(this.oemCountryObj);
      });
  }

  getPickupLocationsList() {
    //  ////////

    this.dataService
      .getPickupLocations(this.arrCountrieCodesStr)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          this.pickupAddressData = resJSON.data;

          this.pickupAddressDataLength =
            this.pickupAddressData.allPickupLocations.length;

          if (this.pickupAddressDataLength != 0) {
            this.arrUsedPickupLocations =
              this.pickupAddressData.usedPickupLocations;
            this.arrAllPickupLocations =
              this.pickupAddressData.allPickupLocations;

            this.assignCopy();

            if (localStorage.getItem('pickupAddress')) {
              let pickupAddress = JSON.parse(
                localStorage.getItem('pickupAddress')
              );
              this.activeTab = 'pickup';
              this.isShowCheckbox = false;
              // localStorage.removeItem('pickupAddress');
              this.activeUsedTab = 0;
              this.user.pickupLocationAddress = pickupAddress;
              this.arrUsedPickupLocations.unshift(
                this.getUsedPickupLocation(pickupAddress)
              );
            }
          }
          // ////
          //this.calculateShipping();

          setTimeout(() => {
            if (this.countryObj) {
              this.shippingType = true;
              this.calculateShipping();
              this.calculateGrandTotal();
              //
              this.changeDetectorRef.detectChanges();
            }
          }, 300);
        }
      });
  }

  getOemCountry(countryName, countryCode) {
    for (var i = 0; i < this.arrOemCountries.length; i++) {
      if (
        this.arrOemCountries[i].countryName == countryName ||
        this.arrOemCountries[i].countryCode == countryCode ||
        this.arrOemCountries[i].countryName == countryCode
      ) {
        return this.arrOemCountries[i];
      }
    }
    return undefined;
  }

  selectUsedPickupAddress(index) {
    this.activeUsedTab = index;
    this.user.pickupLocationAddress = this.arrUsedPickupLocations[index];
    this.savedAddress = this.arrUsedPickupLocations[index];
    localStorage.setItem(
      'pickupAddress',
      JSON.stringify(this.user.pickupLocationAddress)
    );
    this.calculateShipping();
    this.calculateGrandTotal();
  }

  getZipPattern(countryObj) {
    this.user.shippingDetail.selectedCountry = countryObj.country;

    this.user.shippingDetail.phoneExtension = countryObj.ISD;
    this.charMinLength = countryObj.minPostalLength;
    this.charMaxLength = countryObj.maxPostalLength;
    this.user.shippingDetail.selectedCountryCode = countryObj.code;

    if (countryObj.isAlphaNumeric == 'Yes') {
      this.applyPattern = '^[- a-zA-Z0-9]+$';
    } else if (countryObj.isAlphaNumeric == 'No') {
      this.applyPattern = '^[0-9]*$';
    } else {
      this.applyPattern = '^[0-9]*$';
    }
  }

  getZipPatternBl(countryObj) {
    //  ////
    if (!countryObj) {
      return;
    }
    this.user.billingDetail.selectedCountry = countryObj.countryName;
    this.user.billingDetail.phoneExtension = countryObj.ISD;
    this.charMinLengthBl = countryObj.MinPostalLength;
    this.charMaxLengthBl = countryObj.PostalLength;

    if (countryObj.isAlphaNumeric == 'Yes') {
      this.applyPatternBl = '^[- a-zA-Z0-9]+$';
    } else if (countryObj.isAlphaNumeric == 'No') {
      this.applyPatternBl = '^[0-9]*$';
    } else {
      this.applyPatternBl = '^[0-9]*$';
    }
  }
  assignCopy() {
    this.arrAllPickupLocations = Object.assign(
      [],
      this.pickupAddressData.allPickupLocations
    );
  }

  getUniqueZoneCountries() {
    let dataALL = {};
    let dataCountry = [];
    this.arrCountries = [];
    var arrayForShipping = [];

    for (let i = 0; i < this.cartItemList.length; i++) {
      let productInfo =
        this.arrProductInfo[i][this.cartItemList[i].productCode];
      // ////
      let arrCountryCode = [];
      if (productInfo.zoneCountries) {
        for (let j = 0; j < productInfo.zoneCountries.length; j++) {
          dataALL[productInfo.zoneCountries[j].code] =
            productInfo.zoneCountries[j];
          arrCountryCode.push(productInfo.zoneCountries[j].code);
          arrayForShipping.push(productInfo.zoneCountries[j].code);
        }
      }

      dataCountry.push(arrCountryCode);
    }
    //

    var result = dataCountry.shift().filter(function (v) {
      return dataCountry.every(function (a) {
        return a.indexOf(v) !== -1;
      });
    });
    //
    let countterShipping = [];
    let uniqueShipping = [];
    //     for (let i = 0; i < arrayForShipping.length; i++) {
    //       let shipping_type_Array =
    //         dataALL[arrayForShipping[i]].shippingType.split(',');

    //       shipping_type_Array.map((item) => {
    //         countterShipping.push(item);
    //       });
    //     }
    //     countterShipping.map(item=>{

    // if(this.countNoOfOccurence(countterShipping,item)==result.length || result.length==1){

    //   if(uniqueShipping.findIndex(findingIndex=>findingIndex==item)<0){
    //
    //     uniqueShipping.push(item)
    //   }

    // }

    //     })
    //

    for (let i = 0; i < result.length; i++) {
      dataALL[result[i]].shippingOptions = [];
      if (
        dataALL[result[i]].shippingType &&
        dataALL[result[i]].shippingType != ''
      ) {
        //
        if (typeof dataALL[result[i]].shippingType === 'object') {
          dataALL[result[i]].shippingType = dataALL[result[i]].shippingType;
        } else {
          dataALL[result[i]].shippingType =
            dataALL[result[i]].shippingType.split(',');
        }
      } else {
        dataALL[result[i]].shippingOptions = [];
      }

      dataALL[result[i]].shippingType.map((item) => {
        let type = JSON.parse(
          JSON.stringify({
            type: item,
            countryCode: dataALL[result[i]].code,
            languageKey:
              this.languageData.web['shipping_type_' + item.toLowerCase()],
            selected: item == 'MAIL' ? true : false,
          })
        );

        dataALL[result[i]].shippingOptions.push(type);
      });

      //  delete dataALL[result[i]].shippingType;
      this.arrCountries.push(dataALL[result[i]]);
      //
      if (this.userTypeGuest) {
        for (let i = 0; i < this.arrOemCountries.length; i++) {
          if (this.arrOemCountries[i].isDefault == 'Yes') {
            let oemCountryObj = this.getOemCountry(
              this.arrOemCountries[i].countryName,
              this.arrOemCountries[i].Country
            );
          }
        }
      }

      if (this.arrCountrieCodesStr == '') {
        this.arrCountrieCodesStr = dataALL[result[i]].code;
      } else {
        this.arrCountrieCodesStr =
          this.arrCountrieCodesStr + ',' + dataALL[result[i]].code;
      }
    }
    setTimeout(() => {
      this.getAddressBook(undefined, '');
    }, 200);
  }

  countNoOfOccurence(data, value) {
    var result = 0;
    for (var item in data) {
      if (data[item] == value) {
        result++;
      }
    }

    return result;
  }

  showOnlyCommonShipping(shippingType) {
    let counter = 0;
    for (let i = 0; i < this.arrProductData.length; i++) {
      let zonecountries = this.arrProductData[i].zoneCountries;
      for (let j = 0; j < zonecountries.length; j++) {
        if (this.countryObj.code == zonecountries[j].code && zonecountries[j].shippingType) {
          if (
            typeof zonecountries[j].shippingType === 'string' &&
            (zonecountries[j].shippingType.split(',').findIndex((items)=> items == shippingType) > -1)) {
            counter++;
          }
        }
      }
      if (counter + 1 == this.arrProductData.length || counter == 1) {
        return false;
      } else {
        return true;
      }
    }
  }

  increaseQuantity(cartId: string, quantity: number, index: number) {
    this.changeCoupon();
  
    // Update quantity
    const updatedQuantity = quantity ? quantity + 1 : 1;
    this.cartItemList[index].quantity = updatedQuantity;
  
    if (quantity !== undefined) {
      this.changeQuantity(cartId, updatedQuantity, index);
    }
  
    // Skip FB Pixel logic if environment variable exists
    if (environment[global.env].FB_PIXEL) return;
  
    const itemToAdd = this.cartItemList[index];
    const productName = itemToAdd.online_text || '';
    const products = [
      {
        item_id: itemToAdd.productCode,
        item_name: productName,
        affiliation: '',
        coupon: '',
        currency: environment[global.env].currency,
        discount: '',
        index: '',
        item_brand: environment[global.env].oemCode,
        item_category: itemToAdd.ProductCat,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: itemToAdd.total,
        quantity: 1,
      },
    ];
  
    // Add tracking script for "add_to_cart" event
    const timestamp = moment().valueOf() * 1000;
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: ${JSON.stringify(products)},
          custom_event_id: ${timestamp}
        }
      });
    `;
    document.head.appendChild(gaScript);
  }
  

  decreaseQuantity(cartId: string, quantity: number, index: number) {
    this.changeCoupon();
  
    // Update quantity
    if (quantity !== undefined) {
      const updatedQuantity = quantity - 1;
      if (updatedQuantity <= 0) return;
  
      this.cartItemList[index].quantity = updatedQuantity;
      this.changeQuantity(cartId, updatedQuantity, index);
    } else {
      this.cartItemList[index].quantity = 1;
    }
  
    // Skip FB Pixel logic if environment variable exists
    if (environment[global.env].FB_PIXEL) return;
  
    const itemToDecrease = this.cartItemList[index];
    const productName = itemToDecrease.online_text || '';
    const products = [
      {
        item_id: itemToDecrease.productCode,
        item_name: productName,
        affiliation: '',
        coupon: '',
        currency: environment[global.env].currency,
        discount: '',
        index: '',
        item_brand: environment[global.env].oemCode,
        item_category: itemToDecrease.ProductCat,
        item_category2: '',
        item_category3: '',
        item_category4: '',
        item_category5: '',
        item_list_id: '',
        item_list_name: '',
        item_variant: '',
        location_id: '',
        price: itemToDecrease.total,
        quantity: 1,
      },
    ];
  
    // Add tracking script for "remove_from_cart" event
    const timestamp = moment().valueOf() * 1000;
    const gaScript = document.createElement('script');
    gaScript.innerHTML = `
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
          items: ${JSON.stringify(products)},
          custom_event_id: ${timestamp}
        }
      });
    `;
    document.head.appendChild(gaScript);
  }
  

  changeQuantity(id, quantity, index) {
    if (quantity != undefined) {
      if (quantity == 0) {
        quantity = 1;
      }
      quantity = parseInt(quantity);
      this.selectedCartQuantity = parseInt(quantity);
      this.cartItemList[index].isQuantityProcessing = true;
      //
      // this.getdeliveryDates();
      this.dataService
        .updateCartItems(
          id,
          quantity,
          this.cartItemList[index].productCode,
          this.cartItemList[index].paperCode,
          this.cartItemList[index].envelopesCode,
          this.cartItemList[index].coverCode,
          this.cartItemList[index].caseCode
        )
        .subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          if (resJSON.success == true) {
            if (resJSON.data != undefined && resJSON.data != null) {
              //
              //   'productCode=', this.cartItemList[index].productCode,
              //   'deadline=', resJSON.data.xmas_order_deadline,
              //   'productionTimeDays=', resJSON.data.productionTimeDays,
              //   'deliveryDays=', resJSON.data.normalDeliveryDays,
              //   'shippingDaysOffset=', resJSON.data.shippingDaysOffset)

              let itemDate = moment()
                .add(resJSON.data.productionTimeDays, 'days')
                .toDate();
              let itemDeadlineDate = moment(
                resJSON.data.xmas_order_deadline
              ).toDate();
              const isfutureDate = moment(itemDate).isAfter(
                moment(resJSON.data.xmas_order_deadline)
              );
              const isitemDeadlineDate = moment(itemDeadlineDate).isAfter(
                moment()
              );

              if (!isfutureDate) {
                let deliveryFrom =
                  Number(resJSON.data.productionTimeDays) +
                  Number(resJSON.data.normalDeliveryDays);
                deliveryFrom = this.calculateweekdays(deliveryFrom);
                let deliverBy =
                  deliveryFrom + Number(resJSON.data.shippingDaysOffset);
                deliveryFrom = this.calculateweekdays(deliveryFrom);
                this.cartItemList[index].productAvialableForDelivery = true;
                let startEDD = moment()
                  .add(deliveryFrom, 'days')
                  .format('DD/MM/YYYY');
                let endEDD = moment()
                  .add(deliverBy + 1, 'days')
                  .format('DD/MM/YYYY');

                if (startEDD && startEDD !== null && startEDD != '') {
                  startEDD = startEDD.replaceAll('/', '.');
                }
                if (endEDD && endEDD !== null && endEDD != '') {
                  endEDD = endEDD.replaceAll('/', '.');
                }

                let languageKey =
                  this.languageData.web['shipping_between_date_text'];

                languageKey = languageKey.replace('%e', startEDD);
                languageKey = languageKey.replace('%f', endEDD);
                this.shipping_between_date_text = languageKey;
                //
                let valueendEDD = moment(
                  this.cartItemList[index].xmas_order_deadline
                ).format('DD/MM/YYYY');

                this.cartItemList[index].endEDD = valueendEDD.replaceAll(
                  '/',
                  '.'
                );
              } else if (isitemDeadlineDate) {
                this.cartItemList[index].productAvialableForDelivery = true;

                let deliveryFrom = Number(resJSON.data.normalDeliveryDays);
                deliveryFrom = this.calculateweekdays(deliveryFrom);
                // Number(shippingProductInfo.productionTimeDays) +
                // Number(shippingProductInfo.deliveryDays);

                let deliverBy =
                  deliveryFrom + Number(resJSON.data.shippingDaysOffset);

                // let startEDD = moment(resJSON.data.xmas_order_deadline).add(deliveryFrom, 'days').format('DD/MM/YYYY');
                // let endEDD = moment().add(deliverBy + 1, 'days').format('DD/MM/YYYY');
                let startEDD = moment(resJSON.data.xmas_order_deadline)
                  .add(deliveryFrom, 'days')
                  .format('DD/MM/YYYY');
                let endEDD = moment(resJSON.data.xmas_order_deadline)
                  .add(deliveryFrom + 1, 'days')
                  .format('DD/MM/YYYY');

                if (startEDD && startEDD !== null && startEDD != '') {
                  startEDD = startEDD.replaceAll('/', '.');
                }
                if (endEDD && endEDD !== null && endEDD != '') {
                  endEDD = endEDD.replaceAll('/', '.');
                }

                let languageKey =
                  this.languageData.web['shipping_between_date_text'];

                languageKey = languageKey.replace('%e', startEDD);
                languageKey = languageKey.replace('%f', endEDD);
                this.shipping_between_date_text = languageKey;
                //
                let valueendEDD = moment(
                  this.cartItemList[index].xmas_order_deadline
                ).format('DD/MM/YYYY');

                this.cartItemList[index].endEDD = valueendEDD.replaceAll(
                  '/',
                  '.'
                );
                // moment(item.xmas_order_deadline).format('DD.MM.YYYY')
              } else {
                this.cartItemList[index].productAvialableForDelivery = false;
                let deliveryFrom =
                  Number(resJSON.data.productionTimeDays) +
                  Number(resJSON.data.normalDeliveryDays);
                deliveryFrom = this.calculateweekdays(deliveryFrom);
                let deliverBy =
                  deliveryFrom + Number(resJSON.data.shippingDaysOffset);
                // this.productAvialableForDelivery = true
                let startEDD = moment()
                  .add(deliveryFrom, 'days')
                  .format('DD/MM/YYYY');
                let endEDD = moment()
                  .add(deliverBy + 1, 'days')
                  .format('DD/MM/YYYY');

                if (startEDD && startEDD !== null && startEDD != '') {
                  startEDD = startEDD.replaceAll('/', '.');
                }
                if (endEDD && endEDD !== null && endEDD != '') {
                  endEDD = endEDD.replaceAll('/', '.');
                }

                let languageKey =
                  this.languageData.web['shipping_between_date_text'];

                languageKey = languageKey.replace('%e', startEDD);
                languageKey = languageKey.replace('%f', endEDD);
                this.shipping_between_date_text = languageKey;

                let valueendEDD = moment(
                  this.cartItemList[index].xmas_order_deadline
                ).format('DD/MM/YYYY');

                this.cartItemList[index].endEDD = valueendEDD.replaceAll(
                  '/',
                  '.'
                );
                //
              }

              setTimeout(() => {
                if (
                  this.cartItemList.findIndex(
                    (item) => !item.productAvialableForDelivery
                  ) > -1
                ) {
                  this.isBadgeImage = true;
                } else {
                  this.isBadgeImage = false;
                }
              }, 100);
            }
            this.changeCoupon(false);
            this.cartItemList[index].quantity = parseInt(quantity);
            this.calculatePrice(this.cartItemList[index], index);
            this.cartItemList[index].volumecouponIsApplied = undefined;
            this.calculateShipping();
            this.calculateGrandTotal();
            this.cartItemList[index].isQuantityProcessing = false;
          }
        }),
        (err) => {
          this.changeCoupon(false);
          this.cartItemList[index].quantity = parseInt(quantity);
          this.calculatePrice(this.cartItemList[index], index);
          this.cartItemList[index].volumecouponIsApplied = undefined;
          this.calculateShipping();
          this.calculateGrandTotal();
          this.cartItemList[index].isQuantityProcessing = false;
        };
    } else {
      this.priceContainer.quantity = 'qunatityCheck';
    }
  }

  calculateShipping(selectedShippingType = false) {
    //
    let groupShipping = {};
    let groupHandling = {};
    let totalGroupHandlingPrice = 0.0;

    this.totalShippinCost = 0.0;
    this.totalHandlingCost = 0.0;

    let productZone = 0;
    let zoneId = 0;

    for (let i = 0; i < this.arrCountries.length; i++) {
      if (
        this.selectedShippingCountry &&
        this.arrCountries[i].code == this.selectedShippingCountry.code
      ) {
        zoneId = this.arrCountries[i].id;
        break;
      }
    }
    //

    let shippingOptionsIndex = this.countryObj.shippingOptions.findIndex(
      (item) => item.selected === true
    );

    for (var j = 0; j < this.cartItemList.length; j++) {
      //var key = Object.keys(this.arrProductInfo[i])[0];

      //for(var i=0; i<this.arrProductInfo.length; i++)
      //{
      //var key = Object.keys(this.arrProductInfo[i])[0];

      // if(key === this.cartItemList[j].productCode);
      //{

      var productMultiInfo =
        this.arrProductInfo[j][this.cartItemList[j].productCode];

      if (shippingOptionsIndex < 0) {
        productZone = this.getProductZone(productMultiInfo.productZone, zoneId);
      } else {
        selectedShippingType =
          this.countryObj.shippingOptions[shippingOptionsIndex].type;
        productZone = this.getProductZoneBasedOnShippingType(
          productMultiInfo.productZone,
          selectedShippingType,
          zoneId
        );
      }
      this.cartItemList[j]['vat'] =
        productMultiInfo.productZone[productZone].productVat;
      //
      //
      // var obj = {key: productMultiInfo.productZone[productZone].productCode, orderLineProductVat: parseFloat(productMultiInfo.productZone[productZone].productVat)}
      // this.arrProductInformation.push(obj);
      // this.totalProductVat = this.totalProductVat +parseFloat(productMultiInfo.productZone[productZone].productVat);

      var shippingGroup = productMultiInfo.shippingGroup;
      var handlingGroup = productMultiInfo.handlingGroup;
      var priceValues = {};

      if (
        productMultiInfo &&
        productMultiInfo.productZone &&
        productZone > -1 &&
        productMultiInfo.productZone[productZone]
      ) {
        priceValues['firstPrice'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('firstPrice')
          ? productMultiInfo.productZone[productZone].firstPrice
          : 1;
        priceValues['shippingVat'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('shippingVat')
          ? productMultiInfo.productZone[productZone].shippingVat
          : 1;
        priceValues['handlingFee'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('handlingFee')
          ? productMultiInfo.productZone[productZone].handlingFee
          : 1;
        priceValues['handlingVat'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('handlingVat')
          ? productMultiInfo.productZone[productZone].handlingVat
          : 1;
        priceValues['additionalPrice'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('additionalPrice')
          ? productMultiInfo.productZone[productZone].additionalPrice
          : 1;
        priceValues['additionalHandlingFee'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('additionalHandlingFee')
          ? productMultiInfo.productZone[productZone].additionalHandlingFee
          : 1;
      }

      priceValues['key'] = this.cartItemList[j].productCode;
      priceValues['shippingGroup'] = shippingGroup;
      priceValues['handlingGroup'] = handlingGroup;

      var priceValues2 = {};
      if (
        productMultiInfo &&
        productMultiInfo.productZone &&
        productZone > -1 &&
        productMultiInfo.productZone[productZone]
      ) {
        priceValues2['firstPrice'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('firstPrice')
          ? productMultiInfo.productZone[productZone].firstPrice
          : 1;
        priceValues2['shippingVat'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('shippingVat')
          ? productMultiInfo.productZone[productZone].shippingVat
          : 1;
        priceValues2['handlingFee'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('handlingFee')
          ? productMultiInfo.productZone[productZone].handlingFee
          : 1;
        priceValues2['handlingVat'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('handlingVat')
          ? productMultiInfo.productZone[productZone].handlingVat
          : 1;
        priceValues2['additionalPrice'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('additionalPrice')
          ? productMultiInfo.productZone[productZone].additionalPrice
          : 1;
        priceValues2['additionalHandlingFee'] = productMultiInfo.productZone[
          productZone
        ].hasOwnProperty('additionalHandlingFee')
          ? productMultiInfo.productZone[productZone].additionalHandlingFee
          : 1;
      }

      priceValues2['key'] = this.cartItemList[j].productCode;
      priceValues2['shippingGroup'] = shippingGroup;
      priceValues2['handlingGroup'] = handlingGroup;

      if (productMultiInfo.isPackage == 'Yes') {
        priceValues['quantity'] = 1;
      } else {
        priceValues['quantity'] = this.cartItemList[j].quantity || 1;
      }
      if (productMultiInfo.isPackage == 'Yes') {
        priceValues2['quantity'] = 1;
      } else {
        priceValues2['quantity'] = this.cartItemList[j].quantity || 1;
      }
      // making unique shippingGroup
      if (groupShipping[shippingGroup]) {
        var result = this.checkIfProductExistInGroup(
          groupShipping[shippingGroup],
          this.cartItemList[j].productCode
        );
        if (result == false) {
          groupShipping[shippingGroup].push(priceValues);
        } else {
          if (productMultiInfo.isPackage == 'Yes') {
            result.quantity = result.quantity + 1;
          } else {
            result.quantity = result.quantity + this.cartItemList[j].quantity;
          }
        }
      } else {
        var groupByShipping = [];
        groupByShipping.push(priceValues);
        groupShipping[shippingGroup] = groupByShipping;
      }

      // making unique handlingGroup
      if (groupHandling[handlingGroup]) {
        var result = this.checkIfProductExistInGroup(
          groupHandling[handlingGroup],
          this.cartItemList[j].productCode
        );
        if (result == false) {
          groupHandling[handlingGroup].push(priceValues2);
        } else {
          if (productMultiInfo.isPackage == 'Yes') {
            result.quantity = result.quantity + 1;
          } else {
            result.quantity = result.quantity + this.cartItemList[j].quantity;
          }
        }
      } else {
        var groupByHandling = [];
        groupByHandling.push(priceValues2);
        groupHandling[handlingGroup] = groupByHandling;
      }
      // break;
      //}
      //}
    }
    // ---------------------  shipping  ------------------------------------------------------
    for (var key in groupShipping) {
      let arrShippingInfo = groupShipping[key];

      let maxFirstPrice = 0;
      let maxFirstPriceIndex = -1;
      for (let i = 0; i < arrShippingInfo.length; i++) {
        if (maxFirstPrice < parseFloat(arrShippingInfo[i].firstPrice)) {
          maxFirstPrice = parseFloat(arrShippingInfo[i].firstPrice);
          maxFirstPriceIndex = i;
        }
      }

      for (var i = 0; i < arrShippingInfo.length; i++) {
        var orderLineShippingCost = 0.0;
        if (
          arrShippingInfo[maxFirstPriceIndex] &&
          arrShippingInfo[i].key == arrShippingInfo[maxFirstPriceIndex].key
        ) {
          orderLineShippingCost =
            maxFirstPrice +
            (arrShippingInfo[i].quantity - 1) *
              parseFloat(arrShippingInfo[i].additionalPrice);
          this.totalShippinCost =
            this.totalShippinCost +
            maxFirstPrice +
            (arrShippingInfo[i].quantity - 1) *
              parseFloat(arrShippingInfo[i].additionalPrice);
        } else {
          orderLineShippingCost =
            parseInt(arrShippingInfo[i].quantity) *
            parseFloat(arrShippingInfo[i].additionalPrice);
          this.totalShippinCost =
            this.totalShippinCost +
            parseInt(arrShippingInfo[i].quantity) *
              parseFloat(arrShippingInfo[i].additionalPrice);
        }
        arrShippingInfo[i].orderLineShippingCost = orderLineShippingCost;
        var obj = {
          key: arrShippingInfo[i].key,
          orderLineShippingCost: arrShippingInfo[i].orderLineShippingCost,
        };
        this.arrShippingInformation.push(obj);
      }
    }

    this.totalShippinCost = parseFloat(this.totalShippinCost);
    if (
      this.activeTab == 'pickup' &&
      this.user.pickupLocationAddress['shippingDiscount']
    ) {
      this.isShowDiscountMsg = true;
      this.shippingDiscAmount = 0.0;
      var discountedAmount = 0.0;
      discountedAmount =
        (this.totalShippinCost *
          parseFloat(this.user.pickupLocationAddress['shippingDiscount'])) /
        100;
      this.shippingDiscAmount = discountedAmount;
      this.shippingDiscAmount = this.shippingDiscAmount.toFixed(2);
    }
    // ---------------------  handling  -----------------

    for (var key in groupHandling) {
      if (key == '') {
        continue;
      }

      let arrHandlingInfo = groupHandling[key];
      let maxFirstPrice = 0;
      let maxFirstPriceIndex = -1;
      for (let i = 0; i < arrHandlingInfo.length; i++) {
        if (maxFirstPrice <= parseFloat(arrHandlingInfo[i].handlingFee)) {
          maxFirstPrice = parseFloat(arrHandlingInfo[i].handlingFee);
          maxFirstPriceIndex = i;
        }
      }

      let groupHandlingCost = 0.0;
      for (var i = 0; i < arrHandlingInfo.length; i++) {
        let orderLineHandlingCost = 0.0;
        if (arrHandlingInfo[i].key == arrHandlingInfo[maxFirstPriceIndex].key) {
          groupHandlingCost =
            maxFirstPrice +
            (arrHandlingInfo[i].quantity - 1) *
              parseFloat(arrHandlingInfo[i].additionalHandlingFee);
          this.totalHandlingCost = this.totalHandlingCost + groupHandlingCost;
        } else {
          groupHandlingCost =
            arrHandlingInfo[i].quantity *
            parseFloat(arrHandlingInfo[i].additionalHandlingFee);
          this.totalHandlingCost = this.totalHandlingCost + groupHandlingCost;
        }
        arrHandlingInfo[i].orderLineHandlingCost = groupHandlingCost;
        let obj = {
          key: arrHandlingInfo[i].key,
          orderLineHandlingCost: arrHandlingInfo[i].orderLineHandlingCost,
        };
        this.arrHandlingInformation.push(obj);
      }
      groupHandlingCost = Number(groupHandlingCost);
      if (groupHandlingCost == 0) {
        continue;
      }
      var handlingLabelKey = 'mycart_payment_label_handling' + key;
      // var handlingLabelValue = this.languageData[handlingLabelKey]!=undefined?this.languageData[handlingLabelKey]:handlingLabelKey;
      // this.arrHandlingCost.push({handlingLabel:handlingLabelValue, groupHandlingCost:groupHandlingCost.toFixed(2)})
    }
    //
    //this.priceService.getCommaOrDotValue(sumOfVat) + this.priceService.getCurrencySymbol()

    this.getdeliveryDates();

    // this.countryObj.shippingOptions.map((item) => {
    //
    //   setTimeout(() => {
    //     this.calculateShippingForLabel(item);

    //   }, 200);
    // });
  }
  calculateShippingForLabel(shippingData) {
    //
    let groupShipping = {};
    let groupHandling = {};
    let totalGroupHandlingPrice = 0.0;

    let totalShippinCost: any = 0.0;
    let totalHandlingCost = 0.0;

    let productZone = 0;
    let zoneId = 0;

    let selectedShippingType;
    for (let i = 0; i < this.arrCountries.length; i++) {
      if (
        this.selectedShippingCountry &&
        this.arrCountries[i].code == this.selectedShippingCountry.code
      ) {
        zoneId = this.arrCountries[i].id;
        break;
      }
    }
    let shippingOptions = shippingData != undefined ? shippingData.type : false;
    let cartItemList = JSON.parse(JSON.stringify(this.cartItemList));
    let arrProductInfo = JSON.parse(JSON.stringify(this.arrProductInfo));

    for (var j = 0; j < cartItemList.length; j++) {
      var productMultiInfo = arrProductInfo[j][cartItemList[j].productCode];
      if (!shippingOptions) {
        productZone = this.getProductZone(productMultiInfo.productZone, zoneId);
        //
      } else {
        selectedShippingType = shippingOptions;

        productZone = this.getProductZoneBasedOnShippingType(
          productMultiInfo.productZone,
          selectedShippingType,
          shippingData.countryCode
        );
      }

      var shippingGroup = productMultiInfo.shippingGroup;
      var handlingGroup = productMultiInfo.handlingGroup;
      var priceValues = {};
      priceValues['firstPrice'] =
        productMultiInfo.productZone[productZone].firstPrice || 1;
      priceValues['shippingVat'] =
        productMultiInfo.productZone[productZone].shippingVat || 1;
      priceValues['handlingFee'] =
        productMultiInfo.productZone[productZone].handlingFee || 1;
      priceValues['handlingVat'] =
        productMultiInfo.productZone[productZone].handlingVat || 1;
      priceValues['additionalPrice'] =
        productMultiInfo.productZone[productZone].additionalPrice || 1;
      priceValues['additionalHandlingFee'] =
        productMultiInfo.productZone[productZone].additionalHandlingFee || 1;
      priceValues['key'] = this.cartItemList[j].productCode;
      priceValues['shippingGroup'] = shippingGroup;
      priceValues['handlingGroup'] = handlingGroup;

      var priceValues2 = {};
      priceValues2['firstPrice'] =
        productMultiInfo.productZone[productZone].firstPrice || 1;
      priceValues2['shippingVat'] =
        productMultiInfo.productZone[productZone].shippingVat || 1;
      priceValues2['handlingFee'] =
        productMultiInfo.productZone[productZone].handlingFee || 1;
      priceValues2['handlingVat'] =
        productMultiInfo.productZone[productZone].handlingVat || 1;
      priceValues2['additionalPrice'] =
        productMultiInfo.productZone[productZone].additionalPrice || 1;
      priceValues2['additionalHandlingFee'] =
        productMultiInfo.productZone[productZone].additionalHandlingFee || 1;
      priceValues2['key'] = this.cartItemList[j].productCode;
      priceValues2['shippingGroup'] = shippingGroup;
      priceValues2['handlingGroup'] = handlingGroup;

      if (productMultiInfo.isPackage == 'Yes') {
        priceValues['quantity'] = 1;
      } else {
        priceValues['quantity'] = this.cartItemList[j].quantity || 1;
      }
      if (productMultiInfo.isPackage == 'Yes') {
        priceValues2['quantity'] = 1;
      } else {
        priceValues2['quantity'] = this.cartItemList[j].quantity || 1;
      }
      // making unique shippingGroup
      if (groupShipping[shippingGroup]) {
        var result = this.checkIfProductExistInGroup(
          groupShipping[shippingGroup],
          this.cartItemList[j].productCode
        );
        if (result == false) {
          groupShipping[shippingGroup].push(priceValues);
        } else {
          if (productMultiInfo.isPackage == 'Yes') {
            result.quantity = result.quantity + 1;
          } else {
            result.quantity = result.quantity + this.cartItemList[j].quantity;
          }
        }
      } else {
        var groupByShipping = [];
        groupByShipping.push(priceValues);
        groupShipping[shippingGroup] = groupByShipping;
      }

      // making unique handlingGroup
      if (groupHandling[handlingGroup]) {
        var result = this.checkIfProductExistInGroup(
          groupHandling[handlingGroup],
          this.cartItemList[j].productCode
        );
        if (result == false) {
          groupHandling[handlingGroup].push(priceValues2);
        } else {
          if (productMultiInfo.isPackage == 'Yes') {
            result.quantity = result.quantity + 1;
          } else {
            result.quantity = result.quantity + this.cartItemList[j].quantity;
          }
        }
      } else {
        var groupByHandling = [];
        groupByHandling.push(priceValues2);
        groupHandling[handlingGroup] = groupByHandling;
      }
    }

    // ---------------------  shipping  ------------------------------------------------------
    for (var key in groupShipping) {
      let arrShippingInfo = groupShipping[key];

      let maxFirstPrice = 0;
      let maxFirstPriceIndex = -1;
      for (let i = 0; i < arrShippingInfo.length; i++) {
        if (maxFirstPrice < parseFloat(arrShippingInfo[i].firstPrice)) {
          maxFirstPrice = parseFloat(arrShippingInfo[i].firstPrice);
          maxFirstPriceIndex = i;
        }
      }

      for (var i = 0; i < arrShippingInfo.length; i++) {
        var orderLineShippingCost = 0.0;
        if (
          arrShippingInfo[maxFirstPriceIndex] &&
          arrShippingInfo[i].key == arrShippingInfo[maxFirstPriceIndex].key
        ) {
          orderLineShippingCost =
            maxFirstPrice +
            (arrShippingInfo[i].quantity - 1) *
              parseFloat(arrShippingInfo[i].additionalPrice);
          totalShippinCost =
            totalShippinCost +
            maxFirstPrice +
            (arrShippingInfo[i].quantity - 1) *
              parseFloat(arrShippingInfo[i].additionalPrice);
        } else {
          orderLineShippingCost =
            parseInt(arrShippingInfo[i].quantity) *
            parseFloat(arrShippingInfo[i].additionalPrice);
          totalShippinCost =
            totalShippinCost +
            parseInt(arrShippingInfo[i].quantity) *
              parseFloat(arrShippingInfo[i].additionalPrice);
        }
        arrShippingInfo[i].orderLineShippingCost = orderLineShippingCost;
        var obj = {
          key: arrShippingInfo[i].key,
          orderLineShippingCost: arrShippingInfo[i].orderLineShippingCost,
        };
        //  this.arrShippingInformation.push(obj);
      }
    }

    totalShippinCost = parseFloat(totalShippinCost);

    //
    // let deliveryFrom =
    //   Number(productMultiInfo.productZone[productZone].productionTime) +
    //   Number(productMultiInfo.productZone[productZone].deliveryDays);

    // let deliverBy =
    //   deliveryFrom +
    //   Number(productMultiInfo.productZone[productZone].shippingDaysOffset);
    // let startEDD = moment().add(deliveryFrom, 'days').format('DD/MM/YYYY');
    // let endEDD = moment().add(deliverBy + 1, 'days').format('DD/MM/YYYY');

    let deadlineObject;
    let startEDD, endEDD;
    let firstConditionDates = [];
    let secondConditionDates = [];

    if (
      this.deliveryDates &&
      this.deliveryDates[shippingData.type] &&
      Array.isArray(this.deliveryDates[shippingData.type])
    ) {
      this.deliveryDates[shippingData.type].map((item) => {
        // if (moment(item.xmas_order_deadline).isSame(formattedMaxDate)) {
        //   deadlineObject = item;
        // }

        let date24xmas: any;
        let date22xmas: any;
        let shippingProductInfo = item;
        let itemDeadlineDate = moment(
          shippingProductInfo.xmas_order_deadline
        ).toDate();
        const isOrderDateBeforeDeadlineOrSame =
          moment().isBefore(itemDeadlineDate) ||
          moment().isSame(itemDeadlineDate);
        const dateAfterSumOfProductionAndShipping = moment()
          .add(
            Number(shippingProductInfo.productionTimeDays) +
              Number(shippingProductInfo.deliveryDays),
            'days'
          )
          .toDate();
        date24xmas = moment(
          this.productInfo.calculateFromDate,
          'YYYY/MM/DD'
        ).toDate();
        date22xmas = moment(date24xmas).subtract(2, 'days').toDate();
        let startEDD, endEDD;
        const isOrderDateBefore24OrSame =
          moment(dateAfterSumOfProductionAndShipping).isBefore(date24xmas) ||
          moment(dateAfterSumOfProductionAndShipping).isSame(date24xmas);
        //

        //   'orderDate=', moment(moment(), 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss') + '\n',
        //   'productionTimeDays=', shippingProductInfo.productionTimeDays + '\n',
        //   'deliveryDays=', shippingProductInfo.deliveryDays + '\n',
        //   'dateAfterSumOfProductionAndShipping=', moment(dateAfterSumOfProductionAndShipping, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss') + '\n',
        //   'deadline=', shippingProductInfo.xmas_order_deadline,
        // )

        //
        //   isOrderDateBeforeDeadlineOrSame,
        //   'isOrderDateBefore24OrSame=',
        //   isOrderDateBefore24OrSame)
        if (isOrderDateBeforeDeadlineOrSame && isOrderDateBefore24OrSame) {
          //

          let deliveryFrom =
            Number(shippingProductInfo.productionTimeDays) +
            Number(shippingProductInfo.deliveryDays);
          deliveryFrom = this.calculateweekdays(deliveryFrom);

          startEDD = moment().add(deliveryFrom, 'days'); //.format('DD/MM/YYYY');
          endEDD = moment().add(deliveryFrom + 1, 'days'); //.format('DD/MM/YYYY');

          firstConditionDates.push({ startEDD: startEDD, endEDD: endEDD });
        } else if (
          !isOrderDateBeforeDeadlineOrSame &&
          isOrderDateBefore24OrSame
        ) {
          //
          let deliveryFrom =
            Number(shippingProductInfo.productionTimeDays) +
            Number(shippingProductInfo.deliveryDays);
          deliveryFrom = this.calculateweekdays(deliveryFrom);

          startEDD = moment(date22xmas).add(deliveryFrom, 'days'); //.format('DD/MM/YYYY');
          endEDD = moment(date22xmas).add(deliveryFrom + 1, 'days'); //.format('DD/MM/YYYY');
          secondConditionDates.push({ startEDD: startEDD, endEDD: endEDD });
        } else if (
          !isOrderDateBeforeDeadlineOrSame &&
          moment().isBefore(date22xmas)
        ) {
          //
          let deliveryFrom =
            Number(shippingProductInfo.productionTimeDays) +
            Number(shippingProductInfo.deliveryDays);
          deliveryFrom = this.calculateweekdays(deliveryFrom);

          startEDD = moment(date22xmas).add(deliveryFrom, 'days'); //.format('DD/MM/YYYY');
          endEDD = moment(date22xmas).add(deliveryFrom + 1, 'days'); //.format('DD/MM/YYYY');
          secondConditionDates.push({ startEDD: startEDD, endEDD: endEDD });
        } else {
          //
          let deliveryFrom =
            Number(shippingProductInfo.productionTimeDays) +
            Number(shippingProductInfo.deliveryDays);
          deliveryFrom = this.calculateweekdays(deliveryFrom);
          startEDD = moment().add(deliveryFrom, 'days'); //.format('DD/MM/YYYY');
          endEDD = moment().add(deliveryFrom + 1, 'days'); //.format('DD/MM/YYYY');
          secondConditionDates.push({ startEDD: startEDD, endEDD: endEDD });
        }
      });
    }
    //

    // let shippingProductInfo = deadlineObject;
    // let itemDeadlineDate = moment(shippingProductInfo.xmas_order_deadline).toDate();

    // const isOrderDateBeforeDeadlineOrSame = (moment().isBefore(itemDeadlineDate) || moment().isSame(itemDeadlineDate));
    // const dateAfterSumOfProductionAndShipping = moment().add(Number(shippingProductInfo.productionTimeDays) + Number(shippingProductInfo.deliveryDays), 'days').toDate();
    // const date24xmas = moment('24/12/2022', 'DD/MM/YYYY').toDate();
    // const date22xmas = moment('22/12/2022', 'DD/MM/YYYY').toDate();
    // let startEDD, endEDD;
    // const isOrderDateBefore24OrSame = (moment(dateAfterSumOfProductionAndShipping).isBefore(date24xmas) || moment(dateAfterSumOfProductionAndShipping).isSame(date24xmas));
    //

    //   'orderDate=', moment(moment(), 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss') + '\n',
    //   'productionTimeDays=', shippingProductInfo.productionTimeDays + '\n',
    //   'deliveryDays=', shippingProductInfo.deliveryDays + '\n',
    //   'dateAfterSumOfProductionAndShipping=', moment(dateAfterSumOfProductionAndShipping, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm:ss') + '\n',
    //   'deadline=', shippingProductInfo.xmas_order_deadline,
    // )

    //
    //   isOrderDateBeforeDeadlineOrSame,
    //   'isOrderDateBefore24OrSame=',
    //   isOrderDateBefore24OrSame)

    // if (isOrderDateBeforeDeadlineOrSame && isOrderDateBefore24OrSame) {
    //

    //   let deliveryFrom =
    //     Number(shippingProductInfo.productionTimeDays) +
    //     Number(shippingProductInfo.deliveryDays);

    //   startEDD = moment().add(deliveryFrom, 'days').format('DD/MM/YYYY');
    //   endEDD = moment().add(deliveryFrom + 1, 'days').format('DD/MM/YYYY');

    // }

    // if (!isOrderDateBeforeDeadlineOrSame && isOrderDateBefore24OrSame) {
    //
    //   let deliveryFrom =
    //     Number(shippingProductInfo.productionTimeDays) +
    //     Number(shippingProductInfo.deliveryDays);

    //   startEDD = moment(date22xmas).add(deliveryFrom, 'days').format('DD/MM/YYYY');
    //   endEDD = moment(date22xmas).add(deliveryFrom + 1, 'days').format('DD/MM/YYYY');

    // }
    let maxStartDate, maxEndDate;
    //
    if (secondConditionDates.length > 0) {
      let moments = secondConditionDates.map((d) => moment(d.startEDD)),
        maxStartDate = moment.max(moments);
      secondConditionDates.map((item) => {
        if (moment(item.startEDD).isSame(maxStartDate)) {
          startEDD = moment(item.startEDD).format('DD/MM/YYYY');
          endEDD = moment(item.endEDD).format('DD/MM/YYYY');
        }
      });
    } else if (
      firstConditionDates.length > 0 ||
      firstConditionDates.length > 0
    ) {
      let moments = firstConditionDates.map((d) => moment(d.startEDD)),
        maxStartDate = moment.max(moments);
      firstConditionDates.map((item) => {
        if (moment(item.startEDD).isSame(maxStartDate)) {
          startEDD = moment(item.startEDD).format('DD/MM/YYYY');
          endEDD = moment(item.endEDD).format('DD/MM/YYYY');
        }
      });
    }

    if (startEDD && startEDD !== null && startEDD != '') {
      startEDD = startEDD.replaceAll('/', '.');
    }
    if (endEDD && endEDD !== null && endEDD != '') {
      endEDD = endEDD.replaceAll('/', '.');
    }
    //

    let languageKey =
      this.languageData.web['shipping_type_' + shippingData.type.toLowerCase()];
    //
    languageKey = languageKey.replace(
      '%d',
      this.getCommaOrDotValue(totalShippinCost)
    );
    languageKey = languageKey.replace('%e', startEDD);
    languageKey = languageKey.replace('%f', endEDD);
    shippingData.languageKey = languageKey;

    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 1);
  }
  setCartImpression() {
    //

    //return false;

    let userInteracted = localStorage.getItem('interacted');

    const scriptList = document.querySelectorAll(
      "script[type='text/javascript']"
    );
    const convertedNodeList = Array.from(scriptList);
    const gtmScript = convertedNodeList.find((script) => script.id === 'gtm');

    if (gtmScript == undefined) {
      initializeGtmScript();
    }

    setTimeout(() => {
      var gaScript = document.createElement('script');

      //FB Pixel Purchase Event
      if (environment[global.env].FB_PIXEL) {
      } else {
        let products = [];
        this.cartItemList.map((item) => {
          let productName =
            item.online_text == null || item.online_text == undefined
              ? ''
              : item.online_text;
          products.push({
            item_id: item.productCode,
            item_name: productName,
            affiliation: '',
            coupon: '',
            currency: environment[global.env].currency,
            discount: '',
            index: '',
            item_brand: environment[global.env].oemCode,
            item_category: item.ProductCat,
            item_category2: '',
            item_category3: '',
            item_category4: '',
            item_category5: '',
            item_list_id: '',
            item_list_name: '',
            item_variant: '',
            location_id: '',
            price: item.total,
            quantity: item.quantity,
          });
        });

        let timestamp: any = moment().valueOf() * 1000;
        gaScript.innerHTML =
          `dataLayer.push({ ecommerce: null }); 
      dataLayer.push({
        event: "view_cart",
        ecommerce: {
          currency: '` +
          environment[global.env].currency +
          `' ,
          value: '` +
          this.grandTotalAfterShipping +
          `',
          items: ` +
          JSON.stringify(products) +
          `,
          'custom_event_id':` +
          timestamp +
          `
        }
      });`;
      }

      document.head.appendChild(gaScript);
    }, 1000);

    function initializeGtmScript() {
      var gtmScript = document.createElement('script');
      gtmScript.setAttribute('id', 'gtm');
      gtmScript.setAttribute('type', 'text/javascript');
      gtmScript.innerHTML =
        `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','` +
        environment[global.env].GTM_CODE +
        `');`;
      document.head.appendChild(gtmScript);
    }
  }

  calculateweekdays(deliveryDays) {
    const startDate = moment();
    const endDate = moment().add(deliveryDays, 'days');

    // Initialize a counter for Sundays
    let sundayCount = 0;

    for (
      let date = moment(startDate);
      date.isSameOrBefore(endDate);
      date.add(1, 'day')
    ) {
      // Check if the current date falls on a Sunday
      if (date.day() === 0) {
        sundayCount++;
      }
    }
    //
    return deliveryDays + sundayCount;
  }
  calculateGrandTotal(isFirstCartImpression = false) {
    //
    //  ////
    for (let i = 0; i < this.cartItemList.length; i++) {
      let cartItem1 = this.cartItemList[i];
      if (cartItem1.productPrice == undefined) {
        return;
      }
    }

    this.grandTotal = 0;
    this.grandTotalAfterShipping = 0.0;
    let envelopesPrice: any = 0;
    for (let i = 0; i < this.cartItemList.length; i++) {
      let cartItem = this.cartItemList[i];
      this.grandTotal =
        Number(this.grandTotal) + parseFloat(cartItem.productPrice);
      if (cartItem.envelopesPrice) {
        envelopesPrice =
          envelopesPrice + cartItem.envelopesPrice * cartItem.quantity;
      }
    }

    for (var j = 0; j < this.arrShippingInformation.length; j++) {
      for (var i = 0; i < this.cartItemList.length; i++) {
        if (
          this.cartItemList[i].productCode == this.arrShippingInformation[j].key
        ) {
          this.cartItemList[i].shipping =
            this.arrShippingInformation[j].orderLineShippingCost;
          break;
        }
      }
    }

    for (var j = 0; j < this.arrHandlingInformation.length; j++) {
      for (var i = 0; i < this.cartItemList.length; i++) {
        if (
          this.cartItemList[i].productCode == this.arrHandlingInformation[j].key
        ) {
          this.cartItemList[i].handling =
            this.arrHandlingInformation[j].orderLineHandlingCost;
          break;
        }
      }
    }

    this.grandTotal = this.grandTotal.toFixed(2);
    if (this.shippingDiscAmount != undefined && this.shippingDiscAmount != 0) {
      this.grandTotalAfterShipping =
        parseFloat(this.grandTotal) +
        parseFloat(envelopesPrice) +
        parseFloat(this.totalShippinCost) +
        parseFloat(this.totalHandlingCost) -
        this.shippingDiscAmount;
      //
    } else {
      this.grandTotalAfterShipping =
        parseFloat(this.grandTotal) +
        parseFloat(envelopesPrice) +
        parseFloat(this.totalShippinCost) +
        parseFloat(this.totalHandlingCost);
      //
    }
    this.grandTotalAfterShipping = parseFloat(
      this.grandTotalAfterShipping.toFixed(2)
    );
    // if (!isFirstCartImpression) {
    //   this.calculateVats();
    // }
    this.calculateVats();

    if (this.grandTotalAfterShipping > environment[global.env].maxCartValue) {
      this.maxCartValue = environment[global.env].maxCartValue;
      document.getElementById('pricelimit').click();
      // $('#pricelimit').modal('show');
      return;
    }
    this.grandTotalOriginal = this.grandTotalAfterShipping;
    this.isPaymentChecked = false;
    if (localStorage.getItem('couponcode')) {
      let couponCode = localStorage.getItem('couponcode');
      this.applyCouponCode(couponCode);
    }
    let selectePaperArray = this.cartItemList.filter(
      (x) => x.paper != '' && x.selectedPaper == null
    );
    if (selectePaperArray.length > 0) {
      this.showPaperSelectError = true;
    } else {
      this.showPaperSelectError = false;
    }
  }

  getProductZone(arrProductZone, zoneId) {
    //  ////
    for (var i = 0; i < arrProductZone.length; i++) {
      if (arrProductZone[i].zoneId == zoneId) {
        return i;
      }
    }
    return 0;
  }
  getProductZoneBasedOnShippingType(arrProductZone, shippingType, code) {
    ////
    for (var i = 0; i < arrProductZone.length; i++) {
      if (this.countryObj) {
        if (
          arrProductZone[i].shippingType == shippingType &&
          arrProductZone[i].countryCode == this.countryObj.code
        ) {
          return i;
        }
      } else {
        if (
          arrProductZone[i].shippingType == shippingType &&
          arrProductZone[i].countryCode == code
        ) {
          return i;
        }
      }
    }
    return 0;
  }

  editProjectName(projectData) {
    this.editProjectAlbumName = projectData.projectName;
    projectData.isDisplayInputField = true;
    setTimeout(() => {
      document.getElementById('renameProjectName').focus();
    }, 100);
  }

  getCountry(countryName, countryCode) {
    for (var i = 0; i < this.arrCountries.length; i++) {
      if (
        this.arrCountries[i].country == countryName ||
        this.arrCountries[i].code == countryCode
      ) {
        return this.arrCountries[i];
      }
    }
    return undefined;
  }

  checkIfProductExistInGroup(arrGroupInfo, productCode) {
    for (var i = 0; i < arrGroupInfo.length; i++) {
      if (arrGroupInfo[i].key == productCode) {
        return arrGroupInfo[i];
      }
    }
    return false;
  }

  calculatePrice(cartItem, index) {
    if (cartItem.selectedPaper == null && cartItem.paper.length > 0) {
      return;
    }
    if (cartItem.selectedEnvelope == null && cartItem.envelopes.length > 0) {
      return;
    }

    var productInfo = this.arrProductData[index];
    var minPage = parseInt(productInfo.productDetail.minPage);
    var pageCount = cartItem.pageCount;

    if (cartItem.preview == 'SOFTBOOK-' || cartItem.preview == 'RINGBOOK') {
      pageCount = parseInt(pageCount) - 2;
    }
    if (
      cartItem.preview == 'WALLCALENDAR' ||
      cartItem.preview == 'DESKCALENDAR'
    ) {
      pageCount = pageCount - 1;
    }
    let envelopeIndex = 0;
    let paperIndex = 0;
    let coverIndex = 0;
    let quantityIndex = 0;
    //////////
    if (cartItem.arrPaperInfo != undefined) {
      if (cartItem.preview == 'PRINT') {
        paperIndex = this.getDesiredPaperForQuanity(
          cartItem.arrPaperInfo,
          cartItem.totalPrints,
          cartItem.selectedPaper
        );
      } else {
        paperIndex = this.getDesiredPaperForQuanity(
          cartItem.arrPaperInfo,
          cartItem.quantity,
          cartItem.selectedPaper
        );
      }
    }
    quantityIndex = this.getDesiredQuantity(
      cartItem.arrdefaultProductQuantities,
      cartItem.quantity
    );
    //////////
    if (cartItem.envelopes.length > 0 && cartItem.selectedEnvelope) {
      envelopeIndex = this.getDesiredEnvelop(
        cartItem.envelopes,
        cartItem.selectedEnvelope
      );
    }
    if (cartItem.cover.length > 0 && cartItem.selectedCover != null) {
      coverIndex = this.getDesiredCover(cartItem.cover, cartItem.selectedCover);
    }
    this.showPromoAndDefaultPrice(
      productInfo,
      pageCount,
      cartItem,
      paperIndex,
      coverIndex,
      0,
      envelopeIndex,
      minPage
    );
  }

  showPromoAndDefaultPrice(
    priceDefault,
    pageCount,
    cartItem,
    paperIndex,
    coverIndex,
    caseIndex,
    envelopeIndex,
    minPage
  ) {
    //
    let paperIndexSingleQuantity = 0;
    if (cartItem.arrPaperInfo != undefined) {
      paperIndexSingleQuantity = this.getDesiredPaperForQuanity(
        cartItem.arrPaperInfo,
        1,
        cartItem.selectedPaper
      );
    }

    if (priceDefault.promoInfo == null || priceDefault.promoInfo.length == 0) {
      let price = this.priceService.priceCalculator(
        priceDefault,
        pageCount,
        minPage,
        false,
        paperIndex,
        coverIndex,
        caseIndex,
        envelopeIndex,
        cartItem.quantity,
        cartItem
      );
      let listPrice = this.priceService.priceCalculator(
        priceDefault,
        pageCount,
        minPage,
        false,
        paperIndexSingleQuantity,
        coverIndex,
        caseIndex,
        envelopeIndex,
        1,
        cartItem
      );
      //
      if (cartItem.preview == 'PRINT') {
        cartItem.productPrice = price * Number(cartItem.totalPrints);
        cartItem.defaultPrice = price * Number(cartItem.totalPrints);
      } else {
        cartItem.productPrice = price;
        cartItem.defaultPrice = price;
      }

      cartItem.listPrice = listPrice;

      cartItem.promoPrice = false;
      cartItem.listPricePromo = false;
      cartItem.promoId = '';
      if (cartItem.listPrice == 0.0) {
        this.disableCheckout = true;
      }
      if (cartItem.preview == 'PRINT') {
        if (
          Number(cartItem.listPrice * Number(cartItem.totalPrints)).toFixed(
            2
          ) != parseFloat(cartItem.defaultPrice).toFixed(2)
        ) {
          cartItem.isVolumeDiscount = true;
          cartItem.listPricePromo = price / cartItem.quantity;
        } else {
          cartItem.isVolumeDiscount = false;
        }
      } else {
        //
        if (
          Number(cartItem.listPrice * cartItem.quantity).toFixed(2) !=
          parseFloat(cartItem.defaultPrice).toFixed(2)
        ) {
          cartItem.isVolumeDiscount = true;
          cartItem.listPricePromo = price / cartItem.quantity;
        } else {
          cartItem.isVolumeDiscount = false;
        }
      }
    } else {
      cartItem.defaultPrice = this.priceService.priceCalculator(
        priceDefault.promoInfo,
        pageCount,
        minPage,
        false,
        paperIndex,
        coverIndex,
        caseIndex,
        envelopeIndex,
        cartItem.quantity,
        cartItem
      );
      cartItem.productPrice = this.priceService.priceCalculator(
        priceDefault,
        pageCount,
        minPage,
        false,
        paperIndex,
        coverIndex,
        caseIndex,
        envelopeIndex,
        cartItem.quantity,
        cartItem
      );
      cartItem.listPrice = this.priceService.priceCalculator(
        priceDefault.promoInfo,
        pageCount,
        minPage,
        false,
        paperIndexSingleQuantity,
        coverIndex,
        caseIndex,
        envelopeIndex,
        1,
        cartItem
      );
      cartItem.listPricePromo = this.priceService.priceCalculator(
        priceDefault,
        pageCount,
        minPage,
        false,
        paperIndexSingleQuantity,
        coverIndex,
        caseIndex,
        envelopeIndex,
        1,
        cartItem
      );

      cartItem.promoId = priceDefault.promoInfo.promoId;
      if (cartItem.preview == 'PRINT') {
        let totalPrints = Number(cartItem.totalPrints);
        cartItem.promoPrice = cartItem.productPrice * totalPrints;
        cartItem.productPrice = cartItem.productPrice * totalPrints;

        if (cartItem.listPrice * totalPrints != cartItem.defaultPrice) {
          cartItem.defaultPrice = cartItem.listPrice * totalPrints;
        }

        if (cartItem.listPricePromo * totalPrints != cartItem.productPrice) {
          cartItem.listPricePromo = cartItem.productPrice / cartItem.quantity;
        }
      } else {
        cartItem.promoPrice = cartItem.productPrice;
        if (cartItem.listPrice * cartItem.quantity != cartItem.defaultPrice) {
          cartItem.defaultPrice = cartItem.listPrice * cartItem.quantity;
        }
        if (
          cartItem.listPricePromo * cartItem.quantity !=
          cartItem.productPrice
        ) {
          cartItem.listPricePromo = cartItem.productPrice / cartItem.quantity;
        }
      }

      if (cartItem.defaultPrice == cartItem.promoPrice) {
        cartItem.promoPrice = false;
        cartItem.listPricePromo = false;
      }

      if (cartItem.listPrice == 0.0) {
        this.disableCheckout = true;
      }
      cartItem.isVolumeDiscount = false;
    }
  }

  getDesiredPaperForQuanity(arrPaperInfo, quantity, paper) {
    var indexFound = 0;
    var tempArray = [];
    for (var i = 0; i < arrPaperInfo.length; i++) {
      arrPaperInfo[i].index = i;
      if (arrPaperInfo[i].paperCode == paper.paperCode) {
        tempArray.push(arrPaperInfo[i]);
      }
    }
    tempArray.sort(this.compare);
    for (var i = 0; i < tempArray.length; i++) {
      if (parseInt(tempArray[i].quantity) <= quantity) {
        indexFound = i;
      }
    }
    indexFound = tempArray[indexFound].index;
    return indexFound;
  }
  getDesiredQuantity(arrPaperInfo, quantity) {
    var indexFound = 0;
    var tempArray = [];
    for (var i = 0; i < arrPaperInfo.length; i++) {
      arrPaperInfo[i].index = i;
      tempArray.push(arrPaperInfo[i]);
    }
    tempArray.sort(this.compare);
    for (var i = 0; i < tempArray.length; i++) {
      if (parseInt(tempArray[i].quantity) <= quantity) {
        indexFound = i;
      }
    }
    indexFound = tempArray[indexFound].index;
    return indexFound;
  }

  compare(a, b) {
    const genreA = parseInt(a.quantity);
    const genreB = parseInt(b.quantity);
    var comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison;
  }

  getDesiredCover(cover, selectedCover) {
    var selectedCoverIndex = 0;
    for (var i = 0; i < cover.length; i++) {
      if (cover[i].coverCode == selectedCover.coverCode) {
        selectedCoverIndex = i;
        break;
      }
    }
    return selectedCoverIndex;
  }

  getDesiredEnvelop(envelopes, selectedEnvelope) {
    var selectedEnvelopeIndex = 0;
    for (var i = 0; i < envelopes.length; i++) {
      if (envelopes[i].envelopesCode == selectedEnvelope.envelopesCode) {
        selectedEnvelopeIndex = i;
        break;
      }
    }
    return selectedEnvelopeIndex;
  }

  changeEnvelope(envelope, index) {
    this.cartItemList[index].selectedEnvelope = envelope;
    this.cartItemList[index].envelopesCode = envelope.envelopesCode;
    this.cartItemList[index].envelopeName = envelope.envelopeName;
    this.updateProductComponents(this.cartItemList[index], index);
  }

  changePaper(paper, index) {
    //
    this.cartItemList[index].selectedPaper = this.getSelectedPaper(
      paper,
      paper.paperCode,
      this.cartItemList[index]
    );
    this.cartItemList[index].paperCode = paper.paperCode;
    this.cartItemList[index].paperName = paper.paperName;
    if (
      this.cartItemList[index].paperCode &&
      this.cartItemList[index].paperCode != ''
    ) {
      this.cartItemList[index].hideError = true;
    } else {
      this.cartItemList[index].hideError = false;
    }

    this.paperNameValue = paper.paperName;
    this.updateProductComponents(this.cartItemList[index], index);
  }

  viewPriceOption(info: any, fromCover = false) {
    //
    var title, okBtn;
    if (fromCover) {
      title = info.coverName;
      if (info.coverDiscription != '') {
        var subTitle = info.coverDiscription;
      }
      okBtn = this.languageData.web.cart_cover_info_ok;
    } else {
      title = info.paperName;
      if (info.paperDiscription != '') {
        var subTitle = info.paperDiscription;
      }
      okBtn = this.languageData.web.cart_paper_info_ok;
    }

    const modalRef = this.modalService.open(DynamicPopupsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.data = {
      title: title,
      subTitle: subTitle,
      okBtnTxt: okBtn,
      modalName: 'priceOption',
      cancelIcon: false,
    };
  }

  changeCover(cover, index) {
    this.cartItemList[index].selectedCover = cover;
    this.cartItemList[index].coverCode = cover.coverCode;
    this.cartItemList[index].coverName = cover.coverName;

    this.updateProductComponents(this.cartItemList[index], index);
  }

  updateProductComponents(cartItem, index) {
    this.cartItemList[index].isPaperChangeProcessing = true;
    // if(isLoadingData)
    // {
    //     return;
    // }
    // isLoadingData = true;cartItem.paperCod
    this.dataService
      .updateProductItems(
        cartItem.id,
        cartItem.paperCode,
        cartItem.envelopesCode,
        cartItem.coverCode,
        cartItem.caseCode,
        this.cartItemList[index].productCode
      )
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          this.changeCoupon(false);
          this.calculatePrice(cartItem, index);

          if (resJSON.data && resJSON.data != null) {
            //
            //   'productCode=', cartItem.productCode,
            //   'deadline=', resJSON.data.xmas_order_deadline,
            //   'productionTimeDays=', resJSON.data.productionTimeDays,
            //   'deliveryDays=', resJSON.data.normalDeliveryDays,
            //   'shippingDaysOffset=', resJSON.data.shippingDaysOffset)

            let itemDate = moment()
              .add(resJSON.data.productionTimeDays, 'days')
              .toDate();
            let itemDeadlineDate = moment(
              resJSON.data.xmas_order_deadline
            ).toDate();
            const isfutureDate = moment(itemDate).isAfter(
              moment(resJSON.data.xmas_order_deadline)
            );
            const isitemDeadlineDate = moment(itemDeadlineDate).isAfter(
              moment()
            );
            for (let i = 0; i < this.cartItemList.length; i++) {
              for (let j = 0; j < this.cartData.length; j++) {
                let germanDateFormat = '';
                let updateText = '';
                if (this.cartData[j].badgeDescription) {
                  let currentDate = new Date(
                    itemDeadlineDate
                  );
                  germanDateFormat = currentDate.toLocaleDateString('de-DE');
                  updateText = this.cartData[j].badgeDescription.replace(
                    '%d',
                    germanDateFormat
                  );
                  this.cartItemList[i].badgeDescription = updateText;
                }
              }
            }
            if (!isfutureDate) {
              let deliveryFrom =
                Number(resJSON.data.productionTimeDays) +
                Number(resJSON.data.normalDeliveryDays);
              deliveryFrom = this.calculateweekdays(deliveryFrom);
              let deliverBy =
                deliveryFrom + Number(resJSON.data.shippingDaysOffset);
              cartItem.productAvialableForDelivery = true;
              let startEDD = moment()
                .add(deliveryFrom, 'days')
                .format('DD/MM/YYYY');
              let endEDD = moment()
                .add(deliverBy + 1, 'days')
                .format('DD/MM/YYYY');

              if (startEDD && startEDD !== null && startEDD != '') {
                startEDD = startEDD.replaceAll('/', '.');
              }
              if (endEDD && endEDD !== null && endEDD != '') {
                endEDD = endEDD.replaceAll('/', '.');
              }

              let languageKey =
                this.languageData.web['shipping_between_date_text'];

              languageKey = languageKey.replace('%e', startEDD);
              languageKey = languageKey.replace('%f', endEDD);
              this.shipping_between_date_text = languageKey;
              //
              //   cartItem.endEDD = endEDD;
              let valueendEDD = moment(resJSON.data.xmas_order_deadline).format(
                'DD/MM/YYYY'
              );

              cartItem.endEDD = valueendEDD.replaceAll('/', '.');
            } else if (isitemDeadlineDate) {
              cartItem.productAvialableForDelivery = true;

              let deliveryFrom = Number(resJSON.data.normalDeliveryDays);
              deliveryFrom = this.calculateweekdays(deliveryFrom);
              // Number(shippingProductInfo.productionTimeDays) +
              // Number(shippingProductInfo.deliveryDays);

              let deliverBy =
                deliveryFrom + Number(resJSON.data.shippingDaysOffset);

              // let startEDD = moment(resJSON.data.xmas_order_deadline).add(deliveryFrom, 'days').format('DD/MM/YYYY');
              // let endEDD = moment().add(deliverBy + 1, 'days').format('DD/MM/YYYY');

              let startEDD = moment(resJSON.data.xmas_order_deadline)
                .add(deliveryFrom, 'days')
                .format('DD/MM/YYYY');
              let endEDD = moment(resJSON.data.xmas_order_deadline)
                .add(deliveryFrom + 1, 'days')
                .format('DD/MM/YYYY');

              if (startEDD && startEDD !== null && startEDD != '') {
                startEDD = startEDD.replaceAll('/', '.');
              }
              if (endEDD && endEDD !== null && endEDD != '') {
                endEDD = endEDD.replaceAll('/', '.');
              }

              let languageKey =
                this.languageData.web['shipping_between_date_text'];

              languageKey = languageKey.replace('%e', startEDD);
              languageKey = languageKey.replace('%f', endEDD);
              this.shipping_between_date_text = languageKey;
              //
              let valueendEDD = moment(resJSON.data.xmas_order_deadline).format(
                'DD/MM/YYYY'
              );

              cartItem.endEDD = valueendEDD.replaceAll('/', '.');
              // moment(item.xmas_order_deadline).format('DD.MM.YYYY')
            } else {
              cartItem.productAvialableForDelivery = false;
              let deliveryFrom =
                Number(resJSON.data.productionTimeDays) +
                Number(resJSON.data.normalDeliveryDays);
              deliveryFrom = this.calculateweekdays(deliveryFrom);
              let deliverBy =
                deliveryFrom + Number(resJSON.data.shippingDaysOffset);
              // this.productAvialableForDelivery = true
              let startEDD = moment()
                .add(deliveryFrom, 'days')
                .format('DD/MM/YYYY');
              let endEDD = moment()
                .add(deliverBy + 1, 'days')
                .format('DD/MM/YYYY');

              if (startEDD && startEDD !== null && startEDD != '') {
                startEDD = startEDD.replaceAll('/', '.');
              }
              if (endEDD && endEDD !== null && endEDD != '') {
                endEDD = endEDD.replaceAll('/', '.');
              }

              let languageKey =
                this.languageData.web['shipping_between_date_text'];

              languageKey = languageKey.replace('%e', startEDD);
              languageKey = languageKey.replace('%f', endEDD);
              this.shipping_between_date_text = languageKey;
              //
              let valueendEDD = moment(resJSON.data.xmas_order_deadline).format(
                'DD/MM/YYYY'
              );

              cartItem.endEDD = valueendEDD.replaceAll('/', '.');
              //
            }
            setTimeout(() => {
              if (
                this.cartItemList.findIndex(
                  (item) => !item.productAvialableForDelivery
                ) > -1
              ) {
                this.isBadgeImage = true;
              } else {
                this.isBadgeImage = false;
              }

              if (
                this.cartItemList.findIndex(
                  (item) =>
                    item.productStatus == 'Notavailable' ||
                    item.productStatus == 'Inactive'
                ) > -1
              ) {
                this.buyNowDisabled = true;
              } else {
                this.buyNowDisabled = false;
              }
            }, 100);
          }
        }
        // isLoadingData = false;
        cartItem.isPaperChangeProcessing = false;
        this.calculateGrandTotal();
      });
  }

  getSelectedEnvelope(arrEnvelopeInfo, envelopesCode) {
    for (let i = 0; i < arrEnvelopeInfo.length; i++) {
      if (arrEnvelopeInfo[i].envelopesCode == envelopesCode) {
        return arrEnvelopeInfo[i];
      }
    }
    return null;
  }

  getSelectedPaper(arrPaperInfo, paperCode, cartItem) {
    // for (let i = 0; i < arrPaperInfo.length; i++) {
    //     if (arrPaperInfo[i].paperCode == paperCode) {
    //         return arrPaperInfo[i];
    //     }
    // }
    if (arrPaperInfo == 0) {
      return null;
    }
    let quantity;
    if (cartItem.preview == 'PRINT') {
      quantity = parseInt(cartItem.totalPrints);
    } else {
      quantity = cartItem.quantity;
    }
    var tempArray = [];
    let paperIndex = null;
    // Sentry Bug Fixed
    if (cartItem.arrPaperInfo && Array.isArray(cartItem.arrPaperInfo)) {
      for (var i = 0; i < cartItem.arrPaperInfo.length; i++) {
        if (cartItem.arrPaperInfo[i].paperCode == paperCode) {
          tempArray.push(cartItem.arrPaperInfo[i]);
        }
      }
    }

    // tempArray.sort(this.compare);
    for (var i = 0; i < tempArray.length; i++) {
      if (parseInt(tempArray[i].quantity) <= quantity) {
        paperIndex = i;
      }
    }
    if (paperIndex != null) {
      return tempArray[paperIndex];
    } else {
      return null;
    }
  }

  getSelectedCover(arrCoverInfo, coverCode) {
    for (let i = 0; i < arrCoverInfo.length; i++) {
      if (arrCoverInfo[i].coverCode == coverCode) {
        return arrCoverInfo[i];
      }
    }
    return null;
  }

  getUniquePaperArray(arrPaper) {
    let arrUniquePaper = [];
    for (let i = 0; i < arrPaper.length; i++) {
      if (!this.isPaperExist(arrUniquePaper, arrPaper[i].paperCode)) {
        arrUniquePaper.push(arrPaper[i]);
      }
    }
    return arrUniquePaper;
  }

  isPaperExist(arrUniquePaper, paperCode) {
    for (let i = 0; i < arrUniquePaper.length; i++) {
      if (arrUniquePaper[i].paperCode == paperCode) {
        return true;
      }
    }
    return false;
  }
  checkIsNumber() {
    // if(isNaN(this.grandTotal) ||
    //  isNaN(this.totalShippinCost)  ||
    //  isNaN(this.totalShippingDiscont)||
    //  isNaN(this.shippingDiscAmount)||
    //  isNaN(this.totalHandlingDiscont)||
    //  isNaN(this.grandTotalAfterShipping)
    //  ){
    if (isNaN(this.grandTotalAfterShipping)) {
      // this.priceCalculationError = true;
    }
    this.priceCalculationError = true;
  }
  getCommaOrDotValue(value) {
    if (value == undefined) {
      return;
    }
    value = this.priceService.getCommaOrDotValue(value);
    if (environment[global.env].isCurrencySymbolFirst) {
      value = this.priceService.getCurrencySymbol() + value;
    } else {
      value = value + this.priceService.getCurrencySymbol();
    }

    return value;
  }

  // getAGBText() {
  //   let keyValue = this.languageData.web['checkout_terms_text'];
  //   let fullUrl = global.urlSuffix + '/' + this.termsPageUrl;
  //   let atag = "<a class='active' href=" + fullUrl + " target='_blank'>AGB</a>";
  //   if (keyValue.indexOf('AGB') != -1) {
  //     this.AGBTagHtml = keyValue.replace('AGB', atag);
  //   } else {
  //     this.AGBTagHtml = keyValue;
  //   }
  // }

  checkAgb(isagbChecked) {
    this.agbChecked = isagbChecked;
    this.isPaymentChecked = false;
    if (this.agbChecked == false) {
      this.isNoCheckBoxSelected = true;
      this.submitted = false;
    } else {
      this.isNoCheckBoxSelected = false;
    }
  }

  checkPrivacyPolicy(isprivacyPolicyChecked) {
    this.privacyPolicyChecked = isprivacyPolicyChecked;
    this.isPaymentChecked = false;
    if (this.privacyPolicyChecked == false) {
      this.isNoPrivacyBoxSelected = true;
      this.submitted = false;
    } else {
      this.isNoPrivacyBoxSelected = false;
    }
  }

  showInput() {
    this.isShowPhoneInput = true;
  }

  applyCouponCode(coupon: any) {
    let coupanList = coupon.coupans;
    let couponcode = coupanList
      .map((value) => value.coupanDetails.split(' ').join(''))
      .join(',')
      .replace(/,\s*$/, '');
    //
    if (couponcode == undefined || couponcode == '') {
      this.isValidCouponInput = true;
      return;
    }

    ////////
    let substract = 0;

    if (this.grandTotalAfterShipping == 0) {
      let subTitle = this.languageData.web.checkout_cart_value_zero_msg;
      let title = this.languageData.web.checkout_cart_value_zero_tit;
      let okBtn = this.languageData.web.checkout_cart_value_zero_ok_btn;

      const modalRef = this.modalService.open(DynamicPopsModalComponent, {
        size: 'md',
        windowClass: 'modalWindowWidth',
        centered: true,
        backdrop: 'static',
        keyboard: true,
      });
      modalRef.componentInstance.modalData = {
        modalName: 'cartValueZero',
        title: title,
        subTitle: subTitle,
        okBtn: okBtn,
        cancelIcon: false,
      };
      return;
    }
    this.isValidCouponInput = false;
    let totalShippinCost = this.totalShippinCost;
    //
    let totalHandlingCost = this.totalHandlingCost;
    let grandTotal = this.grandTotal;
    let _grandTotal = Number(this.grandTotal);

    if (this.couponcode == couponcode) {
      return;
    }
    if (this.couponValidation == true) {
      return;
    }
    this.submitted = false;
    if (couponcode) {
      this.showLoader = true;
      this.clickedCoupon = true;
      this.couponcode = couponcode;
      this.enteredCouponCode = couponcode;
      this.couponValidation = true;
      this.arrItems = [];
      let additionalPagesPrice;
      let additionalPages;
      for (let i = 0; i < this.cartItemList.length; i++) {
        let additionalPrice;
        let basePrice;
        let pageCount = this.cartItemList[i].pageCount;
        let minPage = parseInt(this.cartItemList[i].minPage);
        if (
          this.cartItemList[i].preview == 'WALLCALENDAR' ||
          this.cartItemList[i].preview == 'DESKCALENDAR'
        ) {
          pageCount = pageCount - 1;
        }
        if (
          this.cartItemList[i].preview == 'SOFTBOOK-' ||
          this.cartItemList[i].preview == 'RINGBOOK'
        ) {
          minPage = minPage + 2;
        }
        if (this.activeUsedTab != undefined) {
          if (this.cartItemList[i].shipping != 0) {
            this.cartItemList[i].shipping =
              this.cartItemList[i].shipping - this.shippingDiscAmount;
            this.cartItemList[i].shipping =
              this.cartItemList[i].shipping.toFixed(2);
          }
          ////////
        }
        additionalPages = pageCount - minPage;

        // additionalPrice = Number(this.cartItemList[i].paper.length > 0 ? this.cartItemList[i].paper[0].additionalPrice : 0);

        if (this.cartItemList[i].paper.length > 0) {
          let selectedPaperIndex = this.cartItemList[i].paper.findIndex(
            (item) => item.paperCode == this.cartItemList[i].paperCode
          );
          additionalPrice = Number(
            this.cartItemList[i].paper[selectedPaperIndex].additionalPrice
          );
        } else {
          additionalPrice = 0;
        }

        additionalPagesPrice = Number(
          additionalPages * additionalPrice * this.cartItemList[i].quantity
        );

        ////////

        ////////
        let paperPrice;
        if (
          this.cartItemList[i].selectedPaper != undefined &&
          this.cartItemList[i].selectedPaper != ''
        ) {
          basePrice =
            Number(this.cartItemList[i].selectedPaper.paperPrice) +
            Number(this.cartItemList[i].basePrice) +
            Number(this.cartItemList[i].coverPrice);
          paperPrice = this.cartItemList[i].selectedPaper.paperPrice;
        } else {
          basePrice = this.cartItemList[i].basePrice;
          paperPrice = this.cartItemList[i].paperPrice;
        }

        let quantity;
        if (this.cartItemList[i].preview == 'PRINT') {
          quantity = parseInt(this.cartItemList[i].totalPrints);
        } else {
          quantity = this.cartItemList[i].quantity;
        }
        basePrice = basePrice * quantity;
        let arrItemData = {
          albumCode: this.cartItemList[i].albumCode,
          quantity: quantity,
          productCode: this.cartItemList[i].productCode,
          // "projectPrice": this.cartItemList[i].productPrice,
          projectPrice: basePrice,
          paperCode: this.cartItemList[i].paperCode,
          paperPrice: paperPrice,
          // "paperPrice": this.cartItemList[i].paperPrice,
          coverCode: this.cartItemList[i].coverCode,
          coverPrice: this.cartItemList[i].coverPrice,
          envelopesCode: this.cartItemList[i].envelopesCode,
          envelopesPrice: this.cartItemList[i].envelopePrice,
          total: this.cartItemList[i].total,
          caseCode: this.cartItemList[i].caseCode,
          casePrice: this.cartItemList[i].casePrice,
          totalPrints: this.cartItemList[i].totalPrints,
          shipping: this.cartItemList[i].shipping,
          handling: this.cartItemList[i].handling,
          listPriceDate: this.cartItemList[i].listPriceDate,
          projectAdditionalPagePrice: additionalPagesPrice,
          countryCode: this.countryObj.code,
          preview: this.cartItemList[i].preview,
          pageCount: this.cartItemList[i].pageCount,
        };
        this.arrItems.push(arrItemData);

        if (this.cartItemList[i].promoPrice != false) {
          arrItemData['promoPrice'] =
            this.cartItemList[i].listPrice *
            parseInt(this.cartItemList[i].quantity);
        }
      }

      this.data = JSON.stringify({
        application: 'IPHONE_APP',
        data: {
          couponvalue: couponcode,
          grandTotalAfterShipping: this.grandTotalAfterShipping,
          totalShipping: totalShippinCost,
          langCode: global.lang,
          appVersion: 'ONL v 1.0.0',
          cartData: {
            items: this.arrItems,
          },
        },
      });

      this.dataService.checkCouponValidity(this.data).subscribe(
        (res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.clickedCoupon = false;
          if (resJSON.success == true) {
            this.showLoader = false;
            this.coupondetails = resJSON.coupondetails;
            this.couponGroup = resJSON.data.couponGroup;
            this.externalCoupon = resJSON.data.externalCoupon;
            this.isPickupShippingDisc = false;
            let obj = { grandTotalOriginal: this.grandTotalOriginal };
            let objCopy = JSON.parse(JSON.stringify(obj));
            this.grandTotalAfterShipping = objCopy.grandTotalOriginal;

            this.isCouponApply = true;
            this.dispalyInvoiceBtn = false;
            this.couponValidation = false;
            this.isValidCoupon = true;
            this.showErrorMessage = false;
            this.successMessage = resJSON.message;
            this.errorMessage = null;
            this.couponCodePrice = resJSON.data.couponvalue;
            this.couponType = resJSON.data.coupontype;
            this.couponCodeGroup = resJSON.data.couponCodeGroup;
            this.totalDiscount = 0.0;
            this.totalProductDiscont = 0.0;
            this.totalShippingDiscont = 0.0;
            this.totalHandlingDiscont = 0.0;
            var totalProductDiscont = 0.0;
            var totalAdditionalPagesDiscont = 0.0;
            this.totalShippinCost = totalShippinCost;
            this.totalHandlingCost = totalHandlingCost;
            this.grandTotal = _grandTotal;

            //

            var arrCoupanItems = resJSON.data.cartData.items;
            // product, shipping and handling discount
            for (var i = 0; i < arrCoupanItems.length; i++) {
              if (
                arrCoupanItems[i].couponVouchers == '' ||
                arrCoupanItems[i].couponVouchers == null
              ) {
                arrCoupanItems[i].couponVouchers = 0;
              }
              if (
                arrCoupanItems[i].couponDiscount == '' ||
                arrCoupanItems[i].couponDiscount == null
              ) {
                arrCoupanItems[i].couponDiscount = 0;
              }
              if (
                arrCoupanItems[i].pageDiscount == '' ||
                arrCoupanItems[i].pageDiscount == null
              ) {
                arrCoupanItems[i].pageDiscount = 0;
              }
              if (
                arrCoupanItems[i].shippingDiscount == '' ||
                arrCoupanItems[i].shippingDiscount == null
              ) {
                arrCoupanItems[i].shippingDiscount = 0;
              }
              if (
                arrCoupanItems[i].handlingDiscount == '' ||
                arrCoupanItems[i].handlingDiscount == null
              ) {
                arrCoupanItems[i].handlingDiscount = 0;
              }
              totalProductDiscont += parseFloat(
                arrCoupanItems[i].couponDiscount
              );
              totalAdditionalPagesDiscont += parseFloat(
                arrCoupanItems[i].pageDiscount
              );
              if (arrCoupanItems[i].shippingDiscount == 0) {
                arrCoupanItems[i].shippingDiscount = 0;
              }
              this.totalShippingDiscont += parseFloat(
                arrCoupanItems[i].shippingDiscount
              );
              this.totalHandlingDiscont +=
                parseFloat(arrCoupanItems[i].handlingDiscount) || 0;
            }

            //
            if (totalAdditionalPagesDiscont != 0) {
              if (totalProductDiscont != 0) {
                this.totalProductDiscont =
                  totalAdditionalPagesDiscont + totalProductDiscont;
                this.totalDiscount =
                  totalAdditionalPagesDiscont +
                  totalProductDiscont +
                  this.totalShippingDiscont;
              } else {
                this.totalProductDiscont = totalAdditionalPagesDiscont;
                this.totalDiscount = totalAdditionalPagesDiscont;
              }
            } else {
              this.totalProductDiscont = totalProductDiscont;

              if (
                this.totalShippingDiscont == 0 &&
                this.shippingDiscAmount != 0 &&
                this.shippingDiscAmount != undefined
              ) {
                this.isPickupShippingDisc = true;
                this.shippingDiscAmount = parseFloat(this.shippingDiscAmount);
                this.totalDiscount =
                  this.totalProductDiscont +
                  this.totalShippingDiscont +
                  this.totalHandlingDiscont +
                  this.shippingDiscAmount;
              } else {
                this.totalDiscount =
                  this.totalProductDiscont +
                  this.totalShippingDiscont +
                  this.totalHandlingDiscont;
              }
            }

            // grandTotal -= this.totalProductDiscont;
            // totalShippinCost -= this.totalShippingDiscont;
            // this.grandTotalAfterShipping -= this.totalDiscount;
            // totalShippinCost = totalShippinCost.toFixed(2);
            // this.grandTotalAfterShipping = this.grandTotalAfterShipping.toFixed(2);
            this.arrCouponItems = arrCoupanItems;

            let totalShippingDiscount = 0;
            if (this.totalShippingDiscount) {
              totalShippingDiscount = this.totalShippingDiscount;
            }
            let applypromoproduct;
            let dataForGrandTotal = {
              data: {
                handlingFee: this.totalHandlingCost,
                shippingFee: this.totalShippinCost,
                shippingDiscount: totalShippingDiscount,
                couponAmount: this.totalDiscount,
                cartData: {
                  items: resJSON.data.cartData.items,
                },
              },
            };
            if (
              resJSON.data.applypromoproduct == 'Yes' &&
              resJSON.data.applyon == 'List'
            ) {
              applypromoproduct = true;
            } else {
              applypromoproduct = false;

              //  this.grandTotalAfterShipping = this.recheckGrandTotalBeforeSubmit(dataForGrandTotal).calculatedTotal
            }
            //
            const recheckTheValues = async function (
              dataForGrandTotal,
              applypromoproduct = false
            ) {
              const result = await this.recheckGrandTotalBeforeSubmit(
                dataForGrandTotal
              );
              this.grandTotalAfterShipping = result.calculatedTotal;
            }.bind(this);
            recheckTheValues(dataForGrandTotal, applypromoproduct);

            //
            this.calculateVats();
            if (
              this.userInfo.userType == 'Guest' ||
              this.userInfo.userType == 'Nonverified'
            ) {
              this.dispalyInvoiceBtn = false;
            } else {
              if (this.creditLimitData != undefined) {
                if (
                  Number(this.creditLimitData.availableCreditLimit) >=
                  this.grandTotalAfterShipping
                ) {
                  this.dispalyInvoiceBtn = true;
                } else {
                  this.dispalyInvoiceBtn = false;
                }
              }
            }
            const couponApplied = { type: 'couponApplied' };

            let filteredData = this.cartItemList.filter(
              (item) => item.isVolumeDiscount == true
            );
            // ////////
            if (filteredData.length > 0) {
              // this.contentfulService.opensuccessModal('couponApplied', couponApplied)
            }
            this.coupans.controls.map((item, index) => {
              item['showInputPlusSignImage'] = false;
              item['showInputMinusSignImage'] = false;
            });
          } else {
            // this.coupans.controls.map((item, index) => {
            //   item['showInputPlusSignImage'] = false;
            //   item['showInputMinusSignImage'] = false;
            //   });
            this.coupondetails = [];
            this.couponGroup = '';
            this.externalCoupon = '';
            this.couponCodeGroup = '';
            if (resJSON.code == 3) {
              localStorage.setItem('couponcode', couponcode);
              let data = {
                type: 'OPEN_LOGIN',
                guestloginCoupon: true,
              };
              // ////////
              this.globalService.setOpenLogin(data);
            }
            this.isCouponApply = false;
            this.couponValidation = false;
            this.isValidCoupon = false;
            this.showErrorMessage = true;
            this.successMessage = null;
            this.errorMessage = resJSON.message;
            this.couponcode = null;
            //
            setTimeout(() => {
              this.showLoader = false;
              this.changeDetectorRef.detectChanges();
            }, 100);

            // setTimeout(() => {
            //  this.errorMessage = null;
            // },5000);
          }
        },
        (err) => {
          this.showErrorPopup(true);
        }
      );
    } else {
      this.couponValidation = false;
      this.successMessage = null;
      this.errorMessage = null;
      if (couponcode == undefined || couponcode == '') {
        this.isValidCoupon = false;
      }
    }
  }

  onKey(event: any, index) {
    if (index == 0 && this.coupans.value.length == index + 1) {
      // Only one item
      if (event.target.value && event.target.value.trim().length >= 1) {
        this.coupans.controls[index]['showInputPlusSignImage'] = true;
        this.coupans.controls[index]['showInputMinusSignImage'] = true;
      } else {
        this.coupans.controls[index]['showInputPlusSignImage'] = false;
        this.coupans.controls[index]['showInputMinusSignImage'] = false;
      }
    } else if (this.coupans.value.length == index + 1) {
      // Last item
      if (event.target.value && event.target.value.trim().length >= 1) {
        this.coupans.controls[index]['showInputPlusSignImage'] = true;
      } else {
        this.coupans.controls[index]['showInputPlusSignImage'] = false;
      }
      this.coupans.controls[index]['showInputMinusSignImage'] = true;
    } else {
      this.coupans.controls[index]['showInputPlusSignImage'] = false;
      this.coupans.controls[index]['showInputMinusSignImage'] = true;
    }

    if (this.errorMessage) {
      this.showErrorMessage = false;
    } else {
      this.showErrorMessage = true;
    }
  }

  changeCoupon(clickedFromButton = false) {
    if (clickedFromButton) {
      for (let i = this.coupans.controls.length - 1; i >= 0; i--) {
        if (i == 0) {
          this.coupans.controls[i]['value'].coupanDetails = '';
          this.coupans.controls[i].patchValue({ coupanDetails: '' });
        } else {
          this.coupans.controls.splice(i, 1);
          this.coupans.value.splice(i, 1);
        }
      }
    }
    this.clickedCoupon = false;
    this.externalCoupon = '';
    this.couponCodeGroup = '';
    this.isCouponApply = false;
    this.enteredCouponCode = '';
    this.isValidCoupon = false;
    this.successMessage = null;
    this.errorMessage = null;
    this.totalDiscount = 0.0;
    this.couponCodePrice = null;
    this.couponType = undefined;
    this.couponcode = null;
    this.couponChanged = true;
    this.submitted = false;
    this.changeDetectorRef.detectChanges();
    this.couponGroup = '';
    this.totalCouponDiscount = 0.0;
    this.shippingDiscAmount = undefined;

    this.totalHandlingDiscount = 0.0;
    this.arrCouponItems = undefined;

    for (var i = 0; i < this.cartItemList.length; i++) {
      this.cartItemList[i].couponDiscount = null;
      delete this.cartItemList[i].pageDiscount;
      delete this.cartItemList[i].shippingDiscount;
      delete this.cartItemList[i].handlingDiscount;
    }

    if (this.shippingDiscAmount != 0 && this.shippingDiscAmount != undefined) {
      this.isPickupShippingDisc = true;
      this.grandTotalAfterShipping =
        this.grandTotalOriginal - this.shippingDiscAmount;
      //
    } else {
      this.grandTotalAfterShipping = this.grandTotalOriginal;

      //
    }
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 100);

    // this.cartItemList.map(item=>{

    //   if(item.isVolumeDiscount){
    //    if(item.listPricePromoBefore){
    //     item.listPricePromo=true;
    //    // item.listPricePromoBefore=false
    //    }

    //     item.volumecouponIsApplied=undefined
    //   }
    // })
    this.calculateVats();
    //this.loadCartData(clickedFromButton);
    if (clickedFromButton) {
      this.loadCartData(clickedFromButton);
      this.hideError = true;
    }
    //   if(this.coupanForm){
    //  //   this.coupanForm.reset();
    //   }

    // this.calculateGrandTotal();
  }

  calculateWithCouponGrandTotal(arrCoupanItems) {
    let projectPrice = 0;
    for (var i = 0; i < this.cartItemList.length; i++) {
      let cartItem = this.cartItemList[i];
      let isPromoApplicable = false;
      for (let j = 0; j < arrCoupanItems.length; j++) {
        if (arrCoupanItems[j].productCode == cartItem.productCode) {
          if (cartItem.promoPrice != false) {
            if (
              arrCoupanItems[j].couponDiscount == 0 ||
              arrCoupanItems[j].pageDiscount == 0
            ) {
              isPromoApplicable = true;
            }
          }
          break;
        }
      }

      if (isPromoApplicable) {
        projectPrice = Number(projectPrice) + parseFloat(cartItem.promoPrice);
      } else {
        projectPrice = Number(projectPrice) + parseFloat(cartItem.defaultPrice);
      }
    }
    let total =
      Number(projectPrice) +
      parseFloat(this.totalShippinCost) +
      parseFloat(this.totalHandlingCost);
    total = parseFloat(total.toFixed(2));
    return total;
  }

  orderSubmit(paymentType, paypalClicked = false) {
    let shippingVat: any;
    this.currentPaymentType = paymentType;
    //
    if (this.isProcessing) {
      return;
    }

    let coupons = this.coupanForm.get('coupans') as FormArray;
    let coupondata = [];
    coupons.controls.map((item) => {
      //
      if (item.value && item.value.coupanDetails) {
        coupondata.push(item.value.coupanDetails);
      }
    });

    if (
      coupondata.length > 0 &&
      this.grandTotalOriginal == this.grandTotalAfterShipping &&
      this.isCouponApply == false
    ) {
      this.applyCouponCodeModal();
      return;
    }

    // if (
    //   this.enteredCouponCode != '' &&
    //   this.grandTotalOriginal == this.grandTotalAfterShipping &&
    //   this.isCouponApply == false
    // ) {
    //   this.applyCouponCodeModal();
    //   return;
    // }

    if (paymentType != 'notselected') {
      //
      this.isProcessing = true;
    } else {
      this.hidePaymentOptions = true;
    }

    this.arrItems = [];

    if (this.arrCouponItems) {
      for (var k = 0; k < this.arrCouponItems.length; k++) {
        for (var l = 0; l < this.cartItemList.length; l++) {
          if (
            this.arrCouponItems[k].albumCode == this.cartItemList[l].albumCode
          ) {
            this.cartItemList[l].couponDiscount =
              this.arrCouponItems[k].couponDiscount;
            this.cartItemList[l].couponVouchers =
              this.arrCouponItems[k].couponVouchers;
            this.cartItemList[l].pageDiscount =
              this.arrCouponItems[k].pageDiscount;
            this.cartItemList[l].shippingDiscount =
              this.arrCouponItems[k].shippingDiscount;
            this.cartItemList[l].handlingDiscount =
              this.arrCouponItems[k].handlingDiscount;
          }
        }
      }
    }
    var totalCouponDiscount = 0.0;
    this.totalCouponDiscount = 0.0;
    this.totalShippingDiscount = 0.0;
    this.totalHandlingDiscount = 0.0;
    let shippingTypeIndex = this.countryObj.shippingOptions.findIndex(
      (item) => item.selected == true
    );
    for (var i = 0; i < this.cartItemList.length; i++) {
      var promoPrice = 0.0;
      var promoUnitPrice = 0.0;
      var isPromoApplicable = false;
      var unitPrice = this.cartItemList[i].listPrice;

      if (this.cartItemList[i].listPricePromo != false) {
        promoUnitPrice = unitPrice;
        unitPrice = this.cartItemList[i].listPricePromo;
      }

      if (this.cartItemList[i].promoPrice != false) {
        promoPrice = this.cartItemList[i].defaultPrice;
        if (
          parseFloat(this.cartItemList[i].couponDiscount) == 0 ||
          this.cartItemList[i].couponDiscount == undefined ||
          parseFloat(this.cartItemList[i].pageDiscount) == 0 ||
          this.cartItemList[i].pageDiscount == undefined
        ) {
          isPromoApplicable = true;
        }
      }

      if (this.couponType) {
        totalCouponDiscount = +(
          parseFloat(this.totalCouponDiscount) +
          parseFloat(this.cartItemList[i].couponDiscount) +
          parseFloat(this.cartItemList[i].pageDiscount)
        ).toFixed(12);
        this.totalShippingDiscount = +(
          parseFloat(this.totalShippingDiscount) +
          parseFloat(this.cartItemList[i].shippingDiscount)
        ).toFixed(12);
        this.totalHandlingDiscount = +(
          parseFloat(this.totalHandlingDiscount) +
          parseFloat(this.cartItemList[i].handlingDiscount)
        ).toFixed(12);
        if (this.couponType == 'AMOUNT') {
          //  this.cartItemList[i].couponDiscount = '0.00';
        }
        this.totalCouponDiscount = totalCouponDiscount;
      }

      //
      // return false
      var arrItemData = {
        albumCode: this.cartItemList[i].albumCode,
        quantity: this.cartItemList[i].quantity,
        projectPrice: parseFloat(this.cartItemList[i].productPrice).toFixed(2),
        coverCode: this.cartItemList[i].coverCode,
        coverPrice: this.cartItemList[i].coverPrice,
        total: (
          parseFloat(this.cartItemList[i].total) +
          parseFloat(this.cartItemList[i].totalGroupShippingCost)
        ).toFixed(2),
        paperCode: this.cartItemList[i].paperCode,
        paperPrice: this.cartItemList[i].paperPrice,
        caseCode: this.cartItemList[i].caseCode,
        casePrice: this.cartItemList[i].casePrice,
        envelopesCode: this.cartItemList[i].envelopesCode,
        envelopePrice: this.cartItemList[i].envelopePrice,
        shipping: this.cartItemList[i].shipping,
        handling: this.cartItemList[i].handling,
        isOptimize: this.cartItemList[i].isOptimize,
        unitPrice: unitPrice,
        vat: this.cartItemList[i].vat,
        badge: this.cartItemList[i].badge,
        promoId: this.cartItemList[i].promoId,
        totalImages: this.cartItemList[i].totalImages,
        totalPrints: this.cartItemList[i].totalPrints,
        listPriceDate: this.cartItemList[i].listPriceDate,
        couponDiscount:
          parseFloat(this.cartItemList[i].couponDiscount) +
          parseFloat(this.cartItemList[i].pageDiscount),
        couponVouchers: this.cartItemList[i].couponVouchers,
        shippingDiscount: this.cartItemList[i].shippingDiscount,
        handlingDiscount: this.cartItemList[i].handlingDiscount,
        ShipBy: this.countryObj.shippingOptions[shippingTypeIndex].type,

        countryCode: this.countryObj.code,
        preview: this.cartItemList[i].preview,
        pageCount: this.cartItemList[i].pageCount,
        // uploadId: this.cartItemList[i].uploadId,
      };

      this.arrItems.push(arrItemData);

      if (isPromoApplicable) {
        arrItemData['promoUnitPrice'] = promoUnitPrice;
        arrItemData['promoPrice'] = promoPrice;
      }
    }

    if (
      this.shippingDiscAmount != undefined &&
      this.totalShippingDiscount == 0
    ) {
      this.totalShippingDiscount = this.shippingDiscAmount;
    }
    shippingVat = this.cartVatData.items.filter((item: any) => {
      return item.type == 'shippingVat';
    });
    // console.info(this.couponType);
    if (this.couponType || Object.keys(this.pickupAddress).length != 0) {
      if (Object.keys(this.pickupAddress).length != 0) {
        this.shippingAddress = {
          firstname: this.pickupAddress.firstName,
          lastname: this.pickupAddress.lastName,
          gender: this.pickupAddress.gender,
          country: this.pickupAddress.countryCode,
          houseNumber: this.pickupAddress.houseNumber,
          street: this.pickupAddress.street,
          company: this.pickupAddress.company,
          city: this.pickupAddress.city,
          postalcode: this.pickupAddress.zipcode,
          phone: this.pickupAddress.phone,
          address1: this.pickupAddress.street,
          address2: this.pickupAddress.houseNumber,
          email: this.billingAddress.email,
          pickupID: this.pickupAddress.id,
        };
      } else {
        // this.shippingAddress = {
        //   firstname: this.shippingAddress.firstName,
        //   lastname: this.shippingAddress.lastName,
        //   gender: this.shippingAddress.gender,
        //   country: this.shippingAddress.selectedCountryCode,
        //   houseNumber: this.shippingAddress.houseNumber,
        //   street: this.shippingAddress.street,
        //   company: this.shippingAddress.company,
        //   city: this.shippingAddress.city,
        //   postalcode: this.shippingAddress.zip,
        //   phone: this.shippingAddress.phone,
        //   address1: this.shippingAddress.street,
        //   address2: this.shippingAddress.houseNumber,
        //   email: this.shippingAddress.email,
        // };
        this.shippingAddress = {
          firstname: this.user.shippingDetail.firstName,
          lastname: this.user.shippingDetail.lastName,
          gender: this.user.shippingDetail.gender,
          country: this.user.shippingDetail.selectedCountryCode,
          houseNumber: this.user.shippingDetail.houseNumber,
          street: this.user.shippingDetail.street,
          company: this.user.shippingDetail.company,
          city: this.user.shippingDetail.city,
          postalcode: this.user.shippingDetail.zip,
          phone: this.user.shippingDetail.phone,
          address1: this.user.shippingDetail.street,
          address2: this.user.shippingDetail.houseNumber,
          email: this.user.shippingDetail.email,
        };
      }
      this.data = JSON.parse(
        JSON.stringify({
          application: 'IPHONE_APP',
          data: {
            ShipBy: this.countryObj.shippingOptions[shippingTypeIndex].type,
            externalCoupon: this.externalCoupon,
            couponGroup: this.couponGroup,
            couponCodeGroup: this.couponCodeGroup,
            shippingVat:
              shippingVat && shippingVat[0]
                ? shippingVat[0].percentileValue
                : '',
            langCode: this.userInfo.langCode,
            OEM: environment[global.env].oemCode,
            userAgent: navigator.userAgent,
            order_comments: '',
            apply_coupon: this.couponcode || '',
            paymentMethod: paymentType,
            grosstotal: Number(
              this.priceService.getCommaOrDotValue(
                this.grandTotalOriginal,
                false
              )
            ),
            handlingFee: Number(
              this.priceService.getCommaOrDotValue(
                this.totalHandlingCost,
                false
              )
            ),
            shippingFee: Number(
              this.priceService.getCommaOrDotValue(this.totalShippinCost, false)
            ),
            currency: environment[global.env].currency,
            appVersion: 'ONL v 1.0.0',
            isMobile: 'No',
            paidAmount: Number(
              this.priceService.getCommaOrDotValue(
                this.grandTotalAfterShipping,
                false
              )
            ),
            totalPrints: this.cartItemList[0].totalPrints,
            totalImages: this.cartItemList[0].totalImages,
            isHoldcart: 'false',
            couponType: this.couponType,
            couponAmount: Number(
              this.priceService.getCommaOrDotValue(
                this.totalCouponDiscount,
                false
              )
            ),
            shippingDiscount: Number(
              this.priceService.getCommaOrDotValue(
                this.totalShippingDiscount,
                false
              )
            ),
            handlingDiscount: Number(
              this.priceService.getCommaOrDotValue(
                this.totalHandlingDiscount,
                false
              )
            ),
            countryDetails: this.getOemCountry(
              this.shippingAddress.selectedCountryCode,
              ''
            ),
            cartData: {
              shipAmount: Number(
                this.priceService.getCommaOrDotValue(
                  this.totalShippinCost,
                  false
                )
              ),
              items: this.arrItems,
            },
            addressDetails: {
              shipping: this.shippingAddress,
              billing: {
                firstname: this.user.billingDetail.firstName,
                lastname: this.user.billingDetail.lastName,
                gender: this.user.billingDetail.gender,
                country: this.user.billingDetail.selectedCountryCode,
                houseNumber: this.user.billingDetail.houseNumber,
                street: this.user.billingDetail.street,
                company: this.user.billingDetail.company,
                city: this.user.billingDetail.city,
                postalcode: this.user.billingDetail.zip,
                phone: this.user.billingDetail.phone,
                address1: this.user.billingDetail.street,
                address2: this.user.billingDetail.houseNumber,
                email: this.user.billingDetail.email,
              },
            },
          },
        })
      );
    } else {
      let fromReferal, referalType;
      if (this.userInfo.userType == 'Guest') {
        fromReferal = localStorage.getItem('fromReferal');
        referalType = localStorage.getItem('referalType');
      }
      shippingVat = this.cartVatData.items.filter((item: any) => {
        return item.type == 'shippingVat';
      });
      this.data = JSON.parse(
        JSON.stringify({
          application: 'IPHONE_APP',

          data: {
            fromReferal: fromReferal,
            ShipBy: this.countryObj.shippingOptions[shippingTypeIndex].type,
            couponCodeGroup: this.couponCodeGroup,
            externalCoupon: this.externalCoupon,
            couponGroup: this.couponGroup,
            shippingVat:
              shippingVat && shippingVat[0]
                ? shippingVat[0].percentileValue
                : '',
            referalType: referalType,
            langCode: this.userInfo.langCode,
            OEM: environment[global.env].oemCode,
            userAgent: navigator.userAgent,
            order_comments: '',
            apply_coupon: this.couponcode || '',
            paymentMethod: paymentType,
            grosstotal: Number(
              this.priceService.getCommaOrDotValue(
                this.grandTotalOriginal,
                false
              )
            ),
            handlingFee: Number(
              this.priceService.getCommaOrDotValue(
                this.totalHandlingCost,
                false
              )
            ),
            shippingFee: Number(
              this.priceService.getCommaOrDotValue(this.totalShippinCost, false)
            ),
            currency: environment[global.env].currency,
            appVersion: 'ONL v 1.0.0',
            isMobile: 'No',
            paidAmount: Number(
              this.priceService.getCommaOrDotValue(
                this.grandTotalAfterShipping,
                false
              )
            ),
            totalPrints: this.cartItemList[0].totalPrints,
            totalImages: this.cartItemList[0].totalImages,
            isHoldcart: 'false',
            countryDetails: this.getOemCountry(
              this.shippingAddress.selectedCountryCode,
              ''
            ),
            cartData: {
              shipAmount: Number(
                this.priceService.getCommaOrDotValue(
                  this.totalShippinCost,
                  false
                )
              ),
              couponAmount: Number(
                this.priceService.getCommaOrDotValue(this.totalDiscount, false)
              ),
              items: this.arrItems,
            },
            addressDetails: {
              // shipping: {
              //   firstname: this.shippingAddress.firstName,
              //   lastname: this.shippingAddress.lastName,
              //   gender: this.shippingAddress.gender,
              //   country: this.shippingAddress.selectedCountryCode,
              //   houseNumber: this.shippingAddress.houseNumber,
              //   street: this.shippingAddress.street,
              //   company: this.shippingAddress.company,
              //   city: this.shippingAddress.city,
              //   postalcode: this.shippingAddress.zip,
              //   phone: this.shippingAddress.phone,
              //   address1: this.shippingAddress.street,
              //   address2: this.shippingAddress.houseNumber,
              //   email: this.shippingAddress.email,
              // },
              shipping: {
                firstname: this.user.shippingDetail.firstName,
                lastname: this.user.shippingDetail.lastName,
                gender: this.user.shippingDetail.gender,
                country: this.user.shippingDetail.selectedCountryCode,
                houseNumber: this.user.shippingDetail.houseNumber,
                street: this.user.shippingDetail.street,
                company: this.user.shippingDetail.company,
                city: this.user.shippingDetail.city,
                postalcode: this.user.shippingDetail.zip,
                phone: this.user.shippingDetail.phone,
                address1: this.user.shippingDetail.street,
                address2: this.user.shippingDetail.houseNumber,
                email: this.user.shippingDetail.email,
              },
              billing: {
                firstname: this.user.billingDetail.firstName,
                lastname: this.user.billingDetail.lastName,
                gender: this.user.billingDetail.gender,
                country: this.user.billingDetail.selectedCountryCode,
                houseNumber: this.user.billingDetail.houseNumber,
                street: this.user.billingDetail.street,
                company: this.user.billingDetail.company,
                city: this.user.billingDetail.city,
                postalcode: this.user.billingDetail.zip,
                phone: this.user.billingDetail.phone,
                address1: this.user.billingDetail.street,
                address2: this.user.billingDetail.houseNumber,
                email: this.user.billingDetail.email,
              },
            },
          },
        })
      );
    }
    if (this.isCouponApply && this.coupondetails != null) {
      this.data['data']['coupondetails'] = this.coupondetails;
    }
    //this.data['data']['coupondetails']='test';
    //

    this.IS_PAYMENT_PROCESSING = false;
    this.IS_PAYMENT_GATEWAY_READY = false;
    // console.info(' LINE NO 4174', "GrossTotal=", this.grandTotalOriginal, "PaidAmount=", this.grandTotalAfterShipping)

    //
    const recheckTheValues = async function () {
      const result = await this.recheckGrandTotalBeforeSubmit(this.data);
      // do something else here after recheckGrandTotalBeforeSubmit completes

      if (!result.valuesmatched) {
        this.data.data.grosstotal = result.calculatedTotal;
        this.data.data.paidAmount = result.calculatedTotal;
        this.grandTotalAfterShipping = result.calculatedTotal;
      }
      // this.data.data.cartData.items.map(item=>{
      //   item.vat=0
      // })
      // console.info('this.data',this.data
      // );return
      //
      ///  return false;
      this.dataService.getOrderSubmit(this.data).subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true && resJSON.data) {
          if (paymentType == 'notselected') {
            //
            this.hidePaymentOptions = false;
          }

          let orderNumber = resJSON.data.order;
          let cartId = resJSON.data.cartId;
          let orderDetails = resJSON;
          this.orderNumber = orderNumber;
          this.IS_PAYMENT_PROCESSING = true;
          this.isNoCheckBoxSelected = false;
          this.isNoPrivacyBoxSelected = false;
          if (orderNumber && orderNumber != null && orderNumber != '') {
            if (paymentType == 'notselected') {
              this.setOrderNumber(this.orderNumber);
              //   this.isProcessing = false;
            } else if (paymentType == 'PayPal') {
              var paymentMethod = paymentType;
              if (paypalClicked) {
                this.setOrderNumber(this.orderNumber);
              } else {
                this.updatePaymentOrderStatus(
                  this.orderNumber,
                  paymentMethod,
                  'paid',
                  this.id
                );
              }
            } else if (paymentType == 'Adyen') {
              var paymentMethod = paymentType;
              this.setOrderNumber(this.orderNumber);
            } else if (paymentType == 'Stripe') {
              var paymentMethod = paymentType;
              this.setOrderNumber(this.orderNumber);
            } else if (paymentType == 'Paygate') {
              var paymentMethod = paymentType;
              this.setOrderNumber(this.orderNumber);
            } else if (
              paymentType == 'Invoice' ||
              paymentType == 'Prepayment'
            ) {
              var paymentMethod = paymentType;
              this.updatePaymentOrderStatus(
                this.orderNumber,
                paymentMethod,
                'postpayment',
                ''
              );
            } else if (paymentType == 'Shop') {
              var paymentMethod = paymentType;
              this.updatePaymentOrderStatus(
                this.orderNumber,
                paymentMethod,
                'Unpaid',
                ''
              );
            } else {
              this.updatePaymentOrderStatus(
                this.orderNumber,
                'None',
                'paid',
                'none'
              );
            }
          } else {
            window.location.reload();
          }
        } else if (resJSON.success == false) {
          let title = this.languageData.web.cart_not_complete_title;
          let subTitle = resJSON.message;
          let okBtn = this.languageData.web.cart_not_complete_cta;
          const modalRef = this.modalService.open(DynamicPopsModalComponent, {
            size: 'md',
            windowClass: 'modalWindowWidth',
            centered: true,
            backdrop: 'static',
            keyboard: true,
          });
          modalRef.componentInstance.modalData = {
            title: title,
            subTitle: subTitle,
            okBtn: okBtn,
            modalName: 'ServerError',
            cartUrl: this.cartPageUrl,
            cancelIcon: false,
          };
        } else {
          // this.errorMsg = resJSON.error.detail;
          //this.errorMsg = resJSON.code;

          if (resJSON.code) {
            this.closeModal();
          }

          if (resJSON.code == 9) {
            let title = this.languageData.web.cart_not_complete_title;
            let subTitle = resJSON.message;
            let okBtn = this.languageData.web.cart_not_complete_cta;
            const modalRef = this.modalService.open(DynamicPopsModalComponent, {
              size: 'md',
              windowClass: 'modalWindowWidth',
              centered: true,
              backdrop: 'static',
              keyboard: true,
            });
            modalRef.componentInstance.modalData = {
              title: title,
              subTitle: subTitle,
              okBtn: okBtn,
              modalName: 'CartIncomplete',
              cartUrl: this.cartPageUrl,
              cancelIcon: false,
            };
          } else if (resJSON.code == 8) {
            // this.router.navigate([this.cartPageUrl]);
            window.location.reload();
          } else if (resJSON.code == 6) {
            let title =
              this.languageData.web.order_submit_invalid_product_title;
            let subTitle = resJSON.message;
            let okBtn =
              this.languageData.web.checkout_product_not_available_cta_btn;
            const modalRef = this.modalService.open(DynamicPopsModalComponent, {
              size: 'md',
              windowClass: 'modalWindowWidth',
              centered: true,
              backdrop: 'static',
              keyboard: true,
            });
            modalRef.componentInstance.modalData = {
              title: title,
              subTitle: subTitle,
              okBtn: okBtn,
              modalName: 'ProductNotAvailable',
              cartUrl: this.cartPageUrl,
              cancelIcon: false,
            };
          } else if (resJSON.code == 7) {
            let title =
              this.languageData.web.order_submit_db_transaction_error_title;
            let subTitle = resJSON.message;
            let okBtn =
              this.languageData.web.checkout_product_not_available_cta_btn;
            const modalRef = this.modalService.open(DynamicPopsModalComponent, {
              size: 'md',
              windowClass: 'modalWindowWidth',
              centered: true,
              backdrop: 'static',
              keyboard: true,
            });
            modalRef.componentInstance.modalData = {
              title: title,
              subTitle: subTitle,
              okBtn: okBtn,
              modalName: 'ProductCartEmpty',
              cartUrl: this.cartPageUrl,
              cancelIcon: false,
            };
          }
        }
      });
    }.bind(this);
    recheckTheValues();

    return false;
  }

  async recheckGrandTotalBeforeSubmit(
    requestPayload: any,
    isAppliedOnPromoProduct = false
  ) {
    let apiData = requestPayload.data;
    let totalPrice: any = 0;
    let envelopesPrice: any = 0;
    let totalPriceAfterShipping = 0.0;
    let couponAmount =
      apiData.couponAmount == undefined ||
      apiData.couponAmount == null ||
      apiData.couponAmount == ''
        ? 0
        : apiData.couponAmount;
    apiData.shippingDiscount =
      apiData.shippingDiscount == undefined ||
      apiData.shippingDiscount == null ||
      apiData.shippingDiscount == ''
        ? 0
        : apiData.shippingDiscount;
    let returnedData: any = {
      valuesmatched: true,
      calculatedTotal: '',
    };

    if (isAppliedOnPromoProduct) {
      let projectPrice = 0;
      for (var i = 0; i < this.cartItemList.length; i++) {
        let cartItem = this.cartItemList[i];
        let isPromoApplicable = false;
        let arrCoupanItems = apiData.cartData.items;
        for (let j = 0; j < arrCoupanItems.length; j++) {
          if (arrCoupanItems[j].productCode == cartItem.productCode) {
            if (cartItem.promoPrice != false) {
              if (
                arrCoupanItems[j].couponDiscount == 0 ||
                arrCoupanItems[j].pageDiscount == 0
              ) {
                isPromoApplicable = true;
              }
            }
            break;
          }
        }

        if (isPromoApplicable) {
          projectPrice = Number(projectPrice) + parseFloat(cartItem.promoPrice);
        } else {
          projectPrice =
            Number(projectPrice) + parseFloat(cartItem.defaultPrice);
        }
      }
      projectPrice = parseFloat(projectPrice.toFixed(2));
      returnedData.calculatedTotal = projectPrice;
      return returnedData;
    } else {
      for (let i = 0; i < apiData.cartData.items.length; i++) {
        let cartItem = apiData.cartData.items[i];
        let quantity;
        let projectAdditionalPagePrice = 0;

        if (apiData.cartData.items[i].preview == 'PRINT') {
          quantity = parseInt(apiData.cartData.items[i].totalPrints);
        } else {
          quantity = apiData.cartData.items[i].quantity;
        }

        if (
          apiData.cartData.items[i].projectAdditionalPagePrice &&
          apiData.cartData.items[i].projectAdditionalPagePrice != null
        ) {
          projectAdditionalPagePrice =
            apiData.cartData.items[i].projectAdditionalPagePrice;
        }
        //
        if (cartItem.envelopesPrice) {
          envelopesPrice =
            envelopesPrice +
            apiData.cartData.items[i].envelopesPrice *
              apiData.cartData.items[i].quantity;
        }

        // let price: any = cartItem.total* cartItem.quantity;

        let price: any = cartItem.projectPrice + projectAdditionalPagePrice;

        totalPrice = Number(totalPrice) + parseFloat(price);
      }
      totalPrice = totalPrice.toFixed(2);
      //
      totalPriceAfterShipping =
        parseFloat(totalPrice) +
        parseFloat(envelopesPrice) +
        parseFloat(apiData.shippingFee) +
        parseFloat(apiData.handlingFee) -
        apiData.shippingDiscount -
        couponAmount;
      let calculatedTotal = parseFloat(totalPriceAfterShipping.toFixed(2));

      if (
        (apiData.grosstotal && apiData.grosstotal != calculatedTotal) ||
        (apiData.paidAmount && apiData.paidAmount != calculatedTotal)
      ) {
        returnedData.valuesmatched = false;
      } else {
        returnedData.valuesmatched = true;
      }
      //

      returnedData.calculatedTotal = calculatedTotal;
    }
    return returnedData;
  }

  setOrderNumber(data) {
    this.orderNoObj.next(data);
  }

  updatePaymentOrderStatus(orderNumber, paymentMethod, paymentStatus, id) {
    this.dataService
      .updatePaymentOrder(orderNumber, paymentMethod, paymentStatus, id)
      .subscribe(
        (res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          if (resJSON.success == true) {
            this.updateOrderStatus(paymentMethod, orderNumber);
          }
        },
        (err) => {
          this.showErrorPopup();
        }
      );
  }

  updateOrderStatus(paymentMethod, orderNumber) {
    this.dataService.updateStatus(orderNumber).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);

      if (resJSON.success == true) {
        let orderNo = orderNumber;
        this.isProcessing = false;
        this.showPaypalBtn = false;
        this.showOnProcess = false;
        // if (paymentMethod == 'scheme') {
        // this.ngZone.run(() => {
        // this.isDisplayloaderMsg = false;
        // });
        // }
        // else {
        this.isDisplayloaderMsg = false;
        // }
        this.setGtmCodeForOrders(orderNumber, paymentMethod);
      }
    });
  }

  applyCouponCodeModal() {
    let subTitle = this.languageData.web.checkout_apply_coupon_code_msg;
    let okBtn = this.languageData.web.checkout_confirm_address_default_cta_ok;
    const modalRef = this.modalService.open(DynamicPopsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.modalData = {
      subTitle: subTitle,
      okBtn: okBtn,
      modalName: 'applyCoupon',
      cancelIcon: false,
    };
  }

  adyenCheckout() {
if (this.grandTotalAfterShipping <=0) {
  return;
}
    let merchantName = '',
      merchantId = '';

    let coupons = this.coupanForm.get('coupans') as FormArray;
    let coupondata = [];
    coupons.controls.map((item) => {
      //
      if (item.value && item.value.coupanDetails) {
        coupondata.push(item.value.coupanDetails);
      }
    });

    if (
      coupondata.length > 0 &&
      this.grandTotalOriginal == this.grandTotalAfterShipping &&
      this.isCouponApply == false
    ) {
      this.applyCouponCodeModal();
      return;
    }
    // if (
    //   this.enteredCouponCode != '' &&
    //   this.grandTotalOriginal == this.grandTotalAfterShipping &&
    //   this.isCouponApply == false
    // ) {
    //   this.applyCouponCodeModal();
    //   return;
    // }
    if (
      this.agbChecked == false ||
      this.agbChecked == undefined ||
      this.privacyPolicyChecked == false ||
      this.privacyPolicyChecked == undefined
    ) {
      this.isNoCheckBoxSelected = true;
      this.isNoPrivacyBoxSelected = true;
      return;
    }
    this.display = 'block';
    this.isProcessing = false;
    var arrLocaleCode = environment[global.env].arrAdyenLocaleCode;
    var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
    var configuration = {
      locale: currentLocale,
      environment: environment[global.env].env,
      originKey: this.originKey,
      paymentMethodsResponse: this.paymentMethodsRespons,
    };
    //  console.info('this.paymentMethodsRespons', this.paymentMethodsRespons);
    var checkout = new AdyenCheckout(configuration);

    let self = this;
    this.uniqueId = uuid.v4();

    const locale = global.lang.toUpperCase();
  //  console.info(environment[global.env].arrAdyenLocaleCode[locale],'environment[global.env].arrAdyenLocaleCode[locale]')
    var paymentsDefaultConfig = {
      application: 'IPHONE_APP',
      data: {
        oemCode: environment[global.env].oemCode,
        // shopperReference: 'reference',
        reference: this.orderNumber,
        uniqueKey: this.uniqueId,
        appVersion: 'ONL v 1.0.0',
        channel: 'web',
        countryCode: this.billingAddress.selectedCountryCode,
        shopperLocale: environment[global.env].arrAdyenLocaleCode[locale],
        returnUrl: location.origin + global.urlSuffix + '/' + this.cartPageUrl,
        amount: {
          value:
          Math.round(Number(this.priceService.getCommaOrDotValue(
            this.grandTotalAfterShipping,
            false
          )*100)
        ),
            // Number(
            //   this.priceService.getCommaOrDotValue(
            //     this.grandTotalAfterShipping,
            //     false
            //   )
            // ) * 100,

          currency: environment[global.env].currency,
        },
      },
    };
  //   console.info(
  //     Math.round(Number(this.priceService.getCommaOrDotValue(
  //     this.grandTotalAfterShipping,
  //     false
  //   )*100)
  // ));return
    let applepayIndex = -1;
    if (this.paymentMethodsRespons?.paymentMethods) {
      applepayIndex = this.paymentMethodsRespons.paymentMethods.findIndex(
      (item) => item.type == 'applepay'
    );
  }
    if (applepayIndex > -1) {
      merchantId = this.paymentMethodsRespons?.paymentMethods[applepayIndex]
        ?.configuration?.merchantId
        ? this.paymentMethodsRespons.paymentMethods[applepayIndex].configuration
            .merchantId
        : '';
      merchantName = this.paymentMethodsRespons?.paymentMethods[applepayIndex]
        ?.configuration?.merchantName
        ? this.paymentMethodsRespons.paymentMethods[applepayIndex].configuration
            .merchantName
        : '';
    }
  // }
    //console.info(this.paymentMethodsRespons.paymentMethods,merchantId,merchantName)
   
    var dropin = checkout
      .create('dropin', {
        paymentMethodsConfiguration: {
          applepay: {
            // Example required configuration for Apple Pay
            configuration: {
              merchantName: merchantName, // Name to be displayed on the form
              merchantIdentifier: merchantId, // Your Apple merchant identifier as described in https://developer.apple.com/documentation/apple_pay_on_the_web/applepayrequest/2951611-merchantidentifier
            },
          },
          paywithgoogle: {
            // Example required configuration for Google Pay
            environment: 'TEST', // Change this to PRODUCTION when you're ready to accept live Google Pay payments
            configuration: {
              gatewayMerchantId: 'YourCompanyOrMerchantAccount', // Your Adyen merchant or company account name. Remove this field in TEST.
              merchantIdentifier: '12345678910111213141', // Required for PRODUCTION. Remove this field in TEST. Your Google Merchant ID as described in https://developers.google.com/pay/api/web/guides/test-and-deploy/deploy-production-environment#obtain-your-merchantID
            },
          },
          card: {
            // Example optional configuration for Cards
            hasHolderName: true,
            holderNameRequired: true,
            enableStoreDetails: true,
            name: this.languageData.web.adyen_credit_card_title,

            // For placeholder change here
            // placeholders: {
            //   encryptedCardNumber: "Your custom card number text",
            //   encryptedExpiryDate: "custom MM/YY",
            //   encryptedSecurityCode: "custom CVC"
            // },
          },
          sepadirectdebit: { name: this.languageData.web.adyen_sepa_title },
          klarna: { name: this.languageData.web.adyen_klarna_title },
          klarna_account: {
            name: this.languageData.web.adyen_klarna_account_title,
          },
          klarna_paynow: {
            name: this.languageData.web.adyen_klarna_paynow_title,
          },
          paypal: { name: this.languageData.web.adyen_paypal_title },
        },
        onSubmit: (state, dropin) => {
          this.IsPaymentProcessing = false;
          this.refusalReasonCode = undefined;
          let btn = this.dropinElement.nativeElement.querySelectorAll('button');
          btn.forEach((button) => {
            button.setAttribute('disabled', 'disabled');
          });

          if (this.orderNumber != undefined) {
            this.isProcessing = false;
            paymentsDefaultConfig.data.reference = this.orderNumber;
            this.globalService.setCartLengthData(0);
            self.dataService
              .makePayment(state.data, paymentsDefaultConfig)
              .subscribe(
                (res: Response) => {
                  let resSTR = JSON.stringify(res);
                  let resJSON = JSON.parse(resSTR);
                  // console.info('redirect result',resJSON);return;
                  this.IsPaymentProcessing = true;
                  if (resJSON.resultCode == 'Refused') {
                    let errorIndex = this.definedPaymentErrors.indexOf(
                      resJSON.refusalReasonCode
                    );
                    this.isDefinedError = errorIndex >= 0 ? true : false;
                    if (!this.isDefinedError) {
                      const data = {
                        cartPage: this.cartPageUrl,
                        refusalReasonCode: resJSON.refusalReasonCode,
                      };
                      this.contentfulService.opensuccessModal(
                        'paymentrefused',
                        data
                      );
                    }
                    this.ngZone.run(() => {
                      localStorage.setItem('Refused', resJSON.resultCode);
                      paymentsDefaultConfig.data.uniqueKey = uuid.v4();
                      this.refusalReasonCode = resJSON.refusalReasonCode;

                      btn.forEach((button) => {
                        button.removeAttribute('disabled');
                      });
                    });
                    return;
                  }

                  if (resJSON.resultCode == 'Authorised') {
                    this.isDisplayloaderMsg = true;
                    localStorage.removeItem('Refused');
                    this.paymentPaid = 'paid';
                    this.updateOrderStatus(
                      state.data.paymentMethod.type,
                      this.orderNumber
                    );
                  } else if (resJSON.resultCode == 'RedirectShopper') {
                    localStorage.removeItem('Refused');
                    localStorage.setItem('orderNo', this.orderNumber);
                    localStorage.setItem('paymentData', resJSON.paymentData);
                    if (resJSON.action.method == 'POST') {
                      //localStorage.setItem('MD', resJSON.redirect.data.MD);
                      if(resJSON.redirect && resJSON.redirect.data){
                        localStorage.setItem('MD', resJSON.redirect.data.MD);
                      }

                      else if(resJSON.redirectResult){
                        //localStorage.setItem('MD', resJSON.redirect.data.MD);
                        localStorage.setItem('redirectResult', resJSON.redirectResult);
                      }


                      

                      // console.info('redirect result',resJSON)
                    }
                    dropin.handleAction(resJSON.action);
                  }
                },
                (err) => {
                  //
                  this.showErrorPopup();
                  // check error status code is 500, if so, do some action
                }
              );
          } else {
            // this.grandTotalAfterShipping= this.priceService.getCurrencyFormat(this.grandTotalAfterShipping);
            // this.grandTotalOriginal= this.priceService.getCurrencyFormat(this.grandTotalOriginal);
            //

            this.orderSubmit('Adyen');
            this.isProcessing = false;
            this.orderNumberSubscription = this.orderNoObj.subscribe((data) => {
              this.orderNumber = data;
              paymentsDefaultConfig.data.reference = this.orderNumber;
              this.globalService.setCartLengthData(0);
              ////////
              self.dataService
                .makePayment(state.data, paymentsDefaultConfig)
                .subscribe(
                  (res: Response) => {
                    let resSTR = JSON.stringify(res);
                    let resJSON = JSON.parse(resSTR);
                    this.IsPaymentProcessing = true;
                    //  console.info('redirect result',resJSON);return
                    //  ////////
                    if (
                      resJSON.status == 422 &&
                      resJSON.errorCode == '14_029'
                    ) {
                      this.ngZone.run(() => {
                        this.refusalReasonMsg =
                          this.languageData.web.refusal_cvv_error_code_msg;
                        // this.refusalReasonMsg = resJSON.message;
                        paymentsDefaultConfig.data.uniqueKey = uuid.v4();
                        localStorage.setItem('Refused', resJSON.errorCode);
                        this.IsPaymentProcessing = false;
                        let btn =
                          this.dropinElement.nativeElement.querySelectorAll(
                            'button'
                          );
                        btn.forEach((button) => {
                          button.removeAttribute('disabled');
                        });
                      });
                      return;
                    }

                    if (resJSON.status == 422 && resJSON.errorCode == '101') {
                      ////////
                      this.ngZone.run(() => {
                        this.refusalReasonMsg =
                          this.languageData.web.refusal_invalidcard_error_code_msg;
                        // this.refusalReasonMsg = resJSON.message;
                        paymentsDefaultConfig.data.uniqueKey = uuid.v4();
                        localStorage.setItem('Refused', resJSON.errorCode);
                        this.IsPaymentProcessing = false;
                        let btn =
                          this.dropinElement.nativeElement.querySelectorAll(
                            'button'
                          );
                        btn.forEach((button) => {
                          setTimeout(() => {
                            button.removeAttribute('disabled');
                            button.disabled = false;
                          }, 100);
                        });
                      });
                      return;
                    }

                    if (resJSON.resultCode == 'Refused') {
                      let errorIndex = this.definedPaymentErrors.indexOf(
                        resJSON.refusalReasonCode
                      );
                      this.isDefinedError = errorIndex >= 0 ? true : false;
                      if (!this.isDefinedError) {
                        const data = {
                          cartPage: this.cartPageUrl,
                          refusalReasonCode: resJSON.refusalReasonCode,
                        };
                        this.contentfulService.opensuccessModal(
                          'paymentrefused',
                          data
                        );
                      }

                      this.ngZone.run(() => {
                        this.refusalReasonCode = resJSON.refusalReasonCode;
                        paymentsDefaultConfig.data.uniqueKey = uuid.v4();
                        localStorage.setItem('Refused', resJSON.resultCode);

                        btn.forEach((button) => {
                          button.removeAttribute('disabled');
                        });
                      });
                      return;
                    }

                    if (resJSON.resultCode == 'Authorised') {
                      localStorage.removeItem('Refused');
                      this.paymentPaid = 'paid';
                      this.updateOrderStatus(
                        state.data.paymentMethod.type,
                        this.orderNumber
                      );
                    } else if (resJSON.resultCode == 'RedirectShopper') {
                      localStorage.removeItem('Refused');
                      localStorage.setItem('orderNo', this.orderNumber);
                      localStorage.setItem('paymentData', resJSON.paymentData);
                      if (resJSON.action.method == 'POST') {
                        localStorage.setItem('MD', resJSON.redirect.data.MD);
                      }

                      dropin.handleAction(resJSON.action);
                    }
                  },
                  (err) => {
                    window.location.reload();
                    // check error status code is 500, if so, do some action
                  }
                );
            });
          }
        },
        onClick: (resolve, reject) => {
          ////////
        },
        onAdditionalDetails: (state, dropin) => {
          let md;
          let pares;
          console.info('onAdditionalDetails',state)
          self.dataService
            .makeDetailsCall(state.data, md, pares, '', this.redirectResult)
            .subscribe(
              (res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                dropin.handleAction(resJSON.action);
              },
              (err) => {
                window.location.reload();
                // check error status code is 500, if so, do some action
              }
            );
        },
        onValidateMerchant: (resolve, reject, validationURL) => {
          // Your server uses the validation URL to request a session from the Apple Pay server.
          // Call resolve(MERCHANTSESSION) or reject() to complete merchant validation.
          // this.dataService.validateMerchant(validationURL).subscribe((response: any) => {
          //   resolve(response);
          // }, (error) => {
          //   reject();
          // })
          // validateMerchant(validationURL)
          //     .then(response => {
          //     // Complete merchant validation with resolve(MERCHANTSESSION) after receiving an opaque merchant session object, MerchantSession
          //     resolve(response);
          //     })
          //     .catch(error => {
          //     // Complete merchant validation with reject() if any error occurs
          //     reject();
          //     });
        },
        // onValidateMerchant: (resolve, reject, validationURL) => {

        //   // Call the validation endpoint with validationURL.
        //   // Call resolve(MERCHANTSESSION) or reject() to complete merchant validation.
        // },
      })
      .mount('#dropin');
  }
  //Stripe Code starts

  // stripeCheckout(amount: number) {
  //   const paymentHandler = (<any>window).StripeCheckout.configure({
  //     key: environment[global.env].STRIPE_CLIENT_SIDE_KEY,
  //     locale: 'auto',
  //     token: function (stripeToken: any) {
  //
  //       paymentstripe(stripeToken);
  //     },
  //   });

  //   const paymentstripe = (stripeToken: any) => {
  //     // this.checkout.makePayment(stripeToken).subscribe((data: any) => {
  //     //
  //     //   if (data.data === "success") {
  //     //     this.success = true
  //     //   }
  //     //   else {
  //     //     this.failure = true
  //     //   }
  //     // });
  //   };

  //   paymentHandler.open({
  //     name: 'TITLE',
  //     description: 'DESCRIPTION',
  //     amount: amount * 100,
  //   });
  // }

  // invokeStripe() {
  //   if (!window.document.getElementById('stripe-script')) {
  //     const script = window.document.createElement('script');
  //     script.id = 'stripe-script';
  //     script.type = 'text/javascript';
  //     script.src = 'https://checkout.stripe.com/checkout.js';
  //     script.onload = () => {
  //       this.paymentHandler = (<any>window).StripeCheckout.configure({
  //         key: environment[global.env].STRIPE_CLIENT_SIDE_KEY,
  //         locale: 'auto',
  //         token: function (stripeToken: any) {
  //
  //         },
  //       });
  //     };

  //     window.document.body.appendChild(script);
  //   }
  // }

  openStripeModal() {
    // this.grandTotalAfterShipping= this.priceService.getCurrencyFormat(this.grandTotalAfterShipping);
    // this.grandTotalOriginal= this.priceService.getCurrencyFormat(this.grandTotalOriginal);
    //

    // document.getElementById('stripeModal').style.display = "block";
    $('#stripeModal').modal('show');
    this.stripeTest.reset();
    //
    this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
      this.elements = elements;
      //elements.clear();
      // Only mount the element the first time
      // if (!this.card) {
      //   this.card = this.elements.create('card', this.cardOptions);
      //   this.card.mount('#card-element');
      // }

      this.card = this.elements.create('card', this.cardOptions);
      this.card.mount('#card-element');
    });
  }
  closeStripeModal() {
    $('#stripeModal').modal('hide');
    this.showStripeLoader = false;
    this.failure = false;
    this.failureStripeMessage = false;
  }
  // this.card.on('change', function(event) {
  //   if (event.complete) {
  //     // enable payment button
  //   } else if (event.error) {
  //     // show validation to customer
  //   }
  // });

  createToken() {
    const name = this.stripeTest.get('name').value;
    if (name == undefined || name == '' || name == null) {
      this.failureStripeMessage =
        this.languageData.web['stripe_card_holder_name_required'];
      return false;
    }
    let amt = this.grandTotalAfterShipping * 100;

    let amount = Number(this.priceService.getCommaOrDotValue(amt, false));

    this.failure = false;
    this.showStripeLoader = true;

    //

    this.stripeService
      .createToken(this.card, { name })
      .subscribe((result: any) => {
        if (result.token) {
          // Use the token

          let resultData = JSON.parse(JSON.stringify(result));
          let requestPayload = {
            token: result.token.id,
            currency: environment[global.env].currency.toLowerCase(),
            id: resultData.token.card.id,
            oemCode: environment[global.env].oemCode,
            amount: amount,
          };

          //
          if (this.orderNumber != undefined) {
            this.isProcessing = false;
            requestPayload['orderNumber'] = this.orderNumber;
            this.globalService.setCartLengthData(0);

            this.makeStripeOrder(requestPayload);
            // ////////
          } else {
            this.orderSubmit('Stripe');
            this.isProcessing = false;
            //   ////////
            this.orderNumberSubscription = this.orderNoObj.subscribe((data) => {
              this.orderNumber = data;
              requestPayload['orderNumber'] = this.orderNumber;
              this.globalService.setCartLengthData(0);
              this.makeStripeOrder(requestPayload);
            });
          }
        } else if (result.error) {
          this.showStripeLoader = false;
          // Error creating the token
          //
        }
      });
  }
  makeStripeOrder(requestPayload) {
    //

    this.failureStripeMessage = false;
    requestPayload['locale'] = this.elementsOptions['locale'];
    if (this.stripePaymentType == 'charge') {
      requestPayload['stripePaymentType'] = this.stripePaymentType;
      this.callStripeApi(requestPayload);
    } else if (this.stripePaymentType == 'intent') {
      this.stripeService
        .createPaymentMethod({
          type: 'card',
          card: this.card,
          billing_details: {
            name: this.stripeTest.get('name').value,
          },
        })
        .subscribe((data: any) => {
          if (data.error) {
          }
          // Handle result.error or data.paymentMethod
          //
          requestPayload['pm'] = data.paymentMethod;
          requestPayload['stripePaymentType'] = this.stripePaymentType;
          requestPayload['type'] = 'intentonly';
          this.callStripeApi(requestPayload);
        });
    }
  }
  callStripeApi(requestPayload) {
    this.dataService
      .makeStripePayment(requestPayload)
      .subscribe((response: any) => {
        let resSTR = JSON.stringify(response);
        let resJSON = JSON.parse(resSTR);

        if (resJSON.success) {
          if (this.stripePaymentType == 'intent') {
            let returnUrl: any;
            if (this.couponcode != undefined && this.couponcode != '') {
              returnUrl =
                location.origin +
                '/' +
                this.thankyouPageUrl +
                '/' +
                this.orderNumber +
                '&couponcode=' +
                this.couponcode;
            } else {
              returnUrl =
                location.origin +
                '/' +
                this.thankyouPageUrl +
                '/' +
                this.orderNumber;
            }
            this.stripeService
              .confirmCardPayment(
                resJSON.intent,
                {
                  payment_method: {
                    card: this.card,
                    billing_details: {
                      name: this.stripeTest.get('name').value,
                    },
                  },
                  return_url: returnUrl,
                }
                //{handleActions: false}
              )
              .subscribe((result: any) => {
                if (result.error) {
                  this.showStripeLoader = false;
                  this.failureStripeMessage = result.error.message;
                  this.failure = true;
                  //
                } else {
                  requestPayload['type'] = 'fullProcess';
                  requestPayload['intentResponce'] = resJSON.intentResponce;

                  this.dataService.makeStripePayment(requestPayload).subscribe(
                    (response: any) => {
                      let resSTR = JSON.stringify(response);
                      let resJSON = JSON.parse(resSTR);

                      if (resJSON.success) {
                        this.failure = false;
                        this.failureStripeMessage = false;

                        let datas = JSON.parse(
                          JSON.stringify(result.paymentIntent)
                        );
                        var action = result.paymentIntent.next_action;

                        if (action != null) {
                          //

                          if (action && action.type === 'use_stripe_sdk') {
                          }
                        } else {
                          //

                          this.setGtmCodeForOrders(
                            requestPayload['orderNumber'],
                            'Stripe'
                          );
                          this.closeStripeModal();
                          this.router
                            .navigate(
                              [this.thankyouPageUrl, this.orderNumber],
                              { queryParams: { couponcode: this.couponcode } }
                            )
                            .then(() => {
                              //  window.location.reload();
                              // this.setGtmCodeForOrders(requestPayload['orderNumber'], 'Stripe')
                            });
                        }

                        //  this.success = true
                      } else {
                        this.failure = true;
                        this.showStripeLoader = false;
                        // this.failureMessage= this.languageData.web[resJSON.message];
                      }
                    },
                    (err) => {
                      this.closeStripeModal();
                      this.showErrorPopup();
                    }
                  );
                }
              });
          } else if (this.stripePaymentType == 'charge') {
            this.setGtmCodeForOrders(requestPayload['orderNumber'], 'Stripe');
            this.closeStripeModal();
            this.router.navigate([this.thankyouPageUrl, this.orderNumber], {
              queryParams: { couponcode: this.couponcode },
            });
          }

          //  this.success = true
        } else {
          // this.failureMessage= this.languageData.web[resJSON.message];

          this.showStripeLoader = false;
          this.failureStripeMessage = resJSON.error.detail;
          this.failure = true;
        }
      });
  }

  showErrorPopup(fromApplyCoupon = false) {
    this.fromApplyCoupon = fromApplyCoupon;
    $('#errorModal').modal('show');
  }
  closeErrorModal() {
    this.fromApplyCoupon = false;
    window.location.reload();
  }
  openSepaModal() {
    // this.grandTotalAfterShipping= this.priceService.getCurrencyFormat(this.grandTotalAfterShipping);
    // this.grandTotalOriginal= this.priceService.getCurrencyFormat(this.grandTotalOriginal);
    //
    //

    $('#sepaPayment').modal('show');
    // options=
    // $('#myModal').modal(options)

    this.sepaFormFields = JSON.parse(JSON.stringify(this.user.billingDetail));

    let oemCountryObj = this.getOemCountry(
      this.sepaFormFields.selectedCountry,
      ''
    );
    this.charMinLengthSP = oemCountryObj.MinPostalLength;
    this.charMaxLengthSP = oemCountryObj.PostalLength;
    this.countPostalCodeBl(this.sepaFormFields.zip, true);
    this.sepaFormFields.phoneExtension = oemCountryObj.ISD;
    this.sepaFormFields.selectedCountry = oemCountryObj.countryName;

    this.sepaFormFields['iBan'] = '';
    this.sepaFormFields['bic'] = '';
    // this.changeCountryAndSetExtn(this.oemCountryObj.countryName, 'sepaForm');
    //
    this.showSepaPopup = true;
    this.changeDetectorRef.detectChanges();
  }
  sepaPaymentSubmit() {
    this.failure = false;
    this.failureMessage = false;

    if (this.sepaForm.form.invalid) {
      return;
    }
    let requestPayload = {
      email: this.sepaFormFields.email,
      firstName: this.sepaFormFields.firstName,
      lastName: this.sepaFormFields.lastName,
      address: this.sepaFormFields.street,
      houseNumber: this.sepaFormFields.houseNumber,
      zipCode: this.sepaFormFields.zip,
      city: this.sepaFormFields.city,
      countryCode: this.sepaFormFields.selectedCountryCode,
      accountNumber: '',
      bankNumber: '',
      iBan: this.sepaFormFields.iBan,
      bic: this.sepaFormFields.bic,
      currency: environment[global.env].currency,
      amount: Number(this.priceService.forSepa(this.grandTotalAfterShipping)),
      orderNumber: '',
      oemCode: environment[global.env].oemCode,
      langCode: global.lang.toUpperCase(),
    };
    //
    this.showSepaLoader = true;
    if (this.orderNumber != undefined) {
      this.isProcessing = false;

      requestPayload['orderNumber'] = this.orderNumber;
      this.globalService.setCartLengthData(0);
      this.makeSepaOrder(requestPayload);
    } else {
      this.orderSubmit('Paygate');
      this.isProcessing = false;

      this.orderNumberSubscription = this.orderNoObj.subscribe((data) => {
        this.orderNumber = data;
        requestPayload['orderNumber'] = this.orderNumber;
        this.globalService.setCartLengthData(0);
        this.makeSepaOrder(requestPayload);
      });
    }
  }
  makeSepaOrder(requestPayload) {
    //
    this.dataService.makeSepaPayment(requestPayload).subscribe(
      (response: any) => {
        let resSTR = JSON.stringify(response);
        let resJSON = JSON.parse(resSTR);

        if (resJSON.success) {
          this.setGtmCodeForOrders(requestPayload['orderNumber'], 'Paygate');
          this.closeSepaModal();
          this.router.navigate([this.thankyouPageUrl, this.orderNumber], {
            queryParams: { couponcode: this.couponcode },
          });
          //  this.success = true
        } else {
          this.failure = true;
          this.showSepaLoader = false;
          // this.sepaErrorMessage=this.languageData.web[resJSON.message]
          this.failureMessage = resJSON.message; //this.languageData.web[resJSON.message];
        }
      },
      (err) => {
        this.closeSepaModal();
        this.showErrorPopup();
      }
    );
  }
  closeSepaModal() {
    $('#sepaPayment').modal('hide');
    this.showSepaLoader = false;
    this.failure = false;
    this.failureMessage = '';
  }

  //Stripe Code ends

  // PAYPAL CODE START
  private initConfig(clientId, paypalAction, payPalIntentType): void {
    //
    var arrLocaleCode = environment[global.env].arrPaypalLocaleCode;
    var currentLocale = 'en_GB'; //arrLocaleCode[global.lang.toUpperCase()];
    var web = this.languageData.web;
    var paymentTransDesc = web['paypal_transaction_description'];
    //

    var paypalamount: any = Number(
      this.priceService.getCommaOrDotValue(this.grandTotalAfterShipping, false)
    );

    if (paypalAction == 'clientSide') {
      this.payPalConfig = {
        currency: environment[global.env].currency,

        //Stage clientId:'AY6RIGkTkDIwnjZIqVtGORJc8GbspfaMRLBIwHvub3gMZFgxW00u8HxTBNueZih01-k4AoorkTPREygE',
        //Ashwani 'Ae8GgwbiItlzEuxW4dUDSteLkyO-qtApXIWShH155uXTfskpoTwdcC2rIh1MZExDm6biz9ppSa8C5ygQ'
        //Rohit 'AT59Fa0TKWOjDwRwN2uB3nU8DJDdm6bKuT2usxTzV_YkOXnjVlVPaW2XiQ8YUXw3ER4rYsUg1S2B--O1'
        clientId: clientId,

        createOrderOnClient: (data) =>
          <ICreateOrderRequest>{
            intent: 'AUTHORIZE',
            locale: currentLocale,

            purchase_units: [
              {
                invoice_id: this.orderNumber,
                amount: {
                  currency_code: environment[global.env].currency,
                  value: paypalamount,
                  breakdown: {
                    item_total: {
                      currency_code: environment[global.env].currency,
                      value: paypalamount,
                    },
                  },
                },
                items: [
                  {
                    name: paymentTransDesc,
                    quantity: '1',
                    category: 'DIGITAL_GOODS',
                    unit_amount: {
                      currency_code: environment[global.env].currency,
                      value: paypalamount,
                    },
                  },
                ],
              },
            ],
          },
        advanced: {
          commit: 'true',
          extraQueryParams: [
            {
              name: 'intent',
              value: 'authorize',
            },
          ],
        },
        style: {
          label: 'paypal',
          layout: 'horizontal',
          height: 40,
          // size: 'responsive',
          shape: 'rect',
          color: 'gold',
          tagline: false,
        },

        onApprove: (data, actions) => {
          //
          actions.order.authorize().then((details) => {
            var authorizationID =
              details.purchase_units[0].payments.authorizations[0].id;
            if (details.status == 'COMPLETED') {
              this.id = authorizationID;
              // this.orderSubmit('PayPal');
              this.updatePaymentOrderStatus(
                this.orderNumber,
                'PayPal',
                'paid',
                this.id
              );
            }
          });
        },

        onCancel: (data, actions) => {
          this.restoreInCartAfterPayment();
        },
        onError: (err) => {
          //
        },

        onClick: (data, actions) => {
          //

          //
          let invoice_id;
          if (this.orderNumber != undefined) {
          } else {
            this.orderSubmit('PayPal', true);
            this.isProcessing = false;
            this.orderNumberSubscription = this.orderNoObj.subscribe((data) => {
              this.orderNumber = data;
              invoice_id = this.orderNumber;
              this.globalService.setCartLengthData(0);
            });
          }
          let coupons = this.coupanForm.get('coupans') as FormArray;
          let coupondata = [];
          coupons.controls.map((item) => {
            //
            if (item.value && item.value.coupanDetails) {
              coupondata.push(item.value.coupanDetails);
            }
          });

          if (
            coupondata.length > 0 &&
            this.grandTotalOriginal == this.grandTotalAfterShipping &&
            this.isCouponApply == false
          ) {
            this.applyCouponCodeModal();
            return;
          }
          // if ((this.enteredCouponCode != '' &&
          //   this.grandTotalOriginal == this.grandTotalAfterShipping &&
          //   this.isCouponApply == false)) {
          //   this.applyCouponCodeModal(); return false;
          // }
        },
      };
    } else if (paypalAction == 'serverSide') {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      let GLOBAL_AUTH_KEY =
        'eyJhdXRoIjoiIiwiRElTVFJJQlVURURfVE9LRU4iOiI1OWVjM2Q1ODQyMWI3ZmMyYjliZGYwZDg1ODJkNjFiNCJ9';
      let authkey = userInfo != null ? userInfo.auth : GLOBAL_AUTH_KEY;
      var input = {
        application: global.application,
        oemCode: environment[global.env].oemCode,
        payPalIntentType,
        data: {
          payPalIntentType,
          intent: payPalIntentType.toUpperCase(),
          locale: currentLocale,
          purchase_units: [
            {
              reference_id: this.orderNumber,
              invoice_id: this.orderNumber,

              amount: {
                currency_code: environment[global.env].currency,
                value: paypalamount,
                breakdown: {
                  item_total: {
                    currency_code: environment[global.env].currency,
                    value: paypalamount,
                  },
                },
              },
              items: [
                {
                  name: paymentTransDesc,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: environment[global.env].currency,
                    value: paypalamount,
                  },
                },
              ],
            },
          ],
        },
      };

      this.payPalConfig = {
        currency: environment[global.env].currency,
        //Stage clientId:'AY6RIGkTkDIwnjZIqVtGORJc8GbspfaMRLBIwHvub3gMZFgxW00u8HxTBNueZih01-k4AoorkTPREygE',
        //Ashwani 'Ae8GgwbiItlzEuxW4dUDSteLkyO-qtApXIWShH155uXTfskpoTwdcC2rIh1MZExDm6biz9ppSa8C5ygQ'
        //Rohit 'AT59Fa0TKWOjDwRwN2uB3nU8DJDdm6bKuT2usxTzV_YkOXnjVlVPaW2XiQ8YUXw3ER4rYsUg1S2B--O1'
        clientId: clientId,
        advanced: {
          commit: 'true',
          extraQueryParams: [
            {
              name: 'intent',
              value: payPalIntentType.toLowerCase(),
            },
          ],
        },
        style: {
          label: 'paypal',
          layout: 'horizontal',
          height: 40,
          // size: 'responsive',
          shape: 'rect',
          color: 'gold',
          tagline: false,
        },

        createOrderOnServer: async (data) => {
          input['data']['orderNumber'] = this.orderNumber;
          input['data'].purchase_units.map((item) => {
            (item.reference_id = this.orderNumber),
              (item.invoice_id = this.orderNumber);
            item.items.map((innerItem) => {
              innerItem.name = paymentTransDesc + ' ' + this.orderNumber;
            });
          });
          return fetch(
            environment[global.env].API_URL + '/service/app/paypal/createorder',
            {
              method: 'POST',
              body: JSON.stringify(input),
              headers: {
                'Content-Type': 'application/json',
                Authorization: authkey,
              },
            }
          ).then((res: any) =>
            res.json().then(
              (data: any) =>
                // {
                //
                //   this.paypalOrderId=data.data.id;
                // }
                data.data.id
            )
          );
        },

        authorizeOnServer: (approveData: any) => {
          approveData['orderNo'] = this.orderNumber;
          approveData['payPalIntentType'] = payPalIntentType;
          return fetch(
            environment[global.env].API_URL + '/service/app/paypal/payments',
            {
              method: 'POST',
              body: JSON.stringify({
                application: global.application,
                oemCode: environment[global.env].oemCode,
                data: approveData,
                payPalIntentType,
                orderNo: this.orderNumber,
              }),
              headers: {
                'Content-Type': 'application/json',
                Authorization: authkey,
              },
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((details) => {
              //
              // alert('Authorization created for ' + details.payer_given_name);
              if (details.success) {
                this.setGtmCodeForOrders(this.orderNumber, 'PayPal');
                setTimeout(() => {
                  this.router.navigate(
                    [this.thankyouPageUrl, this.orderNumber],
                    { queryParams: { couponcode: this.couponcode } }
                  );
                }, 1500);
              }
            })
            .catch((error) => {
              this.showErrorPopup();
            });
        },
        onCancel: (data, actions) => {
          this.restoreInCartAfterPayment();
        },
        onError: (err) => {
          //
        },
        onClick: async (data, actions) => {
          //

          // let invoice_id;
          // if (this.orderNumber != undefined) {

          // } else {
          //   this.orderSubmit('', true);

          //   this.isProcessing = false;
          //   this.orderNumberSubscription = this.orderNoObj.subscribe((data) => {
          //     this.orderNumber = data;
          //     invoice_id = this.orderNumber;
          //     this.globalService.setCartLengthData(0);

          //   })
          // }
          let coupons = this.coupanForm.get('coupans') as FormArray;
          let coupondata = [];
          coupons.controls.map((item) => {
            //
            if (item.value && item.value.coupanDetails) {
              coupondata.push(item.value.coupanDetails);
            }
          });

          if (
            coupondata.length > 0 &&
            this.grandTotalOriginal == this.grandTotalAfterShipping &&
            this.isCouponApply == false
          ) {
            this.applyCouponCodeModal();
            return;
          }
          // if ((this.enteredCouponCode != '' &&
          //   this.grandTotalOriginal == this.grandTotalAfterShipping &&
          //   this.isCouponApply == false)) {
          //   this.applyCouponCodeModal(); return false;
          // }
        },
      };
    }
  }
  // PAYPAL CODE END

  closePaymentPopup() {
    if (this.checkoutIntervalId != null) {
      clearInterval(this.checkoutIntervalId);
      this.checkoutIntervalId = null;
    }

    this.dataService
      .restoreOrder(this.orderNumber)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          this.isProcessing = false;
          this.router.navigate([this.cartPageUrl]);
        }
      });
  }

  restoreInCartAfterPayment() {
    if (!this.IS_PAYMENT_GATEWAY_READY) {
      if (this.checkoutIntervalId != null) {
        clearInterval(this.checkoutIntervalId);
        this.checkoutIntervalId = null;
      }
      this.IS_PAYMENT_PROCESSING = false;
      this.closePaymentPopup();
    }
  }

  openInvoiceModal(isPrePayment = false) {
    // this.grandTotalAfterShipping= this.priceService.getCurrencyFormat(this.grandTotalAfterShipping);
    // this.grandTotalOriginal= this.priceService.getCurrencyFormat(this.grandTotalOriginal);
    //

    let title;
    let checkBoxtext;
    let cancelBtn;
    let okBtn;

    if (!isPrePayment) {
      title = this.languageData.web.mycart_invoice_popup_title;
      checkBoxtext = this.languageData.web.mycart_invoice_popup_msg;
      cancelBtn = this.languageData.web.mycart_invoice_popup_cancel_btn;
      okBtn = this.languageData.web.mycart_invoice_popup_ok_btn;
    } else {
      title = this.languageData.web.mycart_prepayment_popup_title;
      checkBoxtext = this.languageData.web.mycart_prepayment_popup_msg;
      cancelBtn = this.languageData.web.mycart_prepayment_popup_cancel_btn;
      okBtn = this.languageData.web.mycart_prepayment_popup_ok_btn;
    }

    const modalRef = this.modalService.open(DynamicPopsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.modalData = {
      title: title,
      cancelBtn: cancelBtn,
      checkBoxtext: checkBoxtext,
      okBtn: okBtn,
      modalName: 'invoiceModal',
      cancelIcon: false,
      isPrePayment,
    };
    modalRef.componentInstance.invoiceData.subscribe((data) => {
      if (data) {
        if (isPrePayment) {
          this.orderSubmit('Prepayment');
        } else {
          //
          this.orderSubmit('Invoice');
        }
      }
    });
  }

  openInShopModal() {
    // this.grandTotalAfterShipping= this.priceService.getCurrencyFormat(this.grandTotalAfterShipping);
    // this.grandTotalOriginal= this.priceService.getCurrencyFormat(this.grandTotalOriginal);
    //

    let title = this.languageData.web.checkout_inshop_popup_title;
    let checkBoxtext = this.languageData.web.checkout_inshop_popup_msg;
    let cancelBtn = this.languageData.web.checkout_inshop_popup_cancel_btn;
    let okBtn = this.languageData.web.checkout_inshop_popup_ok_btn;

    const modalRef = this.modalService.open(DynamicPopsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.modalData = {
      title: title,
      cancelBtn: cancelBtn,
      checkBoxtext: checkBoxtext,
      okBtn: okBtn,
      modalName: 'inShopModal',
      cancelIcon: false,
    };
    modalRef.componentInstance.inShopData.subscribe((data) => {
      if (data) {
        this.orderSubmit('Shop');
      }
    });
  }

  checkPhone(newVal) {
    let regEx = /^[0-9]\d*$/;
    let emailPattern = regEx.test(newVal);
    if (!emailPattern) {
      return;
    }
    newVal = newVal * 1;
    this.user.shippingDetail.phoneNo = newVal.toString();
  }

  checkPhoneBl(newVal) {
    let regEx = /^[0-9]\d*$/;
    let emailPattern = regEx.test(newVal);
    if (!emailPattern) {
      this.showErrorMsg = true;
      return;
    }
    newVal = newVal * 1;
    this.showErrorMsg = false;
    this.user.billingDetail.phoneNo = newVal.toString();
  }

  checkHouse(val) {
    var houseVal = val.replace(/\s/g, '');
    houseVal = houseVal.toLowerCase();
    if (this.user.shippingDetail.street) {
      var streetVal = this.user.shippingDetail.street.replace(/\s/g, '');
      streetVal = streetVal.toLowerCase();

      if (streetVal.length > 0) {
        if (streetVal == houseVal) {
          this.streetError = true;
        } else {
          this.streetError = false;
        }
      } else {
        this.streetError = false;
      }
    } else {
      this.streetError = false;
    }
  }

  checkHouseBL(val) {
    var houseVal = val.replace(/\s/g, '');
    houseVal = houseVal.toLowerCase();
    if (this.user.billingDetail.street) {
      var streetVal = this.user.billingDetail.street.replace(/\s/g, '');
      streetVal = streetVal.toLowerCase();

      if (streetVal.length > 0) {
        if (streetVal == houseVal) {
          this.streetErrorBl = true;
        } else {
          this.streetErrorBl = false;
        }
      } else {
        this.streetErrorBl = false;
      }
    } else {
      this.streetErrorBl = false;
    }
  }

  checkStreet(val) {
    if (val == '' || val == undefined) {
      return;
    }
    let streetVal = val.replace(/\s/g, '');
    streetVal = streetVal.toLowerCase();
    if (this.user.shippingDetail.houseNumber) {
      let houseVal = this.user.shippingDetail.houseNumber.replace(/\s/g, '');
      houseVal = houseVal.toLowerCase();

      if (houseVal.length > 0) {
        if (houseVal == streetVal) {
          this.streetError = true;
        } else {
          this.streetError = false;
        }
      } else {
        this.streetError = false;
      }
    } else {
      this.streetError = false;
    }
  }

  checkStreetBl(val) {
    if (val == '' || val == undefined) {
      return;
    }
    let streetVal = val.replace(/\s/g, '');
    streetVal = streetVal.toLowerCase();
    if (this.user.billingDetail.houseNumber) {
      let houseVal = this.user.billingDetail.houseNumber.replace(/\s/g, '');
      houseVal = houseVal.toLowerCase();

      if (houseVal.length > 0) {
        if (houseVal == streetVal) {
          this.streetErrorBl = true;
        } else {
          this.streetErrorBl = false;
        }
      } else {
        this.streetErrorBl = false;
      }
    } else {
      this.streetErrorBl = false;
    }
  }

  changeGender(gender, formName) {
    if (formName == 'shippingForm') {
      this.user.shippingDetail.gender = gender;
      this.compareValues('shipping');
    }
    if (formName == 'billingForm') {
      this.user.billingDetail.gender = gender;
      this.compareValues('billing');
    }
  }

  changeCountryAndSetExtn(country, formName) {
    console.info(country);
    if (formName == 'shippingForm') {
      this.isEnabledBillingForm = true;
      this.countryObj = this.getCountry(country, '');
      if (this.countryObj.shippingOptions) {
        this.shippingType = true;
      }
      this.selectedShippingCountry = {
        code: this.countryObj.code,
        country: this.countryObj.country,
      };

      this.charMinLength = this.countryObj.minPostalLength;
      this.charMaxLength = this.countryObj.maxPostalLength;
      let indexofSelectedCountry = this.addressBookData.findIndex(
        (item) => item.IsDefaultShipping == 1 && item.CountryName == country
      );
      if (indexofSelectedCountry > -1) {
        this.user.shippingDetail.zip =
          this.addressBookData[indexofSelectedCountry].Zip;
        this.countPostalCode(this.user.shippingDetail.zip);
      } else {
        this.user.shippingDetail.zip = '';
        this.countPostalCode('');
      }

      this.user.shippingDetail.phoneExtension = this.countryObj.ISD;
      this.user.shippingDetail.selectedCountry = this.countryObj.country;
      // ////////
      this.user.shippingDetail.selectedCountryCode = this.countryObj.code;
      // ////

      if (
        this.countryObj.shippingOptions &&
        this.countryObj.shippingOptions != ''
      ) {
        this.calculateShipping(this.countryObj.shippingOptions[0].type);
      } else {
        this.calculateShipping();
      }

      this.calculateGrandTotal();
      this.getZipPattern(this.countryObj);
      this.compareValues('shipping');
      this.changeDetectorRef.detectChanges();
    }
    if (formName == 'billingForm') {
      this.oemCountryObj = this.getOemCountry(country, '');
      this.charMinLengthBl = this.oemCountryObj.MinPostalLength;
      this.charMaxLengthBl = this.oemCountryObj.PostalLength;
      let indexofSelectedCountry = this.arrBillingAddressBook.findIndex(
        (item) => item.IsDefaultBilling == 1 && item.CountryName == country
      );
      //////
      if (indexofSelectedCountry > -1) {
        this.user.billingDetail.zip =
          this.arrBillingAddressBook[indexofSelectedCountry].Zip;
        this.countPostalCodeBl(this.user.billingDetail.zip);
      } else {
        this.user.billingDetail.zip = '';
        this.countPostalCodeBl('');
      }

      // this.user.billingDetail.zip = '';
      this.user.billingDetail.phoneExtension = this.oemCountryObj.ISD;
      this.user.billingDetail.selectedCountry = this.oemCountryObj.countryName;

      // this.countPostalCodeBl('');
      this.compareValues('billing');
      this.changeDetectorRef.detectChanges();
    }
    if (formName == 'sepaForm') {
      this.failureMessage = false;
      let oemCountryObj = this.getOemCountry(country, '');
      this.charMinLengthSP = oemCountryObj.MinPostalLength;
      this.charMaxLengthSP = oemCountryObj.PostalLength;
      let indexofSelectedCountry = this.arrBillingAddressBook.findIndex(
        (item) => item.IsDefaultBilling == 1 && item.CountryName == country
      );
      //////
      if (indexofSelectedCountry > -1) {
        this.sepaFormFields.zip =
          this.arrBillingAddressBook[indexofSelectedCountry].Zip;
        this.countPostalCodeBl(this.sepaFormFields.zip, true);
      } else {
        this.sepaFormFields.zip = '';
        this.countPostalCodeBl('', true);
      }

      // this.user.billingDetail.zip = '';
      this.sepaFormFields.phoneExtension = oemCountryObj.ISD;
      this.sepaFormFields.selectedCountry = oemCountryObj.countryName;

      // this.countPostalCodeBl('');
      this.changeDetectorRef.detectChanges();
    }
    this.changeCoupon(false);
  }

  changeShippingType(value, shippingOptions, i) {
    if (value.type == 'MAIL-EXPRESS') {
      this.phoneRequired = true;
      this.isExpress = true;
      if (this.user && this.user.shippingDetail && this.user.shippingDetail.phoneNo && this.user.shippingDetail.phoneNo !=undefined && this.user.shippingDetail.phoneNo !='') {
        this.phoneRequired = false;
      }
      else{
        this.phoneRequired = true;
      }
    }
    else{
      this.phoneRequired = false;
      this.isExpress = false;
      this.changeDetectorRef.detectChanges();
    }
    this.shippingType = false;
    value.selected = !value.selected;
    if (value.selected) {
      shippingOptions.map((item, index) => {
        if (i != index) {
          item.selected = false;
        }
      });
      this.shippingType = value.type;
    } else {
      this.shippingType = false;
    }
    this.changeCoupon();
    this.calculateShipping();
    this.calculateGrandTotal();
  }
  checkedCheckboxSameAsShip(isChecked, type) {
    this.oldBillingData = [];
    this.oldShippingData = [];
    if (type == 'billing') {
      this.user.shippingDetail.firstName = this.user.billingDetail.firstName;
      this.user.shippingDetail.gender = this.user.billingDetail.gender;
      this.user.shippingDetail.lastName = this.user.billingDetail.lastName;
      this.user.shippingDetail.company = this.user.billingDetail.company;
      this.user.shippingDetail.street = this.user.billingDetail.street;
      this.user.shippingDetail.houseNumber =
        this.user.billingDetail.houseNumber;
      this.user.shippingDetail.zip = this.user.billingDetail.zip;
      this.user.shippingDetail.city = this.user.billingDetail.city;
      this.user.shippingDetail.selectedCountry =
        this.user.billingDetail.selectedCountry;
      this.user.shippingDetail.email = this.user.billingDetail.email;
      this.user.shippingDetail.phoneNo = this.user.billingDetail.phoneNo;
      this.user.shippingDetail.phoneExtension =
        this.user.billingDetail.phoneExtension;
      this.charMinLength = this.charMinLengthBl;
      this.charMaxLength = this.charMaxLengthBl;
      // console.info('this.user.billingDetail',this.user.billingDetail)

      this.countryObj = this.getCountry(
        this.user.billingDetail.selectedCountry,
        ''
      );
      if (
        this.countryObj.shippingOptions &&
        this.countryObj.shippingOptions != ''
      ) {
        this.calculateShipping(this.countryObj.shippingOptions[0].type);
      } else {
        this.calculateShipping();
      }

      this.calculateGrandTotal();

      setTimeout(() => {
        this.billingButtonEnable = false;
        this.shippingButtonEnable = false;
      }, 2000);
    } else if (type == 'shipping') {
      this.user.billingDetail.firstName = this.user.shippingDetail.firstName;
      this.user.billingDetail.gender = this.user.shippingDetail.gender;
      this.user.billingDetail.lastName = this.user.shippingDetail.lastName;
      this.user.billingDetail.company = this.user.shippingDetail.company;
      this.user.billingDetail.street = this.user.shippingDetail.street;
      this.user.billingDetail.houseNumber =
        this.user.shippingDetail.houseNumber;
      this.user.billingDetail.zip = this.user.shippingDetail.zip;
      this.user.billingDetail.city = this.user.shippingDetail.city;
      this.user.billingDetail.selectedCountry =
        this.user.shippingDetail.selectedCountry;
      this.user.billingDetail.email = this.user.shippingDetail.email;
      this.user.billingDetail.phoneNo = this.user.shippingDetail.phoneNo;
      this.user.billingDetail.phoneExtension =
        this.user.shippingDetail.phoneExtension;
      this.charMinLengthBl = this.charMinLength;
      this.charMaxLengthBl = this.charMaxLength;
      setTimeout(() => {
        this.billingButtonEnable = false;
        this.shippingButtonEnable = false;
      }, 2000);
    }
    this.oldBillingData = {
      firstName: this.user.billingDetail.firstName,
      lastName: this.user.billingDetail.lastName,
      company: this.user.billingDetail.company,
      gender: this.user.billingDetail.gender,
      street: this.user.billingDetail.street,
      houseNumber: this.user.billingDetail.houseNumber,
      zip: this.user.billingDetail.zip,
      city: this.user.billingDetail.city,
      selectedCountry: this.user.billingDetail.selectedCountry,
      email: this.user.billingDetail.email,
      phoneNo: this.user.billingDetail.phoneNo,
      phoneExtension: this.user.billingDetail.phoneExtension,
    };
    this.oldShippingData = {
      firstName: this.user.shippingDetail.firstName,
      lastName: this.user.shippingDetail.lastName,
      company: this.user.shippingDetail.company,
      gender: this.user.shippingDetail.gender,
      street: this.user.shippingDetail.street,
      houseNumber: this.user.shippingDetail.houseNumber,
      zip: this.user.shippingDetail.zip,
      city: this.user.shippingDetail.city,
      selectedCountry: this.user.shippingDetail.selectedCountry,
      email: this.user.shippingDetail.email,
      phoneNo: this.user.shippingDetail.phoneNo,
      phoneExtension: this.user.shippingDetail.phoneExtension,
    };
    this.isSameAsShippingChecked = isChecked;
  }
  preventLeadingSpace(event: KeyboardEvent, value: string) {
    // Check if the key pressed is a space
    if (event.key === ' ') {
      // Allow space if the input already has content (not starting with space)
      if (value.length === 0 || value.startsWith(' ')) {
        event.preventDefault(); // Prevent space at start
      }
    }
    
    if (event.key === '"') {
      event.preventDefault();
    }
  }
  preventPasteLeadingSpace(event: ClipboardEvent) {
    const pasteData = event.clipboardData?.getData('text') || '';
    // Trim leading spaces
    const trimmedData = pasteData.trimStart();

    if (pasteData !== trimmedData) {
      event.preventDefault(); // Prevent the default paste

      // Insert the trimmed data manually at the cursor position
      const inputElement = event.target as HTMLInputElement;
      const start = inputElement.selectionStart || 0;
      const end = inputElement.selectionEnd || 0;
      const currentValue = inputElement.value;

      inputElement.value =
        currentValue.slice(0, start) + trimmedData + currentValue.slice(end);

      // Reset cursor position after paste
      const newCursorPosition = start + trimmedData.length;
      inputElement.setSelectionRange(newCursorPosition, newCursorPosition);
    }
    if (pasteData.includes('"')) {
      event.preventDefault();
    }
  }
  // showPhoneError(phone: any): boolean {
  //   return (
  //     this.phoneRequired || 
  //     (phone.errors && phone.invalid && (phone.dirty || phone.touched))
  //   );
  // }
  // onPhoneInput(phone: any): void {
  //   // Clear the error message as soon as the user starts typing
  //   console.log('phone.value',phone.value.length);
    
  //   if (phone.value) {
  //     this.phoneRequired = false;
  //   }
  //   else if(phone.value.length == 0){
  //     this.phoneRequired = true;
  //     this.showPhoneError(phone);
  //   }
  // }
  compareValues(type: string, data: any = {}) {
    const trimmedValue = data.value2?.trim() || '';
    if (this.isExpress) {
      this.phoneRequired = data.value2?.length === 0;
    }
  
    if (data.trimValueKey1 && data.trimValueKey2) {
      this.user[data.trimValueKey1][data.trimValueKey2] = trimmedValue;
    }
  
    const extractDetails = (detail: any) => ({
      firstName: detail.firstName,
      gender: detail.gender,
      lastName: detail.lastName,
      street: detail.street,
      houseNumber: detail.houseNumber,
      zip: detail.zip,
      city: detail.city,
      company: detail.company.trim(),
      phoneNo: detail.phoneNo,
      email: detail.email,
      phoneExtension: detail.phoneExtension,
    });
  
    const shippingData = extractDetails(this.user.shippingDetail);
    const billingData = extractDetails(this.user.billingDetail);
  
    const isEqual = (data1: any, data2: any) =>
      Object.keys(data1).every(key => data1[key] === data2[key]);
  
    if (type === 'shipping') {
      const shippingMatchesOld = isEqual(shippingData, this.oldShippingData);
      const shippingMatchesBilling = isEqual(shippingData, billingData);
  
      this.shippingButtonEnable = !(shippingMatchesOld || shippingMatchesBilling);
      this.billingButtonEnable = this.shippingButtonEnable;
    } else if (type === 'billing') {
      const billingMatchesOld = isEqual(billingData, this.oldBillingData);
      const billingMatchesShipping = isEqual(billingData, shippingData);
  
      this.billingButtonEnable = !(billingMatchesOld || billingMatchesShipping);
      this.shippingButtonEnable = this.billingButtonEnable;
    }
  
    if (data && Object.keys(data).length > 0) {
      this.addMessageIfChanged(data);
    }
  }
  
  addMessageIfChanged(currentTypeValue: any) {
    let textCharacter = this.languageData?.web?.character_limit_title
      ? this.languageData.web.character_limit_title.replace(
          '%d',
          currentTypeValue.maximumLength
        )
      : '';
    const inputLength: any = currentTypeValue.value2.length;
    if (inputLength == currentTypeValue.maximumLength) {
      this.errorMessageObj['currentValue'] = currentTypeValue.type;
      this.errorMessageObj.msg = textCharacter;
      this.lastTypeValue = currentTypeValue.type;
      setTimeout(() => {
        this.errorMessageObj.msg = '';
      }, 2000);
    } else if (currentTypeValue.type == this.lastTypeValue) {
      this.errorMessageObj.currentValue = currentTypeValue.type;
      // return;
    }
  }
  billingCheckbox() {
    let billingUnchecked = <HTMLInputElement>(
      document.getElementById('shippingSame')
    );
    if (billingUnchecked && billingUnchecked != null)
      billingUnchecked.checked = false;
  }

  checkEmailExist(email) {
    if (email == undefined || email.length == 0) {
      this.showNonverified = false;
      return;
    } else {
      let regEx =
        /[\w-]+(\.[\w-]+)*@[a-z0-9]+([.-][a-z0-9]+)*\.[a-z]{2,6}(:\d{4})?$/;
      let emailPattern = regEx.test(email);
      if (!emailPattern) {
        this.showNonverified = false;
        return;
      }
    }
    this.dataService.checkValidEmailOnly(email).subscribe(
      (res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          // this.error1 = true;
          if (this.isSameAsShippingChecked) {
            this.isEnabledBillingForm = true;
            this.isSameAsShippingChecked = false;
            this.sameShippingChecked = false;
          }
          if (
            this.userInfo.userType == 'Guest' ||
            this.userInfo.userType == 'Nonverified'
          ) {
            this.showNonverified = true;
          }
          // this.user.billingDetail.email = ''
        } else {
          this.showNonverified = false;
          this.showBuyNowButton = false;
          this.error1 = false;
        }
      },
      (err) => {
        if (
          this.userInfo.userType == 'Guest' ||
          this.userInfo.userType == 'Nonverified'
        ) {
          this.showNonverified = true;
        }
      },
      () => console.log('yay')
    );
  }

  countPostalCode(newVal) {
    if (this.charMaxLength == 0 && this.charMinLength == 0) {
      this.user.shippingDetail.zip = '';
      this.charLenIncorrect = false;
      this.isZipRequired = false;
      return;
    } else {
      if (newVal != undefined) {
        //  ////
        if (
          newVal.length == this.charMaxLength ||
          newVal.length == this.charMinLength
        ) {
          this.user.shippingDetail.zip = newVal;
          this.charLenIncorrect = false;
        } else {
          this.charLenIncorrect = true;
          this.isZipRequired = true;
        }
      } else {
        this.isZipRequired = true;
        this.charLenIncorrect = true;
      }
    }
  }

  countPostalCodeBl(newVal, fromSepa = false) {
    if (!fromSepa) {
      if (this.charMaxLengthBl == 0 && this.charMinLengthBl == 0) {
        this.user.billingDetail.zip = '';
        this.charLenIncorrectBl = false;
        this.isZipBlRequired = false;
        return;
      }
      if (newVal != undefined) {
        if (
          newVal.length == this.charMinLengthBl ||
          newVal.length == this.charMaxLengthBl
        ) {
          this.user.billingDetail.zip = newVal;
          this.charLenIncorrectBl = false;
          this.isZipBlRequired = false;
        } else {
          this.charLenIncorrectBl = true;
          this.isZipBlRequired = true;
        }
      } else {
        this.isZipBlRequired = true;
        this.charLenIncorrectBl = true;
      }
    } else {
      if (this.charMaxLengthSP == 0 && this.charMinLengthSP == 0) {
        this.sepaFormFields.zip = '';
        this.charLenIncorrectSP = false;
        this.isZipSPRequired = false;
        return;
      }
      if (newVal != undefined) {
        if (
          newVal.length == this.charMinLengthSP ||
          newVal.length == this.charMaxLengthSP
        ) {
          this.sepaFormFields.zip = newVal;
          this.charLenIncorrectSP = false;
          this.isZipSPRequired = false;
        } else {
          this.charLenIncorrectSP = true;
          this.isZipSPRequired = true;
        }
      } else {
        this.isZipSPRequired = true;
        this.charLenIncorrectSP = true;
      }
    }
    //
  }
  photoBookPrice() {
    var elmnt = document.getElementById('items-section');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  submitAddress() {
    this.paymentProcessingLoader = true;
    if (
      this.agbChecked == false ||
      this.agbChecked == undefined ||
      this.privacyPolicyChecked == false ||
      this.privacyPolicyChecked == undefined
    ) {
      //
      this.isNoCheckBoxSelected = true;
      this.isNoPrivacyBoxSelected = true;
      return;
    }
    let coupons = this.coupanForm.get('coupans') as FormArray;
    let coupondata = [];
    coupons.controls.map((item) => {
      //
      if (item.value && item.value.coupanDetails) {
        coupondata.push(item.value.coupanDetails);
      }
    });

    if (
      coupondata.length > 0 &&
      this.grandTotalOriginal == this.grandTotalAfterShipping &&
      this.isCouponApply == false
    ) {  
      this.applyCouponCodeModal();
      return;
    }
    setTimeout(() => {
      this.submitted = true;
    }, 2000);

    // stop here if form is invalid
    if (this.activeTab == 'shipping') {
      if (this.shippingForm && this.shippingForm.invalid) {
        this.shippingForm.submitted = true;
        if (this.charMinLength == 0 && this.charMaxLength == 0) {
          this.isZipRequired = false;
          this.user.shippingDetail.zip = '';
        } else {
          this.isZipRequired = true;
          if (!this.user.shippingDetail.zip) {
            this.charLenIncorrect = true;
          } else {
            this.charLenIncorrect = false;
          }
          this.changeDetectorRef.detectChanges();
        }

        if (this.isEnabledBillingForm) {
          if (this.billingForm.invalid || this.error1) {
            this.billingForm.submitted = true;
            if (this.charMinLengthBl == 0 && this.charMaxLengthBl == 0) {
              this.isZipBlRequired = false;
              this.user.billingDetail.zip = '';
            } else {
              this.isZipBlRequired = true;
              if (!this.user.billingDetail.zip) {
                this.charLenIncorrectBl = true;
              } else {
                this.charLenIncorrectBl = false;
              }
            }
            // if(this.error1){
            //     this.user.billingDetail.email = '';
            // }
          }
        }
        return;
      }
    }

    if (this.activeTab == 'pickup') {
      ////////
      if (Object.keys(this.user.pickupLocationAddress).length == 0) {
        if (this.isEnabledBillingForm) {
          if (this.billingForm.invalid || this.error1) {
            this.billingForm.submitted = true;
            if (this.charMinLengthBl == 0 && this.charMaxLengthBl == 0) {
              this.isZipBlRequired = false;
              this.user.billingDetail.zip = '';
            } else {
              this.isZipBlRequired = true;
              if (!this.user.billingDetail.zip) {
                this.charLenIncorrectBl = true;
              } else {
                this.charLenIncorrectBl = false;
              }
            }
          }
        }
        let title = this.languageData.web.mycart_pickup_confirm_popup_tit;
        let subTitle =
          this.languageData.web.mycart_pickup_confirm_popup_subtitle;
        let okBtn = this.languageData.web.mycart_pickup_confirm_popup_cta_yes;
        const modalRef = this.modalService.open(DynamicPopsModalComponent, {
          size: 'md',
          windowClass: 'modalWindowWidth',
          centered: true,
          backdrop: 'static',
          keyboard: true,
        });
        modalRef.componentInstance.modalData = {
          modalName: 'selectPickupAddress',
          title: title,
          subTitle: subTitle,
          okBtn: okBtn,
          cancelIcon: false,
        };
        return;
      }
    }

    if (this.isEnabledBillingForm) {
      if (this.billingForm.invalid || this.error1) {
        this.billingForm.submitted = true;
        if (this.charMinLengthBl == 0 && this.charMaxLengthBl == 0) {
          this.isZipBlRequired = false;
          this.user.billingDetail.zip = '';
        } else {
          this.isZipBlRequired = true;
          if (!this.user.billingDetail.zip) {
            this.charLenIncorrectBl = true;
          } else {
            this.charLenIncorrectBl = false;
          }
        }
        // if(this.error1){
        //     this.user.billingDetail.email = '';
        // }
        var elmnt = document.getElementById('address-section');
        elmnt.scrollIntoView({ behavior: 'smooth' });
        return;
      }
    }
    ////////

    // //
    // return false;
    if (
      (isNaN(this.grandTotalAfterShipping) ||
        this.grandTotalAfterShipping == 0) &&
      this.couponcode == null
    ) {
      let subTitle = this.languageData.web.checkout_cart_value_zero_msg;
      let title = this.languageData.web.checkout_cart_value_zero_tit;
      let okBtn = this.languageData.web.checkout_cart_value_zero_ok_btn;

      const modalRef = this.modalService.open(DynamicPopsModalComponent, {
        size: 'md',
        windowClass: 'modalWindowWidth',
        centered: true,
        backdrop: 'static',
        keyboard: true,
      });
      modalRef.componentInstance.modalData = {
        modalName: 'cartValueZero',
        title: title,
        subTitle: subTitle,
        okBtn: okBtn,
        cancelIcon: false,
      };
      return;
    }

    if (
      this.userProfileData != undefined &&
      this.userProfileData != '' &&
      (this.userProfileData.FirstName == undefined ||
        this.userProfileData.FirstName == '') &&
      this.userTypeGuest == false
    ) {
      let title = this.languageData.web.checkout_confirm_billing_address_title;
      let subTitle = this.languageData.web.checkout_confirm_billing_address_msg;
      let okBtn = this.languageData.web.checkout_confirm_address_default_cta_ok;
      let cancelBtn =
        this.languageData.web.checkout_confirm_address_default_cta_cancel;
      const modalRef = this.modalService.open(DynamicPopsModalComponent, {
        size: 'md',
        windowClass: 'modalWindowWidth',
        centered: true,
        backdrop: 'static',
        keyboard: true,
      });
      modalRef.componentInstance.modalData = {
        modalName: 'defaultBillingModal',
        title: title,
        subTitle: subTitle,
        okBtn: okBtn,
        cancelBtn: cancelBtn,
        cancelIcon: false,
      };
      modalRef.componentInstance.getModelDetail.subscribe((data) => {
        if (data) {
          this.saveAddress();
          // document.getElementById('payment-section').scrollIntoView({block: "end", inline: "nearest"});
        }
      });
      return;
    }

    if (this.disableCheckout == true) {
      //
      // $("#low-price").modal('show');
      return;
    }

    if (this.grandTotalAfterShipping > environment[global.env].maxCartValue) {
      this.maxCartValue = environment[global.env].maxCartValue;
      // $('#pricelimit').modal('show');
      return;
    }

    if (this.activeTab == 'shipping') {
      if (this.charMinLength == 0 && this.charMaxLength == 0) {
        this.isZipRequired = false;
        this.user.shippingDetail.zip = '';
      } else {
        this.isZipRequired = true;
        if (!this.user.shippingDetail.zip) {
          this.charLenIncorrect = true;
        } else {
          this.charLenIncorrect = false;
        }
      }
    }
    if (this.isEnabledBillingForm) {
      // this.billingForm.submitted = true;
      if (this.charMinLengthBl == 0 && this.charMaxLengthBl == 0) {
        this.isZipBlRequired = false;
        this.user.billingDetail.zip = '';
      } else {
        this.isZipBlRequired = true;
        if (!this.user.billingDetail.zip) {
          this.charLenIncorrectBl = true;
        } else {
          this.charLenIncorrectBl = false;
        }
      }
      if (this.error1) {
        // this.user.billingDetail.email = '';
        return;
      }
    }

    if (
      this.agbChecked == false ||
      this.agbChecked == undefined ||
      this.privacyPolicyChecked == false ||
      this.privacyPolicyChecked == undefined
    ) {
      //
      this.isNoCheckBoxSelected = true;
      this.isNoPrivacyBoxSelected = true;
      return;
    }
    if (!this.shippingType) {
      //
      return;
    }
    this.showOnProcess = true;
    this.saveAddress();
  }

  checkout() {
    // this.adyenBtnImagePath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + 'email-templates-images/' + environment[global.env].oemCode + '/email_templates_payment_methods.png'
this.paymentMethodsRespons = {};
    this.adyenBtnImagePath =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].RESOURCE_CONTAINER +
      environment[global.env].oemCode +
      '/web-assets' +
      '/cart_payment_icon_ad.png';

    this.stripeBtnImagePath =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].RESOURCE_CONTAINER +
      environment[global.env].oemCode +
      '/web-assets' +
      '/cart_payment_icon_st.png';
      this.getOemSettings();
    this.grandTotalOriginal;

    this.grandTotalOriginal = this.grandTotalAfterShipping;

    this._grandTotal = Number(this.grandTotal);
    this.isDataLoading = true;
    if (this.pickupAddress != undefined && this.pickupAddress != null) {
      this.pickupAddressLength = Object.keys(this.pickupAddress).length;
    }

    if (this.shippingAddress != undefined && this.shippingAddress != null) {
      this.shippingAddressLength = Object.keys(this.shippingAddress).length;
    }

    if (this.cartItemList.length == 0) {
      // $("#noProductInCart").modal("show");
      return false;
    }

    if (this.pickupAddressLength != 0) {
      this.isPickupLocAddress = true;
    }
    let coupons = this.coupanForm.get('coupans') as FormArray;
    let coupondata = [];
    coupons.controls.map((item) => {
      //
      if (item.value && item.value.coupanDetails) {
        coupondata.push(item.value.coupanDetails);
      }
    });

    if (
      coupondata.length > 0 &&
      this.grandTotalOriginal == this.grandTotalAfterShipping &&
      this.isCouponApply == false
    ) {
      this.applyCouponCodeModal();
      return;
    }

    // if (
    //   this.enteredCouponCode != '' &&
    //   this.grandTotalOriginal == this.grandTotalAfterShipping &&
    //   this.isCouponApply == false
    // ) {
    //   this.applyCouponCodeModal();
    //   return;
    // }

    this.googleTrackingCodeForCheckout();
    // var self = this;
    // console.info(this.paymentOptions);
   
    // console.info(this.paymentOptions);
  }
  saveAddress() {
    //

    this.isSavingCartData = true;

    if (this.activeTab == 'shipping') {
      let phoneExtension = this.user.shippingDetail.phoneExtension;
      let phoneNumber = this.user.shippingDetail.phoneNo;

      if (phoneExtension != undefined && phoneExtension.length > 0) {
        if (phoneNumber != undefined && phoneNumber.length > 0) {
          this.user.shippingDetail.phone = phoneExtension + '-' + phoneNumber;
        } else {
          this.user.shippingDetail.phone = '';
        }
      } else if (phoneNumber != undefined && phoneNumber.length > 0) {
        if (phoneExtension != undefined && phoneExtension.length > 0) {
          this.user.shippingDetail.phone = phoneExtension + '-' + phoneNumber;
        } else {
          // this.user.shippingDetail.phone = this.languageData['register_country_code']+'-'+this.shippingForm.phoneNo.$viewValue;
        }
      } else {
        this.user.shippingDetail.phone = '';
      }

      if (this.isEnabledBillingForm) {
        let phoneExtensionB = this.user.billingDetail.phoneExtension;
        let phoneNumberB = this.user.billingDetail.phoneNo;

        if (phoneExtensionB != undefined && phoneExtensionB.length > 0) {
          if (phoneNumberB != undefined && phoneNumberB.length > 0) {
            this.user.billingDetail.phone =
              phoneExtensionB + '-' + phoneNumberB;
          } else {
            this.user.billingDetail.phone = '';
          }
        } else if (phoneNumberB != undefined && phoneNumberB.length > 0) {
          if (phoneExtensionB != undefined && phoneExtensionB.length > 0) {
            this.user.billingDetail.phone =
              phoneExtensionB + '-' + phoneNumberB;
          } else {
            // this.user.billingDetail.phone = this.languageData['register_country_code']+'-'+this.billingForm.phoneNo.$viewValue;
          }
        } else {
          this.user.billingDetail.phone = '';
        }
      }

      let shippingCountryObj = this.getCountry(
        this.user.shippingDetail.selectedCountry,
        ''
      );
      let billingCountryObj = this.getOemCountry(
        this.user.billingDetail.selectedCountry,
        ''
      );

      this.user.shippingDetail.selectedCountryCode = shippingCountryObj.code;
      this.user.billingDetail.selectedCountryCode =
        billingCountryObj.countryCode;
      // if (this.isSameAsShippingChecked) {
      //     this.user.billingDetail = this.getBillingAddress(this.user.shippingDetail);
      //     this.user.billingDetail.phone = this.user.shippingDetail.phone;
      // }

      if (this.isEnabledBillingForm || this.isShowCheckbox) {
        this.setBillingAddress();
      }

      if (this.user.billingDetail.company == undefined) {
        this.user.billingDetail.company = '';
      }
      if (this.addressBookData.length == 0) {
        this.user.shippingDetail.IsDefaultBilling = 1;
        this.user.shippingDetail.IsDefaultShipping = 1;
        this.user.billingDetail.IsDefaultBilling = 1;
        this.user.billingDetail.IsDefaultShipping = 1;
        this.user.billingDetail.ID = this.user.shippingDetail.ID;
        if (this.user.shippingDetail.company == undefined) {
          this.user.shippingDetail.company = '';
        }

        this.dataService
          .setShippingAndBillingAddress(
            this.user.shippingDetail,
            this.user.billingDetail
          )
          .subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
              this.shippingAddress = this.user.shippingDetail;
              this.billingAddress = this.user.billingDetail;
              this.pickupAddress = {};
              this.checkout();
            }
          });
      } else if (this.user.shippingDetail.ID) {
        this.user.billingDetail.ID = this.user.shippingDetail.ID;
        this.user.billingDetail.IsDefaultBilling =
          this.user.shippingDetail.IsDefaultBilling;
        this.user.billingDetail.IsDefaultShipping =
          this.user.shippingDetail.IsDefaultShipping;
        this.dataService
          .setShippingAndBillingAddress(
            this.user.shippingDetail,
            this.user.billingDetail
          )
          .subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
              this.shippingAddress = this.user.shippingDetail;

              this.billingAddress = this.user.billingDetail;
              this.pickupAddress = {};
              this.checkout();
            }
          });
      } else {
        this.user.billingDetail.ID = this.user.shippingDetail.ID;
        this.user.shippingDetail.IsDefaultBilling = 1;
        this.user.shippingDetail.IsDefaultShipping = 1;
        this.user.billingDetail.IsDefaultBilling = 1;
        this.user.billingDetail.IsDefaultShipping = 1;
        this.dataService
          .setShippingAndBillingAddress(
            this.user.shippingDetail,
            this.user.billingDetail
          )
          .subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
              this.shippingAddress = this.user.shippingDetail;
              this.billingAddress = this.user.billingDetail;
              this.pickupAddress = {};
              this.checkout();
            }
          });
      }
    } else {
      if (this.isEnabledBillingForm) {
        let phoneExtensionB = this.user.billingDetail.phoneExtension;
        let phoneNumberB = this.user.billingDetail.phoneNo;

        if (phoneExtensionB != undefined && phoneExtensionB.length > 0) {
          if (phoneNumberB != undefined && phoneNumberB.length > 0) {
            this.user.billingDetail.phone =
              phoneExtensionB + '-' + phoneNumberB;
          } else {
            this.user.billingDetail.phone = '';
          }
        } else if (phoneNumberB != undefined && phoneNumberB.length > 0) {
          if (phoneExtensionB != undefined && phoneExtensionB.length > 0) {
            this.user.billingDetail.phone =
              phoneExtensionB + '-' + phoneNumberB;
          } else {
            // this.user.billingDetail.phone = this.languageData['register_country_code']+'-'+this.billingForm.phoneNo.$viewValue;
          }
        } else {
          this.user.billingDetail.phone = '';
        }

        let billingCountryObj = this.getOemCountry(
          this.user.billingDetail.selectedCountry,
          ''
        );
        this.user.billingDetail.selectedCountryCode =
          billingCountryObj.countryCode;
        this.setBillingAddress();
      }
      this.billingAddress = this.user.billingDetail;
      this.shippingAddress = {};
      this.pickupAddress = this.user.pickupLocationAddress;
      this.checkout();
    }
  }

  setBillingAddress() {
    // if(this.userTypeGuest){
    //     localStorage.setItem('address', JSON.stringify(this.user.billingDetail));
    //     this.billingAddress = this.user.billingDetail;
    // }

    this.user.billingDetail['newsletter'] = this.userInfo.Newsletter;
    this.dataService
      .updateProfileData(this.user.billingDetail)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          delete this.user.billingDetail['newsletter'];
          this.billingAddress = this.user.billingDetail;
        }
      });
  }

  getBillingAddress(addressObj) {
    ////
    let email = this.userTypeGuest == true ? addressObj.email : this.userEmail;
    let city = '';
    let zip = '';
    let phoneNo = '';
    let phone = '';
    let phoneExtension = '';
    let selectedCountry = '';
    let indexOfShippingCountry = this.arrOemCountries.findIndex(
      (item) => item.countryName == addressObj.selectedCountry
    );
    if (indexOfShippingCountry > -1) {
      this.oemCountryObj = this.arrOemCountries[indexOfShippingCountry];
      this.getZipPatternBl(this.oemCountryObj);
    }

    for (let i = 0; i < this.arrOemCountries.length; i++) {
      if (
        this.arrOemCountries[i].countryCode == addressObj.selectedCountryCode
      ) {
        city = addressObj.city;
        zip = addressObj.zip;
        phoneExtension = addressObj.phoneExtension;
        phoneNo = addressObj.phoneNo;
        phone = addressObj.phone;
        selectedCountry = addressObj.selectedCountry;
        ////
      }
    }
    if (city == '' && zip == '') {
      this.isEnabledBillingForm = true;
    }
    ////////
    if (
      addressObj.selectedCountry == 'Austria' ||
      addressObj.selectedCountry == 'Germany'
    ) {
      //Not Scrapped
      this.user.billingDetail = {
        firstName: addressObj.firstName,
        lastName: addressObj.lastName,
        gender: addressObj.gender,
        company: addressObj.company,
        street: addressObj.street,
        houseNumber: addressObj.houseNumber,
        city: addressObj.city,
        selectedCountry: addressObj.selectedCountry,
        zip: addressObj.zip,
        email: addressObj.email,
        phoneExtension: addressObj.phoneExtension,
        phoneNo: addressObj.phoneNo,
        phone: addressObj.phone,
        selectedCountryCode: addressObj.selectedCountryCode,
        ID: addressObj.ID,
        IsDefaultShipping: 0,
        IsDefaultBilling: 0,
      };
    } else {
      //Not Scrapped
      this.user.billingDetail = {
        firstName: addressObj.firstName,
        lastName: addressObj.lastName,
        gender: addressObj.gender,
        company: addressObj.company,
        street: addressObj.street,
        houseNumber: addressObj.houseNumber,
        city: addressObj.city,
        selectedCountry: addressObj.selectedCountry,
        zip: addressObj.zip,
        email: addressObj.email,
        phoneExtension: addressObj.phoneExtension,
        phoneNo: addressObj.phoneNo,
        phone: addressObj.phone,
        selectedCountryCode: addressObj.selectedCountryCode,
        ID: addressObj.ID,
        IsDefaultShipping: 0,
        IsDefaultBilling: 0,
      };
    }
    this.charLenIncorrectBl = false;
    this.isZipBlRequired = false;

    return this.user.billingDetail;
  }

  getOemSettings() {
    this.showBuyNowButton = false;
    this.addressChanged = false;
    this.dataService.getOemSettings().subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      this.oemsDetail = resJSON.data;
      var paymentOptions = this.oemsDetail.paymentOptions;

      this.paymentOptions = paymentOptions.split(',');

      if (this.grandTotalAfterShipping != 0) {
        setTimeout(() => {
          this.orderSubmit('notselected');
          //
        }, 1);
      }

      for (let i = 0; i < this.paymentOptions.length; i++) {
        if (this.paymentOptions[i] == 'PayPal') {
          this.dispalyPaypalBtn = true;

          setTimeout(() => {
            this.initConfig(
              resJSON.data.clientId,
              resJSON.data.paypalAction,
              resJSON.data.payPalIntentType
            );
          }, 1000);
        } else if (this.paymentOptions[i] == 'Adyen') {
          this.dispalyAdyenBtn = true;
        } else if (this.paymentOptions[i] == 'Stripe') {
          this.stripeService.setKey(resJSON.data.stripeClientKey);
          this.stripePaymentType = resJSON.data.stripePaymentType;
          this.elementsOptions['locale'] =
            environment[global.env].stripeLocaleCode[global.lang.toUpperCase()];

          //
          this.dispalyStripeBtn = true;
        } else if (this.paymentOptions[i] == 'Prepayment') {
          this.dispalyPrepaymentBtn = true;
        } else if (this.paymentOptions[i] == 'Paygate') {
          this.dispalySepaBtn = true;
        } else if (this.paymentOptions[i] == 'Invoice') {
          if (this.user.userOrderLength == 0) {
            let isObjectEqual = this.isEquivalent(
              this.shippingAddress,
              this.billingAddress
            );
            if (isObjectEqual) {
              if (
                this.userInfo.userType == 'Guest' ||
                this.userInfo.userType == 'Nonverified'
              ) {
                this.dispalyInvoiceBtn = false;
              }
              //  this.getUserCreditLimit();
            } else {
              this.dispalyInvoiceBtn = false;
            }
          } else {
            if (
              this.userInfo.userType == 'Guest' ||
              this.userInfo.userType == 'Nonverified'
            ) {
              this.dispalyInvoiceBtn = false;
            }
            this.getUserCreditLimit();
          }
        } else if (this.paymentOptions[i] == 'In-Shop') {
          this.dispalyInShopBtn = true;
        }
      }
      this.isSavingCartData = false;

      if (this.dispalyAdyenBtn) {
        this.dataService.getOriginKeyData().subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.originKey =
            resJSON.originKeys[Object.keys(resJSON.originKeys)[0]];
          var arrLocaleCode = environment[global.env].arrAdyenLocaleCode;
          var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
          if (
            this.isValidCoupon &&
            this.enteredCouponCode != undefined &&
            this.enteredCouponCode != '' &&
            this.grandTotalAfterShipping == 0
          ) {
            this.isPaymentChecked = false;
            this.showOnProcess = true;
            this.orderSubmit('NONE');
          } else {
            this.isPaymentChecked = true;
            this.showOnProcess = false;

            if (this.dispalyAdyenBtn) {
              this.dataService
                .getPaymentMethods(
                  currentLocale,
                  this.billingAddress.selectedCountryCode
                )
                .subscribe((res: Response) => {
                  let resSTR = JSON.stringify(res);
                  let resJSON = JSON.parse(resSTR);
                  // Rearranging to ensure Kreditkarte is first and PayPal is last
                  const kreditkarte = resJSON.paymentMethods.find(
                    (method) => method.type === 'scheme'
                  );
                  const klarna_paynow = resJSON.paymentMethods.find(
                    (method) => method.type === 'klarna_paynow'
                  );
                  const paypal = resJSON.paymentMethods.find(
                    (method) => method.type === 'paypal'
                  );
                  const sepadirectdebit = resJSON.paymentMethods.find(
                    (method) => method.type === 'sepadirectdebit'
                  );
                  const klarna = resJSON.paymentMethods.find(
                    (method) => method.type === 'klarna'
                  );
                  const klarna_account = resJSON.paymentMethods.find(
                    (method) => method.type === 'klarna_account'
                  );
                  const others = resJSON.paymentMethods.filter(
                    (method) =>
                      method.type !== 'scheme' &&
                      method.type !== 'klarna_paynow' &&
                      method.type !== 'paypal' &&
                      method.type !== 'sepadirectdebit' &&
                      method.type !== 'klarna' &&
                      method.type !== 'klarna_account'
                  );

                  let rearrangedPaymentMethods = [
                    kreditkarte,
                    klarna_paynow,
                    paypal,
                    sepadirectdebit,
                    klarna,
                    klarna_account,
                    ...others,
                    ,
                  ];

                  // Updating the original array
                  // console.log(rearrangedPaymentMethods);
                  rearrangedPaymentMethods = rearrangedPaymentMethods.filter(
                    (method) => method !== undefined
                  );
                  resJSON.paymentMethods = rearrangedPaymentMethods;
                  this.paymentMethodsRespons = resJSON;
                  this.paymentMethodsLength = resJSON.paymentMethods.length;
                  var self = this;           
                  var timer = setInterval(() => {
                    if (
                      self.paymentOptions != undefined &&
                      self.paymentMethodsRespons != undefined
                    ) {
                      if (
                        
                        self.paymentOptions.length == 1 &&
                        self.paymentOptions[0] == 'Adyen'
                      ) {
                        // setTimeout(() => {
                          self.adyenCheckout();
                        // }, 2000);
                       
                        clearInterval(timer);
                      }
                      if (self.paymentOptions.length > 1) {
                        clearInterval(timer);
                      }
                    }
              
                    // clearInterval(timer);
                  }, 20);
                });
            }

            // setTimeout(() => {
            //   document.getElementById('openModalButton').scrollIntoView({ block: "center" });
            // }, 1000);
          }
        });
      } else if (
        this.isValidCoupon &&
        this.enteredCouponCode != undefined &&
        this.enteredCouponCode != '' &&
        this.grandTotalAfterShipping == 0
      ) {
        this.isPaymentChecked = false;
        this.showOnProcess = true;
        this.orderSubmit('NONE');
      } else {
        this.showOnProcess = false;
        this.isPaymentChecked = true;
      }

      // this.dataService.getOriginKeyData().subscribe((res: Response) => {
      //   let resSTR = JSON.stringify(res);
      //   let resJSON = JSON.parse(resSTR);
      //   this.originKey = resJSON.originKeys[Object.keys(resJSON.originKeys)[0]];
      //   var arrLocaleCode = environment[global.env].arrAdyenLocaleCode;
      //   var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
      //   if (this.isValidCoupon && this.enteredCouponCode != undefined && this.enteredCouponCode != '' && this.grandTotalAfterShipping == 0) {
      //     this.isPaymentChecked = false;
      //     this.showOnProcess = true;
      //     this.orderSubmit('NONE');
      //   } else {
      //     this.isPaymentChecked = true;
      //     this.showOnProcess = false
      //     this.dataService.getPaymentMethods(currentLocale, this.billingAddress.selectedCountryCode).subscribe((res: Response) => {
      //       let resSTR = JSON.stringify(res);
      //       let resJSON = JSON.parse(resSTR);
      //       this.paymentMethodsRespons = resJSON;
      //       this.paymentMethodsLength = resJSON.paymentMethods.length;
      //     });
      //     setTimeout(() => {
      //       document.getElementById('openModalButton').scrollIntoView({block: "end"});
      //     },1000);

      //   }

      // });
    });
  }

  editPhoneNumber(value) {
    if (this.phoneNo.control.status == 'INVALID') {
      this.showErrorMsg = true;
      return;
    } else {
      this.showErrorMsg = false;
      this.isShowPhoneInput = false;
      this.user.billingDetail.phoneNo = value;

      let phoneExtensionB = this.user.billingDetail.phoneExtension;
      let phoneNumberB = this.user.billingDetail.phoneNo;

      if (phoneExtensionB != undefined && phoneExtensionB.length > 0) {
        if (phoneNumberB != undefined && phoneNumberB.length > 0) {
          this.user.billingDetail.phone = phoneExtensionB + '-' + phoneNumberB;
        } else {
          this.user.billingDetail.phone = '';
        }
      } else if (phoneNumberB != undefined && phoneNumberB.length > 0) {
        if (phoneExtensionB != undefined && phoneExtensionB.length > 0) {
          this.user.billingDetail.phone = phoneExtensionB + '-' + phoneNumberB;
        } else {
          // this.user.billingDetail.phone = this.languageData['register_country_code']+'-'+this.billingForm.phoneNo.$viewValue;
        }
      } else {
        this.user.billingDetail.phone = '';
      }
      this.dataService
        .updateProfileData(this.user.billingDetail)
        .subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          if (resJSON.success == true) {
            this.billingAddress = this.user.billingDetail;
          }
        });
    }
  }

  isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      if (propName != 'company') {
        if (a[propName] !== b[propName]) {
          return false;
        }
      }
    }
    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  saveProjectName(projectData, projectName) {
    if (projectName == '' || projectName == undefined) {
      this.isProjectNameInValid = true;
      return;
    }
    this.isProjectNameInValid = false;
    this.dataService
      .projectReName(projectData.albumCode, projectName)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          projectData.projectName = projectName;
          projectData.isDisplayInputField = false;
        }
      });
  }

  getUserCreditLimit() {
    this.dataService.getCreditLimit().subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {
        this.creditLimitData = resJSON.data;
        if (this.creditLimitData != undefined) {
          if (
            Number(this.creditLimitData.availableCreditLimit) >=
            this.grandTotalAfterShipping
          ) {
            this.dispalyInvoiceBtn = true;
          } else {
            this.dispalyInvoiceBtn = false;
          }
        }
      }
    });
  }

  addDuplicateItem(cartItem) {
    var itmArr = Array();
    var itemData = {
      productCode: cartItem.productCode,
      albumCode: cartItem.albumCode,
      quantity: cartItem.quantity,
      projectPrice: 0,
      coverCode: '',
      coverPrice: 0,
      total: 0,
      paperCode: '',
      paperPrice: 0,
      caseCode: '',
      casePrice: 0,
      envelopesCode: '',
      envelopesPrice: 0,
      totalImages: cartItem.totalImages,
      totalPrints: cartItem.totalPrints,
    };

    itmArr.push(itemData);
    var data = {
      application: 'IPHONE_APP',
      data: {
        cartData: {
          items: itmArr,
        },
      },
    };

    this.dataService.addToCart(data).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {
        // window.location.reload();
      }
    });
  }

  setGtmCodeForOrders(orderNumber, paymentMethod) {
    if (
      localStorage.getItem('userInfo') != undefined &&
      localStorage.getItem('userInfo') != null &&
      localStorage.getItem('userInfo') != ''
    ) {
      this.dataService
        .getOrderTrackingDetail(orderNumber)
        .subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          if (resJSON.success == true) {
            let data = resJSON.data;
            let transProducts = [];
            if (
              data &&
              data.transactionProducts &&
              Array.isArray(data.transactionProducts)
            ) {
              for (let i = 0; i < data.transactionProducts.length; i++) {
                let arrProducts = data.transactionProducts[i];
                if (environment[global.env].FB_PIXEL) {
                  transProducts.push({
                    sku: arrProducts.sku,
                    name: arrProducts.name,
                    category: arrProducts.category,
                    price: arrProducts.price,
                    quantity: arrProducts.quantity,
                  });
                } else {
                  transProducts.push({
                    item_id: arrProducts.sku,
                    item_name: arrProducts.name,
                    affiliation: arrProducts.affiliation,
                    coupon: '',
                    currency: environment[global.env].currency,
                    discount: '',
                    index: '',
                    item_brand: environment[global.env].oemCode,
                    item_category: arrProducts.category,
                    item_category2: '',
                    item_category3: '',
                    item_category4: '',
                    item_category5: '',
                    item_list_id: '',
                    item_list_name: ' ',
                    item_variant: '',
                    location_id: '',
                    price: arrProducts.price,
                    quantity: arrProducts.quantity,
                  });
                }
              }
            }
            let products = JSON.stringify(transProducts);

            // FB Pixel Purchase Event

            if (environment[global.env].FB_PIXEL) {
              let timestamp: any = moment().valueOf() * 1000;
              var gaScript = document.createElement('script');
              gaScript.innerHTML =
                `
                  window.dataLayer = window.dataLayer || [];
                  dataLayer.push({'event': 'orderCompleted','transactionId': '` +
                data.transactionId +
                `',` +
                `'transactionTotal': ` +
                data.transactionTotal +
                `,` +
                `'transactionProducts': ` +
                products +
                `,
                'custom_event_id':` +
                timestamp +
                `
              });`;
              document.head.appendChild(gaScript);

              const gaFbScript = document.createElement('script');
              gaFbScript.innerHTML =
                `fbq('track', 'Purchase', {value: ` +
                data.transactionTotal +
                `,currency: '` +
                data.currency +
                `'})`;
              document.head.appendChild(gaFbScript);

              // End FB Pixel Purchase Event
            } else {
              let productVat = 0;
              let shippingVat = 0;
              this.cartVatData.items.map((item) => {
                if (item.type == 'productVat') {
                  productVat = productVat + item.priceValue;
                }
                if (item.type == 'shippingVat') {
                  shippingVat = shippingVat + item.priceValue;
                }
              });
              let productVAtPrice = productVat.toFixed(2); //this.priceService.getCommaOrDotValue(productVat.toFixed(2));
              let shippingVAtPrice = shippingVat.toFixed(2); //this.priceService.getCommaOrDotValue(shippingVat.toFixed(2));
              let userId: any = JSON.parse(localStorage.getItem('userInfo'))[
                'UUID'
              ];

              var gaScript = document.createElement('script');
              let coupons = '';
              if (this.couponcode != undefined && this.couponcode != '') {
                coupons = this.couponcode;
              }

              let timestamp: any = moment().valueOf() * 1000;
              gaScript.innerHTML =
                `
            dataLayer.push({ ecommerce: null }); 
            dataLayer.push({
                "event": "purchase",                        
                "ecommerce": {
                  "transaction_id": "` +
                data.transactionId +
                `",  
                  "affiliation": "",  
                  "value": "` +
                data.transactionTotal +
                `",  
                  "tax":"` +
                productVAtPrice +
                `",   
                  "shipping_tax": "` +
                shippingVAtPrice +
                `",  
                  "total_shipping_value": "` +
                this.totalShippinCost +
                `",                                     
                  "customer_id": "${userId}", 
                  "currency": "` +
                environment[global.env].currency +
                `",  
                  "coupon": "` +
                coupons +
                `",                            
                  "items":` +
                products +
                `
                },           
                'custom_event_id':` +
                timestamp +
                `
                                
             })`;
              //   userId: '`+ userId+`',
              document.head.appendChild(gaScript);
            }

            this.display = 'none';
            this.closeModal();
            if (paymentMethod == 'scheme') {
              this.router.navigate([this.thankyouPageUrl, orderNumber], {
                queryParams: { couponcode: this.couponcode },
              });
              // this.ngZone.run(() => {
              //     location.href =  global.urlSuffix + '/' + this.thankyouPageUrl + '/' + orderNumber;
              // })
            } else {
              this.router.navigate([this.thankyouPageUrl, orderNumber], {
                queryParams: { couponcode: this.couponcode },
              });
            }
          } else {
            this.router.navigate([this.thankyouPageUrl, orderNumber], {
              queryParams: { couponcode: this.couponcode },
            });
          }
          this.getData();
        });
    }
  }

  scrollToView(section) {
    this.selectedSection = section;
    if (section == 'items-section') {
      window.scroll(0, 0);
      this.isListActive = true;
    } else {
      this.isListActive = false;
      var elmnt = document.getElementById(section);
      if (elmnt) {
        elmnt.scrollIntoView({ behavior: 'smooth' });
      }
    }
   
   
  }

  googleTrackingCodeForCheckout() {
    // initiateCheckout
    var gaScript = document.createElement('script');
    let timestamp: any = moment().valueOf() * 1000;
    // End initiateCheckout

    // FB Pixel Event InitiateCheckout
    if (environment[global.env].FB_PIXEL) {
      gaScript.innerHTML =
        `
      window.dataLayer = window.dataLayer || [];
      dataLayer.push({'event': 'initiateCheckout',
      'custom_event_id':` +
        timestamp +
        `
    });`;
      document.head.appendChild(gaScript);

      const gaFbScript = document.createElement('script');
      gaFbScript.innerHTML = `fbq('track','InitiateCheckout');`;
      document.head.appendChild(gaFbScript);
    } else {
      let products = [];
      this.cartItemList.map((item) => {
        let productName =
          item.online_text == null || item.online_text == undefined
            ? ''
            : item.online_text;
        products.push({
          item_id: item.productCode,
          item_name: productName,
          affiliation: '',
          coupon: '',
          currency: environment[global.env].currency,
          discount: '',
          index: '',
          item_brand: environment[global.env].oemCode,
          item_category: item.ProductCat,
          item_category2: '',
          item_category3: '',
          item_category4: '',
          item_category5: '',
          item_list_id: '',
          item_list_name: '',
          item_variant: '',
          location_id: '',
          price: item.total,
          quantity: item.quantity,
        });
      });

      let timestamp: any = moment().valueOf() * 1000;
      gaScript.innerHTML =
        `dataLayer.push({ ecommerce: null }); 
    dataLayer.push({
      event: "begin_checkout",
      'custom_event_id':` +
        timestamp +
        `,
      ecommerce: {
        items: ` +
        JSON.stringify(products) +
        `
      }
    });`;

      document.head.appendChild(gaScript);
    }

    // End FB Pixel Event InitiateCheckout ererer
  }

  closeModal() {
    this.dropinElement.nativeElement.innerHTML = null;
    this.display = 'none';
    if (localStorage.getItem('Refused')) {
      localStorage.removeItem('Refused');
      // this.router.navigate([this.myOrderPageUrl]);
      this.router.navigate([this.cartPageUrl]);
      return;
    } else {
      this.modalService.dismissAll();
    }
    if (this.errorMsg) {
      let title = this.languageData.web.mycart_invalid_user_pop_up_tit;
      let subTitle = this.errorMsg;
      let okBtn = this.languageData.web.mycart_invalid_user_pop_up_cta_ok;
      const modalRef = this.modalService.open(DynamicPopupsModalComponent, {
        size: 'md',
        windowClass: 'modalWindowWidth',
        centered: true,
        backdrop: 'static',
        keyboard: true,
      });
      modalRef.componentInstance.data = {
        title: title,
        subTitle: subTitle,
        okBtnTxt: okBtn,
        modalName: 'invalidAccount',
        cancelIcon: false,
      };
      modalRef.componentInstance.invalidAccountData.subscribe((res) => {
        if (res) {
          this.modalService.dismissAll();
        }
      });
    }
  }

  deleteCartItemConfirmation(id, index) {
    let preview = this.cartItemList[index].preview;
    let subTitle;
    let cancelBtn;
    let okBtn;
    let title;
    if (
      preview == 'BIZCARD' ||
      preview == 'ACRYLICBOARD' ||
      preview == 'ALUDIBOND' ||
      preview == 'FOAMBOARD' ||
      preview == 'MUG' ||
      preview == 'BILET' ||
      preview == 'MASKEDPAGE' ||
      preview == 'CANVAS' ||
      preview == 'CARD-SINGLE' ||
      preview == 'MOUSEPAD' ||
      preview == 'PUZZLE'
    ) {
      title =
        this.languageData.web.mycart_del_inpage_project_confirm_popup_title;
      subTitle =
        this.languageData.web.mycart_del_inpage_project_confirm_popup_msg;
      cancelBtn =
        this.languageData.web.mycart_del_inpage_project_confirm_popup_cancel;
      okBtn = this.languageData.web.mycart_del_inpage_project_confirm_popup_ok;
    } else {
      title = this.languageData.web.mycart_del_confirm_popup_title;
      subTitle = this.languageData.web.mycart_del_confirm_popup_tit;
      okBtn = this.languageData.web.mycart_del_confirm_popup_cta_yes;
      cancelBtn = this.languageData.web.mycart_del_confirm_popup_cta_no;
    }
    this.deleteItemId = id;
    this.deleteItemIndex = index;
    const modalRef = this.modalService.open(DynamicPopsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.modalData = {
      modalName: 'askForRemoveItemModal',
      title: title,
      subTitle: subTitle,
      okBtn: okBtn,
      cancelBtn: cancelBtn,
      cancelIcon: false,
    };
    modalRef.componentInstance.removedItemData.subscribe((data) => {
      if (data) {
        this.deleteCartItem();
        this.router.navigate([this.router.url]);
      }
    });
    ////////
  }

  deleteCartItem() {
    // if(this.isLoadingData)
    // {
    //     return;
    // }
    // isLoadingData = true;
    this.dataService
      .deleteMycart(this.deleteItemId)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          this.changeCoupon();

          var gaScript = document.createElement('script');

          //FB Pixel Purchase Event
          if (environment[global.env].FB_PIXEL) {
          } else {
            let products = [];

            let itemToRemove = this.cartItemList[this.deleteItemIndex];

            let productName =
              itemToRemove.online_text == null ||
              itemToRemove.online_text == undefined
                ? ''
                : itemToRemove.online_text;
            // products.push({
            //   'category': itemToRemove.ProductCat,
            //   'id': itemToRemove.productCode,
            //   'name': productName,
            //   'quantity': itemToRemove.quantity,
            //   'priceGross': itemToRemove.total
            // })

            products.push({
              item_id: itemToRemove.productCode,
              item_name: productName,
              affiliation: '',
              coupon: '',
              currency: environment[global.env].currency,
              discount: '',
              index: '',
              item_brand: environment[global.env].oemCode,
              item_category: itemToRemove.ProductCat,
              item_category2: '',
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_list_id: '',
              item_list_name: '',
              item_variant: '',
              location_id: '',
              price: itemToRemove.total,
              quantity: 1,
            });

            let timestamp: any = moment().valueOf() * 1000;
            gaScript.innerHTML =
              `
            dataLayer.push({
              ecommerce: {'items':` +
              JSON.stringify(products) +
              `,              
              },
              event: "remove_from_cart",
              'custom_event_id':` +
              timestamp +
              `
             })`;
          }

          document.head.appendChild(gaScript);

          this.cartItemList.splice(this.deleteItemIndex, 1);
          this.arrProductData.splice(this.deleteItemIndex, 1);
          this.arrProductInfo.splice(this.deleteItemIndex, 1);

          this.globalService.setCartLengthData(this.cartItemList.length);
          if (this.cartItemList.length == 0) {
            this.isCartDataLoading = true;

            this.getDataFromContentFul(this.pageSlug, true);
            // if (this.contentFullData.length != 0) {
            //   this.getDataFromContentFul(this.pageSlug)
            //   this.dynamicComponentDiv.clear()
            //   this.changeDetectorRef.detectChanges();
            //   const factory = this.resolver.resolveComponentFactory(CategoryhomeComponent);
            //   this.dynamicComponentRef = this.dynamicComponentDiv.createComponent(factory);
            // }
            this.isBadgeImage = false;
          } else {
            for (var i = 0; i < this.cartItemList.length; i++) {
              this.calculatePrice(this.cartItemList[i], i);
              // if(this.cartItemList[i].badgeName == "badge-no-christmas.jpeg" || this.cartItemList[i].badgeName == "badge-no-christmas.png") {
              //   this.isBadgeImage = true;
              // } productAvialableForDelivery
            }
            // let isJpgBadge = this.cartItemList.findIndex(
            //   (item) => item.badgeName === 'badge-no-christmas.jpeg'
            // );
            // let isPngBadge = this.cartItemList.findIndex(
            //   (item) => item.badgeName === 'badge-no-christmas.png'
            // );
            // if (isJpgBadge >= 0 || isPngBadge >= 0) {
            //   this.isBadgeImage = true;
            // } else {
            //   this.isBadgeImage = false;
            // }

            this.cartItemList.map((item) => {
              if (!item.productAvialableForDelivery) {
                this.isBadgeImage = true;
              }
              // if(item.productStatus=='Notavailable' || item.productStatus=='Inactive'){
              //   this.buyNowDisabled=true;
              // }
            });

            this.getUniqueZoneCountries();
            setTimeout(() => {
              this.calculateShipping();
              this.calculateGrandTotal();
            }, 200);

            // this.isLoadingData = false;
            this.router.events.subscribe((event) => {
              if (event instanceof NavigationEnd) {
              }
            });
          }

          setTimeout(() => {
            if (
              this.cartItemList.findIndex(
                (item) => !item.productAvialableForDelivery
              ) > -1
            ) {
              this.isBadgeImage = true;
            } else {
              this.isBadgeImage = false;
            }
          }, 300);
        }
      });
  }

  openNewShippingAddressM(fromBilling = false) {
    let title, subTitle, okBtn, cancelBtn;
    if (fromBilling) {
      title =
        this.languageData.web.checkout_confirm_billing_newaddress_popup_title;
      subTitle =
        this.languageData.web.checkout_confirm_billing_newaddress_popup_msg;
      okBtn =
        this.languageData.web
          .checkout_confirm_billing_address_default_popup_cta_yes;
      cancelBtn =
        this.languageData.web
          .checkout_confirm_billing_address_default_popup_cta_no;
    } else {
      title = this.languageData.web.checkout_confirm_shipping_newaddress_til;
      subTitle = this.languageData.web.checkout_confirm_shipping_newaddress_msg;
      okBtn = this.languageData.web.checkout_confirm_address_default_cta_yes;
      cancelBtn = this.languageData.web.checkout_confirm_address_default_cta_no;
    }

    const modalRef = this.modalService.open(DynamicPopsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.modalData = {
      modalName: 'addNewShippingAddress',
      title: title,
      subTitle: subTitle,
      okBtn: okBtn,
      cancelBtn: cancelBtn,
      cancelIcon: false,
    };
    modalRef.componentInstance.shippingAddres.subscribe((data) => {
      if (data) {
        this.addNewShippingAddress(fromBilling);
      }
    });
  }

  addNewShippingAddress(fromBilling = false) {
    if (!fromBilling) {
      this.user.shippingDetail.firstName = '';
      this.user.shippingDetail.lastName = '';
      this.user.shippingDetail.company = '';
      this.user.shippingDetail.street = '';
      this.user.shippingDetail.houseNumber = '';
      this.user.shippingDetail.city = '';
      this.user.shippingDetail.phoneNo = '';
      this.user.shippingDetail.email = '';
      this.showdefaultShippingBtn = true;
    } else {
      this.user.billingDetail.firstName = '';
      this.user.billingDetail.lastName = '';
      this.user.billingDetail.company = '';
      this.user.billingDetail.street = '';
      this.user.billingDetail.houseNumber = '';
      this.user.billingDetail.city = '';
      this.user.billingDetail.phoneNo = '';
      // this.user.billingDetail.email = '';
      this.showdefaultShippingBtn = true;
    }
  }

  makeAdyenPayment(payloadValue, md, pares) {
    let orderNumber = localStorage.getItem('orderNo');
    console.info(payloadValue, md, pares, '', this.redirectResult);

    this.dataService
      .makeDetailsCall(payloadValue, md, pares, '', this.redirectResult)
      .subscribe(
        (res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          localStorage.removeItem('orderNo');
          if (resJSON.resultCode == 'Authorised') {
            if (md == '' || pares == '') {
              this.isDisplayloaderMsg = false;
              this.setGtmCodeForOrders(orderNumber, '');
              // this.router.navigate([this.thankyouPageUrl, orderNumber])
            } else {
              this.updateOrderStatus('Adyen', orderNumber);
            }
          } else if (resJSON.resultCode == 'Cancelled') {
            // this.router.navigate([this.myOrderPageUrl]);
            //this.router.navigate([this.cartPageUrl]);
            // this.router.navigateByUrl(this.cartPageUrl);
            this.router.navigate([this.cartPageUrl]).then(() => {
              window.location.reload();
            });
          } else if (resJSON.resultCode == 'Refused') {
            let errorIndex = this.definedPaymentErrors.indexOf(
              resJSON.refusalReasonCode
            );
            this.isDefinedError = errorIndex >= 0 ? true : false;
            if (!this.isDefinedError) {
              const data = {
                cartPage: this.cartPageUrl,
                refusalReasonCode: resJSON.refusalReasonCode,
              };
              this.contentfulService.opensuccessModal('paymentrefused', data);
            }
          }
        },
        (err) => {
          window.location.reload();
          // check error status code is 500, if so, do some action
        }
      );
  }

  selectPickupAddress(index) {
    this.activePickUplistTab = index;
    this.user.pickupLocationAddress = this.arrAllPickupLocations[index];
    let selectedAddress = this.arrAllPickupLocations[index];
    localStorage.setItem(
      'pickupAddress',
      JSON.stringify(this.user.pickupLocationAddress)
    );
    var usedPickupAddress;

    if (this.arrUsedPickupLocations.length == 0) {
      usedPickupAddress = selectedAddress;
    } else if (this.arrUsedPickupLocations.length == 1) {
      if (this.arrUsedPickupLocations[0].id != selectedAddress.id) {
        usedPickupAddress = selectedAddress;
      }
    } else {
      for (let i = 0; i < this.arrUsedPickupLocations.length; i++) {
        if (this.arrUsedPickupLocations[i].id == selectedAddress.id) {
          this.arrUsedPickupLocations.splice(i, 1);
          usedPickupAddress = selectedAddress;
        }
      }
    }

    if (usedPickupAddress == undefined) {
      usedPickupAddress = selectedAddress;
    }
    this.arrUsedPickupLocations.unshift(usedPickupAddress);

    this.activeUsedTab = 0;
    this.formCharacterValidation();
    this.calculateShipping();
    this.calculateGrandTotal();
  }

  selectActiveAddress(value) {
    //
    this.activeTab = value;

    this.isShowCheckbox = false;
    this.changeCoupon(false);
    if (value == 'shipping') {
      this.user.pickupLocationAddress = {};
      this.savedAddress = JSON.parse(localStorage.getItem('pickupAddress'));
      localStorage.removeItem('pickupAddress');
      this.isShowDiscountMsg = false;
      this.shippingDiscAmount = undefined;
      this.activeUsedTab = undefined;
      if (this.user.shippingDetail.street == '') {
        this.isShowCheckbox = true;
      }

      if (this.isEnabledBillingForm) {
        if (this.billingForm.invalid || this.error1) {
          this.billingForm.submitted = true;
          if (this.charMinLengthBl == 0 && this.charMaxLengthBl == 0) {
            this.isZipBlRequired = false;
            this.user.billingDetail.zip = '';
          } else {
            this.isZipBlRequired = true;
            if (!this.user.billingDetail.zip) {
              this.charLenIncorrectBl = true;
            } else {
              this.charLenIncorrectBl = false;
            }
          }
          return;
        }
      }

      let billingCountryObj = this.getOemCountry(
        this.user.billingDetail.selectedCountry,
        ''
      );

      this.user.billingDetail.selectedCountryCode =
        billingCountryObj.countryCode;

      //
      //   this.shippingAddress);//return false

      this.dataService
        .setShippingAndBillingAddress('', this.user.billingDetail)
        .subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          if (resJSON.success == true) {
            this.shippingAddress = this.user.shippingDetail;
            this.billingAddress = this.user.billingDetail;
            //  this.addressChanged=false;
            // this.pickupAddress = {};
            this.checkout();
          }
        });
    } else {
      if (this.arrUsedPickupLocations.length == 0) {
        return;
      }
      if (this.savedAddress == undefined) {
        return;
      }
      // ////////
      this.user.pickupLocationAddress = this.savedAddress;
      let selectedAddress = this.savedAddress;
      localStorage.setItem(
        'pickupAddress',
        JSON.stringify(this.user.pickupLocationAddress)
      );
      this.arrUsedPickupLocations.unshift(
        this.getUsedPickupLocation(selectedAddress)
      );
      this.activeUsedTab = 0;
    }

    this.calculateShipping();
    this.calculateGrandTotal();
  }

  getUsedPickupLocation(selectedAddress) {
    var usedPickupAddress;
    if (this.arrUsedPickupLocations.length == 0) {
      usedPickupAddress = selectedAddress;
    } else if (this.arrUsedPickupLocations.length == 1) {
      if (this.arrUsedPickupLocations[0].id != selectedAddress.id) {
        usedPickupAddress = selectedAddress;
      }
    } else {
      for (let i = 0; i < this.arrUsedPickupLocations.length; i++) {
        if (this.arrUsedPickupLocations[i].id == selectedAddress.id) {
          this.arrUsedPickupLocations.splice(i, 1);
          usedPickupAddress = selectedAddress;
        }
      }
    }
    if (usedPickupAddress == undefined) {
      usedPickupAddress = selectedAddress;
    }
    return usedPickupAddress;
  }

  deleteAddress(index, fromBilling) {
    let selectedAddress;

    // // this.closeModal();
    // this.selectedModalData = data;
    let title, subTitle, cancelBtn, okBtn;
    if (fromBilling) {
      selectedAddress = this.arrBillingAddressBook[index];
      title = this.languageData.web.checkout_confirm_delete_billing_address_til;
      subTitle =
        this.languageData.web.checkout_confirm_delete_billing_address_msg;
      cancelBtn =
        this.languageData.web
          .checkout_confirm_delete_billing_address_default_cta_no;
      okBtn =
        this.languageData.web
          .checkout_confirm_delete_address_billing_default_cta_yes;
    } else {
      selectedAddress = this.arrAddressBook[index];
      title = this.languageData.web.checkout_confirm_delete_address_til;
      subTitle = this.languageData.web.checkout_confirm_delete_address_msg;
      cancelBtn =
        this.languageData.web.checkout_confirm_delete_address_default_cta_no;
      okBtn =
        this.languageData.web.checkout_confirm_delete_address_default_cta_yes;
    }

    const modalRef = this.modalService.open(DynamicPopsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.componentInstance.modalData = {
      title: title,
      cancelBtn: cancelBtn,
      subTitle: subTitle,
      okBtn: okBtn,
      modalName: 'deleteAddressFromAddressbook',
      cancelIcon: true,
    };
    modalRef.componentInstance.selectedAddress.subscribe((data) => {
      if (data) {
        this.deleteSelectedAddress(selectedAddress);
      }
    });
  }

  deleteSelectedAddress(selectedAddress) {
    this.dataService
      .deleteAddress(selectedAddress.ID)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          for (let i = 0; i < this.addressBookData.length; i++) {
            if (this.addressBookData[i].ID == selectedAddress.ID) {
              this.addressBookData.splice(i, 1);
              this.billingAddressData.splice(i, 1);
              this.addressBookAssignCopy();
              return;
            }
          }
        }
      });
  }
  selectAddress(index, fromBilling = false) {
    this.updateAddressInShippingForm(index, fromBilling);
  }

  updateAddressInShippingForm(index, fromBilling = false) {
    if (!fromBilling) {
      var currentAddress = this.arrAddressBook[index];
      this.showBuyNowButton = true;
      this.addressChanged = true;
      this.countryObj = this.getCountry(currentAddress.CountryName, '');
      var phone = currentAddress.Phone.split('-');
      //
      if (currentAddress.Phone == '') {
        phone = [this.countryObj.ISD, ''];
      }

      if (this.countryObj) {
        if (this.countryObj.shippingOptions) {
          this.shippingType = true;
        }
        this.selectedShippingCountry = {
          code: this.countryObj.code,
          country: this.countryObj.CountryName,
        };
        this.charMinLength = this.countryObj.minPostalLength;
        this.charMaxLength = this.countryObj.maxPostalLength;

        this.user.shippingDetail.zip = currentAddress.Zip;
        this.user.shippingDetail.selectedCountry = currentAddress.CountryName;

        this.user.shippingDetail.phoneNo = phone[1];
        this.user.shippingDetail.phoneExtension = phone[0];
      } else {
        this.charMinLength = 3;
        this.charMaxLength = 3;
        this.user.shippingDetail.zip = '';
        this.user.shippingDetail.phoneNo = '';
        this.user.shippingDetail.phoneExtension = '';
        this.user.shippingDetail.selectedCountry = '';
      }

      this.user.shippingDetail.firstName = currentAddress.FirstName;
      this.user.shippingDetail.lastName = currentAddress.LastName;
      this.user.shippingDetail.gender = currentAddress.gender;
      this.user.shippingDetail.company = currentAddress.Company;
      this.user.shippingDetail.street = currentAddress.Address1;
      this.user.shippingDetail.houseNumber = currentAddress.Address2;
      this.user.shippingDetail.city = currentAddress.City;

      this.user.shippingDetail.email = currentAddress.Email;

      this.shippingAddress = this.user.shippingDetail;
      this.countPostalCode(this.user.shippingDetail.zip);
    } else {
      var currentAddress = this.arrBillingAddressBook[index];
      // this.countryObj = this.getCountry(currentAddress.CountryName, '');
      var phone = currentAddress.Phone.split('-');
      if (currentAddress.Phone == '') {
        phone = [this.countryObj.ISD, ''];
      }
      this.oemCountryObj = this.getOemCountry(
        currentAddress.CountryName,
        currentAddress.Country
      );
      this.getZipPatternBl(this.oemCountryObj);
      this.user.billingDetail.firstName = currentAddress.FirstName;
      this.user.billingDetail.lastName = currentAddress.LastName;
      this.user.billingDetail.gender = currentAddress.gender;
      this.user.billingDetail.company = currentAddress.Company;
      this.user.billingDetail.street = currentAddress.Address1;
      this.user.billingDetail.houseNumber = currentAddress.Address2;
      this.user.billingDetail.city = currentAddress.City;
      this.user.billingDetail.zip = currentAddress.Zip;
      this.user.billingDetail.selectedCountry = currentAddress.CountryName;
      this.countPostalCodeBl(this.user.billingDetail.zip);
      this.user.billingDetail.phoneNo = phone[1];
      this.user.billingDetail.phoneExtension = phone[0];
      // this.user.billingDetail.email = currentAddress.Email;

      this.billingAddress = this.user.billingDetail;
      //
    }
    this.formCharacterValidation();
    this.calculateShipping();
    this.calculateGrandTotal();
  }

  filterItem(value) {
    if (!value) {
      this.assignCopy();
    }
    this.arrAllPickupLocations = Object.assign(
      [],
      this.pickupAddressData.allPickupLocations
    ).filter(
      (item) =>
        item.firstName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.lastName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.gender.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.street.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.street.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.street.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.houseNumber.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.street.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.zipcode.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.city.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.countryName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        item.emailText.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
  }

  addressBookAssignCopy(fromBilling = false) {
    //  ////////

    this.arrBillingAddressBook = Object.assign([], this.billingAddressData);
    this.arrAddressBook = Object.assign([], this.addressBookData);
    // if(!fromBilling){
    //   this.arrAddressBook = Object.assign([], this.addressBookData);

    // }
    // else{
    //   this.arrBillingAddressBook = Object.assign([], this.billingAddressData);
    // }
  }
  filterAddressBookItem(value, fromBilling = false) {
    if (!value) {
      this.addressBookAssignCopy(fromBilling);
    } // when nothing has typed

    if (!fromBilling) {
      this.arrAddressBook = Object.assign([], this.addressBookData).filter(
        (item) =>
          item.FirstName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.LastName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.gender.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Address1.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Address2.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.City.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.CountryName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Zip.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    } else {
      this.arrBillingAddressBook = Object.assign(
        [],
        this.billingAddressData
      ).filter(
        (item) =>
          item.FirstName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.LastName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.gender.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Address1.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Address2.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.City.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.CountryName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Zip.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    }
  }

  async getDataFromContentFul(pageSlug, cartItemEmpty = false) {
    try {
      const data = await this.contentfulService.getContentByFieldName(
        this.contentType,
        pageSlug
      );

      if (data) {
        if (data.length === 0) {
          let headerObject = {
            headerVersion: false,
            headerData: false,
          };
          let footerObject = {
            footerVersion: false,
            footerData: false,
          };
          this.contentfulService.emitHeaderData(headerObject);
          this.contentfulService.emitFooterData(footerObject);
          return;
        }

        this.contentFullData = data;

        if (this.cartItemList.length === 0) {
          if (
            cartItemEmpty ||
            (this.contentFullData.length !== 0 &&
              (localStorage.getItem('userInfo') == null ||
                localStorage.getItem('userInfo') == ''))
          ) {
            this.dynamicComponentDiv.clear();
            const factory = this.resolver.resolveComponentFactory(
              CategoryhomeComponent
            );
            this.dynamicComponentRef =
              this.dynamicComponentDiv.createComponent(factory);
            this.changeDetectorRef.detectChanges();
          }
        }

        if (data.length > 0 && data[0].fields['seoMetadata']) {
          let seoData = {};
          seoData = data[0].fields['seoMetadata'].fields;
          this.globalService.setSeoMetaData(seoData);
          this.contentfulService.emittranslatedUrl(
            data[0].fields['seoMetadata'].sys.id
          );
        }

        let headerObject = {
          headerVersion: false,
          headerData: false,
        };
        let footerObject = {
          footerVersion: false,
          footerData: false,
        };

        if (data[0].fields['topSection']) {
          let allComponentFilter = JSON.parse(
            JSON.stringify(data[0].fields['topSection'])
          );
          let headerFound = allComponentFilter.filter(
            (item) => item.fields && item.fields.selectDesign
          );

          if (headerFound.length > 0) {
            let headerData = headerFound[0];

            if (headerData.fields.widget) {
              for (let i = 0; i < headerData.fields.widget.length; i++) {
                if (headerData.fields.widget[i].fields == undefined) {
                  headerData.fields.widget.splice(i, 1);
                  i--;
                }
              }
            }

            if (headerData.fields.selectDesign) {
              headerObject = {
                headerVersion: headerData.fields.selectDesign.split('-')[2],
                headerData,
              };
              this.contentfulService.emitHeaderData(headerObject);
            }
          } else {
            this.contentfulService.emitHeaderData(headerObject);
          }
        } else {
          this.contentfulService.emitHeaderData(headerObject);
        }

        if (data[0].fields['pageFooter']) {
          let allComponentFilter = JSON.parse(
            JSON.stringify(data[0].fields['pageFooter'])
          );
          let footerFound = allComponentFilter.filter(
            (item) => item.fields && item.fields.selectDesign
          );

          if (footerFound.length > 0) {
            let footerData = footerFound[0];
            if (footerData.fields.selectDesign) {
              footerObject = {
                footerVersion: footerData.fields.selectDesign.split('-')[2],
                footerData,
              };
              this.contentfulService.emitFooterData(footerObject);
            }
          } else {
            this.contentfulService.emitFooterData(footerObject);
          }
        } else {
          this.contentfulService.emitFooterData(footerObject);
        }
      }
    } catch (error) {
      console.error('Error in getDataFromContentFul:', error);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    if (window.pageYOffset < 200) {
      $('body').scrollspy({ target: '#list-example', offset: 0 });
      this.isListActive = true;
    } else {
      this.isListActive = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
  }

  getData() {
    this.dataService
      .myOrders(1, 100, 'No')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);

          if (resJSON.success == true) {
            this.totalRecord = resJSON.totalRecord;
            this.totalOrderNo = resJSON.totalRecord;
            localStorage.setItem('totalOrderCount', this.totalOrderNo);
          }
        },
        (error) => {}
      );
  }

  showLogin() {
    let data = { type: 'OPEN_LOGIN' };
    this.globalService.setOpenRegister(data);
  }

  get coupans(): FormArray {
    return this.coupanForm.get('coupans') as FormArray;
  }

  buildContacts(coupans: { coupanDetails: string }[] = []) {
    return this.formBuilder.array(
      coupans.map((contact) => this.formBuilder.group(contact))
    );
  }

  addCoupanInputField(index) {
    this.coupans.push(this.formBuilder.group({ coupanDetails: '' }));
    this.coupans.controls[index]['showInputPlusSignImage'] = false;
    this.coupans.controls[index]['showInputMinusSignImage'] = true;
    this.coupans.controls[index + 1]['showInputMinusSignImage'] = true;
  }

  // removeCoupanInputField1(index: number): void {
  //   this.coupans.removeAt(index);
  // }

  removeCoupanInputField(index) {
    if (index == 0) {
      this.coupans.controls[index]['value'].coupanDetails = '';
      this.coupans.controls[index].patchValue({ coupanDetails: '' });
      this.coupans.controls[index]['showInputMinusSignImage'] = false;
      this.coupans.controls[index]['showInputPlusSignImage'] = false;
    } else {
      if (this.coupans.value.length) {
        (this.coupanForm.get('coupans') as FormArray).removeAt(index);
      } else {
        this.coupans.controls[index]['showInputMinusSignImage'] = false;
        this.coupans.controls[index]['showInputPlusSignImage'] = false;
      }

      this.coupans.controls[index - 1]['showInputMinusSignImage'] = true;
      if (
        this.coupans.value[this.coupans.value.length - 1]['coupanDetails'] == ''
      ) {
        this.coupans.controls[this.coupans.value.length - 1][
          'showInputPlusSignImage'
        ] = false;
      } else {
        this.coupans.controls[this.coupans.value.length - 1][
          'showInputPlusSignImage'
        ] = true;
      }
    }
    console.info(this.isCouponApply);

    if (this.coupans.controls.length == 1) {
      this.changeCoupon(true);
    }
  }

  changeStyle(command, value) {
    document.execCommand(command, false, value);
  }

  changeIndentation() {
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const span = document.createElement('span');
    span.style.marginLeft = '20px'; // Adjust the indentation as needed

    range.surroundContents(span);
  }
  preventNumbers(event: KeyboardEvent): void {
    const key = event.key;
    // Check if the key is a number (0-9)
    if (key >= '0' && key <= '9') {
      event.preventDefault();
    }
  }
  getFirstInactiveBadge() {
    return this.cartItemList.find(item => !item.productAvialableForDelivery && item.badgeNameInactive);
  }
}

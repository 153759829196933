<div class="cart-page-content w-90">
    <div class="container">
        <div class="profile-title" *ngIf="isDataLoading">
            <div class="back_cart_btn">
                <a class="btn btn-primary" (click)="goBackToCartPage()">{{'web.checkout_back_button' | translate}}</a>
            </div>
            <div class="text-start">
                <h3>{{'web.checkout_title' | translate}} </h3>
                <div>{{'web.checkout_sub_title' | translate}}</div>
            </div>
        </div>
        <div *ngIf="isDisplayloaderMsg">
            <video playsinline autoplay muted src="{{imageIconPath}}colourful_spinner.webm"></video>
            <div>{{'web.mycart_payment_redirection_loader_msg' | translate}}</div>
        </div>
        <div class="product-container" *ngIf="isDataLoading">
            <table class="w-100">
                <thead>
                    <tr>
                        <th class="text-start">{{'web.mycart_products' | translate}}</th>
                        <th class="text-end">{{'web.my_cart_header_single_price' | translate}}</th>
                        <th class="text-center">{{'web.mycart_quantity' | translate}}</th>
                        <th class="text-end">{{'web.mycart_order_line_total' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cartItem of arrCartLoad">
                        <td>
                            <div class="product-details">
                                <div class="product-img">
                                    <figure>
                                        <img [ngClass]="{'shadow': cartItem.preview == 'PHOTOBOOK' || cartItem.preview == 'DESKCALENDAR' ||  cartItem.preview == 'BILET'
                                        || cartItem.preview == 'WALLCALENDAR' || cartItem.preview == 'CARD-SINGLE'
                                        || cartItem.preview == 'BIZCARD' || cartItem.preview == 'YEARCALENDAR'}" [src]="cartItem.imageUrl" crossorigin="anonymous" alt="" title="">
                                    </figure>
                                </div>
                                <div class="product-detail">
                                    <h4>{{cartItem.online_text}}</h4>
                                    <ul class="product-detail-list">
                                        <li [hidden]="cartItem.projectName == '' || cartItem.ProductCat == 'PRINT'">
                                            <strong>{{'web.checkout_project_name' | translate}}</strong>
                                            <span class="project-name">{{cartItem.projectName}}</span>
                                        </li>
                                        <li *ngIf="cartItem.isPageCountVisible">
                                            <strong>{{'web.mycart_pages' | translate}}</strong>
                                            <span>{{cartItem.pageCount}}</span>
                                        </li>
                                        <li *ngIf="cartItem.cover.length>1">
                                            <strong>{{'web.mycart_type_cover' | translate}}</strong>
                                            <span>{{cartItem.selectedCover.coverName}}</span>
                                        </li>
                                        <li *ngIf="cartItem.paper.length>0">
                                            <strong>{{cartItem.materialTitle}}</strong>
                                            <span>{{cartItem.selectedPaper.paperName}}</span>
                                        </li>
                                        <li *ngIf="cartItem.cases.length>0">
                                            <strong>{{'web.mycart_option1' | translate}}</strong>
                                            <span>{{cartItem.case.caseType}}</span>
                                        </li>
                                        <li *ngIf="cartItem.envelopes.length>0">
                                            <strong>{{'web.checkout_options' | translate}}</strong>
                                            <span>{{cartItem.selectedEnvelope.envelopeName}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="mobile-view-prop">
                                    <div class="mobile-view-head">
                                        <p class="head">{{'web.my_cart_header_single_price' | translate}}</p>
                                        <p class="head text-center">{{'web.mycart_quantity' | translate}}</p>
                                        <p class="head text-end">{{'web.mycart_order_line_total' | translate}}</p>
                                    </div>
                                    <div class="mobile-view-body">
                                        <div class="moblie-unit-price">
                                            <div *ngIf="cartItem.productPrice && cartItem.isPackage != 'Yes'">
                                                <div>
                                                    <span [ngClass]="{'lineThrough': cartItem.listPricePromo != false}">{{ getCommaOrDotValue(cartItem.listPrice)}}</span>
                                                </div>
                                                <div class="text-danger" *ngIf="cartItem.listPricePromo">
                                                    {{ getCommaOrDotValue(cartItem.listPricePromo)}}</div>
                                            </div>
                                        </div>
                                        <div class="moblie-cart-item">
                                            <div *ngIf="!cartItem.isQuantityChangeDisabled">
                                                <input type="text" value="{{cartItem.quantity}}" disabled />
                                            </div>
                                            <div *ngIf="cartItem.isQuantityChangeDisabled">
                                                <input type="text" value="{{cartItem.totalPrints}}" disabled />
                                            </div>
                                        </div>
                                        <div class="moblie-total-price text-end">
                                            <div *ngIf="cartItem.isVolumeDiscount==true">
                                                <div>
                                                    <span [ngClass]="{'lineThrough': cartItem.isVolumeDiscount}" *ngIf="cartItem.ProductCat == 'PRINT'">{{ getCommaOrDotValue(cartItem.listPrice*cartItem.totalPrints)}}</span>
                                                    <span [ngClass]="{'lineThrough': cartItem.isVolumeDiscount}" *ngIf="cartItem.ProductCat != 'PRINT'">{{ getCommaOrDotValue(cartItem.listPrice*cartItem.quantity)}}</span>
                                                </div>
                                                <div class="text-danger">{{ getCommaOrDotValue(cartItem.defaultPrice)}}</div>
                                            </div>
                                            <div *ngIf="cartItem.isVolumeDiscount==false">
                                                <div>
                                                    <span [ngClass]="{'lineThrough': cartItem.promoPrice}">{{ getCommaOrDotValue(cartItem.defaultPrice)}}</span>
                                                </div>
                                                <div class="text-danger" *ngIf="cartItem.promoPrice">{{ getCommaOrDotValue(cartItem.promoPrice)}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="unit-price">
                            <div *ngIf="cartItem.productPrice && cartItem.isPackage != 'Yes'">
                                <div>
                                    <span [ngClass]="{'lineThrough': cartItem.listPricePromo != false}">{{ getCommaOrDotValue(cartItem.listPrice)}}</span>
                                </div>
                                <div class="text-danger" *ngIf="cartItem.listPricePromo">
                                    {{ getCommaOrDotValue(cartItem.listPricePromo)}}</div>
                            </div>
                            <div class="unit_price_wrap">
                            </div>
                        </td>
                        <td class="cart-item">
                            <div *ngIf="!cartItem.isQuantityChangeDisabled">
                                <input type="text" value="{{cartItem.quantity}}" disabled />
                            </div>
                            <div *ngIf="cartItem.isQuantityChangeDisabled">
                                <input type="text" value="{{cartItem.totalPrints}}" disabled />
                            </div>
                        </td>
                        <td class="total-price">
                            <div *ngIf="cartItem.isVolumeDiscount==true">
                                <div>
                                    <span [ngClass]="{'lineThrough': cartItem.isVolumeDiscount}" *ngIf="cartItem.ProductCat == 'PRINT'">{{ getCommaOrDotValue(cartItem.listPrice*cartItem.totalPrints)}}</span>
                                    <span [ngClass]="{'lineThrough': cartItem.isVolumeDiscount}" *ngIf="cartItem.ProductCat != 'PRINT'">{{ getCommaOrDotValue(cartItem.listPrice*cartItem.quantity)}}</span>
                                </div>
                                <div class="text-danger">{{ getCommaOrDotValue(cartItem.defaultPrice)}}</div>
                            </div>
                            <div *ngIf="cartItem.isVolumeDiscount==false">
                                <div>
                                    <span [ngClass]="{'lineThrough': cartItem.promoPrice}">{{ getCommaOrDotValue(cartItem.defaultPrice)}}</span>
                                </div>
                                <div class="text-danger" *ngIf="cartItem.promoPrice">{{ getCommaOrDotValue(cartItem.promoPrice)}}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="product-foot-note" *ngIf="isDataLoading">{{'web.mycart_product_image_msg' | translate}}</div>
        <div class="row g-0 mx-0">
            <div class="col-12 col-md-7 col-lg-8">
                <div class="shipping-details">
                    <div class="row g-0 mx-0">
                        <div class="col-12 col-sm-6" *ngIf="pickupAddressLength != 0">
                            <div class="billing-detail">
                                <div class="billing-detail-head">
                                    <h4>{{'web.checkout_address_pickup_title' | translate }}</h4>
                                </div>
                                <div class="billing-detail-top">
                                    <p>{{pickupAddress.firstName}} {{pickupAddress.lastName}}</p>
                                    <p>{{pickupAddress.company}}</p>
                                    <p>{{pickupAddress.street}}</p>
                                    <p>{{pickupAddress.houseNumber}}</p>
                                    <p>{{pickupAddress.zipcode}} {{pickupAddress.city}}</p>
                                    <p>{{pickupAddress.countryName}}</p>
                                    <p>{{pickupAddress.phone}}</p>
                                    <p>{{pickupAddress.email}}</p>
                                    <p>{{pickupAddress.description}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6" *ngIf="shippingAddressLength != 0">
                            <div class="billing-detail">
                                <div class="billing-detail-head">
                                    <h4>{{'web.checkout_address_shipping_title' | translate }}</h4>
                                </div>
                                <div class="billing-detail-top">
                                    <p>{{shippingAddress.firstName}} {{shippingAddress.lastName}}</p>
                                    <p>{{shippingAddress.company}}</p>
                                    <p>{{shippingAddress.street}}</p>
                                    <p>{{shippingAddress.houseNumber}}</p>
                                    <p>{{shippingAddress.zip}} {{shippingAddress.city}}</p>
                                    <p>{{shippingAddress.selectedCountry}}</p>
                                    <p>{{shippingAddress.phone}}</p>
                                    <p>{{shippingAddress.email}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="billing-detail">
                                <div class="billing-detail-head">
                                    <h4>{{'web.checkout_address_billing_title' | translate }}</h4>
                                </div>
                                <div class="billing-detail-top">
                                    <p>{{billingAddress.firstName}} {{billingAddress.lastName}}</p>
                                    <p>{{billingAddress.company}}</p>
                                    <p>{{billingAddress.street}}</p>
                                    <p>{{billingAddress.houseNumber}}</p>
                                    <p>{{billingAddress.zip}} {{billingAddress.city}}</p>
                                    <p>{{billingAddress.selectedCountry}}</p>
                                    <p>{{billingAddress.phone}}</p>
                                    <p>{{billingAddress.email}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-auto">
                        <a class="btn btn-primary" (click)="goBackToCartPage()">{{'web.checkout_back_button' | translate}}</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-4">
                <div class="checkout-detail">
                    <div class="checkout-detail-top">
                        <div class="head">
                            <h4>{{'web.checkout_order_summary'| translate}}</h4>
                        </div>
                        <ul>
                            <li>
                                <strong>{{'web.checkout_product_sub_total'| translate}}</strong> {{ getCommaOrDotValue(grandTotal) }}
                            </li>
                            <li>
                                <strong>{{'web.checkout_shipping_sub_total'| translate}}</strong> {{getCommaOrDotValue(totalShippinCost||0)}}
                            </li>
                            <li>
                                <strong>{{'web.checkout_total'| translate}}</strong> {{ getCommaOrDotValue(grandTotalOriginal) }}
                            </li>
                        </ul>
                        <!-- promo option section START -->
                        <div class="promo">
                            <input class="form-control" type="text" id="couponCode" [(ngModel)]="enteredCouponCode" [disabled]="isValidCoupon" placeholder="{{'web.checkout_promo_code'| translate}}">
                            <span *ngIf="couponValidation" class="loader">
                                <img src="{{imageIconPath}}small-loader.gif" alt="" title="">
                            </span>
                            <p class="text-danger" *ngIf="enteredCouponCode.length>0">{{errorMessage}}</p>
                            <button *ngIf="enteredCouponCode.length>0 && !isValidCoupon" (click)="applyCouponCode(enteredCouponCode)">{{'web.checkout_cta_apply'| translate}}</button>
                            <button *ngIf="isValidCoupon" (click)="changeCoupon()">{{'web.checkout_cta_remove'| translate}}</button>
                        </div>
                        <p class="text-success text-center">{{successMessage}}</p>

                        <!-- promo option section END -->
                        <ul>
                            <li *ngIf="isValidCoupon && couponCodePrice != null">
                                <strong> {{'web.checkout_sub_total_disc'| translate}}</strong> - {{ getCommaOrDotValue(totalProductDiscont)}}
                            </li>
                            <li *ngIf="isValidCoupon && totalShippingDiscont != null && totalShippingDiscont != '0'">
                                <strong> {{'web.order_shipping_discount'| translate}}</strong> - {{ getCommaOrDotValue(totalShippingDiscont)}}
                            </li>
                            <li *ngIf="isPickupShippingDisc && pickupShippingDiscAmount != 0 && pickupShippingDiscAmount != undefined">
                                <strong> {{'web.mycart_pickup_shipping_discount'| translate}}</strong> - {{ getCommaOrDotValue(pickupShippingDiscAmount)}}
                            </li>
                            <li *ngIf="isValidCoupon && totalHandlingDiscont != null && totalHandlingDiscont != '0'">
                                <strong> {{'web.order_handling_discount'| translate}}</strong> - {{ getCommaOrDotValue(totalHandlingDiscont)}}
                            </li>
                            <li>
                                <strong>{{'web.checkout_grand_total'| translate}}</strong> {{ getCommaOrDotValue(grandTotalAfterShipping) }}
                            </li>
                        </ul>

                        <!-- payment option section START -->
                        <div class="payment" *ngIf="isPaymentChecked && (grandTotalAfterShipping != 0)">
                            <div>
                                <h4>{{'web.checkout_payment_options'| translate}}</h4>
                            </div>
                            <div class="payment_other_option" disabled="isProcessing">
                                <div class="cc_white" (click)="adyenCheckout()" id="openModalButton" data-bs-toggle="modal" data-bs-target="#other_payment_popup" *ngIf="dispalyAdyenBtn">
                                    <span class="cc_white_button">
                                        <img src="assets/images/CC_white.png" alt="" title="">
                                        <small>{{'web.checkout_payment_others_options_btn'| translate}}</small>
                                    </span>
                                    <span class="spinner-border spinner-border-sm" role="status" *ngIf="isProcessing && currentPaymentType == 'Adyen'" aria-hidden="true"></span>
                                </div>
                                <div class="text-center mt-2 mb-2" (click)="adyenCheckout()" *ngIf="dispalyAdyenBtn">
                                    <img [src]="adyenBtnImagePath" alt="" title="">
                                </div>







                            </div>
                            <div *ngIf="dispalyPaypalBtn" disabled="isProcessing">
                                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                            </div>
                            <div disabled="isProcessing">
                                <div (click)="openInShopModal()" class="btn btn-primary shop_btn">
                                    <img src="assets/images/shop_white.png" alt="" title="">
                                    <small class="align-sub">{{'web.checkout_payment_inshop_btn'| translate}}</small>
                                </div>
                                <div class="btn btn-primary shop_btn invoice-btn" *ngIf="dispalyInvoiceBtn && !isPickupLocAddress" (click)="openInvoiceModal()">
                                    <img src="assets/images/invoice-btn.png" alt="" title="">
                                    <small class="align-sub">{{'web.checkout_payment_invoice_btn'| translate}}</small>
                                </div>
                            </div>
                        </div>
                        <!-- payment option section END -->

                    </div>

                    <div class="checkout-detail-bottom">
                        <div class="checkbox-wrap" *ngIf="!isPaymentChecked">
                            <div class="checkbox">
                                <input type="checkbox" id="checkout_agb" [(ngModel)]="agbChecked" (ngModelChange)="checkAgb(agbChecked)" />
                                <label for="checkout_agb" innerHtml="{{AGBTagHtml}}"></label>
                            </div>
                            <div class="text-danger" *ngIf="isNoCheckBoxSelected">
                                {{'web.checkout_checkbox_selection_warning'| translate}}</div>
                        </div>
                        <div class="checkout-note" *ngIf="!isPaymentChecked">{{'web.checkout_agb_desc'| translate}}</div>
                        <div class="checkout-button" *ngIf="!isPaymentChecked || (grandTotalAfterShipping == 0)">
                            <button type="submit" class="btn btn-primary w-100 d-block" (click)="getOrder()" [disabled]="isProcessing">{{'web.checkout_cta_order_now'| translate}}
                                <span class="spinner-border spinner-border-sm" *ngIf="isProcessing" role="status"
                                    aria-hidden="true"></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal payment_popup" [ngStyle]="{'display':display}" role="dialog" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <p *ngIf="refusalReasonMsg">{{refusalReasonMsg}}</p>
                <p [hidden]="refusalReasonCode != undefined || paymentMethodsLength > 0" class="text-success">{{'web.dropin_payment_option_tit' | translate}}</p>
                <p [hidden]="refusalReasonCode != '24'" class="text-danger">{{'web.refusal_reason_code_24' | translate}}</p>
                <p [hidden]="refusalReasonCode != '20'" class="text-danger">{{'web.refusal_reason_code_20' | translate}}</p>
                <p [hidden]="refusalReasonCode != '2'" class="text-danger">{{'web.refusal_reason_code_2' | translate}}</p>
                <p [hidden]="refusalReasonCode != '0'" class="text-danger">{{'web.refusal_reason_code_0' | translate}}</p>
                <p [hidden]="refusalReasonCode != '8'" class="text-danger">{{'web.refusal_reason_code_8' | translate}}</p>
                <p [hidden]="IsPaymentProcessing" class="text-gray">{{'web.checkout_loading_text' | translate}}</p>
                <a class="closeModal" (click)="closeModal()"> <img src="assets/images/close.png" alt="" title=""></a>
            </div>
            <div class="modal-body">
                <div id="dropin" #dropinModal>
                    <!-- Drop-in will be rendered here -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop" [ngStyle]="{'display':display}"></div>

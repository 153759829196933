<div class="container icw icw-1220">
    <div class="thankyou g-0"  *ngIf="data" id="{{data.fields.blockBgId}}" [ngStyle]="data.fields.blockBgObj">
        <div class="col-md-6 hero-image">
            <!-- <div *ngIf="data.fields.heroImage" [ngStyle]="{'background-image': 'url(' + data.fields.heroImage.fields.file.url + ')'}"> -->
            <div *ngIf="data.fields.heroImage">
                <img src="{{data.fields.heroImage.fields.file.url}}"  title="{{data.fields.heroImage.fields.title}}"
                alt="{{data.fields.heroImage.fields.description}}" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="right">
                <div class="title" [innerHtml]="data.fields.bannerLargeTopHeaderRichTxt"
                    *ngIf="data.fields.bannerLargeTopHeaderRichTxt">
                </div>
                <div class="orderno" *ngIf="data.fields.subHeaderTxt">{{data.fields.subHeaderTxt}}
                    <span class='ms-1' routerLink="/{{orderPageUrl}}">{{orderNo}}</span>
                </div>
                <div class="discription" [innerHtml]="data.fields.subHeaderRText"
                    *ngIf="data.fields.subHeaderRText">
                </div>
                <a *ngFor="let cta of data.fields.addHeroCtaButtons" class="btn btn-primary text-white" routerLink="/{{ctaLink}}"
                title="{{cta.fields.ctaTooltip}}">
                    <span>{{cta.fields.ctaText}}</span></a>
            </div>
        </div>
    </div>
</div>
<div #awinscriptArea></div>
<div id="sovendus-container-1"></div>
<div #sovendusscriptArea></div>
<!-- <div id="trustedShopsCheckout" class="css-0" *ngIf="triggerShopData && triggerShopData.trigger">
    <span id="tsCheckoutOrderNr" class="css-0">{{triggerShopData.orderNo}}</span>
    <span id="tsCheckoutBuyerEmail" class="css-0">{{triggerShopData.userdata.Email}}</span>
    <span id="tsCheckoutOrderAmount" class="css-0">{{triggerShopData.amount}}</span>
    <span id="tsCheckoutOrderCurrency" class="css-0">{{triggerShopData.currency}}</span>
    <span id="tsCheckoutOrderPaymentType" class="css-0">Other</span>
    </div> -->

<!-- <div class="container">
    <div class="home-loader" *ngIf="isLoading">
        <div class="spinner-border spinner-border-sm"></div>
    </div>
    <div infiniteScroll [infiniteScrollDistance]="100" [infiniteScrollThrottle]="10"
        [infiniteScrollUpDistance]="2.5" (scrolled)="loadMore()" [scrollWindow]="true"
        [alwaysCallback]="true">
        <ng-container #mainContainer></ng-container>
    </div>

</div> -->
<div class="icw-1220 m-auto">
    <div class="thankyou g-0"  *ngIf="data" id="{{data.fields.blockBgId}}" [ngStyle]="data.fields.blockBgObj">
        <div class="col-md-6 hero-image">
            <!-- <div *ngIf="data.fields.heroImage" [ngStyle]="{'background-image': 'url(' + data.fields.heroImage.fields.file.url + ')'}"> -->
            <div *ngIf="data.fields.heroImage" class="text-center">
                <img src="{{data.fields.heroImage.fields.file.url}}"  title="{{data.fields.heroImage.fields.title}}"
                alt="{{data.fields.heroImage.fields.description}}" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="right">
                <div class="title" [innerHtml]="data.fields.bannerLargeTopHeaderRichTxt"
                    *ngIf="data.fields.bannerLargeTopHeaderRichTxt">
                </div>
                <div class="orderno" *ngIf="data.fields.subHeaderTxt">{{data.fields.subHeaderTxt}}
                    <span class='ms-1' routerLink="/{{orderPageUrl}}">{{orderNo}}</span>
                </div>
                <div class="discription" [innerHtml]="data.fields.subHeaderRText"
                    *ngIf="data.fields.subHeaderRText">
                </div>
                <a *ngFor="let cta of data.fields.addHeroCtaButtons" routerLink="/{{contactusUrl}}"
                title="{{cta.fields.ctaTooltip}}">
                    {{cta.fields.ctaText}}</a>
            </div>
        </div>
    </div>
</div>




<div id="trustedShopsCheckout" class="css-0" style="display:none;">
    <span id="tsCheckoutOrderNr" class="css-0"
        *ngIf="triggerShopData.orderNo">{{triggerShopData.orderNo}}</span>
    <span id="tsCheckoutBuyerEmail" class="css-0"
        *ngIf="triggerShopData.userdata.Email">{{triggerShopData.userdata.Email}}</span>
    <span id="tsCheckoutOrderAmount" class="css-0"
        *ngIf="triggerShopData.amount">{{triggerShopData.amount}}</span>
    <span id="tsCheckoutOrderCurrency" class="css-0"
        *ngIf="triggerShopData.currency">{{triggerShopData.currency}}</span>
    <span id="tsCheckoutOrderPaymentType" class="css-0" *ngIf="triggerShopData.orderNo">Other</span>
</div>




<!-- <div #awinscriptArea></div> -->
<!-- <div id="sovendus-container-1"></div>
<div #sovendusscriptArea></div> -->

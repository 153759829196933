import { Component, OnInit, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as global from 'src/app/globals';
import { environment } from 'src/environments/environment';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';
import { Subscription, Subject, Observable } from 'rxjs';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import * as uuid from 'uuid';
import { DataService } from 'src/app/services/data.service';
import { PriceService } from 'src/app/services/price.service';
import { GlobalService } from 'src/app/services/global.service';
import { ExternalService } from 'src/app/services/external.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicPopsModalComponent } from '../dynamic-pops-modal/dynamic-pops-modal.component';
import { ComponentCanDeactivate } from 'src/app/guard/auth-guard.service';

declare const AdyenCheckout: any;
@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
    standalone: false
})
export class CheckoutComponent implements OnInit, ComponentCanDeactivate {
    imageIconPath: any;

    canDeactivate(): Observable<boolean> | boolean {
        return true
    }
    @ViewChild('dropinModal') private dropinElement: ElementRef;
    public payPalConfig?: IPayPalConfig;
    @ViewChild('scrollDiv') public scrollDiv: ElementRef;
    IS_PAYMENT_PROCESSING = false;
    IS_PAYMENT_GATEWAY_READY = false;
    public orderNoObj: Subject<any> = new Subject<any>();
    isPaymentChecked: Boolean;
    couponType = undefined;
    showSuccess = false;
    public arrCartLoad = [];
    data: any;
    grandTotalAfterShipping: any;
    couponCodePrice = null;
    successMessage = null;
    errorMessage = null;
    couponcode = null;
    totalShippinCost: any;
    totalHandlingCost: any;
    grandTotal: any;
    _grandTotal: any;
    couponValidation = false;
    arrItems = [];
    isValidCoupon = false;
    totalDiscount: any;
    grandTotalOriginal: any;
    arrCouponItems: any;
    enteredCouponCode = "";
    shippingAddress: any;
    billingAddress: any = {};
    pickupAddress: any;
    currentPaymentType = "";
    totalCouponDiscount: any;
    totalShippingDiscount: any;
    totalHandlingDiscount: any;
    paymentTypes = { type: 'None' };
    orderNumber: any;
    isNoCheckBoxSelected: any;
    showPaypalBtn = true;
    id: any;
    checkoutIntervalId = null;
    totalProductDiscont: any;
    totalShippingDiscont: any;
    totalHandlingDiscont: any;
    agbChecked: any;
    isDataLoading: Boolean = false;
    oemsDetail: any;
    paymentOptions: any;
    isProcessing: Boolean = false;
    languageData: any;
    isCouponApply: Boolean = false;
    languageDataSubscription: Subscription;
    paymentMethodsRespons;
    originKey;
    paymentPaid: any;
    isDisplayloaderMsg: Boolean = false;
    redirectUrl: any;
    paymentType: any = null;
    dicUrls: any;
    checkoutPageUrl: any;
    thankyouPageUrl: any;
    refusalReasonCode: any;
    dispalyPaypalBtn: Boolean = false;
    dispalyAdyenBtn: Boolean = false;
    dispalyInvoiceBtn: Boolean = false;
    paymentMethodsLength: any;
    cartPageUrl: any;
    uniqueId: any;
    userInfo: any;
    pickupShippingDiscAmount: any;
    pickupAddressLength = 0;
    shippingAddressLength = 0;
    isPickupShippingDisc: Boolean = false;
    myOrderPageUrl: any;
    adyenBtnImagePath: any;
    termsPageUrl: any;
    AGBTagHtml: any;
    refusalReasonMsg: any;
    dispalyInShopBtn: Boolean = true;
    isPickupLocAddress: Boolean = false;
    userOrderLength: any;
    creditLimitData: any;
    IsPaymentProcessing: Boolean = true;
    display = 'none';

    public orderNumberSubscription: Subscription;
    constructor(@Inject(DOCUMENT) document,
        private location: PlatformLocation,
        private ngZone: NgZone,
        private route: ActivatedRoute,
        private router: Router,
        private dataService: DataService,
        private priceService: PriceService,
        private globalService: GlobalService,
        private modalService: NgbModal,
        private readonly svc: ExternalService
    ) { }

    ngOnInit() {
        this.svc.lazyAdyenQuill().subscribe(_ => {
            this.setupQuill();
        });
        this.imageIconPath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + environment[global.env].oemCode + "/icons/";
    }

    setupQuill() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.adyenBtnImagePath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + 'email-templates-images/' + environment[global.env].oemCode + '/email_templates_payment_methods.png'
        if (this.globalService.gettLanguageData() != undefined) {
            this.languageData = this.globalService.gettLanguageData();
        }
        else {
            this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
                this.languageData = this.globalService.gettLanguageData();
            });
        }

        this.location.onPopState(() => {
            localStorage.removeItem('cartData');
            this.router.navigate([this.cartPageUrl]);
            this.display = 'none'
            return;
        });

        this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
        this.checkoutPageUrl = this.dicUrls['CHECKOUT_PREVIEW'];
        this.thankyouPageUrl = this.dicUrls['THANKYOU_PREVIEW'];
        this.cartPageUrl = this.dicUrls['CART_PREVIEW'];
        this.myOrderPageUrl = this.dicUrls['ORDERS_PREVIEW'];
        this.termsPageUrl = this.dicUrls['TERMOFUSE_PREVIEW'];

        if (localStorage.getItem('Refused')) {
            localStorage.removeItem('Refused');
            this.router.navigate([this.cartPageUrl]);
            return;
        }

        let url: any = document.location;
        let params = (new URL(url)).searchParams;

        this.isDisplayloaderMsg = false;

        if (params.get("PaRes")) {
            this.isDisplayloaderMsg = true;
            let paRes = decodeURIComponent(params.get("PaRes"));
            let md = localStorage.getItem('MD');
            let paymentData = localStorage.getItem('paymentData');
            localStorage.removeItem('MD');
            localStorage.removeItem('paymentData');
            this.makeAdyenPayment(paymentData, md, paRes);
            return;
        }

        if (params.get("payload")) {
            this.isDisplayloaderMsg = true;
            let payloadValue = decodeURIComponent(params.get("payload"));

            this.makeAdyenPayment(payloadValue, '', '');
            this.globalService.setCartLengthData(0);
            return;
        }
        if (this.globalService.getCartData() != undefined) {
            this.initConfig();
            let dataObj = this.globalService.getCartData();
            this.arrCartLoad = dataObj.cartData;
            this.grandTotalAfterShipping = dataObj.grandTotalAfterShipping;
            this.grandTotalOriginal = dataObj.grandTotalAfterShipping;
            this.totalShippinCost = dataObj.totalShippinCost;
            this.totalHandlingCost = dataObj.totalHandlingCost;
            this.grandTotal = dataObj.grandTotal;
            this.shippingAddress = dataObj.shippingAddress;
            this.billingAddress = dataObj.billingAddress;
            this.pickupAddress = dataObj.pickupAddress;
            this._grandTotal = Number(dataObj.grandTotal);
            this.isDataLoading = true;
            this.pickupAddressLength = Object.keys(this.pickupAddress).length;
            this.shippingAddressLength = Object.keys(this.shippingAddress).length;
            this.pickupShippingDiscAmount = dataObj.discountedAmount;
            this.userOrderLength = dataObj.userOrderLength;
            //   this.setOrderNumber(this.orderNumber);
            this.getAGBText();
            if (this.pickupShippingDiscAmount != 0 && this.pickupShippingDiscAmount != undefined) {
                this.isPickupShippingDisc = true;
                this.grandTotalOriginal = parseFloat(this.grandTotalOriginal) + parseFloat(this.pickupShippingDiscAmount);
            }
            if (Object.keys(this.pickupAddress).length != 0) {
                this.isPickupLocAddress = true;
                localStorage.setItem('pickupAddress', JSON.stringify(this.pickupAddress));
            }
            this.getOemSettings();
        }
        else {
            this.isDataLoading = false;
            this.router.navigate([this.cartPageUrl]);
            return;
        }

        this.dataService.getOriginKeyData().subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            this.originKey = resJSON.originKeys[Object.keys(resJSON.originKeys)[0]];
            var arrLocaleCode = environment[global.env].arrAdyenLocaleCode;
            var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
            this.dataService.getPaymentMethods(currentLocale, this.billingAddress.selectedCountryCode).subscribe((res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                this.paymentMethodsRespons = resJSON;
                this.paymentMethodsLength = resJSON.paymentMethods.length;
                this.moveToStructure();
            });
        });
    }

    moveToStructure(): void {
        if (this.scrollDiv) {
            this.scrollDiv.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
        }
    }
    getAGBText() {
        let keyValue = this.languageData.web['checkout_terms_text'];
        let fullUrl = global.urlSuffix + '/' + this.termsPageUrl;
        let atag = "<a class='active' href=" + fullUrl + " target='_blank'>AGB</a>";
        if (keyValue.indexOf("AGB") != -1) {
            this.AGBTagHtml = keyValue.replace('AGB', atag);
        }
    }


    getOemSettings() {
        this.dataService.getOemSettings().subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            this.oemsDetail = resJSON.data;
            var paymentOptions = this.oemsDetail.paymentOptions;
            this.paymentOptions = paymentOptions.split(',');
            for (let i = 0; i < this.paymentOptions.length; i++) {
                if (this.paymentOptions[i] == 'PayPal') {
                    this.dispalyPaypalBtn = true;
                }
                else if (this.paymentOptions[i] == 'Adyen') {
                    this.dispalyAdyenBtn = true;
                }
                else if (this.paymentOptions[i] == 'Invoice') {
                    if (this.userOrderLength == 0) {
                        let isObjectEqual = this.isEquivalent(this.shippingAddress, this.billingAddress)
                        if (isObjectEqual) {
                            if (this.userInfo.userType == 'Guest' || this.userInfo.userType == 'Nonverified') {
                                this.dispalyInvoiceBtn = false;
                            }
                            this.getUserCreditLimit();
                        }
                        else {
                            this.dispalyInvoiceBtn = false;
                        }
                    }
                    else {
                        if (this.userInfo.userType == 'Guest' || this.userInfo.userType == 'Nonverified') {
                            this.dispalyInvoiceBtn = false;
                        }
                        this.getUserCreditLimit();
                    }
                }
                else if (this.paymentOptions[i] == 'In-Shop') {
                    this.dispalyInShopBtn = true;
                }
            }
        });
    }

    setOrderNumber(data) {
        this.orderNoObj.next(data);
    }

    checkAgb(isagbChecked) {
        this.agbChecked = isagbChecked;
        if (this.agbChecked == false) {
            this.isNoCheckBoxSelected = true;
        }
        else {
            this.isNoCheckBoxSelected = false;
        }
    }



    isEquivalent(a, b) {
        // Create arrays of property names
        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);
        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length != bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (propName != 'company') {
                if (a[propName] !== b[propName]) {
                    return false;
                }
            }
        }
        // If we made it this far, objects
        // are considered equivalent
        return true;
    }



    getOrder() {
        if (this.arrCartLoad.length == 0) {
            // $("#noProductInCart").modal("show");
            return false;
        }
        if (this.enteredCouponCode != '' && (this.grandTotalOriginal == this.grandTotalAfterShipping) && this.isCouponApply == false) {
            this.applyCouponCodeModal();
            return;
        }
        if (this.agbChecked == false || this.agbChecked == undefined) {
            this.isNoCheckBoxSelected = true;
            return;
        }

        if (this.grandTotalAfterShipping == 0) {
            this.orderSubmit('None');
        } else {
            this.isPaymentChecked = true;
            if (this.userInfo.userType == 'Guest' || this.userInfo.userType == 'Nonverified') {
                this.dispalyInvoiceBtn = false;
            }
            else {
                if (this.creditLimitData != undefined) {
                    if (Number(this.creditLimitData.availableCreditLimit) >= this.grandTotalAfterShipping) {
                        this.dispalyInvoiceBtn = true;
                    }
                    else {
                        this.dispalyInvoiceBtn = false;
                    }
                }
            }
        }
        this.googleTrackingCodeForCheckout();
    }

    adyenCheckout() {
        if (this.enteredCouponCode != '' && (this.grandTotalOriginal == this.grandTotalAfterShipping) && this.isCouponApply == false) {
            this.applyCouponCodeModal();
            return;
        }
        this.display = 'block'
        this.isProcessing = false;
        this.refusalReasonCode = undefined;
        var arrLocaleCode = environment[global.env].arrAdyenLocaleCode;
        var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
        var configuration = {
            locale: currentLocale,
            environment: environment[global.env].env,
            originKey: this.originKey,
            paymentMethodsResponse: this.paymentMethodsRespons,
        };
        var checkout = new AdyenCheckout(configuration);

        let self = this;
        this.uniqueId = uuid.v4();

        var paymentsDefaultConfig = {
            application: 'IPHONE_APP',
            data: {
                oemCode: environment[global.env].oemCode,
                // shopperReference: 'reference',
                reference: this.orderNumber,
                uniqueKey: this.uniqueId,
                appVersion: "ONL v 1.0.0",
                countryCode: this.billingAddress.selectedCountryCode,
                returnUrl: location.origin + global.urlSuffix + '/' + this.checkoutPageUrl,
                amount: {
                    value: this.grandTotalAfterShipping * 100,
                    currency: environment[global.env].currency
                }
            }
        };

        var dropin = checkout.create('dropin', {
            paymentMethodsConfiguration: {
                applepay: { // Example required configuration for Apple Pay
                    configuration: {
                        merchantName: 'Adyen Test merchant', // Name to be displayed on the form
                        merchantIdentifier: 'adyen.test.merchant' // Your Apple merchant identifier as described in https://developer.apple.com/documentation/apple_pay_on_the_web/applepayrequest/2951611-merchantidentifier
                    }
                },
                paywithgoogle: { // Example required configuration for Google Pay
                    environment: "TEST", // Change this to PRODUCTION when you're ready to accept live Google Pay payments
                    configuration: {
                        gatewayMerchantId: "YourCompanyOrMerchantAccount", // Your Adyen merchant or company account name. Remove this field in TEST.
                        merchantIdentifier: "12345678910111213141" // Required for PRODUCTION. Remove this field in TEST. Your Google Merchant ID as described in https://developers.google.com/pay/api/web/guides/test-and-deploy/deploy-production-environment#obtain-your-merchantID
                    }
                },
                card: { // Example optional configuration for Cards
                    hasHolderName: true,
                    holderNameRequired: true,
                    enableStoreDetails: true,
                    name: this.languageData.web.adyen_credit_card_tit
                }
            },
            onSubmit: (state, dropin) => {
                this.IsPaymentProcessing = false;
                let btn = this.dropinElement.nativeElement.querySelector('button');
                btn.setAttribute("disabled", 'disabled');
                if (this.orderNumber != undefined) {
                    this.isProcessing = false;
                    paymentsDefaultConfig.data.reference = this.orderNumber;
                    this.globalService.setCartLengthData(0);
                    self.dataService.makePayment(state.data, paymentsDefaultConfig).subscribe((res: Response) => {
                        let resSTR = JSON.stringify(res);
                        let resJSON = JSON.parse(resSTR);
                        this.IsPaymentProcessing = true;
                        if (resJSON.resultCode == "Refused") {
                            this.ngZone.run(() => {
                                localStorage.setItem('Refused', resJSON.resultCode);
                                paymentsDefaultConfig.data.uniqueKey = uuid.v4();
                                this.refusalReasonCode = resJSON.refusalReasonCode;
                                btn.removeAttribute("disabled");
                            });
                            return;
                        }

                        if (resJSON.resultCode == 'Authorised') {
                            this.isDisplayloaderMsg = true;
                            localStorage.removeItem('Refused');
                            this.paymentPaid = 'paid'
                            this.updateOrderStatus(state.data.paymentMethod.type, this.orderNumber);
                        }
                        else if (resJSON.resultCode == 'RedirectShopper') {
                            localStorage.removeItem('Refused');
                            localStorage.setItem('orderNo', this.orderNumber);
                            if (resJSON.action.method == 'POST') {
                                localStorage.setItem('paymentData', resJSON.paymentData);
                                localStorage.setItem('MD', resJSON.redirect.data.MD);
                            }
                            dropin.handleAction(resJSON.action);
                        }
                    })
                }
                else {
                    this.orderSubmit('Adyen');
                    this.isProcessing = false;
                    this.orderNumberSubscription = this.orderNoObj.subscribe(data => {
                        this.orderNumber = data;
                        paymentsDefaultConfig.data.reference = this.orderNumber;
                        this.globalService.setCartLengthData(0);

                        self.dataService.makePayment(state.data, paymentsDefaultConfig).subscribe((res: Response) => {
                            let resSTR = JSON.stringify(res);
                            let resJSON = JSON.parse(resSTR);
                            this.IsPaymentProcessing = true;
                            if (resJSON.status == 422 && resJSON.errorCode == '14_029') {
                                this.ngZone.run(() => {
                                    this.refusalReasonMsg = this.languageData.web.refusal_cvv_error_code_msg;
                                    // this.refusalReasonMsg = resJSON.message;
                                    paymentsDefaultConfig.data.uniqueKey = uuid.v4();
                                    localStorage.setItem('Refused', resJSON.errorCode);
                                    btn.removeAttribute("disabled");
                                });
                                return;
                            }

                            if (resJSON.resultCode == "Refused") {
                                this.ngZone.run(() => {
                                    this.refusalReasonCode = resJSON.refusalReasonCode;
                                    paymentsDefaultConfig.data.uniqueKey = uuid.v4();
                                    localStorage.setItem('Refused', resJSON.resultCode);
                                    btn.removeAttribute("disabled");
                                });
                                return;
                            }

                            if (resJSON.resultCode == 'Authorised') {
                                localStorage.removeItem('Refused');
                                this.paymentPaid = 'paid';
                                this.updateOrderStatus(state.data.paymentMethod.type, this.orderNumber);
                            }
                            else if (resJSON.resultCode == 'RedirectShopper') {
                                localStorage.removeItem('Refused');
                                localStorage.setItem('orderNo', this.orderNumber);
                                if (resJSON.action.method == 'POST') {
                                    localStorage.setItem('paymentData', resJSON.paymentData);
                                    localStorage.setItem('MD', resJSON.redirect.data.MD);
                                }
                                dropin.handleAction(resJSON.action);
                            }
                        })
                    });
                }
            },
            onAdditionalDetails: (state, dropin) => {
                let md;
                let pares;
                self.dataService.makeDetailsCall(state.data, md, pares, '').subscribe((res: Response) => {
                    let resSTR = JSON.stringify(res);
                    let resJSON = JSON.parse(resSTR);
                    dropin.handleAction(resJSON.action);
                })

            },
            onValidateMerchant: (resolve, reject, validationURL) => {
                // Call the validation endpoint with validationURL.
                // Call resolve(MERCHANTSESSION) or reject() to complete merchant validation.
            }
        })
            .mount('#dropin');
    }


    openInvoiceModal() {
        let title = this.languageData.web.mycart_invoice_popup_title;
        let checkBoxtext = this.languageData.web.mycart_invoice_popup_msg;
        let cancelBtn = this.languageData.web.mycart_invoice_popup_cancel_btn;
        let okBtn = this.languageData.web.mycart_invoice_popup_ok_btn;
        const modalRef = this.modalService.open(DynamicPopsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.modalData = { title: title, cancelBtn: cancelBtn, checkBoxtext: checkBoxtext, okBtn: okBtn, modalName: 'invoiceModal', cancelIcon: false };
        modalRef.componentInstance.invoiceData.subscribe((data) => {
            if (data) {
                this.orderSubmit('Invoice');
            }
        })
    }

    openInShopModal() {
        let title = this.languageData.web.checkout_inshop_popup_title;
        let checkBoxtext = this.languageData.web.checkout_inshop_popup_msg;
        let cancelBtn = this.languageData.web.checkout_inshop_popup_cancel_btn;
        let okBtn = this.languageData.web.checkout_inshop_popup_ok_btn;
        const modalRef = this.modalService.open(DynamicPopsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.modalData = { title: title, cancelBtn: cancelBtn, checkBoxtext: checkBoxtext, okBtn: okBtn, modalName: 'inShopModal', cancelIcon: false };
        modalRef.componentInstance.inShopData.subscribe((data) => {
            if (data) {
                this.orderSubmit('Shop');
            }
        })
    }

    applyCouponCodeModal() {
        let subTitle = this.languageData.web.checkout_apply_coupon_code_msg;
        let okBtn = this.languageData.web.checkout_confirm_address_default_cta_ok;
        const modalRef = this.modalService.open(DynamicPopsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.modalData = { subTitle: subTitle, okBtn: okBtn, modalName: 'applyCoupon', cancelIcon: false };
    }


    orderSubmit(paymentType) {

        this.currentPaymentType = paymentType;

        if (this.isProcessing) {
            return;
        }

        if (this.enteredCouponCode != '' && (this.grandTotalOriginal == this.grandTotalAfterShipping) && this.isCouponApply == false) {
            this.applyCouponCodeModal();
            return;
        }

        this.isProcessing = true;
        this.arrItems = [];

        if (this.arrCouponItems) {
            for (var k = 0; k < this.arrCouponItems.length; k++) {
                for (var l = 0; l < this.arrCartLoad.length; l++) {
                    if (this.arrCouponItems[k].albumCode == this.arrCartLoad[l].albumCode) {
                        this.arrCartLoad[l].couponDiscount = this.arrCouponItems[k].couponDiscount;
                        this.arrCartLoad[l].pageDiscount = this.arrCouponItems[k].pageDiscount;
                        this.arrCartLoad[l].shippingDiscount = this.arrCouponItems[k].shippingDiscount;
                        this.arrCartLoad[l].handlingDiscount = this.arrCouponItems[k].handlingDiscount;
                    }
                }
            }
        }
        var totalCouponDiscount = 0.00;
        this.totalCouponDiscount = 0.00;
        this.totalShippingDiscount = 0.00;
        this.totalHandlingDiscount = 0.00;

        for (var i = 0; i < this.arrCartLoad.length; i++) {
            var promoPrice = 0.00;
            var promoUnitPrice = 0.00;
            var isPromoApplicable = false;
            var unitPrice = this.arrCartLoad[i].listPrice;

            if (this.arrCartLoad[i].listPricePromo != false) {
                promoUnitPrice = unitPrice;
                unitPrice = this.arrCartLoad[i].listPricePromo;
            }

            if (this.arrCartLoad[i].promoPrice != false) {
                promoPrice = this.arrCartLoad[i].defaultPrice;
                if (parseFloat(this.arrCartLoad[i].couponDiscount) == 0 || this.arrCartLoad[i].couponDiscount == undefined || parseFloat(this.arrCartLoad[i].pageDiscount) == 0 || this.arrCartLoad[i].pageDiscount == undefined) {
                    isPromoApplicable = true;
                }
            }

            if (this.couponType) {
                totalCouponDiscount = +(parseFloat(this.totalCouponDiscount) + parseFloat(this.arrCartLoad[i].couponDiscount) + parseFloat(this.arrCartLoad[i].pageDiscount)).toFixed(12);
                this.totalShippingDiscount = +(parseFloat(this.totalShippingDiscount) + parseFloat(this.arrCartLoad[i].shippingDiscount)).toFixed(12);
                this.totalHandlingDiscount = +(parseFloat(this.totalHandlingDiscount) + parseFloat(this.arrCartLoad[i].handlingDiscount)).toFixed(12);
                if (this.couponType == 'AMOUNT') {
                    this.arrCartLoad[i].couponDiscount = "0.00";
                }
                this.totalCouponDiscount = totalCouponDiscount;
            }

            var arrItemData = {
                "albumCode": this.arrCartLoad[i].albumCode,
                "quantity": this.arrCartLoad[i].quantity,
                "projectPrice": parseFloat(this.arrCartLoad[i].productPrice),
                "coverCode": this.arrCartLoad[i].coverCode,
                "coverPrice": this.arrCartLoad[i].coverPrice,
                "total": parseFloat(this.arrCartLoad[i].total) + parseFloat(this.arrCartLoad[i].totalGroupShippingCost),
                "paperCode": this.arrCartLoad[i].paperCode,
                "paperPrice": this.arrCartLoad[i].paperPrice,
                "caseCode": this.arrCartLoad[i].caseCode,
                "casePrice": this.arrCartLoad[i].casePrice,
                "envelopesCode": this.arrCartLoad[i].envelopesCode,
                "envelopePrice": this.arrCartLoad[i].envelopePrice,
                "shipping": this.arrCartLoad[i].shipping,
                "handling": this.arrCartLoad[i].handling,
                "isOptimize": this.arrCartLoad[i].isOptimize,
                "unitPrice": unitPrice,
                "promoId": this.arrCartLoad[i].promoId,
                "totalImages": this.arrCartLoad[i].totalImages,
                "totalPrints": this.arrCartLoad[i].totalPrints,
                "listPriceDate": this.arrCartLoad[i].listPriceDate,
                "couponDiscount": parseFloat(this.arrCartLoad[i].couponDiscount) + parseFloat(this.arrCartLoad[i].pageDiscount),
                "shippingDiscount": this.arrCartLoad[i].shippingDiscount,
                "handlingDiscount": this.arrCartLoad[i].handlingDiscount
            }
            this.arrItems.push(arrItemData);

            if (isPromoApplicable) {
                arrItemData['promoUnitPrice'] = promoUnitPrice;
                arrItemData['promoPrice'] = promoPrice;
            }
        }

        if (this.pickupShippingDiscAmount != undefined && this.totalShippingDiscount == 0) {
            this.totalShippingDiscount = this.pickupShippingDiscAmount;
        }

        if (this.couponType || Object.keys(this.pickupAddress).length != 0) {
            if (Object.keys(this.pickupAddress).length != 0) {
                this.shippingAddress = {
                    "firstname": this.pickupAddress.firstName,
                    "lastname": this.pickupAddress.lastName,
                    "gender": this.pickupAddress.gender,
                    "country": this.pickupAddress.countryCode,
                    "houseNumber": this.pickupAddress.houseNumber,
                    "street": this.pickupAddress.street,
                    "company": this.pickupAddress.company,
                    "city": this.pickupAddress.city,
                    "postalcode": this.pickupAddress.zipcode,
                    "phone": this.pickupAddress.phone,
                    "address1": this.pickupAddress.street,
                    "address2": this.pickupAddress.houseNumber,
                    "email": this.billingAddress.email,
                    "pickupID": this.pickupAddress.id,
                };
            }
            else {
                this.shippingAddress = {
                    "firstname": this.shippingAddress.firstName,
                    "lastname": this.shippingAddress.lastName,
                    "gender": this.shippingAddress.gender,
                    "country": this.shippingAddress.selectedCountryCode,
                    "houseNumber": this.shippingAddress.houseNumber,
                    "street": this.shippingAddress.street,
                    "company": this.shippingAddress.company,
                    "city": this.shippingAddress.city,
                    "postalcode": this.shippingAddress.zip,
                    "phone": this.shippingAddress.phone,
                    "address1": this.shippingAddress.street,
                    "address2": this.shippingAddress.houseNumber,
                    "email": this.shippingAddress.email,
                }
            }

            this.data = JSON.stringify({
                "application": "IPHONE_APP",
                data: {
                    "langCode": this.userInfo.langCode,
                    "OEM": environment[global.env].oemCode,
                    "order_comments": "",
                    "apply_coupon": this.couponcode || '',
                    "paymentMethod": paymentType,
                    "grosstotal": this.grandTotalOriginal,
                    "handlingFee": this.totalHandlingCost,
                    "shippingFee": this.totalShippinCost,
                    "currency": environment[global.env].currency,
                    "appVersion": "ONL v 1.0.0",
                    "isMobile": "No",
                    "paidAmount": this.grandTotalAfterShipping,
                    "totalPrints": this.arrCartLoad[0].totalPrints,
                    "totalImages": this.arrCartLoad[0].totalImages,
                    "isHoldcart": "false",
                    "couponType": this.couponType,
                    "couponAmount": this.totalCouponDiscount,
                    "shippingDiscount": this.totalShippingDiscount,
                    "handlingDiscount": this.totalHandlingDiscount,
                    "cartData": {
                        "shipAmount": this.totalShippinCost,
                        "items": this.arrItems
                    },
                    "addressDetails": {
                        "shipping": this.shippingAddress,
                        "billing": {
                            "firstname": this.billingAddress.firstName,
                            "lastname": this.billingAddress.lastName,
                            "gender": this.billingAddress.gender,
                            "country": this.billingAddress.selectedCountryCode,
                            "houseNumber": this.billingAddress.houseNumber,
                            "street": this.billingAddress.street,
                            "company": this.billingAddress.company,
                            "city": this.billingAddress.city,
                            "postalcode": this.billingAddress.zip,
                            "phone": this.billingAddress.phone,
                            "address1": this.billingAddress.street,
                            "address2": this.billingAddress.houseNumber,
                            "email": this.billingAddress.email
                        }
                    }
                }
            });
        }
        else {
            this.data = JSON.stringify({
                "application": "IPHONE_APP",
                data: {
                    "langCode": this.userInfo.langCode,
                    "OEM": environment[global.env].oemCode,
                    "order_comments": "",
                    "apply_coupon": this.couponcode || '',
                    "paymentMethod": paymentType,
                    "grosstotal": this.grandTotalOriginal,
                    "handlingFee": this.totalHandlingCost,
                    "shippingFee": this.totalShippinCost,
                    "currency": environment[global.env].currency,
                    "appVersion": "ONL v 1.0.0",
                    "isMobile": "No",
                    "paidAmount": this.grandTotalAfterShipping,
                    "totalPrints": this.arrCartLoad[0].totalPrints,
                    "totalImages": this.arrCartLoad[0].totalImages,
                    "isHoldcart": "false",
                    "cartData": {
                        "shipAmount": this.totalShippinCost,
                        "couponAmount": this.totalDiscount,
                        "items": this.arrItems
                    },
                    "addressDetails": {
                        "shipping": {
                            "firstname": this.shippingAddress.firstName,
                            "lastname": this.shippingAddress.lastName,
                            "gender": this.shippingAddress.gender,
                            "country": this.shippingAddress.selectedCountryCode,
                            "houseNumber": this.shippingAddress.houseNumber,
                            "street": this.shippingAddress.street,
                            "company": this.shippingAddress.company,
                            "city": this.shippingAddress.city,
                            "postalcode": this.shippingAddress.zip,
                            "phone": this.shippingAddress.phone,
                            "address1": this.shippingAddress.street,
                            "address2": this.shippingAddress.houseNumber,
                            "email": this.shippingAddress.email
                        },
                        "billing": {
                            "firstname": this.billingAddress.firstName,
                            "lastname": this.billingAddress.lastName,
                            "gender": this.billingAddress.gender,
                            "country": this.billingAddress.selectedCountryCode,
                            "houseNumber": this.billingAddress.houseNumber,
                            "street": this.billingAddress.street,
                            "company": this.billingAddress.company,
                            "city": this.billingAddress.city,
                            "postalcode": this.billingAddress.zip,
                            "phone": this.billingAddress.phone,
                            "address1": this.billingAddress.street,
                            "address2": this.billingAddress.houseNumber,
                            "email": this.billingAddress.email
                        }
                    }
                }
            });
        }


        this.IS_PAYMENT_PROCESSING = false;
        this.IS_PAYMENT_GATEWAY_READY = false;

        this.dataService.getOrderSubmit(this.data).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                let orderNumber = resJSON.data.order;
                let cartId = resJSON.data.cartId;
                let orderDetails = resJSON;
                this.orderNumber = orderNumber;
                this.IS_PAYMENT_PROCESSING = true;
                this.isNoCheckBoxSelected = false;

                if (paymentType == 'PayPal') {
                    var paymentMethod = paymentType;
                    this.updatePaymentOrderStatus(this.orderNumber, paymentMethod, 'paid', this.id);
                }
                else if (paymentType == 'Adyen') {
                    var paymentMethod = paymentType;
                    this.setOrderNumber(this.orderNumber);
                }
                else if (paymentType == 'Invoice') {
                    var paymentMethod = paymentType;
                    this.updatePaymentOrderStatus(this.orderNumber, paymentMethod, 'postpayment', '');
                }
                else if (paymentType == 'Shop') {
                    var paymentMethod = paymentType;
                    this.updatePaymentOrderStatus(this.orderNumber, paymentMethod, 'Unpaid', '');
                }
                else {
                    this.updatePaymentOrderStatus(this.orderNumber, 'None', 'paid', 'none');
                }
            }
            else {
                if (resJSON.code == 8) {
                    // 
                    // this.router.navigate([this.cartPageUrl]);
                    window.location.reload();
                }
                else if (resJSON.code == 6) {
                    let title = this.languageData.web.order_submit_invalid_product_title;
                    let subTitle = resJSON.message;
                    let okBtn = this.languageData.web.checkout_product_not_available_cta_btn;
                    const modalRef = this.modalService.open(DynamicPopsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
                    modalRef.componentInstance.modalData = { title: title, subTitle: subTitle, okBtn: okBtn, modalName: 'ProductNotAvailable', cartUrl: this.cartPageUrl, cancelIcon: false };
                }
                else if (resJSON.code == 7) {
                    let title = this.languageData.web.order_submit_db_transaction_error_title;
                    let subTitle = resJSON.message;
                    let okBtn = this.languageData.web.checkout_product_not_available_cta_btn;
                    const modalRef = this.modalService.open(DynamicPopsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
                    modalRef.componentInstance.modalData = { title: title, subTitle: subTitle, okBtn: okBtn, modalName: 'ProductCartEmpty', cartUrl: this.cartPageUrl, cancelIcon: false };
                }
            }
        });
    }


    makeAdyenPayment(payloadValue, md, pares) {
        let orderNumber = localStorage.getItem('orderNo');

        this.dataService.makeDetailsCall(payloadValue, md, pares, '').subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            localStorage.removeItem('orderNo');
            if (resJSON.resultCode == "Authorised") {
                if (md == '' || pares == '') {
                    this.isDisplayloaderMsg = false;
                    this.setGtmCodeForOrders(orderNumber, '');
                    // this.router.navigate([this.thankyouPageUrl, orderNumber])
                }
                else {
                    this.updateOrderStatus('Adyen', orderNumber);
                }
            }
            else if (resJSON.resultCode == "Cancelled" || resJSON.resultCode == "Refused" || resJSON.resultCode == "refused") {
                // this.router.navigate([this.myOrderPageUrl]);
                this.router.navigate([this.cartPageUrl]);
            }
        })
    }

    updatePaymentOrderStatus(orderNumber, paymentMethod, paymentStatus, id) {

        this.dataService.updatePaymentOrder(orderNumber, paymentMethod, paymentStatus, id).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.updateOrderStatus(paymentMethod, orderNumber);
            }
        })
    }


    updateOrderStatus(paymentMethod, orderNumber) {
        this.dataService.updateStatus(orderNumber).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);

            if (resJSON.success == true) {

                let orderNo = orderNumber;
                this.isProcessing = false;
                this.showPaypalBtn = false;

                if (paymentMethod == 'scheme') {
                    this.ngZone.run(() => {
                        this.isDisplayloaderMsg = false;
                    });
                    this.display = 'none';
                    this.setGtmCodeForOrders(orderNumber, paymentMethod);
                }
                else {
                    this.isDisplayloaderMsg = false;
                    this.setGtmCodeForOrders(orderNumber, paymentMethod);
                }
            }
        });
    }

    applyCouponCode(couponcode) {
        //{'coupan-validity': isValidCoupon}"
        let totalShippinCost = this.totalShippinCost;
        let totalHandlingCost = this.totalHandlingCost;
        let grandTotal = this.grandTotal;
        let _grandTotal = Number(this.grandTotal);

        if (this.couponcode == couponcode) {
            return;
        }
        if (this.couponValidation == true) {
            return;
        }


        if (couponcode) {
            this.couponcode = couponcode;
            this.couponValidation = true;
            this.arrItems = [];
            let additionalPagesPrice;
            let additionalPages;
            for (let i = 0; i < this.arrCartLoad.length; i++) {
                let additionalPrice;
                let basePrice;
                let pageCount = this.arrCartLoad[i].pageCount;
                let minPage = parseInt(this.arrCartLoad[i].minPage);
                if (this.arrCartLoad[i].preview == 'WALLCALENDAR' || this.arrCartLoad[i].preview == 'DESKCALENDAR') {
                    pageCount = pageCount - 1;
                }
                if (this.arrCartLoad[i].preview == 'SOFTBOOK-' || this.arrCartLoad[i].preview == 'RINGBOOK') {
                    minPage = minPage + 2;
                }

                additionalPages = pageCount - minPage;
                additionalPrice = Number(this.arrCartLoad[i].paper.length > 0 ? this.arrCartLoad[i].paper[0].additionalPrice : 0);
                additionalPagesPrice = additionalPages * additionalPrice * this.arrCartLoad[i].quantity;
                if (this.arrCartLoad[i].selectedPaper != null) {
                    basePrice = Number(this.arrCartLoad[i].selectedPaper.paperPrice) + Number(this.arrCartLoad[i].basePrice) + Number(this.arrCartLoad[i].coverPrice);
                }
                else {
                    basePrice = this.arrCartLoad[i].basePrice;
                }
                basePrice = basePrice * this.arrCartLoad[i].quantity;

                let arrItemData = {
                    "albumCode": this.arrCartLoad[i].albumCode,
                    "quantity": this.arrCartLoad[i].quantity,
                    "productCode": this.arrCartLoad[i].productCode,
                    // "projectPrice": this.arrCartLoad[i].productPrice,
                    "projectPrice": basePrice,
                    "paperCode": this.arrCartLoad[i].paperCode,
                    "paperPrice": this.arrCartLoad[i].paperPrice,
                    "coverCode": this.arrCartLoad[i].coverCode,
                    "coverPrice": this.arrCartLoad[i].coverPrice,
                    "envelopesCode": this.arrCartLoad[i].envelopesCode,
                    "envelopesPrice": this.arrCartLoad[i].envelopePrice,
                    "total": this.arrCartLoad[i].total,
                    "caseCode": this.arrCartLoad[i].caseCode,
                    "casePrice": this.arrCartLoad[i].casePrice,
                    "totalPrints": this.arrCartLoad[i].totalPrints,
                    "shipping": this.arrCartLoad[i].shipping,
                    "handling": this.arrCartLoad[i].handling,
                    "listPriceDate": this.arrCartLoad[i].listPriceDate,
                    "projectAdditionalPagePrice": additionalPagesPrice
                }
                this.arrItems.push(arrItemData);

                if (this.arrCartLoad[i].promoPrice != false) {
                    arrItemData['promoPrice'] = this.arrCartLoad[i].listPrice * parseInt(this.arrCartLoad[i].quantity);
                }
            }
            this.data = JSON.stringify(
                {
                    "application": "IPHONE_APP",
                    "data": {
                        "couponvalue": couponcode,
                        'langCode': global.lang,
                        "appVersion": "ONL v 1.0.0",
                        "cartData": {
                            "items": this.arrItems
                        }
                    }
                });

            this.dataService.checkCouponValidity(this.data).subscribe((res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                if (resJSON.success == true) {
                    this.isPickupShippingDisc = false;
                    this.grandTotalAfterShipping = this.grandTotalOriginal;
                    this.isCouponApply = true;
                    this.dispalyInvoiceBtn = false;
                    this.couponValidation = false;
                    this.isValidCoupon = true;
                    this.successMessage = resJSON.message;
                    this.errorMessage = null;
                    this.couponCodePrice = resJSON.data.couponvalue;
                    this.couponType = resJSON.data.coupontype;
                    this.totalDiscount = 0.0;
                    this.totalProductDiscont = 0.0;
                    this.totalShippingDiscont = 0.0;
                    this.totalHandlingDiscont = 0.0;
                    var totalProductDiscont = 0.0;
                    var totalAdditionalPagesDiscont = 0.0;
                    this.totalShippinCost = totalShippinCost;
                    this.totalHandlingCost = totalHandlingCost;
                    this.grandTotal = _grandTotal;
                    if (resJSON.data.applypromoproduct == "Yes" && resJSON.data.applyon == "List") {
                        this.grandTotalAfterShipping = this.calculateGrandTotal(resJSON.data.cartData.items);
                    }
                    else {
                        this.grandTotalAfterShipping = this.grandTotalOriginal;
                    }
                    var arrCoupanItems = resJSON.data.cartData.items;
                    // product, shipping and handling discount
                    for (var i = 0; i < arrCoupanItems.length; i++) {
                        totalProductDiscont += parseFloat(arrCoupanItems[i].couponDiscount);
                        totalAdditionalPagesDiscont += parseFloat(arrCoupanItems[i].pageDiscount);
                        this.totalShippingDiscont += parseFloat(arrCoupanItems[i].shippingDiscount);
                        this.totalHandlingDiscont += parseFloat(arrCoupanItems[i].handlingDiscount) || 0;
                    }

                    if (totalAdditionalPagesDiscont != 0) {
                        if (totalProductDiscont != 0) {
                            this.totalProductDiscont = totalAdditionalPagesDiscont + totalProductDiscont;
                            this.totalDiscount = totalAdditionalPagesDiscont + totalProductDiscont + this.totalShippingDiscont;
                        }
                        else {
                            this.totalProductDiscont = totalAdditionalPagesDiscont;
                            this.totalDiscount = totalAdditionalPagesDiscont;
                        }
                    }
                    else {
                        this.totalProductDiscont = totalProductDiscont;

                        if (this.totalShippingDiscont == 0 && this.pickupShippingDiscAmount != 0 && this.pickupShippingDiscAmount != undefined) {
                            this.isPickupShippingDisc = true;
                            this.pickupShippingDiscAmount = parseFloat(this.pickupShippingDiscAmount);
                            this.totalDiscount = this.totalProductDiscont + this.totalShippingDiscont + this.totalHandlingDiscont + this.pickupShippingDiscAmount;
                        }
                        else {
                            this.totalDiscount = this.totalProductDiscont + this.totalShippingDiscont + this.totalHandlingDiscont;
                        }
                    }

                    grandTotal -= this.totalProductDiscont;
                    totalShippinCost -= this.totalShippingDiscont;
                    this.grandTotalAfterShipping -= this.totalDiscount;
                    totalShippinCost = totalShippinCost.toFixed(2);
                    this.grandTotalAfterShipping = this.grandTotalAfterShipping.toFixed(2);
                    this.arrCouponItems = arrCoupanItems;

                    if (this.userInfo.userType == 'Guest' || this.userInfo.userType == 'Nonverified') {
                        this.dispalyInvoiceBtn = false;
                    }
                    else {
                        if (this.creditLimitData != undefined) {
                            if (Number(this.creditLimitData.availableCreditLimit) >= this.grandTotalAfterShipping) {
                                this.dispalyInvoiceBtn = true;
                            }
                            else {
                                this.dispalyInvoiceBtn = false;
                            }
                        }
                    }

                } else {
                    this.isCouponApply = false;
                    this.couponValidation = false;
                    this.isValidCoupon = false;
                    this.successMessage = null;
                    this.errorMessage = resJSON.message;
                    this.couponcode = null;

                    setTimeout(() => {
                        this.errorMessage = null;
                    }, 5000);
                }
            })


        } else {
            this.couponValidation = false;
            this.successMessage = null;
            this.errorMessage = null;
            if (couponcode == undefined || couponcode == '') {
                this.isValidCoupon = false;
            }
        }
    }

    changeCoupon() {
        this.isCouponApply = false;
        this.enteredCouponCode = '';
        this.isValidCoupon = false;
        this.successMessage = null;
        this.errorMessage = null;
        this.totalDiscount = 0.0;
        this.couponCodePrice = null;
        this.couponType = undefined;
        this.couponcode = null;
        if (this.pickupShippingDiscAmount != 0 && this.pickupShippingDiscAmount != undefined) {
            this.isPickupShippingDisc = true;
            this.grandTotalAfterShipping = this.grandTotalOriginal - this.pickupShippingDiscAmount;
        }
        else {
            this.grandTotalAfterShipping = this.grandTotalOriginal;
        }
    }

    calculateGrandTotal(arrCoupanItems) {
        let projectPrice = 0;
        for (var i = 0; i < this.arrCartLoad.length; i++) {
            let cartItem = this.arrCartLoad[i];
            let isPromoApplicable = false;
            for (let j = 0; j < arrCoupanItems.length; j++) {
                if (arrCoupanItems[j].productCode == cartItem.productCode) {
                    if (cartItem.promoPrice != false) {
                        if (arrCoupanItems[j].couponDiscount == 0 || arrCoupanItems[j].pageDiscount == 0) {
                            isPromoApplicable = true;
                        }
                    }
                    break;
                }
            }

            if (isPromoApplicable) {
                projectPrice = Number(projectPrice) + parseFloat(cartItem.promoPrice);
            }
            else {
                projectPrice = Number(projectPrice) + parseFloat(cartItem.defaultPrice);
            }
        }
        let total = Number(projectPrice) + parseFloat(this.totalShippinCost) + parseFloat(this.totalHandlingCost);
        total = parseFloat(total.toFixed(2));
        return total;
    }

    closePaymentPopup() {
        if (this.checkoutIntervalId != null) {
            clearInterval(this.checkoutIntervalId);
            this.checkoutIntervalId = null;
        }

        this.dataService.restoreOrder(this.orderNumber).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.isProcessing = false;
                this.router.navigate([this.cartPageUrl]);
            }
        })
    }

    restoreInCartAfterPayment() {
        if (!this.IS_PAYMENT_GATEWAY_READY) {
            if (this.checkoutIntervalId != null) {
                clearInterval(this.checkoutIntervalId);
                this.checkoutIntervalId = null;
            }
            this.IS_PAYMENT_PROCESSING = false;
            this.closePaymentPopup();
        }
    }

    goBackToCartPage() {
        this.router.navigate([this.cartPageUrl]);
    }

    getUserCreditLimit() {
        this.dataService.getCreditLimit().subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.creditLimitData = resJSON.data;

            }
        })
    }

    getCommaOrDotValue(value) {
        if (value == undefined) {
            return;
        }
        value = this.priceService.getCommaOrDotValue(value);
        if (environment[global.env].isCurrencySymbolFirst) {
            value = this.priceService.getCurrencySymbol() + value;
        }
        else {
            value = value + this.priceService.getCurrencySymbol();
        }

        return value;
    }

    closeModal() {
        this.dropinElement.nativeElement.innerHTML = null;
        this.display = 'none'
        if (localStorage.getItem('Refused')) {
            localStorage.removeItem('Refused');
            // this.router.navigate([this.myOrderPageUrl]);
            this.router.navigate([this.cartPageUrl]);
            return;
        }
    }

    // PAYPAL CODE START
    private initConfig(): void {
        var arrLocaleCode = environment[global.env].arrPaypalLocaleCode;
        var currentLocale = arrLocaleCode[global.lang.toUpperCase()];
        var web = this.languageData.web;
        var paymentTransDesc = web['paypal_transaction_description'];
        
        this.payPalConfig = {
            currency: environment[global.env].currency,
            // clientId: "Ad1Er7v_9ZpUOTdq_FK-9GdJsjm8X1edpcaLL9LVsPdg8yl-cYkD3zev6WxVYfWPhYD_XQdVKHdk07SU",
            clientId: "AY6RIGkTkDIwnjZIqVtGORJc8GbspfaMRLBIwHvub3gMZFgxW00u8HxTBNueZih01-k4AoorkTPREygE",
            createOrderOnClient: (data) => <ICreateOrderRequest>{
                intent: 'CAPTURE',
                locale: currentLocale,

                purchase_units: [
                    {
                        amount: {
                            currency_code: environment[global.env].currency,
                            value: this.grandTotalAfterShipping,
                            breakdown: {
                                item_total: {
                                    currency_code: environment[global.env].currency,
                                    value: this.grandTotalAfterShipping
                                }
                            }
                        },
                        items: [
                            {
                                name: paymentTransDesc,
                                quantity: '1',
                                category: 'DIGITAL_GOODS',
                                unit_amount: {
                                    currency_code: environment[global.env].currency,
                                    value: this.grandTotalAfterShipping
                                },
                            }]
                    }]
            },
            advanced: {
                commit: 'true',
            },
            style: {
                label: 'paypal',
                layout: 'horizontal',
                // size: 'responsive',
                height: 40,
                shape: 'rect',
                color: 'gold',
                tagline: false,
            },

            onApprove: (data, actions) => {
                actions.order.get().then(details => {

                });
            },
            onClientAuthorization: (data) => {

                var obj: any = data['purchase_units'][0];
                if (obj.payments.captures[0].status == "COMPLETED") {
                    this.id = obj.captures[0].id;
                    this.orderSubmit('PayPal');
                }
                this.showSuccess = true;
            },
            onCancel: (data, actions) => {
                this.restoreInCartAfterPayment();
            },
            onError: err => {
            },
            onClick: (data, actions) => {

            },
        };
    }
    // PAYPAL CODE END


    setGtmCodeForOrders(orderNumber, paymentMethod) {

        this.dataService.getOrderTrackingDetail(orderNumber).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                let data = resJSON.data;
                let transProducts = [];
                // let purchasedProducts=[];
                for (let i = 0; i < data.transactionProducts.length; i++) {
                    let arrProducts = data.transactionProducts[i];
                    if (environment[global.env].FB_PIXEL) {
                        transProducts.push({
                            'sku': arrProducts.sku,
                            'name': arrProducts.name,
                            'category': arrProducts.category,
                            'price': arrProducts.price,
                            'quantity': arrProducts.quantity
                        });
                    } else {

                        transProducts.push({
                            item_id: arrProducts.sku,
                            item_name: arrProducts.name,
                            affiliation: "",
                            coupon: "",
                            currency: environment[global.env].currency,
                            discount: '',
                            index: '',
                            item_brand: environment[global.env].oemCode,
                            item_category: arrProducts.category,
                            item_category2: "",
                            item_category3: "",
                            item_category4: "",
                            item_category5: "",
                            item_list_id: "",
                            item_list_name: " ",
                            item_variant: "",
                            location_id: "",
                            price: arrProducts.price,
                            quantity: arrProducts.quantity
                        })

                    }


                }
                let products = JSON.stringify(transProducts);
                const date = new Date();
                let timestamp:any = date.getTime() * 1000;
                if (environment[global.env].FB_PIXEL) {

                    
                    var gaScript = document.createElement('script');
                    gaScript.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({'event': 'orderCompleted','transactionId': '` + data.transactionId + `',` + `'transactionTotal': ` + data.transactionTotal +
                        `,` + `'transactionProducts': ` + products + `, 'custom_event_id':`+ timestamp + `});`
                    document.head.appendChild(gaScript);
                    // FB Pixel Purchase Event
                    const gaFbScript = document.createElement('script');
                    gaFbScript.innerHTML = `fbq('track', 'Purchase', {value: ` + data.transactionTotal + `,currency: '` + data.currency + `'})`;
                    document.head.appendChild(gaFbScript);
                } else {

                   
                    var gaScript = document.createElement('script');
                    gaScript.innerHTML = `dataLayer.push({ ecommerce: null }); 
                    dataLayer.push({
                        event: "purchase", 
                        'custom_event_id':`+ timestamp + `,                       
                        ecommerce: {
                            transaction_id: '`+ data.transactionId  + `',  
                            affiliation: '',  
                            value: '`+ data.transactionTotal + `',  
                            tax:'`+ data.tax + `',  
                            shipping: '`+ data.shipping + `',  
                            currency: '`+ environment[global.env].currency + `',  
                            coupon: '',                            
                            'items':`+ products + `,
                        }
                      
                     },
                                        
                     })`
                    document.head.appendChild(gaScript);

                }

                // End FB Pixel Purchase Event

                if (paymentMethod == 'scheme') {
                    location.href = global.urlSuffix + '/' + this.thankyouPageUrl + '/' + orderNumber;
                }
                else {
                    this.router.navigate([this.thankyouPageUrl, orderNumber]);
                }
            }
        })
    }


    googleTrackingCodeForCheckout() {
        // initiateCheckout
        const date = new Date();
        let timestamp:any = date.getTime() * 1000;
        var gaScript = document.createElement('script');
        gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({'event': 'initiateCheckout', 'custom_event_id':`+ timestamp + `});`
        document.head.appendChild(gaScript);
        // End initiateCheckout

        // FB Pixel Event InitiateCheckout
        if (environment[global.env].FB_PIXEL) {
            const gaFbScript = document.createElement('script');
            gaFbScript.innerHTML = `fbq('track','InitiateCheckout');`;
            document.head.appendChild(gaFbScript);
        }
        // End FB Pixel Event InitiateCheckout
    }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentfulService } from 'src/app/services/contentful.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import * as global from 'src/app/globals';
import { RatingmodalComponent } from '../ratingmodal/ratingmodal.component';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { environment } from 'src/environments/environment';
import { ComponentCanDeactivate } from 'src/app/guard/auth-guard.service';
import { Subscription, Observable } from 'rxjs';
import { Console } from 'console';
import { ExternalService } from 'src/app/services/external.service';
declare var $: any;
declare var moment: any;
@Component({
    selector: 'app-thankyou',
    templateUrl: './thankyou.component.html',
    styleUrls: ['./thankyou.component.scss'],
    standalone: false
})
export class ThankyouComponent implements OnInit, ComponentCanDeactivate {
  pageFooter: any = [];
  userInfo: any;
  userEmail: any;
  addressBookData: any;
  couponCode:any;
  canDeactivate(): Observable<boolean> | boolean {
    return true
  }
  contentType: string = 'page';
  data: any;
  orderNo: any;
  orderPageUrl: any;
  contactusUrl: any;
  dicUrls: any;
  triggerShopData: any;

  @ViewChild('sovendusscriptArea') private sovendusscriptArea: ElementRef;
  @ViewChild('awinscriptArea') private awinscriptArea: ElementRef;
  constructor(private router: Router, private contentfulService: ContentfulService, private dataService: DataService, private activeRoute: ActivatedRoute, private modalService: NgbModal, private globalService: GlobalService, private readonly svc: ExternalService) { 

    const scriptList = document.querySelectorAll("script[type='text/javascript']");
    const convertedNodeList = Array.from(scriptList);
    const gtmScriptLoaded = convertedNodeList.find(script => script.id === "gtm");
  
    if ( gtmScriptLoaded == undefined || gtmScriptLoaded==null) {
      
      if (environment[global.env].GTM_CODE) {
        var gtmScript = document.createElement('script');
        gtmScript.setAttribute('id', 'gtm');
        gtmScript.setAttribute('type', 'text/javascript');
        gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','`+ environment[global.env].GTM_CODE + `');`;
        document.head.appendChild(gtmScript);
      
    }
  }
  }




  ngOnInit(): void {

   
    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      window.scrollTo(0, 0);
      let routeUrl = this.router.url;
      let arrUrl = routeUrl.split('/');
      
      this.orderNo = params.get('orderNo');
      var url = decodeURIComponent(arrUrl[2]);
      localStorage.removeItem('pickupAddress');
      this.globalService.setCartLengthData(0);

      this.loadData(arrUrl[1]+'/'+arrUrl[2]);
      let dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
      this.orderPageUrl = dicUrls['ORDERS_PREVIEW'];
    });
    this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
    this.contactusUrl = this.dicUrls['CONTACTUS_PREVIEW'];
    document.body.classList.remove("stop-scrolling");
    document.body.style.removeProperty('top');



    this.contentfulService.$triggerTrustedApi.subscribe((response) => {

      this.triggerShopData = response;

      if (this.triggerShopData.trigger) {

        let divToAppend: any = '<div id="trustedShopsCheckout" class="css-0" style="display:none">';
        divToAppend += '<span id="tsCheckoutOrderNr" class="css-0">' + this.triggerShopData.orderNo + '</span>';
        divToAppend += '<span id="tsCheckoutBuyerEmail" class="css-0">' + this.triggerShopData.userdata.Email + '</span>';
        divToAppend += '<span id="tsCheckoutOrderAmount" class="css-0">' + this.triggerShopData.amount + '</span>';
        divToAppend += '<span id="tsCheckoutOrderCurrency" class="css-0">' + this.triggerShopData.currency + '</span>';
        divToAppend += '<span id="tsCheckoutOrderPaymentType" class="css-0">Other</span>';
        divToAppend += '</div>'

        const template = document.createElement('div');
        template.innerHTML = divToAppend;

        // document.body.appendChild(template)

        $("#trustedShopsCheckout").css("display", "block");
        $("#tsCheckoutOrderNr").append(this.triggerShopData.orderNo)
        $("#tsCheckoutBuyerEmail").append(this.triggerShopData.userdata.Email)
        $("#tsCheckoutOrderAmount").append(this.triggerShopData.amount)
        $("#tsCheckoutOrderCurrency").append(this.triggerShopData.currency)
        $("#tsCheckoutOrderPaymentType").append('Other')

      }



    });


  }


  ngAfterViewInit() {
    if(localStorage.getItem('userInfo') !=undefined &&  localStorage.getItem('userInfo')!=null &&  localStorage.getItem('userInfo')!=''){
       // 
   

    // 
    if (environment[global.env].SOVENDUS_KEY) {
      this.dataService
        .getOrderTrackingDetail(this.orderNo)
        .subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          if (resJSON.success == true) {
            let data = resJSON.data;
            let transactionTotal = data.transactionTotal;
            let transProducts = [];
            // for (let i = 0; i < data.transactionProducts.length; i++) {
            //   let arrProducts = data.transactionProducts[i];
            //   if (environment[global.env].FB_PIXEL) {
            //     transProducts.push({
            //       sku: arrProducts.sku,
            //       name: arrProducts.name,
            //       category: arrProducts.category,
            //       price: arrProducts.price,
            //       quantity: arrProducts.quantity,
            //     });
            //   } else {
  
            //     transProducts.push({
            //         item_id: arrProducts.sku,
            //         item_name: arrProducts.name,
            //         affiliation: "",
            //         coupon: "",
            //         currency: environment[global.env].currency,
            //         discount: '',
            //         index: '',
            //         item_brand: environment[global.env].oemCode,
            //         item_category: arrProducts.category,
            //         item_category2: "",
            //         item_category3: "",
            //         item_category4: "",
            //         item_category5: "",
            //         item_list_id: "",
            //         item_list_name: " ",
            //         item_variant: "",
            //         location_id: "",
            //         price: arrProducts.price,
            //         quantity: arrProducts.quantity
            //     })
  
            // }
  
            // }
            // let products = JSON.stringify(transProducts);
  
  
           
  
            // if (environment[global.env].FB_PIXEL) {
  
            //   var gaScript = document.createElement('script');
            //   gaScript.innerHTML =
            //     `
            //         window.dataLayer = window.dataLayer || [];
            //         dataLayer.push({'event': 'orderCompleted','transactionId': '` +
            //     data.transactionId +
            //     `',` +
            //     `'transactionTotal': ` +
            //     data.transactionTotal +
            //     `,` +
            //     `'transactionProducts': ` +
            //     products +
            //     `});`;
            //   document.head.appendChild(gaScript);
  
            //   const gaFbScript = document.createElement('script');
            //   gaFbScript.innerHTML =
            //     `fbq('track', 'Purchase', {value: ` +
            //     data.transactionTotal +
            //     `,currency: '` +
            //     data.currency +
            //     `'})`;
            //   document.head.appendChild(gaFbScript);
  
            //   // End FB Pixel Purchase Event
            // } else {
  
  
            //   var gaScript = document.createElement('script');
            //   gaScript.innerHTML = `dataLayer.push({ ecommerce: null }); 
            //   dataLayer.push({
            //       event: "purchase",                        
            //       ecommerce: {
            //           transaction_id: '`+ data.transactionId  + `',  
            //           affiliation: '',  
            //           value: '`+ data.transactionTotal + `',  
            //           tax:'`+ data.tax + `',   
            //           shipping: '`+ data.shipping + `',  
            //           currency: '`+ environment[global.env].currency + `',  
            //           coupon: '',                            
            //           'items':`+ products + `,
            //       }
                
            //    },
                                  
            //    })`
            //   document.head.appendChild(gaScript);
  
            // }
  


            
            let lang = global.lang.toLocaleUpperCase();
            this.dataService
              .getShippingAndBillingAddress()
              .subscribe((res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.userEmail = this.userInfo.Email;

                if (resJSON.success == true) {
                  this.addressBookData = resJSON.data;


                  this.dataService
                    .getprofileDetails(this.userInfo.auth)
                    .subscribe((res: Response) => {
                      let resSTR = JSON.stringify(res);
                      let resJSON = JSON.parse(resSTR);



                      for (var i = 0; i < this.addressBookData.length; i++) {


                        if (this.addressBookData[i].IsDefaultBilling == 1) {

                          let addressBookData = this.addressBookData[i];
                          let phone = addressBookData.Phone.split('-');


                          
                          let gender;
                          if (addressBookData.gender == 'male') {
                            gender = 'Mr.'
                          }
                          else if (addressBookData.gender == 'female') {
                            gender = 'Mrs.'
                          }

                          this.svc.lazyMomentQuill().subscribe(_ => {
                            let sovendusData = {
                              trafficSourceNumber: environment[global.env].SOVENDUS_KEY[lang].TRAFFIC_SOURCE_NUMBER,
                              trafficMediumNumber: environment[global.env].SOVENDUS_KEY[lang].TRAFFIC_MEDIUM_NUMBER,
                              sessionId: this.userInfo.SSO_ID,
                              timestamp: moment().unix(),
                              orderId: this.orderNo,
                              orderValue: transactionTotal,
                              orderCurrency: environment[global.env].currency,
                              usedCouponCode: '',
                              iframeContainerId: 'sovendus-container-1',
  
                              consumerSalutation: gender,
                              consumerFirstName: addressBookData.FirstName,
                              consumerLastName: addressBookData.LastName,
                              consumerEmail: addressBookData.Email,
                              consumerStreet: addressBookData.Address1,
                              consumerStreetNumber: addressBookData.Address2,
                              consumerCountry: addressBookData.CountryName,
                              consumerZipcode: addressBookData.Zip,
                              consumerCity: addressBookData.City,
                              consumerPhone: phone[1],
                              consumerYearOfBirth: resJSON.data.Dateofbirth,
  
                            };
  
                            
                            this.callSovendusScripts(sovendusData)
                          });

                         




                        }
                      }


                    });

                }
              });

          }


        });






    }
    // if (environment[global.env].AWIN_ADVERTISE_ID) {
    //   this.dataService
    //     .getOrderTrackingDetail(this.orderNo)
    //     .subscribe((res: Response) => {
    //       let resSTR = JSON.stringify(res);
    //       let resJSON = JSON.parse(resSTR);
    //       if (resJSON.success == true) {
    //         let data = resJSON.data;
    //         let transactionTotal = data.transactionTotal;
          
            
    //         if(document.getElementById('awin-script')==undefined ||document.getElementById('awin-script')==null){
    //           let awinScrptLink = document.createElement('script');
    //           awinScrptLink.setAttribute("defer", "defer")
    //           awinScrptLink.type="text/javascript"
    //           awinScrptLink.id="awin-script"
    //           awinScrptLink.async=true;
    //           awinScrptLink.src = "https://www.dwin1.com/" + environment[global.env].AWIN_ADVERTISE_ID + '.js'
    //           document.getElementsByTagName("body")[0].appendChild(awinScrptLink);
    //         }
           
    //          setTimeout(() => {
    //           this.setAwinScript(data)
    //          }, 4000);
           
    //       }


    //     });

    // }

    }
   

 

  }

  callSovendusScripts(sovendusData) {
    
    if(environment[global.env].oemCode=='MPB-ES'){
      return false;
    }

    var sovDomain = window.location.protocol + '\x2F\x2F' + 'api.sovendus.com';
    var sovJsFile = sovDomain + '\x2Fsovabo\x2Fcommon\x2Fjs\x2FflexibleIframe.js';



    var s = document.createElement('script');
    s.async = true; s.type = 'text/javascript';

    s.src = sovJsFile;
    document.body.appendChild(s);

    let isTrustedStore = 'test'// environment[global.env].TRUSTED_SHOP_ID;
    let sovendusScript = document.createElement('script');
    sovendusScript.id = "sovendus-Script"
    sovendusScript.async = true;
    sovendusScript.setAttribute('type', 'text/javascript');
    sovendusScript.innerHTML = ` window['sovIframes'] = window['sovIframes'] || [];window['sovIframes'].push({
      trafficSourceNumber: '`+ sovendusData.trafficSourceNumber + `',
      trafficMediumNumber: '`+ sovendusData.trafficMediumNumber + `',
      sessionId: '`+ sovendusData.sessionId + `',
      timestamp:'`+ sovendusData.timestamp + `',
      orderId:'`+ sovendusData.orderId + `',
      orderValue:'`+ sovendusData.orderValue + `',
      orderCurrency: '`+ sovendusData.orderCurrency + `',
      usedCouponCode: '`+ sovendusData.usedCouponCode + `',
      iframeContainerId: '`+ sovendusData.iframeContainerId + `'
    });
    window['sovConsumer'] = window['sovConsumer'] || {};
    window['sovConsumer'] = {
      consumerSalutation: '`+ sovendusData.consumerSalutation + `',
      consumerFirstName: '`+ sovendusData.consumerFirstName + `',
      consumerLastName: '`+ sovendusData.consumerLastName + `',
      consumerEmail: '`+ sovendusData.consumerEmail + + `',
      consumerStreet: '`+ sovendusData.consumerStreet + + `',
      consumerStreetNumber:'`+ sovendusData.consumerStreetNumber + `',
      consumerCountry: '`+ sovendusData.consumerCountry + `',
      consumerZipcode: '`+ sovendusData.consumerZipcode + `',
      consumerCity:'`+ sovendusData.consumerCity + `',
      consumerPhone: '`+ sovendusData.consumerPhone + `',
      consumerYearOfBirth: '`+ sovendusData.consumerYearOfBirth + `'
    };`;



    if (this.sovendusscriptArea) {
      this.sovendusscriptArea.nativeElement.appendChild(sovendusScript);
    }




  }
  setAwinScript(data){
    var s = document.createElement('script');
    s.type = 'text/javascript';

    let awinsScript = document.createElement('script');
    awinsScript.id = "awin-Script"
  
    awinsScript.setAttribute('type', 'text/javascript');

    // AWIN['Tracking'].Sale.channel = "{{channel}}";
    // AWIN['Tracking'].Sale.orderRef = "{{orderReference}}";
    // AWIN['Tracking'].Sale.parts = "{{commissionGroupCode}}:{{totalAmount}}";
    // AWIN['Tracking'].Sale.voucher = "{{voucherCode}}";
  
    // AWIN['Tracking'].Sale.test = "{{isTest}}";
  //   awinsScript.innerHTML=`    
  //   var AWIN = {};
  //   AWIN['Tracking'] = {};
  //   AWIN['Tracking'].Sale = {};
  //   AWIN['Tracking'].Sale.amount = `+data.transactionTotal+`;
  //   AWIN['Tracking'].Sale.currency = '`+environment[global.env].currency+`';
  //  `

   let commissionGroupCode;
   let voucherCode;
   this.couponCode = (this.activeRoute.snapshot.queryParamMap.get('couponcode')==undefined || this.activeRoute.snapshot.queryParamMap.get('couponcode')==null || this.activeRoute.snapshot.queryParamMap.get('couponcode')=='')?false:this.activeRoute.snapshot.queryParamMap.get('couponcode');

   if(this.couponCode){
     commissionGroupCode='S0002';
     voucherCode=this.couponCode;
   }else{
     commissionGroupCode='S0001';
     voucherCode='';
   }
   let valuAfterRemovingVat;
   if(data.tax && data.tax!=null && data.tax!=''){
    if(data.shipping==undefined || data.shipping==''){
      data.shipping=0;
    }
     valuAfterRemovingVat=data.transactionTotal-(data.tax+data.shipping);
   }else{
     valuAfterRemovingVat=data.transactionTotal;
   }
   valuAfterRemovingVat=valuAfterRemovingVat.toFixed(2)
   
  let salesPart=commissionGroupCode+':'+valuAfterRemovingVat;
   awinsScript.innerHTML=`    
   var AWIN = {};
   AWIN['Tracking'] = {};   
   AWIN['Tracking'].Sale = {};
   AWIN['Tracking'].Sale.channel = 'AW';
   AWIN['Tracking'].Sale.orderRef = '`+this.orderNo+`';
   AWIN['Tracking'].Sale.amount = '`+valuAfterRemovingVat+`';
   AWIN['Tracking'].Sale.currency = '`+environment[global.env].currency+`';
   AWIN['Tracking'].Sale.voucher = '`+voucherCode+`';
   AWIN['Tracking'].Sale.parts = '`+salesPart+`';`;

    if (this.awinscriptArea) {
      this.awinscriptArea.nativeElement.appendChild(awinsScript);
    }
  }

  loadData(pageSlug) {
    if(localStorage.getItem('userInfo') ==undefined || localStorage.getItem('userInfo')==null ||  localStorage.getItem('userInfo')==''){
      // this.router.navigate(['/']);
       this.router.navigate(['/']).then(result => { window.location.reload() });
       return false;
     }

    window.scrollTo(0, 0);
    this.contentfulService.getContentByFieldName(this.contentType, pageSlug).then(data => {
      // this.isLoading = false;
      this.prepareToLoad(data);
    })
  }
  prepareToLoad(data) {

    let headerObject = {
      headerVersion: false,
      headerData: false
    }
       

    if (data && data[0] &&  data[0].fields && data[0].fields['topSection']) {
      let allComponentFilter = JSON.parse(JSON.stringify(data[0].fields['topSection']))
      let headerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


      if (headerFound.length > 0) {
        let headerData = headerFound[0];
        if (headerData.fields.selectDesign) {
          let headerObject = {
            headerVersion: headerData.fields.selectDesign.split('-')[2],
            headerData
          }
          // 
          this.contentfulService.emitHeaderData(headerObject)

        }
      } else {
        this.contentfulService.emitHeaderData(headerObject)
      }


    } else {
      this.contentfulService.emitHeaderData(headerObject)
    }


    if (data[0].fields['pageContent']) {
      this.data = data[0].fields['pageContent'][0];

      if (this.data.fields.blockBg) {
        let blockBg = this.data.fields.blockBg;
        blockBg = blockBg.substring(0, blockBg.indexOf("_"))
        this.data.fields.blockBgId = 'color-' + blockBg.replace('#', '');
        var blockBgObj = blockBg.replace('-1', '');
        blockBgObj = blockBgObj.replace('-2', '');
        this.data.fields.blockBgObj = { 'background-color': blockBgObj };
      }

      if (this.data.fields.bannerLargeTopHeaderRichTxt) {
        this.data.fields.bannerLargeTopHeaderRichTxt = documentToHtmlString(this.data.fields.bannerLargeTopHeaderRichTxt, global.embeddedImage)
        this.data.fields.bannerLargeTopHeaderRichTxt = this.data.fields.bannerLargeTopHeaderRichTxt.replace(/(?:\r\n|\r|\n)/g, '<br>');

      }
      if (this.data.fields.subHeaderRText) {
        this.data.fields.subHeaderRText = documentToHtmlString(this.data.fields.subHeaderRText, global.embeddedImage);
        this.data.fields.subHeaderRText = this.data.fields.subHeaderRText.replace(/(?:\r\n|\r|\n)/g, '<br>');
      }
      if (this.data.fields.subHeaderTxt) {
        if (this.data.fields.subHeaderTxt.indexOf("%d") != -1) {
          this.data.fields.subHeaderTxt = this.data.fields.subHeaderTxt.replace('%d', '');
        }
      }
      if (this.data.fields.addHeroCtaButtons) {
        let buttons = this.data.fields.addHeroCtaButtons;
        
        for (let i = 0; i < buttons.length; i++) {
          if (buttons[i].fields.brightnessDropdown) {
            let buttonBg = buttons[i].fields.brightnessDropdown;
            buttonBg = buttonBg.substring(0, buttonBg.indexOf("_"))
            buttons[i].fields.ButtonBgId = 'cta-color-' + buttonBg.replace('#', '');
            var buttonBgObj = buttonBg.replace('-1', '');
            buttonBgObj = buttonBgObj.replace('-2', '')
            buttons[i].fields.buttonBgObj = { 'background-color': buttonBgObj };
          }
        }
      }
      this.getUserDetails()
      setTimeout(() => {
        this.ratingModal();
      }, 1000);
     
    }
    if (data[0].fields['pageFooter']) {

      this.pageFooter = data[0].fields['pageFooter']
      this.loadFooter()
    }
  }

  loadFooter() {

    let footerObject = {
      footerVersion: false,
      footerData: false
    }

    if (this.pageFooter != '') {

      let allComponentFilter = JSON.parse(JSON.stringify(this.pageFooter))
      let footerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


      if (footerFound.length > 0) {
        let footerData = footerFound[0];
        if (footerData.fields.selectDesign) {
          let footerObject = {
            footerVersion: footerData.fields.selectDesign.split('-')[2],
            footerData
          }
          //  
          this.contentfulService.emitFooterData(footerObject)

        }
      } else {
        this.contentfulService.emitFooterData(footerObject)
      }


    } else {
      this.contentfulService.emitFooterData(footerObject)
    }
  }



  clearSetTime() {
    this.dataService.gettingLastRatingTime().subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {
        if (resJSON.lastRatingDate == '') {
          this.ratingModal();
        }
        else {
          // var hours = 24*60*60*1000; // Reset when storage is more than 24hours
          // let offsetValue = environment[global.env].offsetValue;
          // var now = moment().utcOffset(offsetValue).format("YYYY-MM-DD HH:mm:ss");
          // var setupTime: any = resJSON.lastRatingDate;
          // setupTime = new Date(setupTime).getTime();
          // let nowTime = new Date(now).getTime();
          // if(nowTime - setupTime > hours) {
          //     this.ratingModal();
          // }
          this.ratingModal();
        }
      }
    })
  }

  ratingModal() {
    const modalRef = this.modalService.open(RatingmodalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.orderNo = this.orderNo;
    modalRef.result.then((result) => {

    }).catch((error) => {
    });
  }

  getUserDetails() {
    if (localStorage.getItem('userInfo') != undefined && localStorage.getItem('userInfo') != null) {
      let sessionID = JSON.parse(localStorage.getItem('userInfo')).SSO_ID;
      let userType = JSON.parse(localStorage.getItem('userInfo')).userType;
      if (sessionID != undefined && userType == 'Guest') {
        this.dataService.getUserDetailBySessionID(sessionID).subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          if (resJSON.success == true) {
            if (resJSON.data.user_type == 'Guest' && localStorage.getItem("isUserTypeGuest") == null) {
              localStorage.setItem("isUserTypeGuest", 'true');
              let data = { displayKeysforGuest: true, guestloginCoupon: false };
              
            }
            let userInfo = {
              FirstName: resJSON.data.FirstName, LastName: resJSON.data.LastName, Email: resJSON.data.Email, Username: resJSON.data.Username, isDesigner: resJSON.data.isDesigner, auth: resJSON.auth,
              userType: resJSON.data.user_type, userLoginKey: resJSON.data.userLoginKey, Newsletter: resJSON.data.Newsletter, langCode: resJSON.data.langCode, SSO_ID: sessionID,
              isPassword: resJSON.data.isPassword
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            this.globalService.setUserData(userInfo);

          }
          else {

            return;
          }

        });

      }

    }

  }

}

import { ApplicationRef, HostListener, Injectable, Injector } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { SwUpdate } from '@angular/service-worker';
import { timer, interval, concat } from 'rxjs';
import { take, first, switchMap } from 'rxjs/operators';
import { PwaconfirmationComponent } from '../shared/pwaconfirmation/pwaconfirmation.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentfulService } from 'src/app/services/contentful.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import * as global from '../globals';
import { filter } from 'rxjs/operators';
import { UtilityService } from './utility.service';
import { DataService } from './data.service';
import * as uuid from 'uuid';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class PwaService {
  private promptEvent: any;
  pwamodalRef: any;
  public modalService: NgbModal;
  private contentfulService: ContentfulService;
  public appRef: ApplicationRef
  public data: { mobileType: string, promptEvent?: any, platform: any }
  lastActivityAt: any;
  currentDateTime: any;
  dicUrls: any;
  constructor(private activeRoutes:ActivatedRoute, private idle: Idle,private dataService:DataService, private platform: Platform, injector: Injector, private router: Router, public updates: SwUpdate, private globalService: GlobalService, private utilityService: UtilityService) {
    this.data = {
      mobileType: '',
      promptEvent: this.promptEvent,
      platform: this.platform,

    }

    //   this.router.events.subscribe((event: any) => {

    //     if (event instanceof NavigationEnd) {
    //         // Hide loading indicator
    //     }


    // });

    router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe(event => {
      let currentUrl = window.location.href;
      this.injectScript()

      this.lastActivityAt = new Date(localStorage.getItem('ng2Idle.lastactivity.doneat'))//moment(localStorage.getItem('ng2Idle.lastactivity.at')).toDate();
      this.currentDateTime = new Date();
      let differenceInSeconds = (this.currentDateTime - this.lastActivityAt) / 1000;
  
  
  
      // USER'S SESSION DETECTION STARTS
      if (differenceInSeconds > environment[global.env].userSessionTimeOutDuration) {
        // 
        //If user reopens tab after TimeOut period
        const showPopup = false
        // this.idle.clearInterrupts();
        // this.idle.stop();
      //  localStorage.removeItem('ng2Idle.lastactivity.doneat');
      //  this.globalService.resetTimer(showPopup);
  
  
      } else {
        // 
        const isUserIsPresent = ((localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null || localStorage.getItem('userInfo') == '')) ? false : true;
        if (isUserIsPresent) {
          // console.info('hererer')
          this.globalService.startWatchingUserDuration()
        }
  
      }
  
      this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
     
      setTimeout(() => {
        if(this.router.url.includes(this.dicUrls.THANKYOU_PREVIEW)){
         // console.info('dfddf')
          this.setInitialScripts()
       }

      //  console.info(this.router.url,this.dicUrls.THANKYOU_PREVIEW)

      if (this.router.url != `/${this.dicUrls.CONTACT_PREVIEW}`) {
          const element: HTMLScriptElement = document.querySelector('[data-test-id="userlike-container"]');
          if (element) {
            element.style.display = 'none';
          }
        
      }
    }, 2000);
      let urlSegmentFromEnd = currentUrl.substr(currentUrl.lastIndexOf('/') + 1);
      let fromApp = (urlSegmentFromEnd == 'app') ? true : false;

      if (fromApp || currentUrl.includes('create/easy') || currentUrl.includes('app/product') || currentUrl.includes('fotoabzuege/bestellen') || currentUrl.includes('photo-prints/order') || currentUrl.includes('myphotos') || currentUrl.includes('myphotos')) {

        const scriptList = document.querySelectorAll("script[type='text/javascript']")
        $("[id^='trustbadge-container']").css("display", "none");
        //  trustedStoreScript.parentNode.removeChild( trustedStoreScript );
        //  
        const convertedNodeList = Array.from(scriptList)
        const scriptToRemove = convertedNodeList.find(script => script.id === "trust-badge")
        if (scriptToRemove && scriptToRemove.hasOwnProperty('parentNode')) {
          scriptToRemove.parentNode.removeChild(scriptToRemove)
        }

      } else {
        //  $("[id^='trustbadge-container']").removeClass('hideBadge');
        //  

        if (this.router.url == '/shopping-cart' || this.router.url == '/warenkorb' || currentUrl.includes('mycart')) {
          // if (document.getElementById("awin-script") != undefined) {
          //   $('#awin-script').remove();


          // }


        }


        setTimeout(() => {
          if ((localStorage.getItem('interacted') != null && localStorage.getItem('interacted') != undefined && localStorage.getItem('interacted') == 'Yes') && !fromApp && !window['isOffline']) {
            this.manageTrustBadge()

            const scriptList = document.querySelectorAll("script[type='text/javascript']");
            const convertedNodeList = Array.from(scriptList);
            const gtmScriptLoaded = convertedNodeList.find(script => script.id === "gtm");

            if (gtmScriptLoaded == undefined || gtmScriptLoaded == null) {
              this.setInitialScripts()
            }

          }



        },);

        // this.manageTrustBadge()

      }


    })





    setTimeout(() => {
      this.contentfulService = injector.get(ContentfulService)
      this.appRef = injector.get(ApplicationRef);
      if (updates.isEnabled) {

        const appIsStable$ = this.appRef.isStable.pipe(first(stable => stable === true));
        // 
        const intervalTime$ = interval(1 * 60 * 60 * 1000);
        const timeIntervalAppIsStable$ = concat(appIsStable$, intervalTime$);
        timeIntervalAppIsStable$.subscribe(() => updates.checkForUpdate());
      }

    }





    )

    // 


    // const appIsStable$ = this.appRef.isStable.pipe(first(stable => stable === true));
    // 
    // const intervalTime$ = interval(6 * 60 * 60 );
    // const timeIntervalAppIsStable$ = concat( intervalTime$);
    // timeIntervalAppIsStable$.subscribe(() => updates.checkForUpdate());

    // if (updates.isEnabled) {

    //   interval(6 * 60 * 60  ).subscribe(() => updates.checkForUpdate()
    //     .then(() =>{
    
    //     }

    //     ));
    // }

    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe(event => {
        
        switch (event.type) {
          case 'VERSION_DETECTED':
            
            this.promptUser(event);
            break;
          case 'VERSION_READY':
            
            
            this.promptUser(event);
            // await updates.activateUpdate();
            // location.reload();
            break;
          case 'VERSION_INSTALLATION_FAILED':
            
            break;
        }

      });

    }

   


  }

  public injectScript() {
    // Your script logic goes here
   

    (function (d, s) {
     // 
      var iv = 1000 * 60 * 30;
      var dt = new Date();
      var t = Math.round(dt.getTime() / iv) * iv;
      var f = d.getElementsByTagName(s)[0],
        j:any = d.createElement(s);
      j.async = true;
      var aDomain = top.location.hostname.split('.');
      aDomain.shift();
      j.src = 'https://d.' + aDomain.join('.') + '/live/dom.js?t=' + t;
      f.parentNode.insertBefore(j, f);
    })(document, 'script');
  }


  public initPwaPrompt() {
    var _this = this;
//   window.addEventListener('message', async function(event) {
//     console.log('event received:', event);
//     let offlineInfo = event.data.info;
//     let type = offlineInfo.type;
//     let sessionId = offlineInfo.sessionId;
//     console.log('offlineInfo==>',offlineInfo);
//    console.log(' _this.router.url', _this.router.url,location.host);
//    const path = _this.router.url;
//   // let baseUrl = environment[global.env].DOMAIN;
//   let baseUrl = 'http://localhost:4700';
//   console.log('baseUrl==>',baseUrl);
  
// let actualUrl = `${baseUrl}/${type}?offlineApp=true&sessionId=${sessionId}`
// console.log('actualUrl==>',actualUrl);

// let queryData = '?offlineApp=true&sessionId=${sessionId}'
//     // Get the host (window.location.host includes hostname and port)
//     // const host = window.location.host;

//     // Get the protocol (http or https)
//     // const protocol = window.location.protocol;

//     // Build the full URL
//     // const fullUrl = `${protocol}//${host}${path}`;
//     // console.log('fullUrl===>',fullUrl);
//     console.log('actualUrl==>',actualUrl);
//       _this.router.navigate([actualUrl]).then(result => {
// console.log('result==>',result);

//          window.location.reload()
//          });
//         // await _this.router.navigate([fullUrl]);
//         // _this.router.navigateByUrl(actualUrl);
// //  _this.router.navigate([actualUrl], {
// //         queryParams: {offlineApp:true,sessionId:offlineInfo.sessionId},
// //         queryParamsHandling: 'merge', // Keep other existing query params intact
// //       });
//   });
   

    this.activeRoutes.queryParams.subscribe(params => {
      let offlineAppParam = params['offlineApp'];
      if (offlineAppParam) {
      const isUserIsPresent = ((localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null || localStorage.getItem('userInfo') == '')) ? false : true;
          if (!isUserIsPresent) {
            let sessionId = params['sessionId'];
           this.userdataBySSOId(sessionId);
              }
      }
    });

    // const urlParams = new URLSearchParams(window.location.search);
    // const offlineApp = urlParams.get('offlineApp');
    // if (offlineApp) {
    //   let sessionId = urlParams.get('sessionId');
    //   window['isOffline'] = offlineApp;
    //   this.userdataBySSOId(sessionId);
    // }

   
    //this.injectScript()
    // this.setInitialScripts()

    // this.manageTrustBadge()

    //For finding whether the user is loggedIn or not


    this.lastActivityAt = new Date(localStorage.getItem('ng2Idle.lastactivity.doneat'))//moment(localStorage.getItem('ng2Idle.lastactivity.at')).toDate();
    this.currentDateTime = new Date();
    let differenceInSeconds = (this.currentDateTime - this.lastActivityAt) / 1000;



    // USER'S SESSION DETECTION STARTS
    if (differenceInSeconds > environment[global.env].userSessionTimeOutDuration) {
      // 
      //If user reopens tab after TimeOut period
      const showPopup = false
      localStorage.clear()
      // this.idle.clearInterrupts();
      // this.idle.stop();
    //  localStorage.removeItem('ng2Idle.lastactivity.doneat');
    //  this.globalService.resetTimer(showPopup);


    } else {
      // 
      const isUserIsPresent = ((localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null || localStorage.getItem('userInfo') == '')) ? false : true;
      if (isUserIsPresent) {
        this.globalService.startWatchingUserDuration()
      }

    }



    //User loggedIn check ends here




    if (window['environmentType'] != 'at') {
      if (this.platform.ANDROID || (!this.platform.ANDROID && !this.platform.IOS)) {
        window.addEventListener('beforeinstallprompt', (event: any) => {
          event.preventDefault();
          this.promptEvent = event;
          this.data = {
            mobileType: 'android',
            promptEvent: this.promptEvent,
            platform: this.platform
          }


          this.openPromptComponent();
        });
      }
      if ((this.platform.IOS) || this.platform.SAFARI) {
        const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator['standalone']);

        if (!isInStandaloneMode) {
          this.data = {
            mobileType: 'ios',
            promptEvent: this.promptEvent,
            platform: this.platform
          }
          this.openPromptComponent();
        }
      }
    }


  }
  userdataBySSOId(sessionId){
    let ssoId = JSON.parse(JSON.stringify(sessionId));
    this.dataService.getUserDetailBySessionID(ssoId).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success) {
        resJSON.data.auth = resJSON.auth;
        resJSON.data.UUID = uuid.v4();
        let userData:any = JSON.stringify(resJSON.data);
        // localStorage.clear();
        localStorage.setItem('userInfo',userData);
        location.reload();
      }

      
    })
  }
  setTrustBadge(trustedStoreScript) {
    document.body.appendChild(trustedStoreScript);
    $("[id^='trustbadge-container']").css("display", "none");
  }
  removeTrustBadge(trustedStoreScript) {
    trustedStoreScript.parentNode.removeChild(trustedStoreScript)
  }

  manageTrustBadge() {
    let isMobileDevice = this.utilityService.detect();
    let trustedStoreKey = environment[global.env].TRUSTED_SHOP_ID;
    const scriptList = document.querySelectorAll("script[type='text/javascript']")
    const convertedNodeList = Array.from(scriptList)
    let dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
    let uploadPhotosUrl = dicUrls['UPLOAD_FOTO'];
    const scriptToRemove = convertedNodeList.find(script => script.id === "trust-badge")
    if (scriptToRemove) {
      let currentUrl = window.location.href;

      if (currentUrl.includes('create/easy') || currentUrl.includes('app/product') || currentUrl.includes('fotoabzuege/bestellen') || currentUrl.includes('photo-prints/order') || currentUrl.includes(uploadPhotosUrl)) {
        const scriptList = document.querySelectorAll("script[type='text/javascript']")

        //  trustedStoreScript.parentNode.removeChild( trustedStoreScript );
        const convertedNodeList = Array.from(scriptList)
        const scriptToRemove = convertedNodeList.find(script => script.id === "trust-badge")

        if (scriptToRemove && scriptToRemove.hasOwnProperty('parentNode')) {
          scriptToRemove.parentNode.removeChild(scriptToRemove)
        }

      }
      //  scriptToRemove.parentNode.removeChild(scriptToRemove)
    } else {
      let trustedStoreScript = document.createElement('script');
      trustedStoreScript.id = "trust-badge"
      trustedStoreScript.async = true;
      trustedStoreScript.setAttribute('type', 'text/javascript');
      let currentUrl = window.location.href;





      if (trustedStoreKey) {
        trustedStoreScript.innerHTML = `(function () { 
        var _tsid = '`+ trustedStoreKey + `';
        _tsConfig = { 
          'yOffset': '0',
          'variant': 'reviews',
          'customElementId': '',
          'trustcardDirection': '',
          'customBadgeWidth': '', 
          'customBadgeHeight': '',
          'disableResponsive': 'false',
          'disableTrustbadge': 'false' 
        };
        var _ts = document.createElement('script');
        _ts.type = 'text/javascript'; 
        _ts.charset = 'utf-8'; 
        _ts.async = true; 
        _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js'; 
        var __ts = document.getElementsByTagName('script')[0];
        __ts.parentNode.insertBefore(_ts, __ts);
      })();`;


      }



      if (currentUrl.includes('create/easy') || currentUrl.includes('app/product') || currentUrl.includes('fotoabzuege/bestellen') || currentUrl.includes('photo-prints/order') || (isMobileDevice && currentUrl.includes(uploadPhotosUrl))) {

        const scriptList = document.querySelectorAll("script[type='text/javascript']")

        //  trustedStoreScript.parentNode.removeChild( trustedStoreScript );
        const convertedNodeList = Array.from(scriptList)
        const scriptToRemove = convertedNodeList.find(script => script.id === "trust-badge")

        if (scriptToRemove && scriptToRemove.hasOwnProperty('parentNode')) {
          scriptToRemove.parentNode.removeChild(scriptToRemove)
        }

      } else {
        //  $("[id^='trustbadge-container']").removeClass('hideBadge');
        //  


        this.setTrustBadge(trustedStoreScript)


      }
    }

  }
  setInitialScripts() {


    let autofill = document.createElement('script');
    autofill.setAttribute('id', 'detect-autofill');
    autofill.setAttribute('src', './assets/js/detect-autofill.js');

    let didomiScript = document.createElement('script');
    didomiScript.setAttribute('type', 'text/javascript');

    didomiScript.defer = true;

    if (environment[global.env].DIDOMI_KEY) {
      didomiScript.innerHTML =
        `window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}
  else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}
  var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}
  if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){
    var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})('`+ environment[global.env].DIDOMI_KEY + `')})();`
      var head = document.getElementsByTagName('head')[0];

      head.appendChild(didomiScript);
    }


    if (environment[global.env].COOKIEBOT_KEY) {

      let cookieScript1 = document.createElement('script');
      cookieScript1.setAttribute('id', 'Cookiebot');
      cookieScript1.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
      cookieScript1.setAttribute('data-cbid', environment[global.env].COOKIEBOT_KEY);
      cookieScript1.setAttribute('type', 'text/javascript');

      let cookieScript2 = document.createElement('script');
      cookieScript2.setAttribute('id', 'CookieDeclaration');
      cookieScript2.setAttribute('src', 'https://consent.cookiebot.com/' + environment[global.env].COOKIEBOT_KEY + '/cd.js');
      cookieScript2.setAttribute('type', 'text/javascript');

      document.head.appendChild(cookieScript1);
      document.head.appendChild(cookieScript2);


    }

    if (environment[global.env].GTM_CODE) {
      this.loadGtm();

    }

    //   if (environment[global.env].AWIN_ADVERTISE_ID) {
    //     

    //     let awinScrptLink = document.createElement('script');
    //     awinScrptLink.setAttribute("defer", "defer")
    //     awinScrptLink.type="text/javascript"
    //     awinScrptLink.src = "https://www.dwin1.com/" + environment[global.env].AWIN_ADVERTISE_ID + '.js'
    //     document.getElementsByTagName("body")[0].appendChild(awinScrptLink);

    // }


  }

  loadGtm() {
    let gtmScript = document.createElement('script');
    gtmScript.setAttribute('id', 'gtm');

    // gtmScript.setAttribute('type', 'didomi/javascript');
    gtmScript.setAttribute('type', 'text/javascript');
    // gtmScript.setAttribute('data-vendor', 'c:googletag-wmRmDKfz');
    if (environment[global.env].COOKIEBOT_KEY) {
      gtmScript.setAttribute('data-cookieconsent', 'statistics');
    }
    gtmScript.async = true;

    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','`+ environment[global.env].GTM_CODE + `');`;

    document.head.appendChild(gtmScript);

  }


  public checkForUpdates() {

    //  if(this.appRef.isStable){
    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe(event => {
        
        
        
        this.promptUser(event);
      });
      this.updates.versionUpdates.subscribe(event => {
        
        
      });
    }
    // }

  }

  private promptUser(e): void {
    if (this.contentfulService && this.contentfulService != undefined && this.contentfulService != null) {
      this.contentfulService.webAppupdateAvailable(true);
    }

    //   
    // if(e.available) {
    //   this.contentfulService.webAppupdateAvailable(true);
    //   // let snackBarRef = this.snackBar.open(
    //   //   'A new version of the dashboard is available',
    //   //   'Update',
    //   //   {horizontalPosition: 'left'}
    //   // );
    //   // snackBarRef.onAction().subscribe(() => document.location.reload());
    // }else{
    //   this.contentfulService.webAppupdateAvailable(false);
    //  // 
    // }
  }


  private openPromptComponent() {
    //Popup closed
    //   timer(1500)
    //     .pipe(take(1))
    //     .subscribe(() => {
    //       let url = this.router.url.split('?')[0];
    //       
    //       if (url == '/') {
    //         this.contentfulService.openPwaConfirmation(this.data);
    //       }

    //  })


  }


  closePwaConfirmation() {
    this.pwamodalRef.close();

  }
}


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guard/auth-guard.service';

import { CartpageComponent } from './cartpage/cartpage.component';
import { RateusComponent } from './cartpage/rate-us/rate-us.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { ThankyouComponent } from './thankyou/thankyou.component';

const routes: Routes = [
  
  { path: '', component: CartpageComponent,canDeactivate: [AuthGuard]},
  { path: 'zahlung', component: CheckoutComponent ,canDeactivate: [AuthGuard]},
  { path: 'checkout', component: CheckoutComponent ,canDeactivate: [AuthGuard]},
  { path: 'thank-you/:orderNo', component: ThankyouComponent,canDeactivate: [AuthGuard] },
  { path: 'danke/:orderNo', component: ThankyouComponent ,canDeactivate: [AuthGuard]},

  { path: 'merci/:orderNo', component: ThankyouComponent ,canDeactivate: [AuthGuard]},
  { path: 'bedankt/:orderNo', component: ThankyouComponent ,canDeactivate: [AuthGuard]},
  { path: 'grazie/:orderNo', component: ThankyouComponent ,canDeactivate: [AuthGuard]},
  { path: 'tak/:orderNo', component: ThankyouComponent ,canDeactivate: [AuthGuard]},
  { path: 'tack/:orderNo', component: ThankyouComponent ,canDeactivate: [AuthGuard]},
  { path: 'gracias/:orderNo', component: ThankyouComponent ,canDeactivate: [AuthGuard]},
  { path: 'rate-us', component: RateusComponent ,canDeactivate: [AuthGuard]},
  { path: 'bewerte-uns', component: RateusComponent ,canDeactivate: [AuthGuard]},
  
 







  { path: ':level1/:level2', component: CartpageComponent ,canDeactivate: [AuthGuard]},
  { path: ':level1/:level2/:level3', component: CartpageComponent ,canDeactivate: [AuthGuard]},
  { path: ':level1/:level2/:level3/:level4', component: CartpageComponent,canDeactivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CartRoutingModule { }

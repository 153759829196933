<!-- <div id="editableDiv" contenteditable="true">This is a sample text with <span style="color: blue;">blue</span> and <span style="color: red;">red</span> words.</div>
  
  <button (click)="changeStyle('foreColor', 'blue')">Change to Blue</button>
  <button (click)="changeStyle('foreColor', 'red')">Change to Red</button>
  <button (click)="changeStyle('fontName', 'Arial')">Change to Arial</button>
  <button (click)="changeStyle('fontName', 'Georgia')">Change to Georgia</button>

  <button (click)="changeIndentation()">Increase Indentation</button>
   -->


<div class="cart" *ngIf="!isCartDataLoading || isDisplayloaderMsg">
    <div class="loader">        
        <video playsinline autoplay muted src="{{colourfulSpinner}}"></video>
    </div>
</div>

<div class="text-center cart-section" [hidden]="cartItemList.length != 0 && !isCartDataLoading">
    <!-- <p class="text-center" >{{'web.mycart_empty_cart_msg'| translate}}</p>  -->
    <ng-container #dynamicComponentDiv></ng-container>
</div>

<div class="cart icw icw-1260 cart-section" *ngIf="isCartDataLoading && cartItemList.length != 0" [ngClass]="{'offlineCart pt-0': isOfflineApp}">
    <div class="cart-left-side" *ngIf="!isOfflineApp">
        <p class="cart-title">{{'web.mycart_title_txt' | translate}}</p>
        <div id="navbar-cart" class="links">
            <!-- [ngClass]="{'list-active': isListActive == true}" -->
            <h5 class="list-group-item mb-0" data-bs-target="#items-section"
                (click)="scrollToView('items-section')" 
                [ngClass]="{'list-active': isListActive == true}">{{'web.mycart_products' | translate}} ({{cartItemsLength}})
            </h5>
                <!-- [ngClass]="{'list-active': selectedSection == 'address-section'}" -->
            <h5 class="list-group-item mb-0" data-bs-target="#address-section"
                (click)="scrollToView('address-section')" 
                [ngClass]="{'list-active': selectedSection == 'address-section'}">
                {{'web.mycart_left_panel_address_txt' | translate}}
            </h5>
                <!-- [ngClass]="{'list-active': selectedSection == 'payment-section'}" -->
            <h5 class="list-group-item mb-0" data-bs-target="#payment-section"    
                (click)="scrollToView('payment-section')"
                [ngClass]="{'list-active': selectedSection == 'payment-section'}"
                >{{'web.mycart_left_panel_payment_txt' | translate}}
            </h5>
            <!-- <a class="list-group-item" data-bs-target="#address-section" [ngClass]="{'list-active': selectedSection == 'address-section'}" (click)="scrollToView('address-section')">Adresse</a>
            <a class="list-group-item" data-bs-target="#payment-section" [ngClass]="{'list-active': selectedSection == 'payment-section'}" (click)="scrollToView('payment-section')">Zahlung</a> -->
        </div>
    </div>
    <div class="cart-right-side scrollspy-cart" data-bs-spy="scroll" data-bs-target="#navbar-cart" data-bs-offset="0" tabindex="null">
        <div class="cart-items" id="items-section">
            <div class="cart-item" *ngFor="let cartItem of cartItemList; let idx = index"
                [ngClass]="{ 'coverCartItem': (cartItem.selectedPaper != undefined && cartItem.selectedPaper != '' && cartItem.selectedPaper.paperDiscription != '' && cartItem.selectedPaper.paperDiscription != undefined  && cartItem.selectedPaper.paperDiscription)}">
                <!-- [ngClass]="{(coverCartItem) ? (cartItem.selectedPaper != undefined && cartItem.selectedPaper != '' && cartItem.selectedPaper.paperDiscription != '' && cartItem.selectedPaper.paperDiscription != undefined  && cartItem.selectedPaper.paperDiscription) : cartItem.selectedPaper.paperDiscription}" -->


                <div class="cart-details mobile">
                    <div class="product-detail">
                        <div class="left left-mobile">
                            <h3 class="project-name"
                                [hidden]="cartItem.projectName == '' || cartItem.ProductCat == 'PRINT'">
                                <span *ngIf="!cartItem.isDisplayInputField && cartItem.isRenderable!='No'">{{cartItem.projectName}}</span>
                                <img src="{{editProjectNameIcon}}" class="editProjectName"
                                    alt="{{'web.my_cart_edit_project_tooltip' | translate}}"
                                    title="{{'web.my_cart_edit_project_tooltip' | translate}}"
                                    (click)="editProjectName(cartItem)"
                                    *ngIf="!cartItem.isDisplayInputField && cartItem.isRenderable!='No'">
                                <div class="d-flex align-items-center w-100"
                                    *ngIf="cartItem.isDisplayInputField  && cartItem.isRenderable!='No'">
                                    <input type="text" name="name" [ngClass]="{'error': isProjectNameInValid == true}"
                                        class="form-control mb-0" [(ngModel)]="editProjectAlbumName" maxlength="25">
                                    <span class="edit text-end" (click)="saveProjectName(cartItem, editProjectAlbumName)">
                                        <img src="{{okIcon}}" alt="" title="{{'web.my_cart_save_project_tooltip' | translate}}">
                                    </span>
                                </div>
                            </h3>
                            <p>{{cartItem.online_text}}</p>
                        </div>
                    </div>
                </div>
                <!-- <pre> {{cartItem.imageUrl |json}} </pre> -->
                <div class="image">
                    <figure>

                        <img [ngClass]="{'image-shadow': cartItem.preview == 'PHOTOBOOK' || cartItem.preview == 'DESKCALENDAR' ||  cartItem.preview == 'BILET'
                            || cartItem.preview == 'YEARCALENDAR' || cartItem.preview == 'WALLCALENDAR' || cartItem.preview == 'CARD-SINGLE' || cartItem.preview =='BIZCARD'}"
                            [src]="cartItem.imageUrl" crossorigin="anonymous" alt="" title="" *ngIf="!cartItem.isIPL">
                        <img [ngClass]="{'image-shadow': cartItem.preview == 'PHOTOBOOK' || cartItem.preview == 'DESKCALENDAR' ||  cartItem.preview == 'BILET'
                            || cartItem.preview == 'YEARCALENDAR' || cartItem.preview == 'WALLCALENDAR' || cartItem.preview == 'CARD-SINGLE' || cartItem.preview =='BIZCARD'}"
                            src="{{cartItem.previewURL}}" alt="" title="" *ngIf="cartItem.isIPL">
                    </figure>
                    <div class="icons">
                        <!-- <img src="{{editIcon}}" (click)="confrimModal(cartItem)" *ngIf="cartItem.isDisplayEditIcon" alt=""> -->
                        <div class="position-relative">
                            <img class="d-none" src="{{duplicateProjectIcon}}" (click)="addDuplicateItem(cartItem)"
                                alt="" title="">
                            <span class="top-tooltip">{{'web.mycart_duplicate_project_title' | translate}}</span>
                        </div>
                        <div class="position-relative d-lg-none d-block">
                            <img src="{{binIcon}}" class="show-tooltip" alt="" title=""
                                (click)="deleteCartItemConfirmation(cartItem.id, idx)">
                            <span class="top-tooltip">{{'web.mycart_delete_icon_title' | translate}}</span>
                        </div>
                    </div>
                </div>
                <div class="cart-details">
                    <!-- <pre>{{cartItem |json}}</pre> -->
                    <div class="product-detail">
                        <div class="left">
                            <h3 class="project-name"
                                [hidden]="cartItem.projectName == '' || cartItem.ProductCat == 'PRINT'">
                                <span *ngIf="!cartItem.isDisplayInputField  && cartItem.isRenderable!='No'">{{cartItem.projectName}}</span>
                                <img src="{{editProjectNameIcon}}" class="editProjectName"
                                    alt="{{'web.my_cart_edit_project_tooltip' | translate}}" title=""
                                    (click)="editProjectName(cartItem)"
                                    *ngIf="!cartItem.isDisplayInputField && cartItem.isRenderable!='No'">
                                <div class="d-flex align-items-center w-100"
                                    *ngIf="cartItem.isDisplayInputField  && cartItem.isRenderable!='No'">
                                    <input type="text" id='renameProjectName' name="name" [ngClass]="{'error': isProjectNameInValid == true}"
                                    class="form-control mb-0" [(ngModel)]="editProjectAlbumName" maxlength="50">
                                    <span class="edit text-end" (click)="saveProjectName(cartItem, editProjectAlbumName)">
                                        <img src="{{okIcon}}" alt="" title="{{'web.my_cart_save_project_tooltip' | translate}}">
                                    </span>
                                </div>
                            </h3>
                            <p>{{cartItem.online_text}}</p>
                        </div>
                        <div class="right">

                            <!-- <pre>listPrice- {{cartItem.listPrice |json}} </pre> 
                          <pre>totalPrints- {{cartItem.totalPrints |json}} </pre> 
                          <pre>quantity- {{cartItem.quantity |json}} </pre> 
                          <pre>ProductCat- {{cartItem.ProductCat |json}} </pre> 
                          <pre>preview- {{cartItem.preview |json}} </pre>  -->
                            <div class="d-flex justify-content-start">


                                <!-- <div class="badge-wrapper position-relative"
                                    *ngIf="cartItem.isVolumeDiscount==true || cartItem.isVolumeDiscount==false">
                                    <img class="badge-image me-3" crossorigin="anonymous" src="{{cartItem.badgeUrl}}"
                                        alt="" [hidden]="!cartItem.badgeDescription" />
                                    <span class="position-absolute"
                                        [hidden]="!cartItem.badgeDescription">{{cartItem.badgeDescription}} zxzx</span>
                                </div> -->


                                <div [hidden]="(cartItem.paper.length > 0 && (cartItem.selectedPaper==undefined ||         
    cartItem.selectedPaper==null||cartItem.selectedPaper=='' )) ||

    (cartItem.envelopes.length > 1 &&(cartItem.selectedEnvelope==undefined ||
    cartItem.selectedEnvelope==null||cartItem.selectedEnvelope=='' ))||

    (cartItem.cover.length > 1 && (cartItem.selectedCover==undefined ||
    cartItem.selectedCover==null||cartItem.selectedCover=='' ))">

                                    <div class="badge-wrapper position-relative" *ngIf="(cartItem.productAvialableForDelivery || !cartItem.productAvialableForDelivery)">
                                        <img class="badge-image me-1" crossorigin="anonymous"
                                            *ngIf="cartItem.productAvialableForDelivery &&  cartItem.productStatus=='Active' && cartItem.badgeName!=null"
                                            [src]="badgeImagePath + cartItem.badgeName"
                                            alt="" />

                                        <img class="badge-image me-1" crossorigin="anonymous"
                                            *ngIf="!cartItem.productAvialableForDelivery && cartItem.badgeName!= null"
                                            [src]="badgeImagePath + cartItem.badgeNameInactive"
                                            alt="" />
                                        <span class="position-absolute" *ngIf="cartItem.productAvialableForDelivery">{{cartItem.badgeDescription |translate}}</span>

                                        <span class="position-absolute text-danger"
                                            *ngIf="!cartItem.productAvialableForDelivery">{{cartItem.badgeDescriptionInactive |translate}}</span>


                                    </div>  
                                    <!-- commented on 23-12-22 -->
                                </div>


                                <h6
                                    *ngIf="cartItem.productStatus=='Notavailable' || cartItem.productStatus=='Inactive'">
                                    <span class="text-danger">{{'web.product_na_for_delivery' | translate}}</span>
                                </h6>

                                <h3 *ngIf="cartItem.isVolumeDiscount==true && cartItem.productStatus=='Active'">
                                    <span *ngIf="cartItem.preview == 'PRINT'"
                                        [ngClass]="{'lineThrough': cartItem.isVolumeDiscount && cartItem.volumecouponIsApplied==undefined }">
                                        {{ getCommaOrDotValue(cartItem.listPrice*cartItem.totalPrints)}}</span>


                                    <span *ngIf="cartItem.preview != 'PRINT'"
                                        [ngClass]="{'lineThrough': cartItem.isVolumeDiscount && cartItem.volumecouponIsApplied==undefined}">


                                        {{ getCommaOrDotValue(cartItem.listPrice*cartItem.quantity)}}


                                    </span>




                                    <span class="text-danger" [hidden]="cartItem.volumecouponIsApplied">{{
                                        getCommaOrDotValue(cartItem.defaultPrice)}}</span>
                                </h3>
                                <!-- <h3 *ngIf="cartItem.isVolumeDiscount==true">
                                    <span class="text-danger">{{ getCommaOrDotValue(cartItem.defaultPrice)}}</span>
                                </h3> -->

                                <h3 *ngIf="cartItem.isVolumeDiscount==false && cartItem.productStatus=='Active'">

                                    <span
                                        [ngClass]="{'lineThrough': cartItem.promoPrice != false}">{{getCommaOrDotValue(cartItem.defaultPrice)}}</span>
                                    <span *ngIf="cartItem.promoPrice" class="text-danger"> {{
                                        getCommaOrDotValue(cartItem.promoPrice)}}</span>
                                </h3>
                            </div>
                            <p
                                *ngIf="cartItem.productPrice && cartItem.quantity > 1 && cartItem.isPackage != 'Yes' && cartItem.productStatus=='Active'">
                                {{'web.my_cart_header_single_price' | translate}}
                                <span
                                    [ngClass]="{'lineThrough': cartItem.listPricePromo != false}">{{getCommaOrDotValue(cartItem.listPrice)}}</span>
                                <span class="text-danger" *ngIf="cartItem.listPricePromo">{{
                                    getCommaOrDotValue(cartItem.listPricePromo)}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="product-quntity">
                        <!-- <div class="product-quntity" [ngClass]="{'PageCountVisible': !cartItem.isPageCountVisible}"> -->
                        <div class="list d-none d-sm-block">
                            <p [hidden]="!cartItem.isPageCountVisible">
                                <span class="list-name">{{'web.mycart_pages' | translate}}</span>
                                <span>{{cartItem.pageCount}}</span>
                            </p>
                            <p *ngIf="cartItem.totalImages > 0">
                                <span class="list-name">{{'web.mycart_total_images' | translate}}</span>
                                <span>{{cartItem.totalImages}}</span>
                            </p>
                        </div>

                        <div class="counter-box">
                            <div class="title">{{'web.mycart_quantity' | translate}}</div>
                            <div class="counter"
                                *ngIf="cartItem.isPackage == 'No' && !cartItem.isQuantityChangeDisabled">
                                <span class="minus" (click)="decreaseQuantity(cartItem.id,cartItem.quantity,idx)">
                                    <img src="{{minusIcon}}" title="" alt="">
                                </span>
                                <label class="cart-quantity">{{cartItem.quantity}}</label>
                                <!-- <input type="text" name="" id="" value="{{cartItem.quantity}}" disabled> -->
                                <span class="plus" (click)="increaseQuantity(cartItem.id,cartItem.quantity,idx)">
                                    <img src="{{plusIcon}}" title="" alt="">
                                </span>
                            </div>
                            <div class="position-relative delete-cart d-none d-lg-block"
                                [ngClass]="{cartItem_cl: (cartItem.isPackage == 'Yes') || (cartItem.isQuantityChangeDisabled)}">
                                <div class="mt-2" [ngClass]="{mt4: !cartItem.isPageCountVisible}">
                                    <img src="{{binIcon}}" class="show-tooltip cursor-pointer" alt="" title=""
                                        (click)="deleteCartItemConfirmation(cartItem.id, idx)">
                                    <span class="top-tooltip mt-2">{{'web.mycart_delete_icon_title' | translate}}</span>
                                </div>
                            </div>
                            <div class="counter" *ngIf="cartItem.isPackage == 'Yes'">
                                <!-- <select *ngIf="cartItem.arrUniqueQuantity.length>1"
                                    [(ngModel)]="cartItem.selectedQuantityModal"
                                    (ngModelChange)="changeQuantity(cartItem.id,cartItem.selectedQuantityModal.quantity, idx)">
                                    <option *ngFor="let selectedQuantityModal of cartItem.arrUniqueQuantity"
                                        [ngValue]="selectedQuantityModal">{{selectedQuantityModal.quantity}}
                                    </option>
                                </select> -->
                                <!-- <pre>{{selectedCartQuantity |json}}</pre> -->

                                <div class="select-box" *ngIf="cartItem.arrUniqueQuantity.length>1">
                                    <button class="btn btn-link ps-0" type="button" id="selectedQuantityTag"
                                        data-flip="false" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">{{selectedCartQuantity}}</button>
                                    <div class="dropdown-menu text-center" aria-labelledby="selectedQuantityTag">
                                        <span class="dropdown-item"
                                            *ngFor="let selectedQuantityModal of cartItem.arrUniqueQuantity"
                                            (click)="changeQuantity(cartItem.id,selectedQuantityModal.quantity, idx)">{{selectedQuantityModal.quantity}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="counter" *ngIf="cartItem.isQuantityChangeDisabled">

                                <input type="text" class="text-center print-cart" *ngIf="cartItem.preview=='PRINT'"
                                    value="{{cartItem.totalPrints}}" />
                                <input type="text" class="text-center print-cart" *ngIf="cartItem.preview=='RETRO'"
                                    readonly value="{{cartItem.quantity}}" />
                            </div>
                        </div>
                    </div>

                    <!-- <div class="cover-list d-sm-block d-none" *ngIf="cartItem.cover.length > 1">
                        <p *ngIf="cartItem.totalImages > 0" class="d-flex">
                            <label for="mycart_type_cover"
                                class="mb-0 d-inline-block fi-title">{{'web.mycart_type_cover' | translate}}</label>
                            <span *ngIf="cartItem.selectedCover">{{cartItem.selectedCover.coverName}}</span>
                        </p>
                    </div> -->


                 

                    <!-- <div class="cover-list d-sm-none d-block" *ngIf="cartItem.cover.length > 1">
                        <p *ngIf="cartItem.totalImages > 0" class="d-flex">
                            <label for="mycart_type_cover"
                                class="mb-0 d-inline-block fi-title">{{'web.mycart_type_cover' | translate}}</label>
                            <span *ngIf="cartItem.selectedCover">{{cartItem.selectedCover.coverName}}</span>
                        </p>
                    </div> -->

                    <div class="d-flex flex-column">
                        <div class="select-item cover-info cover-list position-relative" *ngIf="cartItem.cover.length > 0">
                            
                            <label for="mycart_type_cover" class="mt-0 fi-title">{{'web.mycart_type_cover' |
                                translate}}</label>
                            <label for="" class="mt-0">{{cartItem.materialTitle}}</label>

                            <span [hidden]="cartItem.selectedCover || cartItem.productPrice || cartItem.hideError"
                                class="text-danger">{{'web.my_cart_select_cover_options' | translate}}</span>

                            <div class="select-box mb-0" [ngClass]="{ 'readonlyclass':!cartItem.allowChangeCover}">
                                <button class="btn btn-link ps-0" type="button" id="selectedCoverTag" data-flip="false"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span *ngIf="cartItem.selectedCover">{{cartItem.selectedCover.coverName}}</span>
                                    <span *ngIf="!cartItem.selectedCover">{{'web.mycart_select_dropdown_options' |
                                        translate}}</span>
                                </button>


                            <div class="dropdown-menu" aria-labelledby="selectedCoverTag"
                            [hidden]="!cartItem.allowChangeCover" >
                                <span class="dropdown-item" *ngFor="let selectedCover of cartItem.cover"
                                    (click)="changeCover(selectedCover,idx)">{{selectedCover.coverName}}</span>
                            </div>

                        
                            <div [hidden]="!cartItem.allowChangeCover">

                                
                                <span *ngIf="(cartItem.selectedCover != undefined && cartItem.selectedCover.coverDiscription != undefined && cartItem.selectedCover != '' && cartItem.selectedCover.coverDiscription != '' && cartItem.selectedCover.coverDiscription )"
                                    (click)="viewPriceOption(cartItem.selectedCover,true)" class="info icon cursor-pointer"
                                    title="">
                                
                                    <img src="{{orderInfoIcon}}" class="infoIcon">
                                </span>
                            </div>

                            </div>
                        </div>

                        <div class="select-item cover-info cover-list position-relative" *ngIf="cartItem.paper.length > 0">
                        
                            <label for="mycart_type_paper" class="mt-0 fi-title">{{'web.mycart_type_paper' |
                                translate}}</label>
                            <label for="" class="mt-0">{{cartItem.materialTitle}}</label>

                            <span [hidden]="cartItem.selectedPaper || cartItem.productPrice || cartItem.hideError"
                                class="text-danger">{{'web.my_cart_select_options' | translate}}</span>

                            <div class="select-box mb-0" [ngClass]="{ 'readonlyclass':!cartItem.allowChangePaper && cartItem.selectedPaper!=undefined}">
                                <button class="btn btn-link ps-0" type="button" id="selectedPaperTag" data-flip="false"
                                    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span *ngIf="cartItem.selectedPaper">{{cartItem.selectedPaper.paperName}}</span>
                                    <span *ngIf="!cartItem.selectedPaper">{{'web.mycart_select_dropdown_options' |
                                        translate}}</span>
                                </button>



                                <!-- <div class="dropdown-menu" aria-labelledby="selectedPaperTag"
                                    *ngIf="!cartItem.isIPL  || (cartItem.isIPL && cartItem.preview != 'ADVENTSCAL'&& cartItem.preview != 'ADVENTSCAL-FRONT'&& cartItem.preview != 'ADVENTSCAL-PICTURES')">
                                    <a class="dropdown-item" *ngFor="let selectedPaper of cartItem.paper"
                                        (click)="changePaper(selectedPaper,idx)">{{selectedPaper.paperName}}</a>
                                </div>
                                <div [hidden]="(cartItem.productCode=='EL-4300-1510' && cartItem.isIPL)">
                                    <a *ngIf="(cartItem.selectedPaper != undefined && cartItem.selectedPaper.paperDiscription != undefined && cartItem.selectedPaper != '' && cartItem.selectedPaper.paperDiscription != '' && cartItem.selectedPaper.paperDiscription )|| (cartItem.selectedPaper && cartItem.showPaperDiscription) "
                                        (click)="viewPriceOption(cartItem.selectedPaper)" class="info icon cursor-pointer"
                                        title="">
                                    
                                        <img src="{{orderInfoIcon}}" class="infoIcon">
                                    </a>
                                </div> -->

                                <div class="dropdown-menu" aria-labelledby="selectedPaperTag"
                                [hidden]="!cartItem.allowChangePaper && cartItem.selectedPaper!=undefined">
                                <span class="dropdown-item" *ngFor="let selectedPaper of cartItem.paper"
                                    (click)="changePaper(selectedPaper,idx)">{{selectedPaper.paperName}}</span>
                            </div>
                            <div [hidden]="!cartItem.allowChangePaper && cartItem.selectedPaper!=undefined">
                                <span *ngIf="(cartItem.selectedPaper != undefined && cartItem.selectedPaper.paperDiscription != undefined && cartItem.selectedPaper != '' && cartItem.selectedPaper.paperDiscription != '' && cartItem.selectedPaper.paperDiscription )|| (cartItem.selectedPaper && cartItem.showPaperDiscription) "
                                    (click)="viewPriceOption(cartItem.selectedPaper)" class="info icon cursor-pointer"
                                    title="">
                                
                                    <img src="{{orderInfoIcon}}" class="infoIcon">
                                </span>
                            </div>

                            </div>
                        </div>
                    </div>


                    <div class="list d-sm-none d-block mt-3">
                        <p [hidden]="!cartItem.isPageCountVisible">
                            <label class="list-name">{{'web.mycart_pages' | translate}}</label>
                            <span>{{cartItem.pageCount}}</span>
                        </p>
                        <p *ngIf="cartItem.totalImages > 0">
                            <label class="list-name">{{'web.mycart_total_images' | translate}}</label>
                            <span>{{cartItem.totalImages}}</span>
                        </p>
                    </div>

                    <div class="select-item" *ngIf="cartItem.cases > 0">
                        <label for="mycart_option1">{{'web.mycart_option1' | translate}}</label>
                        <select>
                            <option *ngFor="let case of cartItem.cases">{{case.caseType}}</option>
                        </select>
                    </div>
                   
                    <div class="select-item" *ngIf="cartItem.envelopes.length > 1">
                        <label for="checkout_options"> {{'web.checkout_options' | translate}}</label>
                        <div class="select-box" [ngClass]="{ 'readonlyclass':!cartItem.allowChangeEnvelopes && cartItem.selectedEnvelope!=undefined}">
                            <button class="btn btn-link ps-0" type="button" id="selectedEnvelopeTag" data-flip="false"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              
                                <span
                                    *ngIf="cartItem.selectedEnvelope">{{cartItem.selectedEnvelope.envelopeName}}</span>
                                <span *ngIf="!cartItem.selectedEnvelope">{{'web.mycart_select_dropdown_options' |
                                    translate}}</span>
                            </button>
                          
                            <!-- (click)="changeEnvelope(selectedEnvelope,idx)" -->

                            <div [hidden]="!cartItem.allowChangeEnvelopes && cartItem.selectedEnvelope!=undefined">
                                <div class="dropdown-menu" aria-labelledby="selectedEnvelopeTag"
                                    >
                                    <span class="dropdown-item" (click)="changeEnvelope(selectedEnvelope,idx)" *ngFor="let selectedEnvelope of cartItem.envelopes"
                                        >
                                        {{selectedEnvelope.envelopeName}}</span>
                                </div>
                            </div>

                            <span [hidden]="cartItem.selectedEnvelope || cartItem.productPrice || hideError"
                                class="text-danger danger-alert">{{'web.my_cart_select_options' | translate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="address" id="address-section">
            <div class="row mx-0">
                <div class="col-sm-6 pe-25">
                    <div class="address-list dropdown">
                        <!-- <button class="btn dropdown-toggle" type="button" data-flip="false" id="collapseAddress"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            *ngIf="activeTab == 'shipping'">
                            <span>{{'web.checkout_address_shipping_title' | translate }}</span>
                            <img src="{{searchIcon}}" alt=""
                                *ngIf="addressBookData.length >0 && activeTab == 'shipping'">
                            <img src="{{arrowDownIcon}}" alt=""
                                *ngIf="addressBookData.length == 0 && activeTab == 'shipping'">
                        </button> -->

                        <button class="btn" type="button"
                            *ngIf="addressBookData.length == 0 && activeTab == 'shipping'">
                            <span>{{'web.checkout_address_shipping_title' | translate }}</span>
                            <img src="{{arrowDownIcon}}" alt="">
                        </button>
                        <button class="btn dropdown-toggle" type="button" data-flip="false" id="collapseAddress"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            *ngIf="addressBookData.length >0 && activeTab == 'shipping'">
                            <span>{{'web.checkout_address_shipping_title' | translate }}</span>
                            <img src="{{searchIcon}}" alt="">
                        </button>

                        <div class="dropdown-menu" aria-labelledby="collapseAddress"
                            *ngIf="addressBookData && addressBookData.length>0 && activeTab == 'shipping'">
                            <form class="search-input">
                                <img src="{{searchIcon}}" alt="" title="">
                                <input type="email" class="form-control" #myInput name="search" [(ngModel)]="searchText"
                                    placeholder="{{'web.mycart_ship_address_search'| translate}}"
                                    (ngModelChange)="filterAddressBookItem(myInput.value);focusInput(myInput)">
                            </form>
                            <span class="title pb-2"
                                (click)="openNewShippingAddressM()">{{'web.mycart_ship_address_dropdown_tit' | translate
                                }}</span>
                            <div class="address-item" *ngFor="let address of arrAddressBook; let indx = index">
                                <div (click)="selectAddress(indx)" style="cursor: pointer;">
                                    <p class="address-name">{{address.FirstName}} {{address.LastName}}</p>
                                    <p>{{address.Address1}} {{address.Address2}}, {{address.Zip}} {{address.City}},
                                        {{address.CountryName}}
                                    </p>
                                </div>
                                <img src="{{binIcon}}" alt="" title="" (click)="deleteAddress(indx)">
                            </div>
                        </div>
                        <!-- addressbook dropdown  end-->
                        <!-- pickup location dropdown  start-->
                        <button class="btn dropdown-toggle" type="button" id="collapseAddress-1" data-flip="false"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            *ngIf="pickupAddressDataLength >0  && activeTab == 'pickup'">
                            <span>{{'web.checkout_select_pickup_address_title' | translate }}</span>
                            <img src="{{searchIcon}}" alt=""
                                *ngIf="pickupAddressDataLength >0  && activeTab == 'pickup'">
                            <img src="{{arrowDownIcon}}" alt=""
                                *ngIf="pickupAddressDataLength == 0  && activeTab == 'pickup'">
                        </button>
                        <div class="dropdown-menu" aria-labelledby="collapseAddress-1"
                            *ngIf="pickupAddressDataLength >0  && activeTab == 'pickup'">
                            <form class="search-input">
                                <img src="{{searchIcon}}" alt="" title="">
                                <input type="email" class="form-control" #myInput1 name="search-Text"
                                    [(ngModel)]="searchText"
                                    placeholder="{{'web.mycart_ship_address_search'| translate}}"
                                    (ngModelChange)="filterItem(myInput1.value);focusInput(myInput)">
                            </form>
                            <!-- <p class="title">Neue adresse</p> -->
                            <div class="address-item" *ngFor="let address of arrAllPickupLocations; let indx = index"
                                (click)="selectPickupAddress(indx)" style="cursor: pointer;">
                                <div>
                                    <p class="address-name">{{address.firstName}} {{address.lastName}}</p>
                                    <p>{{address.street}} {{address.houseNumber}}, {{address.zipcode}} {{address.city}}
                                        {{address.countryName}}
                                    </p>
                                    <p>{{address.emailText}}</p>
                                </div>
                                <img src="{{okIcon}}" alt="" title="" *ngIf="activePickUplistTab == indx">
                            </div>
                        </div>
                        <!-- pickup location dropdown  end-->
                    </div>

                    <!-- pickup location dropdown  button clicked open-->
                    <div class="pickup-address" aria-labelledby="collapseAddress-1"
                        *ngIf="arrUsedPickupLocations.length == 0  && activeTab == 'pickup'">
                        <form class="search-box position-relative">
                            <img src="{{searchIcon}}" alt="" title="">
                            <input type="email" class="form-control" #myInput1 name="search-Text"
                                [(ngModel)]="searchText" placeholder="{{'web.mycart_ship_address_search'| translate}}"
                                (ngModelChange)="filterItem(myInput1.value);focusInput(myInput)">
                        </form>
                        <div class="address-item" *ngFor="let address of arrAllPickupLocations; let indx = index"
                            (click)="selectPickupAddress(indx)" style="cursor: pointer;">
                            <div>
                                <p class="address-name">{{address.firstName}} {{address.lastName}}</p>
                                <p>{{address.street}} {{address.houseNumber}}, {{address.zipcode}} {{address.city}}
                                    {{address.countryName}}
                                </p>
                                <p>{{address.emailText}}</p>
                            </div>
                            <img src="{{okIcon}}" alt="" title="" *ngIf="activePickUplistTab == indx">
                        </div>
                    </div>
                    <!-- pickup location dropdown  button clicked end-->

                    <div *ngIf="arrUsedPickupLocations.length>0 && activeTab == 'pickup'">
                        <div class="pickupaddress"
                            *ngFor="let usedPickupLocation of arrUsedPickupLocations; let indx = index"
                            (click)="selectUsedPickupAddress(indx)"> 
                            <div>
                                <p class="address-name">{{usedPickupLocation.firstName}} {{usedPickupLocation.lastName}}
                                </p>
                                <p>{{usedPickupLocation.street}} {{usedPickupLocation.houseNumber}},
                                    {{usedPickupLocation.zipcode}} {{usedPickupLocation.city}},
                                    {{usedPickupLocation.countryName}}
                                </p>
                                <p>{{usedPickupLocation.emailText}}</p>
                            </div>
                            <img src="{{okIcon}}" alt="" title="" *ngIf="activeUsedTab == indx">
                        </div>
                    </div>
                    <div class="shippingForm mt-5" *ngIf="activeTab == 'shipping'">
                        <form (ngSubmit)="shippingForm.form.valid && submitAddress()" #shippingForm="ngForm" name="form"
                            novalidate>
                            <div class="checkbox-wrap position-absolute" *ngIf=" billingButtonEnable && (user.billingDetail.gender && user.billingDetail.firstName && user.billingDetail.lastName && user.billingDetail.street && user.billingDetail.houseNumber && user.billingDetail.city)" style="top: 39px;">
                                <div class="checkbox" id="shippingSame" name="defaultCheckbox" (click)="checkedCheckboxSameAsShip(true,'billing')">
                                    <input class="custom-checkbox mb-0" type="checkbox" id="shippingCheckbox" value="option1">
                                    <label for="shippingCheckbox">{{ 'web.checkout_address_same_as_billing' | translate }}</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <div class="select-box" [ngClass]="{ 'pr':user.shippingDetail.gender == ''}">
                                        <button class="border-0" type="button" id="genderShippingTag"
                                            data-flip="false" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false" [ngClass]="{ 'bg-danger':(user.shippingDetail.gender == '')}">
                                            <span *ngIf="user.shippingDetail.gender!=''">
                                          
                                                {{(user.shippingDetail.gender == 'Male')?('web.address_gender_male' |
                                                translate):(user.shippingDetail.gender == 'Female')?('web.address_gender_female' | translate):('web.address_gender_neutral' | translate)}}
                                            </span>
                                            <span *ngIf="user.shippingDetail.gender == ''">{{'web.address_gender' | translate }}</span>
                                        </button>

                                        <div class="dropdown-menu" aria-labelledby="genderShippingTag">
                                            <span class="dropdown-item" *ngFor="let gender of getGender"
                                                (click)="changeGender(gender.gender, 'shippingForm')"  
                                                >
                                                {{(gender.gender == 'Male')?('web.address_gender_male' |
                                                translate):gender.gender == 'Female'?('web.address_gender_female' | translate):('web.address_gender_neutral' | translate)}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div class="row">
                                        <div class="col-6">

                                            <input class="form-control"
                                                placeholder="{{'web.address_fname' | translate }}*" name="fName"
                                                (paste)="preventPasteLeadingSpace($event)"
                                                [(ngModel)]="user.shippingDetail.firstName" #fName="ngModel" required
                                                (keydown)="compareValues('shipping',{type:'fName',minimumLength:1,maximumLength:17,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'firstName'});preventLeadingSpace($event, user.shippingDetail.firstName)"
                                                (input)="submitted=false;compareValues('shipping',{type:'fName',minimumLength:1,maximumLength:17,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'firstName'})"
                                                [ngClass]="{ 'bg-danger':(user.shippingDetail.firstName==undefined || user.shippingDetail.firstName=='')|| (fName.touched && fName.invalid) || (shippingForm.submitted && fName.invalid)}" minlength="1" maxlength="17"/>
                                                <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.firstNameLength && user.shippingDetail.firstName.length>17">{{errorMessageObj.firstNameLength}}</div>

                                                <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'fName' ">{{errorMessageObj.msg}}</div>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" class="form-control" name="lName"
                                                (paste)="preventPasteLeadingSpace($event)"
                                                placeholder="{{'web.address_lname' | translate }}*" required
                                                [(ngModel)]="user.shippingDetail.lastName" #lName="ngModel"
                                                (keydown)="preventLeadingSpace($event, user.shippingDetail.lastName);compareValues('shipping',{type:'lName',minimumLength:1,maximumLength:17,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'lastName'})"
                                                (input)="submitted=false;compareValues('shipping',{type:'lName',minimumLength:1,maximumLength:17,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'lastName'})"
                                                [ngClass]="{ 'bg-danger': (user.shippingDetail.lastName==undefined || user.shippingDetail.lastName=='')|| (lName.touched && lName.invalid) || (shippingForm.submitted && lName.invalid)}" minlength="1" maxlength="17"/>
                                                <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'lName'">{{errorMessageObj.msg}}</div>
                                                <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.lastnameLength && user.shippingDetail.lastName.length>17">{{errorMessageObj.lastnameLength}}</div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <input type="text" name="sCompany" [(ngModel)]="user.shippingDetail.company"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="preventLeadingSpace($event, user.shippingDetail.company);compareValues('shipping',{type:'sCompany',minimumLength:2,maximumLength:50,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'company'})"
                                        (input)="submitted=false;compareValues('shipping',{type:'sCompany',minimumLength:2,maximumLength:50,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'company'})"
                                        #sCompany="ngModel" class="form-control"
                                        placeholder="{{'web.address_company' | translate }}" minlength="2" maxlength="50"/>
                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.companyLength && user.shippingDetail.company.length>50">{{errorMessageObj.companyLength}}</div>

                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'sCompany'">{{errorMessageObj.msg}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-9 2">
                                    <label for="">Strasse</label>
                                    <input type="road" name="sStreet" class="form-control"
                                        [(ngModel)]="user.shippingDetail.street" #sStreet="ngModel"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        placeholder="{{'web.address_street' | translate }}*"
                                        (keyup)="checkStreet(sStreet.value)" 
                                        (keydown)="preventLeadingSpace($event, user.shippingDetail.street);compareValues('shipping',{type:'sStreet',minimumLength:1,maximumLength:35,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'street'})"
                                        (input)="submitted=false;compareValues('shipping',{type:'sStreet',minimumLength:1,maximumLength:35,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'street'})"
                                        [ngClass]="{ 'bg-danger': (user.shippingDetail.street==undefined || user.shippingDetail.street=='')|| (sStreet.touched && sStreet.invalid) || (shippingForm.submitted && sStreet.invalid) || streetError}"
                                        required minlength="1" maxlength="35"/>
                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.streetLength && user.shippingDetail.street.length>35">{{errorMessageObj.streetLength}}</div>

                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'sStreet' ">{{errorMessageObj.msg}}</div>
                                    <div *ngIf="streetError" class="text-danger errorMsg">
                                        {{'web.register_street_house_val' | translate }}
                                    </div>
                                </div>
                                <div class="col-3">
                                    <label for="">Haus Nr.</label>
                                    <input type="text" class="form-control" name="houseno"
                                        [(ngModel)]="user.shippingDetail.houseNumber" #houseno="ngModel"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="preventLeadingSpace($event, user.shippingDetail.houseNumber);compareValues('shipping',{type:'houseno',minimumLength:1,maximumLength:10,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'houseNumber'})"
                                        (input)="submitted=false;compareValues('shipping',{type:'houseno',minimumLength:1,maximumLength:10,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'houseNumber'})"
                                        placeholder="{{'web.address_house' | translate }}"
                                        (keyup)="checkHouse(houseno.value)"
                                        [ngClass]="{ 'bg-danger': (user.shippingDetail.houseNumber==undefined || user.shippingDetail.houseNumber=='')||(houseno.touched && houseno.invalid) || (shippingForm.submitted && houseno.invalid)}"
                                        required minlength="1" maxlength="10"/>
                                        <div class="text-danger fs-12 align-err-text" style="top: 32px" *ngIf="errorMessageObj.houseLength && user.shippingDetail.houseNumber.length>10">{{errorMessageObj.houseLength}}</div>
                                        <div class="text-danger errorMsg" *ngIf="errorMessageObj.currentValue == 'houseno'">{{errorMessageObj.msg}}</div>
                                    <!-- <div *ngIf="houseno.dirty && streetError" class="text-danger errorMsg">
                                        {{'web.register_street_house_val' | translate }}
                                    </div> -->
                                </div>
                            </div>
                            <!-- {{charMinLength}} {{charMaxLength}} -->
                            <div class="row position-relative" *ngIf="charMaxLength != 0 && charMinLength != 0">
                                <div class="col-3">
                                    <label for="">PLZ</label>
                                    <input type="text" name="sZip" [(ngModel)]="user.shippingDetail.zip"
                                        class="form-control" #sZip="ngModel" maxlength="{{charMaxLength}}"
                                        (keyup)="countPostalCode(sZip.value)" 
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="submitted=false;preventLeadingSpace($event, user.shippingDetail.zip);compareValues('shipping',{value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'zip'})"
                                        placeholder="{{'web.address_zip' | translate }}*" minlength="{{charMinLength}}"
                                        class="form-control" required pattern="{{applyPattern}}"
                                        [ngClass]="{ 'bg-danger': (user.shippingDetail.zip==undefined || user.shippingDetail.zip=='') || (sZip.touched && sZip.invalid) || (shippingForm.submitted && sZip.invalid)}">
                                </div>

                                <div class="d-flex errorMsg">
                                    <span *ngIf="sZip.invalid && (sZip.dirty || sZip.touched) && sZip.errors.pattern"
                                        class="text-danger me-2">
                                        {{'web.myprofile_zip_val_pattern_wrong' | translate }}
                                    </span>
                                    <span *ngIf="charLenIncorrect && sZip.dirty" class="text-danger me-2">
                                        {{'web.myprofile_zip_val_min_charac' | translate }} {{charMinLength}}
                                    </span>
                                    <span *ngIf="charLenIncorrect && sZip.dirty" class="text-danger">
                                        {{'web.myprofile_zip_val_max_charac' | translate }} {{charMaxLength}}
                                    </span>
                                </div>
                                <div class="col-9">
                                    <label for="">Ort</label>
                                    <input type="text" name="sCity" placeholder="{{'web.address_city' | translate }}*"
                                        class="form-control" [(ngModel)]="user.shippingDetail.city" #sCity="ngModel"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="preventLeadingSpace($event, user.shippingDetail.city);compareValues('shipping',{type:'sCity',minimumLength:1,maximumLength:35,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'city'})"
                                        (input)="submitted=false;compareValues('shipping',{type:'sCity',minimumLength:1,maximumLength:35,value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'city'})"
                                        [ngClass]="{ 'bg-danger': (user.shippingDetail.city==undefined || user.shippingDetail.city=='') || (sCity.touched && sCity.invalid) || (shippingForm.submitted && sCity.invalid)}"
                                         required minlength="1" maxlength="35"/>
                                         <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.cityLength && user.shippingDetail.city.length>35">{{errorMessageObj.cityLength}}</div>

                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'sCity' ">{{errorMessageObj.msg}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label for="">Land</label>

                                    <!-- <select class="form-control" name="sCountry"
                                        [(ngModel)]="user.shippingDetail.selectedCountry"
                                        #sCountry="ngModel"
                                        (ngModelChange)="changeCountryAndSetExtn($event, 'shippingForm')"
                                        [ngClass]="{ 'bg-danger': (sCountry.touched && sCountry.invalid) || (shippingForm.submitted && sCountry.invalid)}">
                                        <option *ngFor="let country of arrCountries"
                                            [ngValue]="country.country">{{country.country}}
                                        </option>
                                    </select> -->
                                    <!-- <code>
                                        <pre>{{arrCountries |json}}</pre>
                                    </code> -->


                                    <div class="select-box">
                                        <button class="btn btn-link ps-0" type="button" id="shippingCountryTag"
                                            data-flip="false" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <span *ngIf="user.shippingDetail.selectedCountry!=''">
                                                {{user.shippingDetail.selectedCountry}}
                                            </span>

                                            <span *ngIf="user.shippingDetail.selectedCountry==''"
                                                class="text-danger">{{'web.address_country' |
                                                translate }}</span>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="shippingCountryTag">
                                            <span class="dropdown-item" *ngFor="let country of arrCountries"
                                                (click)="changeCountryAndSetExtn(country.country, 'shippingForm')">
                                                {{country.country}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label for="">Vorwahl</label>
                                    <input type="text" placeholder="{{'web.address_phone_ext' | translate }}"
                                        class="form-control" name="phoneExt"
                                        readonly
                                        (paste)="preventPasteLeadingSpace($event)"
                                        [(ngModel)]="user.shippingDetail.phoneExtension" #phoneExt="ngModel"
                                        (keydown)="submitted=false;preventLeadingSpace($event, user.shippingDetail.phoneExtension);compareValues('shipping',{value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'phoneExtension'})"
                                        [attr.disabled]="true" required>
                                </div>
                                <div class="col-9 position-relative">
                                    <label for="">Telefon nummer</label>
                                    <input type="tel" class="form-control" name="phone"
                                        [(ngModel)]="user.shippingDetail.phoneNo" #phone="ngModel"
                                        (input)="submitted=false;preventLeadingSpace($event, user.shippingDetail.phoneNo);compareValues('shipping',{value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'phoneNo'})"
                                        placeholder="{{'web.address_pnone_no' | translate}}"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keyup)="checkPhone(phone.value)" (keydown.space)="$event.preventDefault();"
                                        pattern="^[1-9]\d*$"
                                        [required]="phoneRequired"
                                        [ngClass]="{ 'bg-danger': (phone.touched && phone.invalid) || (shippingForm.submitted && phone.invalid)}" minlength="0" maxlength="20"/>
                                        <!-- <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'phone' ">{{errorMessageObj.msg}}</div> -->
                                    <div *ngIf="(phone.errors && phone.invalid && (phone.dirty || phone.touched))"
                                        class="errorMsg text-danger">
                                        {{'web.register_val_invalid_phone' | translate }}
                                    </div>
                                    <!-- <div *ngIf="phoneRequired == true" class="errorMsg text-danger">
                                        {{'web.register_val_invalid_phone' | translate }}
                                    </div> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 position-relative">
                                    <label for="">E-Mail</label>
                                    <input type="email" name="sEmail" class="form-control"
                                        [(ngModel)]="user.shippingDetail.email" #sEmail="ngModel"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (input)="submitted=false;preventLeadingSpace($event, user.shippingDetail.email);compareValues('shipping',{value2:$event.target.value,trimValueKey1:'shippingDetail',trimValueKey2:'email'})"
                                        appEmailValidation="contentfulService.emailValidation()"
                                        [ngClass]="{ 'bg-danger': (user.shippingDetail.email==undefined || user.shippingDetail.email=='') || (sEmail.touched && sEmail.invalid) || (shippingForm.submitted && sEmail.invalid)}"
                                        placeholder="{{'web.register_field_label_email' | translate }}*"
                                        (blur)="user.shippingDetail.email = user.shippingDetail.email.toLowerCase()"
                                        required />
                                        <div class="text-danger errorMsg" *ngIf="showNonverified">
                                            {{'web.register_val_email_existing' | translate }}
                                            <span class="text-primary"
                                                (click)="showLogin()">{{'web.register_val_email_existing_login' |
                                                translate}}</span>
                                        </div>
                                        <div *ngIf="sEmail.errors && (sEmail.dirty || sEmail.touched) && sEmail.errors.invalidEmail"
                                            class="text-danger errorMsg">
                                            {{'web.register_val_invalid_email' | translate }}
                                        </div>
                                    <!-- <div *ngIf="sEmail.invalid && (sEmail.dirty || sEmail.touched)"
                                        class="errorMsg text-danger">
                                        {{'web.address_email' | translate }}
                                    </div> -->
                                </div>
                            </div>
                        </form>
                    </div>

                    <button class="btn btn-primary w-100 d-block btn-md" (click)="selectActiveAddress('pickup')"
                        *ngIf="pickupAddressDataLength != 0 && activeTab == 'shipping'">{{'web.mycart_select_pickup_location'
                        | translate }}</button>
                    <button class="btn btn-primary w-100 d-block btn-md" (click)="selectActiveAddress('shipping')"
                        *ngIf="addressBookData.length >0 && activeTab == 'pickup'">{{'web.mycart_select_shipping_address'
                        | translate }}</button>

                </div>
                <div class="col-sm-6 ps-25 shipping">
                    <h3 *ngIf="!billingAddressEditable">{{'web.checkout_address_billing_title' | translate }}</h3>

                    <div class="address-list dropdown" *ngIf="billingAddressEditable">
                        <!-- <button class="btn dropdown-toggle" type="button" data-flip="false" id="billingcollapseAddress"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            *ngIf="activeTab == 'shipping' || activeTab == 'pickup'">
                            <span>{{'web.checkout_address_billing_title' | translate }}</span>
                            <img src="{{searchIcon}}" alt=""
                                *ngIf="billingAddressData.length >0 && (activeTab == 'shipping' || activeTab == 'pickup')">
                            <img src="{{arrowDownIcon}}" alt=""
                                *ngIf="billingAddressData.length == 0 && (activeTab == 'shipping' || activeTab == 'pickup')">
                        </button> -->


                        <button class="btn" type="button"
                            *ngIf="billingAddressData.length == 0 && (activeTab == 'shipping' || activeTab == 'pickup')">
                            <span>{{'web.checkout_address_billing_title' | translate }}</span>
                            <img src="{{arrowDownIcon}}" alt="">
                        </button>

                        <button class="btn dropdown-toggle" type="button" data-flip="false" id="billingcollapseAddress"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            *ngIf="billingAddressData.length >0 && (activeTab == 'shipping' || activeTab == 'pickup')">
                            <span>{{'web.checkout_address_billing_title' | translate }}</span>
                            <img src="{{searchIcon}}" alt="">
                        </button>

                        <div class="dropdown-menu" aria-labelledby="billingcollapseAddress"
                            *ngIf="billingAddressData.length >0 && (activeTab == 'shipping' || activeTab == 'pickup')">
                            <form class="search-input">
                                <img src="{{searchIcon}}" alt="" title="">
                                <input type="email" class="form-control" #myInput name="search"
                                    [(ngModel)]="searchTextBilling" placeholder="{{'web.mycart_bill_address_search' | translate
                              }}" (ngModelChange)="filterAddressBookItem(myInput.value,true);focusInput(myInput)">
                            </form>
                            <span class="title pb-2"
                                (click)="openNewShippingAddressM(true)">{{'web.mycart_bill_address_dropdown_tit' | translate}}</span>
                            <div class="address-item" *ngFor="let address of arrBillingAddressBook; let indx = index">
                                <div (click)="selectAddress(indx,true)" style="cursor: pointer;">
                                    <p class="address-name">{{address.FirstName}} {{address.LastName}}</p>
                                    <p>{{address.Address1}} {{address.Address2}}, {{address.Zip}} {{address.City}},
                                        {{address.CountryName}}
                                    </p>
                                </div>
                                <img src="{{binIcon}}" alt="" title="" (click)="deleteAddress(indx,true)">
                            </div>
                        </div>
                    </div>

                    <div class="billing-detail-top" *ngIf="!isEnabledBillingForm">
                        <p>{{user.billingDetail.firstName}} {{user.billingDetail.lastName}}</p>
                        <p>{{user.billingDetail.company}}</p>
                        <p>{{user.billingDetail.street}} </p>
                        <p>{{user.billingDetail.houseNumber}}</p>
                        <p *ngIf="user.billingDetail.zip">{{user.billingDetail.zip}}</p>
                        <p>{{user.billingDetail.city}}</p>
                        <p>{{user.billingDetail.selectedCountry}}</p>
                        <p class="phoneNo">
                            <span *ngIf="!isShowPhoneInput">
                                {{user.billingDetail.phoneExtension}} {{user.billingDetail.phoneNo}}
                                <!-- <i class="fas fa-pen" ></i> -->
                                <img src="{{editProjectNameIcon}}" class="edit-icon" alt="" title=""
                                    (click)="showInput()">
                            </span>
                            <span class="edit-phone-no" *ngIf="isShowPhoneInput">{{user.billingDetail.phoneExtension}}
                                <input type="tel" class="form-control" name="phoneNo" #phoneNo="ngModel"
                                    [(ngModel)]="user.billingDetail.phoneNo"
                                    placeholder="{{'web.registration_phone_format_placeholder' | translate}}"
                                    (keyup)="checkPhoneBl(phoneNo.value)" (keydown.space)="$event.preventDefault();"
                                    pattern="^[1-9]\d*$" maxlength="13" minlength="8"
                                    [ngClass]="{ 'error': phoneNo.touched && phoneNo.invalid}">
                                <img src="{{okIcon}}" alt="" title="" (click)="editPhoneNumber(phoneNo.value)">
                            </span>
                        </p>
                        <p [hidden]="!showErrorMsg" class="text-danger">
                            {{'web.register_val_invalid_phone' | translate }}
                        </p>
                        <p>{{user.billingDetail.email}}</p>
                    </div>
                    <div *ngIf="isEnabledBillingForm" class="mt-5">
                       
                        <!-- SENTRY BUG FIXED  -->
                        <form class="form-horizontal" name="form1" (keyup)="billingCheckbox()"
                        (ngSubmit)="billingForm?.form1?.valid && submitAddress()" #billingForm="ngForm" novalidate>
                            <div class="checkbox-wrap position-absolute" *ngIf="shippingButtonEnable && (user.shippingDetail.gender && user.shippingDetail.firstName && user.shippingDetail.lastName && user.shippingDetail.street && user.shippingDetail.houseNumber && user.shippingDetail.city)" style="top: 39px;">
                                
                                <div class="checkbox" id="shippingSame" name="defaultCheckbox" (click)="checkedCheckboxSameAsShip(true,'shipping')">
                                    <input class="custom-checkbox mb-0" type="checkbox" id="billingCheckbox" value="option1">
                                    <label for="billingCheckbox">{{ 'web.checkout_address_same_as_shipping' | translate }}</label>
                                </div>

                            </div>
                            
                            <div class="row">
                                <div class="col-3">
                                    <div class="select-box" [ngClass]="{ 'pr':user.billingDetail.gender == ''}">
                                        <button class="border-0" type="button"
                                            id="genderBillingingTag" data-flip="false" data-bs-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false" [ngClass]="{ 'bg-danger':(user.billingDetail.gender == '')}">
                                            <span *ngIf="user.billingDetail.gender!=''">
                                                {{(user.billingDetail.gender == 'Male')?('web.address_gender_male' |
                                                translate):(user.billingDetail.gender == 'Female')?('web.address_gender_female' | translate):('web.address_gender_neutral' | translate)}}
                                            </span>
                                            <span *ngIf="user.billingDetail.gender == ''">{{'web.address_gender' |translate }}</span>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="genderBillingingTag">
                                            <span class="dropdown-item" *ngFor="let gender of getGender"
                                                (click)="changeGender(gender.gender, 'billingForm');submitted=false;">
                                                {{(gender.gender == 'Male')?('web.address_gender_male' |
                                                translate):(gender.gender == 'Female')?('web.address_gender_female' | translate):('web.address_gender_neutral' | translate)}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-9">
                                    <div class="row">
                                        <div class="col-6">
                                            <input type="text" class="form-control"
                                                placeholder="{{'web.address_fname' | translate }}*" name="firstName"
                                                (paste)="preventPasteLeadingSpace($event)"
                                                [(ngModel)]="user.billingDetail.firstName" #firstName="ngModel" required
                                                (keydown)="compareValues('billing',{type:'firstName',minimumLength:1,maximumLength:17,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'firstName'});preventLeadingSpace($event, user.billingDetail.firstName);"
                                                (input)="submitted=false;compareValues('billing',{type:'firstName',minimumLength:1,maximumLength:17,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'firstName'})"
                                                 disabled="{{isBillingDisabled}}"
                                                [ngClass]="{ 'bg-danger': (user.billingDetail.firstName==undefined || user.billingDetail.firstName=='') || (firstName.touched && firstName.invalid) || (billingForm.submitted && firstName.invalid)}" minlength="1" maxlength="17"/>
                                                <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.firstNameLength && user.billingDetail.firstName.length>17">{{errorMessageObj.firstNameLength}}</div>

                                                <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'firstName' ">{{errorMessageObj.msg}}</div>
                                        </div>
                                        <div class="col-6">
                                            <input type="text" class="form-control" name="lastName"
                                                disabled="{{isBillingDisabled}}"
                                                placeholder="{{'web.address_lname' | translate }}*" required
                                                (paste)="preventPasteLeadingSpace($event)"
                                                [(ngModel)]="user.billingDetail.lastName" #lastName="ngModel"
                                                (keydown)="compareValues('billing',{type:'lastName',minimumLength:1,maximumLength:17,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'lastName'});preventLeadingSpace($event, user.billingDetail.lastName);"
                                                (input)="submitted=false;compareValues('billing',{type:'lastName',minimumLength:1,maximumLength:17,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'lastName'})"
                                                [ngClass]="{ 'bg-danger':(user.billingDetail.lastName==undefined || user.billingDetail.lastName=='') || (lastName.touched && lastName.invalid) || (billingForm.submitted && lastName.invalid)}" minlength="1" maxlength="17"/>
                                                <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'lastName'">{{errorMessageObj.msg}}</div>
                                                <div class="text-danger fs-12 align-err-text" *ngIf="user.billingDetail.lastName.length>17 && errorMessageObj.lastnameLength">{{errorMessageObj.lastnameLength}}</div>

                                            </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <input type="text" class="form-control" [(ngModel)]="user.billingDetail.company"
                                        #company="ngModel" disabled="{{isBillingDisabled}}" name="company"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="compareValues('billing',{type:'company',minimumLength:2,maximumLength:50,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'company'});preventLeadingSpace($event, user.billingDetail.company);"
                                        (input)="submitted=false;compareValues('billing',{type:'company',minimumLength:2,maximumLength:50,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'company'})"
                                        placeholder="{{'web.address_company' | translate }}" minlength="2" maxlength="50"/>
                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'company'">{{errorMessageObj.msg}}</div>
                                        <div class="text-danger fs-12 align-err-text" *ngIf="user.billingDetail.company.length>50 && errorMessageObj.companyLength">{{errorMessageObj.companyLength}}</div>

                                        
                                    </div>
                            </div>
                            <div class="row">
                                <div class="col-9 3">
                                    <label for="">Strasse</label>
                                    <input type="road" class="form-control" [(ngModel)]="user.billingDetail.street"
                                        #street="ngModel" placeholder="{{'web.address_street' | translate }}*"
                                        name="street" disabled="{{isBillingDisabled}}"
                                        (keyup)="checkStreetBl(street.value)"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="compareValues('billing',{type:'street',minimumLength:1,maximumLength:35,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'street'});preventLeadingSpace($event, user.billingDetail.street);" 
                                        (input)="submitted=false;compareValues('billing',{type:'street',minimumLength:1,maximumLength:35,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'street'})"
                                        [ngClass]="{ 'bg-danger': (user.billingDetail.street==undefined || user.billingDetail.street=='') || (street.touched && street.invalid) || (billingForm.submitted && street.invalid)}"
                                        required minlength="1" maxlength="35"/>

                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'street'">{{errorMessageObj.msg}}</div>
                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.streetLength && user.billingDetail.street.length>35">{{errorMessageObj.streetLength}}</div>
                                        
                                    <div *ngIf="streetErrorBl" class="text-danger errorMsg">
                                        {{'web.register_street_house_val' | translate }}</div>
                                </div>
                                <div class="col-3">
                                    <label for="">Haus Nr.</label>
                                    <input type="text" class="form-control" name="houseNumber"
                                        [(ngModel)]="user.billingDetail.houseNumber" disabled="{{isBillingDisabled}}"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="preventLeadingSpace($event, user.billingDetail.houseNumber);compareValues('billing',{type:'houseNumber',minimumLength:1,maximumLength:10,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'houseNumber'})"
                                        (input)="submitted=false;compareValues('billing',{type:'houseNumber',minimumLength:1,maximumLength:10,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'houseNumber'})"
                                         #houseNumber="ngModel"
                                        (keyup)="checkHouseBL(houseNumber.value)"
                                        placeholder="{{'web.address_house' | translate }}"
                                        [ngClass]="{ 'bg-danger': (user.billingDetail.houseNumber==undefined || user.billingDetail.houseNumber=='') ||(houseNumber.touched && houseNumber.invalid) || (billingForm.submitted && houseNumber.invalid)}" minlength="1" maxlength="10"/>
                                        <div class="text-danger errorMsg" *ngIf="errorMessageObj.currentValue == 'houseNumber'">{{errorMessageObj.msg}}</div>
                                        <div class="text-danger fs-12 align-err-text" style="top: 32px" *ngIf="errorMessageObj.houseLength && user.billingDetail.houseNumber.length>10">{{errorMessageObj.houseLength}}</div>
                                    <!-- <span *ngIf="streetErrorBl" class="text-danger">
                                        {{'web.register_street_house_val' | translate }}</span> -->
                                </div>
                            </div>
                            <div class="row position-relative">
                                <div class="col-3">
                                    <label for="">PLZ</label>
                                    <input type="text" name="zip" [(ngModel)]="user.billingDetail.zip" #zip="ngModel"
                                        maxlength="{{charMaxLengthBl}}" (keyup)="countPostalCodeBl(zip.value)"
                                        disabled="{{isBillingDisabled}}"
                                        placeholder="{{'web.address_zip' | translate }}*"
                                        minlength="{{charMinLengthBl}}" class="form-control"
                                        required
                                        pattern="{{applyPatternBl}}"
                                        
                                        (keydown)="submitted=false;compareValues('billing',{value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'zip'})"
                                        [ngClass]="{ 'bg-danger': ((user.billingDetail.zip==undefined || user.billingDetail.zip=='')) || (zip.touched && zip.invalid) || (billingForm.submitted && zip.invalid)}"/>
                                        <!--
                                            this required attribute for zip input above 
                                            required="{{isZipRequiredBl}}" -->
                                    </div>
                                <div class="d-flex errorMsg">
                                    <span *ngIf="zip?.invalid && (zip?.dirty || zip?.touched) && zip?.errors?.pattern"
                                        class="text-danger me-2">
                                        {{'web.myprofile_zip_val_pattern_wrong' | translate }}
                                    </span>
                                    <span *ngIf="charLenIncorrectBl && zip.dirty" class="text-danger me-2">
                                        {{'web.myprofile_zip_val_min_charac' | translate }} {{charMinLengthBl}}
                                    </span>
                                    <span *ngIf="charLenIncorrectBl && zip.dirty" class="text-danger">
                                        {{'web.myprofile_zip_val_max_charac' | translate }} {{charMaxLengthBl}}
                                    </span>
                                </div>
                                <div class="col-9">
                                    <label for="">Ort</label>
                                    <input type="text" name="city" [ngClass]="text-danger"
                                        placeholder="{{'web.address_city' | translate }}*" class="form-control"
                                        [(ngModel)]="user.billingDetail.city" #city="ngModel"
                                        disabled="{{isBillingDisabled}}"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="preventLeadingSpace($event, user.billingDetail.city);compareValues('billing',{type:'city',minimumLength:1,maximumLength:35,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'city'})" 
                                        (input)="submitted=false;compareValues('billing',{type:'city',minimumLength:1,maximumLength:35,value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'city'})"
                                        [ngClass]="{ 'bg-danger': (user.billingDetail.city==undefined || user.billingDetail.city=='') || (city.touched && city.invalid) || (billingForm.submitted && city.invalid)}"
                                         required minlength="1" maxlength="35"/>
                                        
                                         <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.cityLength && user.billingDetail.city.length>35">{{errorMessageObj.cityLength}}</div>

                                         <div class="text-danger fs-12 align-err-text" *ngIf="errorMessageObj.currentValue == 'city' ">{{errorMessageObj.msg}}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label for="">Land</label>
                                    <!-- <select class="form-control" name="selectedCountry"
                                    [(ngModel)]="user.billingDetail.selectedCountry"
                                    #selectedCountry="ngModel"
                                    (ngModelChange)="changeCountryAndSetExtn($event, 'billingForm')"
                                    disabled="{{isBillingDisabled}}"
                                    [ngClass]="{ 'bg-danger': (selectedCountry.touched && selectedCountry.invalid) || (billingForm.submitted && selectedCountry.invalid)}">
                                    <option *ngFor="let country of arrOemCountries" [ngValue]="country.countryName">
                                        {{country.countryName}}
                                    </option>
                                </select> -->

                                    <div class="select-box">
                                        <button class="btn btn-link ps-0" type="button" id="billingCountryTag"
                                            data-flip="false" data-bs-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <!-- {{user.billingDetail.selectedCountry}} -->
                                            <span> {{user.billingDetail.selectedCountry}}</span>
                                            <span *ngIf="!user.billingDetail.selectedCountry"
                                                class="text-danger">{{'web.address_country' |
                                                translate }}</span>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="billingCountryTag">
                                            <span class="dropdown-item" *ngFor="let country of arrOemCountries"
                                                (click)="changeCountryAndSetExtn(country.countryName, 'billingForm')">
                                                {{country.countryName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <label for="">Vorwahl</label>
                                    <input type="text" placeholder="{{'web.address_phone_ext' | translate }}"
                                        class="form-control" name="phoneExtension" disabled="{{isBillingDisabled}}"
                                        [(ngModel)]="user.billingDetail.phoneExtension" #phoneExtension="ngModel"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="submitted=false;preventLeadingSpace($event, user.billingDetail.phoneExtension);compareValues('billing',{value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'phoneExtension'})" readonly required />
                                </div>
                                <!-- <div class="col-9 position-relative">
                                    <label for="">Telefon nummer</label>
                                    <input type="tel" class="form-control" name="phoneNo"
                                        [(ngModel)]="user.billingDetail.phoneNo" #phoneNo="ngModel"
                                        placeholder="{{'web.address_pnone_no' | translate}}"
                                        (keyup)="checkPhoneBl(phoneNo.value)" pattern="^[1-9]\d*$"
                                        (keydown.space)="$event.preventDefault();" maxlength="13" minlength="8" required
                                        disabled="{{isBillingDisabled}}"
                                        [ngClass]="{ 'bg-danger': (phoneNo.touched && phoneNo.invalid) || (billingForm.submitted && phoneNo.invalid)}" />
                                    <div *ngIf="phoneNo.invalid && (phoneNo.dirty || phoneNo.touched) && phoneNo.errors"
                                        class="errorMsg text-danger">
                                        {{'web.register_val_invalid_phone' | translate }}
                                    </div>
                                </div> -->

                                <div class="col-9 position-relative">
                                    <label for="">Telefon nummer</label>
                                    <input type="tel" class="form-control" name="phoneNo"
                                        [(ngModel)]="user.billingDetail.phoneNo" #phoneNo="ngModel"
                                        placeholder="{{'web.address_pnone_no' | translate}}"
                                        (keyup)="checkPhoneBl(phoneNo.value)" pattern="^[1-9]\d*$"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (input)="submitted=false;compareValues('billing',{value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'phoneNo'})"
                                         (keydown.space)="$event.preventDefault();"
                                        disabled="{{isBillingDisabled}}" minlength="0" maxlength="20"/>
                                        <div *ngIf="phoneNo.errors && phoneNo.invalid && (phoneNo.dirty || phoneNo.touched)"
                                        class="errorMsg text-danger">
                                        {{'web.register_val_invalid_phone' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 position-relative" *ngIf="!userTypeGuest">
                                    <label for="">E-Mail</label>
                                    <input type="email" name="email" class="form-control"
                                        [(ngModel)]="user.billingDetail.email" #email="ngModel"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        (keydown)="submitted=false;preventLeadingSpace($event, user.billingDetail.email);compareValues('billing',{value2:$event.target.value,trimValueKey1:'billingDetail',trimValueKey2:'email'})"
                                        placeholder="{{'web.address_email' | translate }}*" readonly required appEmailValidation="contentfulService.emailValidation()"/>
                                        <div class="text-12">
                                            <div class="error-msg" *ngIf="email.dirty || email.touched">
                                                <span class="text-danger" *ngIf="email.errors && email.errors.invalidEmail">
                                                    {{'web.register_val_invalid_email' | translate }}
                                                </span>
                    
                                            </div>
                    
                                            <!-- <div class="error-msg" *ngIf="errorMessage!=''">
                                                <span class="text-danger">
                                                    {{errorMessage}}
                                                </span>
                                            </div> -->
                                        </div>
                                </div>
                                <div class="col-12 position-relative" *ngIf="userTypeGuest">
                                    <label for="">E-Mail</label>
                                    <input type="email" name="billingEmail" class="form-control"
                                        [(ngModel)]="user.billingDetail.email" #billingEmail="ngModel"
                                        (ngModelChange)="showBuyNowButton=true;submitted=false"
                                        appEmailValidation="contentfulService.emailValidation()"
                                        (paste)="preventPasteLeadingSpace($event)"
                                        placeholder="{{'web.address_email' | translate }}*" required
                                        [ngClass]="{ 'bg-danger': error1 || (user.billingDetail.email==undefined || user.billingDetail.email=='') || (billingEmail.touched && billingEmail.invalid) || (billingForm.submitted && billingEmail.invalid)}"
                                        (keyup)="checkEmailExist(billingEmail.value)">
                                    <!-- <div class="text-danger" *ngIf="billingEmail.invalid && (billingEmail.dirty || billingEmail.touched) || error1">
                                        {{'web.register_val_email_existing' | translate }}
                                    </div>
                                    <div *ngIf="billingEmail.invalid && (billingEmail.dirty || billingEmail.touched) && billingEmail.errors.pattern" class="text-danger">
                                        {{'web.register_val_invalid_email' | translate }}
                                    </div> -->
                                    <div class="text-danger errorMsg" *ngIf="showNonverified">
                                        {{'web.register_val_email_existing' | translate }}
                                        <span class="text-primary"
                                            (click)="showLogin()">{{'web.register_val_email_existing_login' |
                                            translate}}</span>
                                    </div>
                                    <div *ngIf="billingEmail.invalid && (billingEmail.dirty || billingEmail.touched) && billingEmail.errors && billingEmail.errors.invalidEmail"
                                        class="text-danger errorMsg">
                                        {{'web.register_val_invalid_email' | translate }}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <p class="userTypeGuest" *ngIf="!userTypeGuest">{{'web.mycart_billing_address_info_msg' |
                            translate}}
                        </p>
                    </div>
                    <div class="discription" *ngIf="!isShowCheckbox">{{'web.mycart_contact_customer_service_msg' |
                        translate}}
                        <a routerLink="/{{contactusUrl}}">{{'web.mycart_contact_us' | translate}}</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="payment" id="payment-section">
            <div class="row g-0 w-100">
                <div class="col-sm-6 address pt-sm-3">
                    <!-- <div *ngIf="countryObj">
                        {{countryObj.shippingOptions.length}}
                        {{displayShippingOption}}

                    </div> -->
                   
                    <div class="shipping-actions"
                        *ngIf="countryObj && countryObj.shippingOptions && displayShippingOption">
                        <h3>{{'web.shipping_action_title' | translate }}</h3>
                        <div class="checkbox-wrap">
                           

                            <!-- <pre>   {{countryObj.shippingOptions |json}}</pre>    -->
                            <div class="checkbox mb-2" *ngFor="let type of countryObj.shippingOptions;let i=index;"
                                [hidden]="showOnlyCommonShipping(type.type)">

                                <input type="checkbox" id="{{type.type}}" name="{{type.type}}"
                                    (ngModelChange)="changeShippingType(type,countryObj.shippingOptions,i)"
                                    [disabled]="type.selected ||showLoader" [(ngModel)]="type.selected" />
                                <!-- <label for="{{type.type}}">{{type.languageKey}}</label> -->
                                <label for="{{type.type}}" innerHtml="{{type.languageKey}}"></label>
                            </div>
                        </div>
                    </div>

                    <div class="coupone-box w-100" *ngIf="!couponDisabled"> 
                        <h3>{{'web.checkout_promo_code'| translate}}</h3>

                        <form [formGroup]="coupanForm" (submit)="applyCouponCode(coupanForm.value)">
                            <div formArrayName="coupans" *ngFor="let coupan of coupans.controls; index as i">
                                <!-- <pre>{{coupan.value |json}}</pre>                             -->
                                <div class="coupan-Box position-relative" [formGroupName]="i">
                                    <input class="form-control" type="text" formControlName="coupanDetails"
                                        name="couponCode-{{i}}" id="couponCode-{{i}}" [readonly]="isCouponApply"
                                        placeholder="{{'web.checkout_promo_code'| translate}}"
                                        (blur)="$event.target.value = $event.target.value.split(' ').join('')"
                                        [ngClass]="{'error': isValidCouponInput}" (keyup)="onKey($event,i);">
                                    <!-- [ngClass]="(showInputPlusSignImageOnTextLentgh >=3 ) ? 'd-block' : 'd-none'" -->
                                    <img id="couponPlusId-{{i}}"
                                        *ngIf="multyCoupon && coupan.showInputPlusSignImage && !isCouponApply"
                                        (click)="addCoupanInputField(i)"
                                        style="position:absolute;right: 10px;top: 50%;transform: translateY(-50%);"
                                        src="{{checkmarkPlusWht16}}" alt=""
                                        class="bg-primary rounded-circle cursor-pointer">

                                    <img id="couponMinusId-{{coupan}}"
                                        *ngIf="multyCoupon && coupan.showInputMinusSignImage && !isCouponApply"
                                        (click)="removeCoupanInputField(i)"
                                        style="position:absolute;right: 30px;top: 50%;transform: translateY(-50%);"
                                        src="{{checkmarkMinusWht16}}" alt=""
                                        class="bg-primary rounded-circle cursor-pointer">
                                </div>
                            </div>

                            <button class="btn btn-primary w-100 d-block couponBtn"
                                [disabled]="showPaperSelectError ||disableCoupon ||clickedCoupon"
                                *ngIf="!isValidCoupon">{{'web.checkout_cta_apply'| translate}}
                                <span *ngIf="couponValidation"><img src="{{imageIconPath}}small-loader.gif" alt=""
                                        title=""></span>
                            </button>
                            <button class="btn btn-primary w-100 d-block" [disabled]="disableCoupon" *ngIf="isValidCoupon"
                                (click)="changeCoupon(true)">{{'web.checkout_cta_remove'| translate}}</button>
                        </form>

                        <!-- <input class="form-control" type="text" id="couponCode" [(ngModel)]="enteredCouponCode"
                            [disabled]="isValidCoupon || disableCoupon" placeholder="{{'web.checkout_promo_code'| translate}}"
                            [ngClass]="{'error': isValidCouponInput}" (keyup)="onKey()">
                        <button class="btn btn-primary w-100 d-block couponBtn" [disabled]="disableCoupon" *ngIf="!isValidCoupon"
                            (click)="applyCouponCode(enteredCouponCode)">{{'web.checkout_cta_apply'|
                            translate}}
                            <span *ngIf="couponValidation"><img src="{{imageIconPath}}small-loader.gif" alt="" title=""></span>
                        </button>
                        <button class="btn btn-primary w-100 d-block"  [disabled]="disableCoupon" *ngIf="isValidCoupon"
                            (click)="changeCoupon(true)">{{'web.checkout_cta_remove'| translate}}</button> -->

                        <div *ngIf="enteredCouponCode.length>0">
                            <p class="caption text-danger" *ngIf="showErrorMessage">{{errorMessage}}</p>
                            <p class="caption text-success">{{successMessage}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6 ps-25">
                    <div class="payment-method">
                        <h3>{{'web.mycart_order_summary'| translate}}</h3>
                        <div class="payment-list">
                            <div class="payment-text f-16 mb-2 d-flex">
                                <span>{{'web.checkout_product_sub_total' | translate}}</span>
                                <span class="ms-auto" *ngIf="!showPaperSelectError">{{ getCommaOrDotValue(grandTotal)
                                    }}</span>
                            </div>
                            <div class="payment-text f-16 mb-2 d-flex">
                                <span>{{'web.mycart_shipping' | translate}}</span>
                                <span class="ms-auto"> {{getCommaOrDotValue(totalShippinCost||0)}}</span>
                            </div>
                            <div class="payment-text d-flex" *ngIf="isValidCoupon && couponCodePrice != null">
                                <span> {{'web.checkout_sub_total_disc'| translate}}</span>
                                <span class="text-danger ms-auto"> - {{ getCommaOrDotValue(totalProductDiscont)}}</span>
                            </div>
                            <div class="payment-text d-flex"
                                *ngIf="isValidCoupon && totalShippingDiscont && totalShippingDiscont != null && totalShippingDiscont != '0'">
                                <span> {{'web.order_shipping_discount'| translate}}</span>
                                <span class="text-danger ms-auto">- {{ getCommaOrDotValue(totalShippingDiscont)}}</span>
                            </div>
                            <div class="payment-text d-flex"
                                *ngIf="isShowDiscountMsg && shippingDiscAmount && shippingDiscAmount != undefined && shippingDiscAmount != 0">
                                <span>{{'web.mycart_pickup_shipping_discount'| translate}}</span>
                                <span class="text-danger ms-auto"> - {{ getCommaOrDotValue(shippingDiscAmount)}}</span>
                            </div>

                            <div class="payment-text d-flex"
                                *ngIf="isValidCoupon && totalHandlingDiscont && totalHandlingDiscont != null && totalHandlingDiscont != '0'">
                                <span> {{'web.order_handling_discount'| translate}}</span>
                                <span class="text-danger ms-auto">- {{ getCommaOrDotValue(totalHandlingDiscont)}}</span>
                            </div>
                        </div>
                        <div class="final-price d-flex align-items-center justify-content-between">
                            <span class="final-text w-50 fw-bold">{{'web.checkout_grand_total' |
                                translate}}</span>

                               
                            <span class="final-value text-end w-50 fw-bold" *ngIf="!showPaperSelectError">{{
                                getCommaOrDotValue(grandTotalAfterShipping) }}</span>



                            <span class="text-danger text-end w-50" *ngIf="showPaperSelectError"
                                (click)="photoBookPrice()">{{'web.my_cart_select_options_total' | translate}}</span>

                        </div>

                        <div [hidden]="hideTaxInfo">
                            <div class="payment-list mb-2" *ngIf="cartVatData">
                                <div class="payment-text d-flex fw-bold" *ngIf="cartVatData['sumOfVat'] ">
                                    <span>{{'web.checkout_vat_sum_total' | translate}}</span>
                                    <span class="ms-auto">{{
                                        cartVatData['sumOfVat']}}</span>
                                </div>
                                <ng-container *ngIf="cartVatData && cartVatData.items">
                                    <ng-container *ngFor="let item of cartVatData.items">

                                        <div class="payment-text d-flex" *ngIf="item.show">
                                            <span>{{item.label}}</span>
                                            <span class="ms-auto">
                                                {{item.priceInFormat}}</span>
                                        </div>
                                    </ng-container>

                                </ng-container>
                            </div>
                        </div>



                        <!-- <div class="final-price d-flex align-items-center justify-content-between">
                            <span class="final-text w-50 fw-bold">{{'web.checkout_vat_sum_total' |
                                translate}}</span>
                            <span class="final-value text-end w-50 fw-bold"
                                *ngIf="cartVatData && cartVatData['sumOfVat'] ">{{
                                cartVatData['sumOfVat']}}</span>
                            <span class="final-text w-50 fw-bold">{{'web.checkout_product_vat' |
                                translate}}</span>
                            <span class="final-value text-end w-50 fw-bold"
                                *ngIf="cartVatData && cartVatData['sumOfVat'] ">{{
                                cartVatData['productVat']}}</span>
                            <span class="final-text w-50 fw-bold">{{'web.checkout_shipping_vat' |
                                translate}}</span>
                            <span class="final-value text-end w-50 fw-bold"
                                *ngIf="cartVatData && cartVatData['sumOfVat'] ">{{
                                cartVatData['shippingVat']}}</span>
                        </div>  -->

                        <div class="checkbox-wrap">
                            <div class="checkbox" *ngIf="termsDateValue==false && languageData && languageData.web && languageData.web.registration_popup_nolinks_terms_conditions">
                                <label>
                                    {{ 'web.registration_popup_nolinks_terms_conditions' | translate }}
                                </label> 
                            </div>
                            <div class="checkbox">
                                <input type="checkbox" id="checkout_agb" [(ngModel)]="agbChecked"
                                    (ngModelChange)="checkAgb(agbChecked)" />
                                <label for="checkout_agb" innerHtml="{{AGBTagHtml}}"></label>
                            </div>

                            <div class="checkbox mt-2" *ngIf="privacyPolicyTagHtml">
                                <input type="checkbox" id="privacy-policy" [(ngModel)]="privacyPolicyChecked"
                                    (ngModelChange)="checkPrivacyPolicy(privacyPolicyChecked)" />
                                <label for="privacy-policy" innerHtml="{{privacyPolicyTagHtml}}"></label>
                            </div>

                            <div class="text-danger fs-14 position-absolute" *ngIf="isNoCheckBoxSelected || isNoPrivacyBoxSelected">
                                {{'web.checkout_checkbox_selection_warning'| translate}} </div>
                        </div>

                        <!-- <div class="text-danger" *ngIf="!shippingType">
                            {{'web.checkout_shippingType_selection_warning'| translate}}
                        </div> -->
                        <div class="action">
                            <div class="checkout-note" *ngIf="!isPaymentChecked">
                                <span class="d-block mt-4">{{'web.checkout_agb_desc'| translate}}</span>
                                
                                <div class="badge-wrapper position-relative">
                                    <ng-container *ngIf="getFirstInactiveBadge() as item">
                                      <img
                                        class="position-absolute"
                                        crossorigin="anonymous"
                                        (mouseleave)="showTooltip = false"
                                        (mouseenter)="showTooltip = true"
                                        [src]="badgeImagePath + item.badgeNameInactive"
                                        alt=""
                                      />
                                      <span class="text-danger me-3 text-christmas">
                                        {{ item.badgeDescriptionInactive | translate }}
                                      </span>
                                    </ng-container>
                                  </div>
                                   
                                 <!-- commented on 23-12-22 -->

                            </div>

                            <div id="checkout-button" class="checkout-button" *ngIf="!submitted ||priceCalculationError || buyNowDisabled || (submitted && addressChanged )||((submitted || !submitted|| showNonverified || showBuyNowButton) && (((!isPaymentChecked || (grandTotalAfterShipping == 0) ||(enteredCouponCode != '' && !isCouponApply))
                                
                                
                                ||   (( this.shippingForm.invalid || (this.user.shippingDetail.gender == '' || this.user.shippingDetail.gender == undefined || this.user.shippingDetail.gender == null) 
         
                                || this.shippingForm.invalid || (this.user.shippingDetail.firstName == '' || this.user.shippingDetail.firstName == undefined || this.user.shippingDetail.firstName == null) 
                               
                                || this.shippingForm.invalid || (this.user.shippingDetail.lastName == '' || this.user.shippingDetail.lastName == undefined || this.user.shippingDetail.lastName == null) 
                               
                                || this.shippingForm.invalid || (this.user.shippingDetail.street == '' || this.user.shippingDetail.street == undefined || this.user.shippingDetail.street == null) 
                               
                                || this.shippingForm.invalid ||(this.user.shippingDetail.email == '' || this.user.shippingDetail.email == undefined || this.user.shippingDetail.email == null) 
                               
                                || this.shippingForm.invalid ||((this.charMinLength != 0 && this.charMaxLength != 0) && !this.user.shippingDetail.zip)
                                ||       
                                (        
                                   (this.isEnabledBillingForm &&   ( this.billingForm.invalid || this.user.billingDetail.gender == '' || this.user.billingDetail.gender == undefined || this.user.billingDetail.gender == null)) 
                                  || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.firstName == '' || this.user.billingDetail.firstName == undefined || this.user.billingDetail.firstName == null)) 
                                  || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.lastName == '' || this.user.billingDetail.lastName == undefined || this.user.billingDetail.lastName == null)) 
                                  || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.street == '' || this.user.billingDetail.street == undefined || this.user.billingDetail.street == null)) 
                                  || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.email == '' || this.user.billingDetail.email == undefined || this.user.billingDetail.email == null)) 
                                  || (this.isEnabledBillingForm &&  ( this.billingForm.invalid ||(this.charMinLengthBl != 0 && this.charMaxLengthBl != 0) && !this.user.billingDetail.zip)) 
                              )
                              ))))|| (isNoCheckBoxSelected || isNoPrivacyBoxSelected) ||disableCoupon )  ">

                                <ng-container *ngIf="shippingForm !='undefined'">

                                    <button class="btn btn-primary w-100 d-block btn-md mt-0 1" (click)="submitAddress()" [disabled]="showNonverified|| isSavingCartData ||priceCalculationError|| buyNowDisabled  || showOnProcess || showLoader|| user.shippingDetail.gender==undefined || user.shippingDetail.gender=='' ||
                                        user.shippingDetail.selectedCountry==undefined || user.shippingDetail.selectedCountry=='' ||

                                        this.shippingForm &&(
                                        (( this.shippingForm.invalid || (this.user.shippingDetail.gender == '' || this.user.shippingDetail.gender == undefined || this.user.shippingDetail.gender == null) 
            
                                        || this.shippingForm.invalid || (this.user.shippingDetail.firstName == '' || this.user.shippingDetail.firstName == undefined || this.user.shippingDetail.firstName == null) 
                                    
                                        || this.shippingForm.invalid || (this.user.shippingDetail.lastName == '' || this.user.shippingDetail.lastName == undefined || this.user.shippingDetail.lastName == null) 
                                    
                                        || this.shippingForm.invalid || (this.user.shippingDetail.street == '' || this.user.shippingDetail.street == undefined || this.user.shippingDetail.street == null) 
                                    
                                        || this.shippingForm.invalid || (this.user.shippingDetail.email == '' || this.user.shippingDetail.email == undefined || this.user.shippingDetail.email == null) 
                                    
                                        || this.shippingForm.invalid || ((this.charMinLength != 0 && this.charMaxLength != 0) && !this.user.shippingDetail.zip))
                                        ||  
                                        this.billingForm &&     
                                        (        
                                        (this.isEnabledBillingForm &&   ( this.billingForm.invalid || this.user.billingDetail.gender == '' || this.user.billingDetail.gender == undefined || this.user.billingDetail.gender == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.firstName == '' || this.user.billingDetail.firstName == undefined || this.user.billingDetail.firstName == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.lastName == '' || this.user.billingDetail.lastName == undefined || this.user.billingDetail.lastName == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.street == '' || this.user.billingDetail.street == undefined || this.user.billingDetail.street == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.email == '' || this.user.billingDetail.email == undefined || this.user.billingDetail.email == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || (this.charMinLengthBl != 0 && this.charMaxLengthBl != 0) && !this.user.billingDetail.zip)) 
                                        )
                                        ))
                                        ">
                                        {{'web.checkout_cta_order_now'| translate}}
                                        <!-- <span class="spinner-border" *ngIf="isSavingCartData ||showOnProcess" role="status" aria-hidden="true"></span> -->
                                        <img src="{{imageIconPath}}small-loader.gif" alt="loader" *ngIf="isSavingCartData ||showOnProcess">
                                    </button>

                                    <span class="cursor-pointer text-center d-block paymentInfoBtn" data-bs-toggle="modal"  data-bs-target="#paymetMethodInfo" *ngIf="languageData && languageData.web && languageData.web.payment_method_info_text"> 
                                        <pre class="mb-0 mt-2">{{'web.payment_method_info_text' | translate}}</pre>
                                        <img src="{{assetIconPath}}cart_payment_icon_ad.png" alt="paymentMethod">
                                    </span>

                                </ng-container>

                                <span class="text-danger" *ngIf="
                                
                                (shippingForm && (this.shippingForm.invalid || (this.shippingForm.invalid && this.charMinLength!= 0 && this.charMaxLength != 0) && !this.user.shippingDetail.zip
                                ))
                                
                                ||(billingForm && isEnabledBillingForm &&  (isEnabledBillingForm  && (this.billingForm.invalid || this.error1) ||( charMinLengthBl != 0 && charMaxLengthBl != 0 && !this.user.billingDetail.zip)))
                                ">
                                    {{'web.cart_form_invalid_message'| translate}}
                                </span>

                                <!-- <span class="text-danger" *ngIf="buyNowDisabled && !
                                (shippingForm && (this.shippingForm.invalid || (this.shippingForm.invalid && this.charMinLength!= 0 && this.charMaxLength != 0) && !this.user.shippingDetail.zip
                                ))
                                
                                ||(billingForm && isEnabledBillingForm &&  (isEnabledBillingForm  && (this.billingForm.invalid || this.error1) ||( charMinLengthBl != 0 && charMaxLengthBl != 0 && !this.user.billingDetail.zip)))
                                ">
                                    {{'web.cart_product_na_message'| translate}}
                                </span> -->
                            </div>

                           

                            <div class="payment-options" *ngIf="isPaymentChecked && 
                            (grandTotalAfterShipping != 0) &&!disableCoupon    && submitted   && !buyNowDisabled   && !priceCalculationError">


                                <div *ngIf="((enteredCouponCode != '' && isCouponApply) ||(enteredCouponCode == '' && !isCouponApply)) && !showNonverified && !showBuyNowButton && !addressChanged &&
            
                                    !(( this.shippingForm.invalid || (this.user.shippingDetail.gender == '' || this.user.shippingDetail.gender == undefined || this.user.shippingDetail.gender == null) 
                                    
                                    || this.shippingForm.invalid || (this.user.shippingDetail.firstName == '' || this.user.shippingDetail.firstName == undefined || this.user.shippingDetail.firstName == null) 
                                    
                                    || this.shippingForm.invalid || (this.user.shippingDetail.lastName == '' || this.user.shippingDetail.lastName == undefined || this.user.shippingDetail.lastName == null) 
                                    
                                    || this.shippingForm.invalid || (this.user.shippingDetail.street == '' || this.user.shippingDetail.street == undefined || this.user.shippingDetail.street == null) 
                                    
                                    || this.shippingForm.invalid ||(this.user.shippingDetail.email == '' || this.user.shippingDetail.email == undefined || this.user.shippingDetail.email == null) 
                                    
                                    || this.shippingForm.invalid ||((this.charMinLength != 0 && this.charMaxLength != 0) && !this.user.shippingDetail.zip)
                                    ||       
                                    (        
                                        (this.isEnabledBillingForm &&   ( this.billingForm.invalid || this.user.billingDetail.gender == '' || this.user.billingDetail.gender == undefined || this.user.billingDetail.gender == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.firstName == '' || this.user.billingDetail.firstName == undefined || this.user.billingDetail.firstName == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.lastName == '' || this.user.billingDetail.lastName == undefined || this.user.billingDetail.lastName == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.street == '' || this.user.billingDetail.street == undefined || this.user.billingDetail.street == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid || this.user.billingDetail.email == '' || this.user.billingDetail.email == undefined || this.user.billingDetail.email == null)) 
                                        || (this.isEnabledBillingForm &&  ( this.billingForm.invalid ||(this.charMinLengthBl != 0 && this.charMaxLengthBl != 0) && !this.user.billingDetail.zip)) 
                                    )
                                    ))">

                                    <div class="checkout-note">{{'web.checkout_payment_options'| translate}}</div>

                                    <div class="text-center" *ngIf="hidePaymentOptions && paymentProcessingLoader">
                                        <img src="{{imageIconPath}}pf_loader.gif" alt="loaderImg">
                                    </div>

                                    <div disabled="isProcessing" [hidden]="hidePaymentOptions">
                                        <div (click)="adyenCheckout()" id="openModalButton" data-bs-toggle="modal"
                                            data-bs-target="#other_payment_popup" *ngIf="dispalyAdyenBtn"
                                            class="btn btn-warning w-100 d-block btn-md">
                                            {{'web.checkout_payment_others_options_btn'| translate}}

                                            <span class="spinner-border spinner-border-sm" role="status"
                                                *ngIf="isProcessing && currentPaymentType == 'Adyen'"
                                                aria-hidden="true"></span>
                                        </div>

                                        <!-- <div class="adyenCheckout btn w-100 d-block shadow-none" (click)="adyenCheckout()"
                                            *ngIf="dispalyAdyenBtn">
                                            <p>{{'web.my_cart_payment_icon_tit'| translate}}</p>
                                            <img [src]="adyenBtnImagePath" alt="" title="">
                                        </div> -->

                                        <div class="cursor-pointer text-center d-block" *ngIf="languageData && languageData.web && languageData.web.payment_method_info_text && dispalyAdyenBtn" data-bs-toggle="modal"  data-bs-target="#paymetMethodInfo"> 
                                            <pre class="mb-0 mt-2">{{'web.payment_method_info_text' | translate}}</pre>
                                            <img src="{{assetIconPath}}cart_payment_icon_ad.png" alt="paymentMethod">
                                        </div>

                                        <div (click)="openStripeModal()" *ngIf="dispalyStripeBtn">
                                            <div id="openModalButton" class="btn mt-3 btn-warning w-100 d-block btn-md">
                                                {{'web.checkout_stripe_payment_cta'| translate}}

                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    *ngIf="isProcessing && currentPaymentType == 'Stripe'"
                                                    aria-hidden="true"></span>
                                            </div>

                                            <div class="adyenCheckout btn w-100 d-block shadow-none">
                                                <img [src]="stripeBtnImagePath" alt="" title="">
                                                <p>{{'web.my_cart_payment_stripe_icon_tit'| translate}}</p>
                                            </div>
                                        </div>

                                        <div *ngIf="dispalySepaBtn" class="mt-3">
                                            <button type="button" class="btn btn-warning w-100 d-block btn-md"
                                                (click)="openSepaModal()"> {{'web.checkout_sepa_payment_cta' |
                                                translate}}
                                            </button>
                                            <span class="spinner-border spinner-border-sm" role="status"
                                                *ngIf="showSepaLoader && currentPaymentType == 'Paygate'"
                                                aria-hidden="true"></span>
                                        </div>


                                        <div [hidden]="!dispalyPaypalBtn" disabled="isProcessing" class="mt-3">
                                            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
                                        </div>
                                        <div (click)="openInShopModal()" *ngIf="dispalyInShopBtn && isPickupLocAddress"
                                            class="btn btn-primary w-100 d-block btn-md openInShop">
                                            {{'web.checkout_payment_inshop_btn'| translate}}

                                        </div>
                                        <div *ngIf="dispalyInvoiceBtn && !isPickupLocAddress"
                                            class="btn btn-primary w-100 d-block btn-md mt-3" (click)="openInvoiceModal()">

                                            {{'web.checkout_payment_invoice_btn'| translate}}
                                        </div>



                                        <div *ngIf="dispalyPrepaymentBtn && !isPickupLocAddress"
                                            class="btn btn-warning w-100 d-block btn-md mt-3"
                                            (click)="openInvoiceModal(true)">

                                            {{'web.checkout_pre_payment_btn'| translate}}
                                        </div>


                                    </div>

                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>

<!--Stripe starts-->
<div class="modal fade" id="stripeModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header bg-transparent">
                <p class="modal-title my-0">{{'web.stripe_pay_popup_title' | translate}}
                </p>
                <img class="d-block mb-0 cursor-pointer closeModal" *ngIf="!showStripeLoader"
                    (click)="closeStripeModal()" src="{{popupCrossCheckmark}}">
            </div>
            <form novalidate #myStripeElement *ngIf="dispalyStripeBtn" [formGroup]="stripeTest"
                (ngSubmit)="createToken()">
                <div class="modal-body py-3 border-0">
                    <div id="card-element" class="field mt-2 mb-4"></div>
                    <input type="text" required formControlName="name" class="w-100"
                        placeholder="{{'web.stripe_pay_popup_name_placeholder' | translate}}">

                    <span class="text-danger me-2 d-flex stripeerrorMsg mt-2" *ngIf="failureStripeMessage">
                        {{failureStripeMessage }}
                    </span>
                    <button type="submit" [disabled]="showStripeLoader" class="btn btn-md mt-2 w-100 btn-primary">
                        {{'web.stripe_pay_submit_cta'| translate}}
                        <span *ngIf="showStripeLoader" class="position-absolute ms-2"><img
                                src="{{imageIconPath}}small-loader.gif" width="20"></span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<!--Stripe ends-->

<!-- Start sepa payment -->

<div class="modal sepaPayment" id="sepaPayment" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header border-0 bg-transparent">
                <p class="modal-title my-0">{{'web.sepa_pay_popup_title' | translate}}</p>
                <div class="row">
                    <span class="text-danger me-2 d-flex sepaerrorMsg mt-2" *ngIf="failureMessage">
                        {{failureMessage }}
                    </span>
                </div>

                <img class="d-block mb-0 cursor-pointer closeModal" *ngIf="!showSepaLoader" (click)="closeSepaModal();"
                    src="{{popupCrossCheckmark}}">
            </div>

            <!-- Modal body -->
            <div class="modal-body px-3 border-0 pt-0">
                <div class="shippingForm" *ngIf="showSepaPopup">
                    <form (ngSubmit)="sepaPaymentSubmit()" #sepaForm="ngForm" name="form" novalidate>
                        <div class="row">
                            <div class="col-6">
                                <input class="form-control"
                                    placeholder="{{'web.register_field_label_fname' | translate }}*" name="fName"
                                    (keypress)="restrictQuotes($event)"
                                    (paste)="preventPaste($event)"
                                    [(ngModel)]="sepaFormFields.firstName" #fName="ngModel" required
                                    (ngModelChange)="failureMessage=false;"
                                    [ngClass]="{ 'bg-danger': (fName.touched && fName.invalid) || (sepaForm.submitted && fName.invalid)}">
                            </div>
                            <div class="col-6">
                                <input type="text" class="form-control" name="lName"
                                    placeholder="{{'web.register_field_label_lname' | translate }}*" required
                                    [(ngModel)]="sepaFormFields.lastName" #lName="ngModel"
                                    (ngModelChange)="failureMessage=false;"
                                    [ngClass]="{ 'bg-danger': (lName.touched && lName.invalid) || (sepaForm.submitted && lName.invalid)}">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-9 1">
                                <label for="">Strasse</label>
                                <input type="road" name="sStreet" class="form-control"
                                    [(ngModel)]="sepaFormFields.street" #sStreet="ngModel"
                                    placeholder="{{'web.register_field_label_street' | translate }}*"
                                    (ngModelChange)="failureMessage=false;" (keyup)="checkStreet(sStreet.value)"
                                    [ngClass]="{ 'bg-danger': (sStreet.touched && sStreet.invalid) || (sepaForm.submitted && sStreet.invalid) || streetError}"
                                    required />
                                <div *ngIf="streetError" class="text-danger position-absolute fs-12">
                                    {{'web.register_street_house_val' | translate }}
                                </div>
                            </div>
                            <div class="col-3">
                                <label for="">Haus Nr.</label>
                                <input type="text" class="form-control" name="houseno"
                                    [(ngModel)]="sepaFormFields.houseNumber" #houseno="ngModel"
                                    (ngModelChange)="failureMessage=false;"
                                    placeholder="{{'web.register_field_label_house' | translate }}"
                                    (keyup)="checkHouse(houseno.value)"
                                    [ngClass]="{ 'bg-danger': (houseno.touched && houseno.invalid) || (sepaForm.submitted && houseno.invalid)}"
                                    required />
                                <div *ngIf="houseno.dirty && streetError" class="text-danger">
                                    {{'web.register_street_house_val' | translate }}
                                </div>
                            </div>
                        </div>

                        <div class="row position-relative" *ngIf="charMaxLengthSP != 0 && charMinLengthSP != 0">
                            <div class="col-3">
                                <label for="">PLZ</label>
                                <input type="text" name="sZip" [(ngModel)]="sepaFormFields.zip" class="form-control"
                                    #sZip="ngModel" maxlength="{{charMaxLengthSP}}"
                                    (keyup)="countPostalCodeBl(sZip.value,true)" (ngModelChange)="failureMessage=false;"
                                    placeholder="{{'web.register_field_label_zip' | translate }}*"
                                    minlength="{{charMinLengthSP}}" class="form-control" required="{{isZipRequiredSP}}"
                                    pattern="{{applyPattern}}"
                                    [ngClass]="{ 'bg-danger': (sZip.touched && sZip.invalid) || (sepaForm.submitted && sZip.invalid)}">
                            </div>

                            <div class="d-flex errorMsg">
                                <span *ngIf="sZip.invalid && (sZip.dirty || sZip.touched) && sZip.errors.pattern"
                                    class="text-danger me-2">
                                    {{'web.myprofile_zip_val_pattern_wrong' | translate }}
                                </span>
                                <span *ngIf="charLenIncorrectSP && sZip.dirty" class="text-danger me-2">
                                    {{'web.myprofile_zip_val_min_charac' | translate }} {{charMinLengthSP}}
                                </span>
                                <span *ngIf="charLenIncorrectSP && sZip.dirty" class="text-danger">
                                    {{'web.myprofile_zip_val_max_charac' | translate }} {{charMaxLengthSP}}
                                </span>
                            </div>
                            <div class="col-9">
                                <label for="">Ort</label>
                                <input type="text" name="sCity"
                                    placeholder="{{'web.register_field_label_city' | translate }}*" class="form-control"
                                    [(ngModel)]="sepaFormFields.city" #sCity="ngModel"
                                    (ngModelChange)="failureMessage=false;"
                                    [ngClass]="{ 'bg-danger': (sCity.touched && sCity.invalid) || (sepaForm.submitted && sCity.invalid)}"
                                    maxlength="30" required>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <label for="">Land</label>

                                <div class="select-box">
                                    <button class="btn btn-link ps-0" type="button" id="sepaCountryTag" data-flip="false"
                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span *ngIf="sepaFormFields.selectedCountry!=''">
                                            {{sepaFormFields.selectedCountry}}
                                        </span>

                                        <span *ngIf="sepaFormFields.selectedCountry==''"
                                            class="text-danger">{{'web.mycart_shipping_country_placeholder' |
                                            translate }}</span>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="sepaCountryTag">
                                        <span class="dropdown-item" *ngFor="let country of arrOemCountries"
                                            (click)="changeCountryAndSetExtn(country.countryName, 'sepaForm')">
                                            {{country.countryName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <input class="form-control" [(ngModel)]="sepaFormFields.iBan" #iban="ngModel"
                                    placeholder="{{'web.iban_field' | translate }}*" name="iban"
                                    pattern="^[a-zA-Z0-9]{5,34}$" maxlength="34" required
                                    (ngModelChange)="failureMessage=false;"
                                    [ngClass]="{ 'bg-danger': (iban.touched && iban.invalid) || (sepaForm.submitted && iban.invalid)}">


                                <div class="d-flex errorMsg mt-2">
                                    <span *ngIf="iban.invalid && (iban.dirty || iban.touched) && iban.errors.pattern"
                                        class="text-danger me-2">
                                        {{'web.sepa_iban_pattern_wrong' | translate }}
                                    </span>


                                </div>
                            </div>
                            <div class="col-12">
                                <input type="text" [(ngModel)]="sepaFormFields.bic" #bic="ngModel"
                                    class="form-control mb-0" name="bic" placeholder="{{'web.bic_field' | translate }}*"
                                    pattern="^[a-zA-Z0-9]{8,12}$" maxlength="12" required
                                    (ngModelChange)="failureMessage=false;"
                                    [ngClass]="{ 'bg-danger': (bic.touched && bic.invalid) || (sepaForm.submitted && bic.invalid)}">


                                <div class="d-flex errorMsg mt-2">
                                    <span *ngIf="bic.invalid && (bic.dirty || bic.touched) && bic.errors.pattern"
                                        class="text-danger me-2">
                                        {{'web.sepa_bic_pattern_wrong' | translate }}
                                    </span>


                                </div>
                            </div>
                        </div>

                        <div class="modal-footer border-0 p-0">
                            <div class="d-flex errorMsg mt-2" *ngIf="failure">
                                <!-- <span class="text-danger me-2">
                                    {{failureMessage | translate }}
                                </span> -->
                            </div>
                            <button type="submit" class="btn btn-md w-100 btn-primary mt-3 mb-0" [disabled]="showSepaLoader">
                                {{'web.sepa_pay_submit_cta'| translate}}
                                <span class="position-absolute ms-2" *ngIf="showSepaLoader"><img
                                        src="{{imageIconPath}}small-loader.gif" width="20"></span>
                            </button>
                        </div>

                    </form>
                </div>
            </div>

            <!-- Modal footer -->


        </div>
    </div>
</div>

<!-- End sepa payment -->


<div class="modal fade" id="errorModal" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="static"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header bg-transparent">
                <p class="modal-title my-0" *ngIf="!fromApplyCoupon">{{'web.error_in_order_popup_title' | translate}}
                </p>
                <p class="modal-title my-0" *ngIf="fromApplyCoupon">{{'web.error_in_coupon_popup_title' | translate}}
                </p>
                <img class="d-block mb-0 cursor-pointer closeModal"
                    (click)="closeErrorModal()" src="{{popupCrossCheckmark}}">
            </div>
            <form novalidate (ngSubmit)="closeErrorModal()">
                <div class="modal-body py-3 border-0">
                    <span class="me-2 d-flex stripeerrorMsg mt-2"  *ngIf="!fromApplyCoupon" >
                        {{'web.error_in_order_popup_message'|translate }}
                    </span>
                    <span class="me-2 d-flex stripeerrorMsg mt-2"  *ngIf="fromApplyCoupon" >
                        {{'web.error_in_coupon_popup_message'|translate }}
                    </span>
                    <button type="submit"  class="btn btn-md mt-2 w-100 btn-primary"  *ngIf="!fromApplyCoupon">
                        {{'web.error_in_order_popup_cta'| translate}}
                       
                    </button>
                    <button type="submit"  class="btn btn-md mt-2 w-100 btn-primary"  *ngIf="fromApplyCoupon">
                        {{'web.error_in_coupon_popup_cta'| translate}}
                       
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal payment_popup" [ngStyle]="{'display':display}" role="dialog" data-backdrop="static"
    data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-transparent">
                <p *ngIf="!(refusalReasonMsg) && !(refusalReasonCode === undefined && paymentMethodsLength === 0) && !(isDefinedError && refusalReasonCode) && !(!isDefinedError && refusalReasonCode)">{{'web.ayden_payment_title' | translate}}</p>

                <p *ngIf="refusalReasonMsg">{{refusalReasonMsg}}</p>
                <!-- <p [hidden]="refusalReasonCode != undefined || paymentMethodsLength > 0" class="text-success">
                    {{'web.dropin_payment_option_tit' | translate}}</p> -->
                 
                
                <p *ngIf="refusalReasonCode === undefined && paymentMethodsLength === 0" class="text-success">
                    {{'web.dropin_payment_option_tit' | translate}}</p>

                <ng-container *ngIf="isDefinedError && refusalReasonCode">
                    <p [hidden]="refusalReasonCode != '24'" class="text-danger 1">{{'web.refusal_reason_code_24' |
                        translate}}
                    </p>
                    <p [hidden]="refusalReasonCode != '20'" class="text-danger 2">{{'web.refusal_reason_code_20' |
                        translate}}
                    </p>
                    <p [hidden]="refusalReasonCode != '2'" class="text-danger 3">{{'web.refusal_reason_code_2' |
                        translate}}
                    </p>
                    <p [hidden]="refusalReasonCode != '0'" class="text-danger 4">{{'web.refusal_reason_code_0' |
                        translate}}
                    </p>
                    <p [hidden]="refusalReasonCode != '8'" class="text-danger 5">{{'web.refusal_reason_code_8' |
                        translate}}
                    </p>
                    <p [hidden]="refusalReasonCode != '4'" class="text-danger 6">{{'web.refusal_reason_code_4' |
                        translate}}
                    </p>
                    <p [hidden]="refusalReasonCode != '5'" class="text-danger 7">{{'web.refusal_reason_code_5' |
                        translate}}
                    </p>
                    <p [hidden]="refusalReasonCode != '6'" class="text-danger 8">{{'web.refusal_reason_code_6' |
                        translate}}
                    </p>
                    <p [hidden]="IsPaymentProcessing" class="text-gray 9">{{'web.checkout_loading_text' | translate}}</p>

                </ng-container>

                <ng-container *ngIf="!isDefinedError && refusalReasonCode">
                    <p>{{'web.refusal_reason_code_dropin_other' | translate}}</p>
                </ng-container>


                <span class="closeModal" (click)="closeModal()">
                    <img alt="" title="" src="{{popupCloseIcon}}">
                </span>
            </div>
            <div class="modal-body custom-scroll">
                <div id="dropin" #dropinModal>
                    <!-- Drop-in will be rendered here -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop" [ngStyle]="{'display':display}"></div>
<div id="stripe3dsDiv"></div>


<div class="modal fade" id="paymetMethodInfo" tabindex="-1" aria-labelledby="exampleModalLabel" data-backdrop="true" data-keyboard="true" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header border-0">
          <p class="modal-title">{{'web.payment_method_info_title' | translate}}</p>
          <button type="button" class="close bg-transparent border-0" data-bs-dismiss="modal" aria-label="Close" style="opacity: 1;">
            <img src="{{popupCrossCheckmark}}" alt="closeImg">
          </button>
        </div>
        <div class="modal-body py-0">
            <ng-container *ngIf="languageData && languageData.web">
                <div class="d-flex align-items-start media" *ngIf="languageData.web.payment_visa_title">
                    <img src="{{imageIconPath}}payment_visa.svg" class="me-3" alt="">
                    <div class="media-body">
                    <p class="my-0 fw-bolder fs-12">{{'web.payment_visa_title' | translate}}</p>
                    <span class="fs-12">{{'web.payment_visa_sub_title' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-start media mt-2" *ngIf="languageData.web.payment_mc_title">
                    <img src="{{imageIconPath}}payment_mc.svg" class="me-3" alt="">
                    <div class="media-body">
                    <p class="my-0 fw-bolder fs-12">{{'web.payment_mc_title' | translate}}</p>
                    <span class="fs-12">{{'web.payment_mc_sub_title' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-start media mt-2" *ngIf="languageData.web.payment_maestro_title">
                    <img src="{{imageIconPath}}payment_maestro.svg" class="me-3" alt="">
                    <div class="media-body">
                    <p class="my-0 fw-bolder fs-12">{{'web.payment_maestro_title' | translate}}</p>
                    <span class="fs-12">{{'web.payment_maestro_sub_title' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-start media mt-2" *ngIf="languageData.web.payment_klarna_paynow_title">
                    <img src="{{imageIconPath}}payment_klarna.svg" class="me-3" alt="">
                    <div class="media-body">
                    <p class="my-0 fw-bolder fs-12">{{'web.payment_klarna_paynow_title' | translate}}</p>
                    <span class="fs-12">{{'web.payment_klarna_paynow_sub_title' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-start media mt-2" *ngIf="languageData.web.payment_paypal_title">
                    <img src="{{imageIconPath}}payment_paypal.svg" class="me-3" alt="">
                    <div class="media-body">
                    <p class="my-0 fw-bolder fs-12">{{'web.payment_paypal_title' | translate}}</p>
                    <span class="fs-12">{{'web.payment_paypal._sub_title' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-start media mt-2" *ngIf="languageData.web.payment_sepadirectdebit_title">
                    <img src="{{imageIconPath}}payment_sepadirectdebit.svg" class="me-3" alt="">
                    <div class="media-body">
                    <p class="my-0 fw-bolder fs-12">{{'web.payment_sepadirectdebit_title' | translate}}</p>
                    <span class="fs-12">{{'web.payment_sepadirectdebit_sub_title' | translate}}</span>
                    </div>
                </div>    
                <div class="d-flex align-items-start media mt-2" *ngIf="languageData.web.payment_klarna_title">
                    <img src="{{imageIconPath}}payment_klarna.svg" class="me-3" alt="">
                    <div class="media-body">
                    <p class="my-0 fw-bolder fs-12">{{'web.payment_klarna_title' | translate}}</p>
                    <span class="fs-12">{{'web.payment_klarna_sub_title' | translate}}</span>
                    </div>
                </div>
                <div class="d-flex align-items-start media mt-2" *ngIf="languageData.web.payment_klarna_account_title">
                    <img src="{{imageIconPath}}payment_klarna.svg" class="me-3" alt="">
                    <div class="media-body">
                    <p class="my-0 fw-bolder fs-12">{{'web.payment_klarna_account_title' | translate}}</p>
                    <span class="fs-12">{{'web.payment_klarna_account_sub_title' | translate}}</span>
                    </div>
                </div>        
            </ng-container>
        </div>
        <div class="modal-footer justify-content-center border-0">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="submitAddress()">
            {{'web.payment_method_info_ok_btn' | translate}}
          </button>
        </div>
      </div>
    </div>
</div>
